import React, { useMemo } from "react";
import "./checklist.css";
import {
  IonBadge,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonRow,
} from "@ionic/react";
import Footer from "../../components/Footer/Footer";
import ChecklistNotCreated from '../../components/ChecklistNotCreated/ChecklistNotCreated';

import { ParamsInspection } from "../../Models/ParamsInspection";

import { useParams } from "react-router";
import MenuMobile from "../../components/MenuMobile/MenuMobile";
import ScrollToUp from "../../components/ScrollToUp/ScrollToUp";
import { useEntityUser, useEntityConnectivity, useEntityChecklist } from "../../store";
import { useSendToApproval } from "./useSendToApproval";
import { useResponseChecklist } from "./useResponseChecklist";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { ChecklistTabs, TabPanel } from "./Tabs";
import { ChecklistHeader } from "./ChecklistHeader";
import Question from "./Question/Question";



export interface ChecklistProps {
  showSidebar: boolean;
  handleShowSidebar(): any
}

const Checklist: React.FC<ChecklistProps> = ({ showSidebar = true, handleShowSidebar, children }) => {

  const isOnline = useEntityConnectivity(store => !store.connectivity);
  const [contentRef, scrollToTop] = useScrollToTop()
  const { numberInspection } = useParams<ParamsInspection>();
  const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);

  const {
    valueTab,
    setValueTab
  } = useEntityChecklist();

  const { dataChecklist, isLoading } = useResponseChecklist();

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  const isInvalidQuestion = useMemo(() => {
    if (!dataChecklist || !sendToApproval) return;
    return dataChecklist?.listaTab[valueTab]?.domande.some((el) =>
      (el.idRisposta === -1)
      ||
      (el.visualizzaAggiungi &&
        el.idRisposta === 4 &&
        (el.problemi.length === 0 ||
          el.problemi.some((problema) =>
            !problema.competenza ||
            !problema.stimaCosti ||
            !problema.intervento ||
            !problema.possibiliCause
          )
        )
      )
    );
  }, [dataChecklist, valueTab, sendToApproval]);

  return (
    <>
   
      <MenuMobile disableLink={isOnline} />
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonGrid className="p-0 mb-6">
          <IonRow>
            {children}
            {!dataChecklist ? (
              <ChecklistNotCreated showSidebar={showSidebar} />
            ) : (
              <IonCol
                offsetLg={showSidebar ? "0" : "1"}
                sizeXs="12"
                sizeXl={showSidebar ? "12" : "11"}
                sizeLg={showSidebar ? "12" : "11"}
              >
                <ChecklistHeader showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} dataChecklist={dataChecklist} />
                <IonRow>
                  <IonCol>
                    <ChecklistTabs
                      valueTab={valueTab}
                      onChange={handleChangeTabs}
                      listTab={dataChecklist?.listaTab}
                      isInvalidQuestion={isInvalidQuestion}
                    />
                    {dataChecklist !== undefined &&
                      <TabPanel value={valueTab} index={valueTab} >
                        <IonGrid>
                          {dataChecklist?.listaTab?.[valueTab].domande?.map((question) => {
                            return (
                              <Question
                                valueTab={valueTab}
                                question={question}
                                key={question.idDomanda}
                                stateInspection={dataChecklist?.stato}
                              />
                            );
                          })}
                        </IonGrid>
                      </TabPanel>
                    }
                  </IonCol>
                </IonRow>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
        <Footer />
        <ScrollToUp up={scrollToTop} />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={isLoading}
          message={"Sincronizzazione Data with Server"}
        />
      </IonContent>
    </>
  );


};

export default Checklist;