export const getSessionStorage = async <T> (key: string): Promise< T | undefined> =>{ 
    const item = await sessionStorage.getItem(key);
    return item ? JSON.parse(item) as T : undefined
}
export const getSessionStorageArray = <T>(key: string): T[]  =>{
    const item = sessionStorage.getItem(key)
    return  item ? item?.split("$$$$$")?.map( el => JSON.parse(el)) as T[] : []
}
export const setSessionStorage = async (key: string, value: unknown) => 
    await sessionStorage.setItem(key, JSON.stringify(value));
export const updateSessionStorage = async (key: string, value: unknown) => {
    const item = sessionStorage.getItem(key);
    const prevValue = item ? item + "$$$$$" : '';
    await sessionStorage.setItem(key,  prevValue + JSON.stringify(value))
    
}
export const removeSessionStorage = async (key: string) => await sessionStorage.removeItem(key);