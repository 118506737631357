import { DBSchema } from ".";

export const photoSchema ={
    store: 'photo',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'endpoint', keypath: 'endpoint', options: { unique: false } }
    ]
}
export interface PhotoSchema  extends DBSchema{}