import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { convertGuidToInt } from "../service";
import { Photo } from "../Models/Photo";
import { compress, EImageType } from "image-conversion";
import { dateNowForPhoto } from "../utiles";
import exifr from "exifr";
import { Geoposition } from "@ionic-native/geolocation";

const CONFIG = {
  facingMode: "user",
  width: { ideal: 1920 },
  height: { ideal: 1080 },
};

const QUALITY = 0.8;

type ConfigType = typeof CONFIG;

type CustomFile = File & { lastModifiedDate: Date };
// const iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

// export const isMobile = () => /Android|webOS|iPhone|iPad|MacOs|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || iPad;

export const usePhotoPictureHook = (
  callBackSavePhoto: (photo: Photo) => void,
  location,
  option = CONFIG,
  quality = QUALITY
) => {
  const videoRef = useRef<HTMLDivElement>();
  const cropperRef = useRef<HTMLImageElement>(null);
  const galleryRef = useRef<HTMLInputElement>();
  const actualVideoTrack = useRef<MediaStreamTrack>(null);
  const actualStream = useRef<MediaStream>(null);

  const [video] = useState(document.createElement("video"));
  const [picture, setPicture] = useState<Photo>();
  const [videoConfig, setVideoConfig] = useState<ConfigType>(option);
  const [modify, setModify] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(0);

  const initStream = useCallback(() => {
    if (isMobile()) return;
    if (hasGetUserMedia()) {
      navigator.mediaDevices
        .getUserMedia({ video: { ...videoConfig } })
        .then((stream: MediaStream) => {
          actualStream.current = stream;
          const [videoTrack] = stream.getVideoTracks();
          video.srcObject = stream;
          video.autoplay = true;
          video.controls = false;
          video.playsInline = true;
          video.style.width = "100%";
          actualVideoTrack.current = videoTrack;
        });
    } else {
      alert("I Video non sono supportati dal tuo browser");
    }
  }, [video, videoConfig]);
  const closeStream = useCallback(() => {
    if (isMobile()) return;
    video.srcObject = null;
    actualVideoTrack.current?.stop();
    actualStream.current = null;
  }, [video]);

  useEffect(() => {
    initStream();
    return closeStream;
  }, [closeStream, initStream]);

  const openCamera = useCallback(() => {
    video.srcObject = actualStream.current;
    !videoRef?.current?.hasChildNodes() &&
      videoRef?.current?.appendChild(video);
  }, [video]);
  const closeCamera = useCallback(() => {
    !!video &&
      videoRef?.current?.hasChildNodes() &&
      videoRef?.current?.removeChild(video);
    video.srcObject = null;
  }, [video]);

  const initGalleryUserPicker = useCallback(() => {
    
    const input = document.createElement("input");
    galleryRef.current = input;
    input.type = "file";
    input.accept = "image/x-png,image/jpg, image/jpeg,image/gif,image/webp";
    input.hidden = true;
    input.onchange = upload(
      isMobile() ? callBackSavePhoto : setPicture,
      galleryRef,
      isMobile() ? null : closeCamera,
      location
    );
    galleryRef.current.click();
  }, [callBackSavePhoto, closeCamera, location]);

  const takeGalleryPicture = () => initGalleryUserPicker();

  const changeCamera = useCallback(() => {
    setVideoConfig((prev) => ({
      ...prev,
      facingMode: prev.facingMode === "environment" ? "user" : "environment",
    }));
  }, []);
  const takePicture = useCallback(async () => {
    const canvas = document.createElement("canvas");
    const settings = actualVideoTrack?.current?.getSettings();
    const x = settings?.width * zoom;
    const y = settings?.height * zoom;
    canvas.width = settings?.width - x / 2;
    canvas.height = settings?.height - y / 2;
    canvas
      ?.getContext("2d")
      ?.drawImage(
        video,
        x / 2,
        y / 2,
        settings?.width - x / 2,
        settings?.height - y / 2,
        0,
        0,
        settings.width,
        settings.height
      );
    const webviewPath = canvas.toDataURL("image/jpeg", quality);
    const id = convertGuidToInt(uuidv4());
    const geoloc = location as Geoposition;
    setPicture({
      filepath: `${dateNowForPhoto(new Date())}.jpg`,
      webviewPath,
      date: new Date().toLocaleString("it-IT").replace(/,/g, ""),
      idFoto: id,
      idImmagineProblema: id,
      latitudine: geoloc?.coords?.latitude ?? -1,
      longitudine: geoloc?.coords?.longitude ?? -1,
    });
    closeCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeCamera, quality, video, zoom, !!location]);

  const deletePicture = useCallback(() => {
    setPicture(undefined);
    setModify(false);
    !isMobile() && openCamera();
  }, [openCamera]);

  const changeZoom = (val: number) => {
    if (!video) return;
    video.setAttribute("style", `transform: scale(${val + 1})`);
    setZoom(val);
  };
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const data = cropper.getCroppedCanvas().toDataURL();
    setPicture((prev) => ({ ...prev, webviewPath: data }));
    onModify();
  };
  const onModify = () => {
    if (!picture?.webviewPath) return;
    setModify((m) => !m);
  };
  const handleSavePhoto = () => {
    callBackSavePhoto(picture);
    setPicture(undefined);
    !isMobile() && openCamera();
  };

  return {
    videoRef,
    cropperRef,
    picture,
    openCamera,
    closeCamera,
    changeCamera,
    takePicture,
    deletePicture,
    takeGalleryPicture,
    zoom,
    changeZoom,
    modify,
    onModify,
    onCrop,
    handleSavePhoto,
  };
};
const convert = (myFile: Blob): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    if (fileReader && myFile) {
      fileReader.readAsDataURL(myFile);
      // fileReader.readAsBinaryString(myFile);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    } else {
      reject("No file provided");
    }
  });
const getImg = (compressedFile): Promise<{ width: number; height: number }> =>
  new Promise(async (resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = await convert(compressedFile);
  });

const hasGetUserMedia = () =>
  !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);

const upload =
  (save: (picture: Photo) => void, galleryRef: any, callback: Function, location: Geoposition) =>
  async (): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      if (
        !galleryRef.current ||
        !galleryRef.current.files ||
        galleryRef.current.files.length <= 0
      ) {
        reject("No file selected.");
        return;
      }
      const myFile: CustomFile = galleryRef.current.files[0];
      const size = await getImg(myFile);
      const compressedFile = await compress(myFile, {
        quality: 0.3,
        width: size.width / 2,
        height: size.height / 2,
        type: EImageType.JPEG,
      });
      const myBase64File = await convert(compressedFile);
      const id = convertGuidToInt(uuidv4());
      const date =
        myFile.lastModifiedDate?.toString() ?? myFile.lastModified
          ? new Date(myFile.lastModified)
          : new Date();
      let gps: {
        latitude?: number;
        longitude?: number;
      } = undefined;
      try {
        const res = await exifr.gps(myFile);
        if(!res) {
          gps = {
            latitude: location?.coords?.latitude,
            longitude: location?.coords?.longitude
          }
        } else {
          gps = res
        }
      } catch (error) {
        alert ('extract metadata lib not working!')
      }

      save({
        filepath: `${dateNowForPhoto(date)}.jpg`,
        webviewPath: myBase64File,
        date: date.toLocaleString("it-iT").replace(/,/g, ""),
        idFoto: id,
        idImmagineProblema: id,
        latitudine: gps?.latitude ?? -1,
        longitudine: gps?.longitude ?? -1,
      });
      callback?.();
      resolve();
    });
  };

export const isMobile = () => {
  
  if ("maxTouchPoints" in navigator) {
    return navigator.maxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      return !!mQ.matches;
    } else if ("orientation" in window) {
      return true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const userAgent = (navigator as Navigator).userAgent;
      return (
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent)
      );
    }
  }
};
