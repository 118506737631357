import { IonButton, IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import { FormHelperText, Select } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { AssignFornitoreSelect } from "../../components/AssignFornitoreSelect";
import { AssociateBUSelect } from "../../components/AssociateBUSelect";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomInputEmail from "../../components/CustomInput/CustomInputEmail";
import { ShowIf } from "../../components/ShowIf";
import { useEntityUser } from "../../store";
import { validEmail } from "../../utiles";
import { RoleID } from "../Detail/useResponseDetail";
import { AddUserType } from "./useCRUDUser";
import { useEffect } from "react";

interface FormUserProps {
    submitAddUser(data: AddUserType): void;
    addCancelUser(): void;
    asBu: number | null;
}

export const FormUser = ({ submitAddUser, addCancelUser, asBu }: FormUserProps) => {
    const { user: currentUser } = useEntityUser()
    const { control, formState:{ errors }, handleSubmit, setValue } = useFormContext();
    const selectedUserRole = useWatch({ control, name: 'ruolo' });
    const selectedBuId = useWatch({ control, name: 'businessUnitId' });

    useEffect(() => {
        if(asBu) setValue("businessUnitId", asBu, { shouldDirty: true })
    } , [asBu, setValue])

    function clearFornitore(){
        setValue("fornitoreId", null, { shouldDirty: true });
    }

    function clearBu(newSelectedRole: RoleID){
        if([RoleID.AMM_SIST, RoleID.BME].includes(newSelectedRole)) {
            setValue("businessUnitId", null, { shouldDirty: true });
        }
    }

    return (
        <form onSubmit={handleSubmit(submitAddUser)}>
            <IonGrid className="m-0 mx-3">
                <IonRow>
                    <IonCol>
                        <Controller
                            control={control}
                            name='nome'
                            rules={{ required: true }}
                            render={({ field: { onChange, value, } }) =>
                                <CustomInput
                                    label="Nome"
                                    setValue={onChange}
                                    value={value}
                                    error={!!errors?.nome}
                                />
                            }
                        />
                    </IonCol>
                    <IonCol>
                        <Controller
                            control={control}
                            name='cognome'
                            rules={{ required: true }}
                            render={({ field: { onChange, value, } }) =>
                                <CustomInput
                                    label="Cognome"
                                    setValue={onChange}
                                    value={value}
                                    error={!!errors?.cognome}
                                />
                            } />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Controller
                            defaultValue={''}
                            control={control}
                            name='mail'
                            rules={{
                                required: true,
                                validate: (field) => validEmail.test(field)
                            }}
                            render={({ field: { onChange, value, } }) =>
                                <CustomInputEmail
                                    label="Email"
                                    setValue={onChange}
                                    value={value}
                                    type="email"
                                    id="email"
                                    error={!!errors?.mail}
                                />} />
                    </IonCol>
                </IonRow>
                
                <IonRow>
                    <IonCol>
                        <IonLabel className="label p-0 ion-text-capitalize">Ruolo</IonLabel>
                        <Controller
                            control={control}
                            name='ruolo'
                            rules={{ required: true }}
                            render={({ field: { onChange, value, } }) =>
                                <Select
                                    defaultValue=''
                                    labelId='cambio-ruolo-modale-id'
                                    id="cambio-ruolo-modale"
                                    value={value}
                                    onChange={(event: any) => {
                                        clearFornitore();
                                        clearBu(event.target.value);
                                        onChange(event.target.value)
                                    }}
                                    variant="outlined"
                                    style={{ 'width': '100%', 'marginTop': '0.5rem' }}
                                    error={!!errors?.ruolo}
                                >
                                    <MenuItem value={RoleID.AMM_SIST}>Amministratore di Sistema</MenuItem>
                                    <MenuItem value={RoleID.AMM_BU}>Amministratore Business Unit</MenuItem>
                                    <MenuItem value={RoleID.APPROVATORE}>Approvatore</MenuItem>
                                    <MenuItem value={RoleID.BO}>BackOffice</MenuItem>
                                    <MenuItem value={RoleID.BM}>Building Manager</MenuItem>
                                    <MenuItem value={RoleID.BME}>Building Manager Esterno</MenuItem>
                                    <MenuItem value={RoleID.LETTORE}>Lettore</MenuItem>
                                </Select>
                            } />
                        {!!errors?.ruolo && <FormHelperText style={{ 'color': '#eb445a', 'marginLeft': "14px" }}>Campo obbligatorio</FormHelperText>}
                    </IonCol>
                </IonRow>

                <ShowIf condition={currentUser.profilo === RoleID.AMM_SIST && !asBu && selectedUserRole && ![RoleID.AMM_SIST, RoleID.BME].includes(selectedUserRole)}>
                    <IonRow>
                        <IonCol>
                            <AssociateBUSelect 
                                formName="businessUnitId" 
                                label="BU" 
                                onChangeValue={clearFornitore}
                            />
                        </IonCol>
                    </IonRow>
                </ShowIf>

                <ShowIf condition={selectedUserRole === RoleID.BME}>
                    <IonRow>
                        <IonCol>
                            <AssignFornitoreSelect 
                                formName="fornitoreId" 
                                label="Fornitore" 
                                filterBUId={currentUser.profilo === RoleID.AMM_SIST ? null : currentUser.businessUnitId}
                            />
                        </IonCol>
                    </IonRow>
                </ShowIf>

                <IonRow className="ion-justify-content-end">
                    <IonCol sizeXl="3" className="ion-text-end pe-0">
                        <IonButton expand="block" fill="clear" className="fw-bold ion-text-uppercase" onClick={addCancelUser}>ANNULLA</IonButton>
                    </IonCol>
                    <IonCol sizeXl="3" className="ion-text-end ps-0">
                        <IonButton type="submit" expand="block" fill="solid" className="fw-bold ion-text-uppercase" >AGGIUNGI</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </form>
    )
}
