import React from "react";
import { IonLabel } from "@ionic/react";
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& input": {
                backgroundColor: "white",
                borderWidth: 2
            },
            width: '88%',
            marginTop: "0.5rem"
        },
    }),
);
export interface PropsCustomInputNumber {
    label: string;
    number: number;
    setValue: (newValue: number) => void;
    id?: string;
    disabled?: boolean;
    error?: boolean;
    max: number;
    isInt?: boolean,
}
const CustomInputNumber: React.FC<PropsCustomInputNumber> = ({ label, number, setValue, id, disabled, error, max, isInt = true }) => {
    const classes = useStyles();
    
    const handler = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = +event.target.value;   
        const setValueNumber = value <= max ? value : number;
        setValue(setValueNumber);
    };

    const handleCss = (label: string) => {
        switch (label) {
            case "Posti auto scoperti":
            case "Posti auto coperti":
            case "Box auto coperti":
                return 'w-82 w-xs-82 w-lg-82'
            default:
                return "w-88 w-xs-82"
        }
    };

    return (
        <>
            <IonLabel className="label p-0 ion-text-capitalize">{label}</IonLabel>
            <TextField
                disabled={disabled}
                type="text"
                id={id}
                variant="outlined"
                color="primary"
                className={`${classes.root} ${handleCss(label)}`}
                value={number}
                onChange={handler}
                InputProps={{ 
                    ...isInt ? { inputMode: 'numeric', pattern: '[0-9]*' } : {},
                    inputProps: { 
                        min: "0",
                        max: "100000000000000", 
                        step: "1" 
                    }, 
                }}
                error={error}
                helperText={error ? "Campo obbligatorio" : null}
            />
        </>
    );
}

export default CustomInputNumber;