import { requestPostOptions } from "../crud";
import { BaseUrl } from "../../utiles"
import { Report } from "../../Models/Report";
import { Option } from "../../Models/Checklist";
import { ResponseForApproval } from "../../Models/ResponseForApproval";

export const getReport = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Report/GetReport`, options);
        const response: Report = await promise.json();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const getApprovatori = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Report/GetApprovatori`, options);
        const response: Option[] = await promise.json();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const getExportCosti = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Report/ExportCosti`, options);
        const response = await promise.json();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const sendToApproval = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Report/SendToApproval`, options);
        const response: ResponseForApproval = await promise.json();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const sendToRevision = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Report/SendToRevision`, options);
        const response: ResponseForApproval = await promise.json();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}
export const reject = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Report/Reject`, options);
        if (promise.ok) onSuccess();
    } catch (error) {
        onError(error)
    }
}

export const sendMail = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Mail/SendMail`, options);
        const response: string = await promise.text();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const translateInspectionData = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Translate/TranslateInspectionData`, options);
        const response: string = await promise.text();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const approval = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/report/approval`, options);
        const response: string = await promise.text();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const RegenerateReportOnNewerTemplate = async <TIn>(data: TIn, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<TIn>(data);
    try {
        const promise = await fetch(`${BaseUrl}/report/RegenerateOnNewerTemplate`, options);
        const response: string = await promise.text();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const GetReportDownloadURL = async <TIn,TOut>(data:TIn, onSuccess: Function, onError: Function )  => {
    const options = requestPostOptions<TIn>(data);
    try {
        const promise = await fetch(`${BaseUrl}/report/GetReportDownloadURL`, options);
        const response: TOut =  JSON.parse(await promise.text());
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const nuovaRevisione = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/sopralluoghi/NuovaRevisione`, options);
        const response: string = await promise.text();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}