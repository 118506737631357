import { checklistSchema } from "./checklistSchema";
import { detailSchema } from "./detailSchema";
import { photoSchema } from "./photoSchema";
import { reportSchema } from "./reportSchema";
import { listaIspezioniSchema } from './listaIspezioniSchema';
import { settingsSchema } from "./settingsSchema";
import { homepageSchema } from "./homepage";
export interface DBSchema {
    id: number;
    data: any;
    endpoint: string
}
export const DBConfig = {
    name: 'Prelios',
    version: 8,
    objectStoresMeta: [
        photoSchema,
        reportSchema,
        checklistSchema,
        detailSchema,
        listaIspezioniSchema,
        settingsSchema,
        homepageSchema
    ]
};