import { IonButton, IonCol, IonGrid, IonLabel, IonRow, } from '@ionic/react';
import React, { useEffect, useState } from 'react';
//import { useHistory } from 'react-router-dom';
//import { TextField } from '@material-ui/core';
// components
import SelectSearchable from '../SelectSearchable/SelectSearchable';

//hooks
//import { useResponseNewInspectionFilter } from '../../hooks/useApi';

//models
import { Option } from '../../Models/Checklist';
import { AddInspection } from '../../Models/AddInspection';
import { UserDb } from '../../Models/UserDb';


//function utilies
import { dateNow, initialStateCurrentUser, useStyles } from '../../utiles';
import { Filter } from '../../Models/Filter';
//import { exit } from 'process';
import { DateInput } from '../DateInput';
import { useEntityHomepage } from '../../store';
import { RoleID } from '../../pages/Detail/useResponseDetail';

//base url API
//const BaseUrl: string = process.env.REACT_APP_BASE_URL_API;

export interface ModalProps {
    currentUserParse: UserDb
    CancelButtonInsertInspection: () => void
    addIspezione: (data: AddInspection) => void
    error: string | ""
}

const ModalNewInspection: React.FC<ModalProps> = ({ currentUserParse, CancelButtonInsertInspection,  error, addIspezione }) => {

    const classesDatepicker = useStyles();

    // data for add inspection
    const { dataSopralluoghiOption, assegnatariForFornitore, getAssegnatariFromFornitore, creatori, getCreatoriSopralluogo } = useEntityHomepage(store => store);

    //const { dataSopralluoghiOption } = useResponseNewInspectionFilter(currentUserParse);

    // cambio valore delle tendine
    const [valueModalFund, setValueModalFund] = useState<Option>(null);
    const [valueModalEconomicUnit, setModalValueEconomicUnit] = useState<Option>(null);
    const [valueModalCity, setModalValueCity] = useState<Option>(null);

    const [valueModalCityAddress, setModalValueCityAddress] = useState<Option>(null);

    const setValuesCityAddressFilterFromFund = (valueModalFund: Option) => {
        setValueModalFund(valueModalFund);
    }
    const setValuesCityAddressFilterFromEconomicUnit = (valueModalEconomicUnit: Option) => {
        setModalValueEconomicUnit(valueModalEconomicUnit);
    };
    const setValuesCityAddressFilterFromCity = (valueModalCity: Option) => {
        setModalValueCity(valueModalCity);
    };

    const [newValueState, setNewValueState] = useState<Filter>(null);

    let newValue;


    function loadData() {

        newValue = {
            //...dataSopralluoghiOption,
            listaCittaIndirizzo: filterCittaIndirizzi(dataSopralluoghiOption.listaCittaIndirizzo, valueModalFund, valueModalEconomicUnit, valueModalCity)
        };

        let fondi = new Array();
        let citta = new Array();
        let ue = new Array();
        newValue.listaCittaIndirizzo.forEach(function (x) {
            if (!fondi.includes(x.fondoID))
                fondi.push(x.fondoID);
            if (!citta.includes(x.citta))
                citta.push(x.citta);
            if (!ue.includes(x.unitaEconomica))
                ue.push(x.unitaEconomica);
        });
        newValue.listaCitta = dataSopralluoghiOption.listaCitta.filter(function (x) { return citta.includes(x.id); });
        newValue.listaFondi = dataSopralluoghiOption.listaFondi.filter(function (x) { return fondi.includes(x.id); });
        newValue.listaUnitaEconomica = dataSopralluoghiOption.listaUnitaEconomica.filter(function (x) { return ue.includes(x.id); });



        setvaluesFilteredCityAddress(newValue);

        setNewValueState(newValue);
    }


    // al cambio di una qualunque scatta questo userEffect
    useEffect(() => {
        // ricalcolo i valori
        newValue = {
            //...dataSopralluoghiOption,
            listaCittaIndirizzo: filterCittaIndirizzi(dataSopralluoghiOption.listaCittaIndirizzo, valueModalFund, valueModalEconomicUnit, valueModalCity)
        };

        // valorizzazione tendina indirizzi
        setvaluesFilteredCityAddress(newValue);
        //setModalValueCityAddress(newValue.listaCittaIndirizzo[0]);


        // lista fondi, citta e UE correlate
        let fondi = new Array();
        let citta = new Array();
        let ue = new Array();
        newValue.listaCittaIndirizzo.forEach(function (x) {
            if (!fondi.includes(x.fondoID))
                fondi.push(x.fondoID);
            if (!citta.includes(x.citta))
                citta.push(x.citta);
            if (!ue.includes(x.unitaEconomica))
                ue.push(x.unitaEconomica);
        });
        newValue.listaCitta = dataSopralluoghiOption.listaCitta.filter(function (x) { return citta.includes(x.id); });
        newValue.listaFondi = dataSopralluoghiOption.listaFondi.filter(function (x) { return fondi.includes(x.id); });
        newValue.listaUnitaEconomica = dataSopralluoghiOption.listaUnitaEconomica.filter(function (x) { return ue.includes(x.id); });

        setNewValueState(newValue);

    }, [dataSopralluoghiOption]);

    // aggiornamento tendina indirizzi
    const [valuesFilteredCityAddress, setvaluesFilteredCityAddress] = useState(dataSopralluoghiOption);
    useEffect(() => {
        if (dataSopralluoghiOption !== undefined && dataSopralluoghiOption !== null) {
            //setModalValueCityAddress(dataSopralluoghiOption.listaCittaIndirizzo[0]);
        }
    }, [dataSopralluoghiOption]);

    const filterCittaIndirizzi = (oldCittaIndirizzi: Option[], valueModalFund: Option, valueModalEconomicUnit: Option, valueModalCity: Option) => {

        const newCittaIndirizzi = oldCittaIndirizzi.filter(el =>
            (valueModalCityAddress === null || el.id === valueModalCityAddress.id) &&
            (valueModalFund === null || el.fondoID === valueModalFund.id) &&
            (valueModalEconomicUnit === null || el.unitaEconomica === valueModalEconomicUnit.id) &&
            (valueModalCity === null || el.citta === valueModalCity.id)
        );
        return newCittaIndirizzi;
    }


    // != null perche deve aggiornare quando viene selezionato il valore e non quando viene cancellato
    useEffect(() => {

        if (valueModalFund == null && newValueState != null) { // se vuoto 
            let tmp = newValueState;
            tmp = dataSopralluoghiOption;
            setNewValueState(tmp)
            setvaluesFilteredCityAddress(tmp);

            setModalValueCityAddress(null);
            setValueModalFund(null);
            setModalValueEconomicUnit(null);
            setModalValueCity(null);

            return;
        }
        else {
            loadData();
        }


    }, [valueModalFund]);

    useEffect(() => {
        if (valueModalEconomicUnit == null && newValueState != null) { // se vuoto 
            let tmp = newValueState;
            tmp = dataSopralluoghiOption;
            setNewValueState(tmp)
            setvaluesFilteredCityAddress(tmp);

            setModalValueCityAddress(null);
            setValueModalFund(null);
            setModalValueCity(null);
            return;
        }
        else {
            loadData();
        }

    }, [valueModalEconomicUnit]);


    useEffect(() => {
        if (valueModalCity == null && newValueState != null) { // se vuoto 
            let tmp = newValueState;
            tmp = dataSopralluoghiOption;
            setNewValueState(tmp)
            setvaluesFilteredCityAddress(tmp);

            setModalValueCityAddress(null);
            setValueModalFund(null);
            setModalValueEconomicUnit(null);

            return;
        }
        else {
            loadData();
        }
    }, [valueModalCity]);



    useEffect(() => {
        if (valueModalCityAddress == null && newValueState != null) { // se vuoto
            let tmp = newValueState;
            tmp = dataSopralluoghiOption;
            setNewValueState(tmp)
            setvaluesFilteredCityAddress(tmp);

            setValueModalFund(null);
            setModalValueEconomicUnit(null);
            setModalValueCity(null);

            return;
        }
        else {
            loadData();
            //setValueModalFund(null);
            //setModalValueEconomicUnit(null);
            //setModalValueCity(null);
        }
    }, [valueModalCityAddress]);

    const clearFields = async () => {
        setModalValueCityAddress(null);
        setValueModalFund(null);
        setModalValueEconomicUnit(null);
        setModalValueCity(null);

        // CancelButtonInsertInspection();
        // return;
    }

    //TODO
    // useEffect(() => {
    //     if (!isOpen) {
    //         clearFields();
    //     }
    // }, [isOpen])


    // state iniziale: 0 = default
    const [valueModalProject, setModalValueProject] = useState<Option>(null);
    // state iniziale: l'utente corrente
    const [valueModalUser, setModalValueUser] = useState<Option>(currentUserParse.profilo != RoleID.LETTORE ? initialStateCurrentUser(currentUserParse) : null as Option);
    const [valueModalUserCreate, setModalValueUserCreate] = useState<Option>(currentUserParse.profilo != RoleID.LETTORE ? initialStateCurrentUser(currentUserParse) : null as Option);
    console.log(valueModalUserCreate);
    // state iniziale: la data di oggi
    const [ValueModalData, setModalValueData] = useState(dateNow);

    // cambio data
    const OnChangeCalendarModalDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setModalValueData(newValue);
    }

    // const history = useHistory();
    const OnformSubmitInsertInspection = (): void => {
        console.log(currentUserParse.profilo != RoleID.BO);
        console.log(valueModalUserCreate);
        const data: AddInspection = {
            indirizzoCitta: valueModalCityAddress["id"],
            assegnatario: valueModalUser["id"],
            progetto: Number(valueModalProject["id"]),
            data: ValueModalData,
            idUtente: Number(currentUserParse.id),
            creatore: currentUserParse.profilo != RoleID.BO ? 0 : valueModalUserCreate["id"]
        }

        addIspezione(data)
        
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic ' + base64Encoding(process.env.REACT_APP_API_AUTH) },
        //     body: JSON.stringify(data)
        // };
        // fetch(`${BaseUrl}/Sopralluoghi/InsertSopralluoghi`, requestOptions)
        //     .then(response => response.json())
        //     .then((result: number) => {
        //         if (result > 0) {
        //             let path = `/dati/${valueModalCityAddress.id}/${result}`;
        //             CancelButtonInsertInspection();
        //             setTimeout(() => { history.push(path); }, 1000)
        //         }

        //         else {
        //             setError("Errore durante la creazione del sopralluogo");
        //         }
        //     })
        //     .catch((err) => {
        //         setError("Errore durante la creazione del sopralluogo");
        //         console.error(err)
        //     })

    }

    function handleChangeProject(value: any) {
        setModalValueProject(value);
        setModalValueUser(currentUserParse.profilo != RoleID.LETTORE ? initialStateCurrentUser(currentUserParse) : null as Option);
        setModalValueUserCreate(currentUserParse.profilo != RoleID.LETTORE ? initialStateCurrentUser(currentUserParse) : null as Option);
        if(value) getAssegnatariFromFornitore(Number(currentUserParse.id), Number(value["id"]));
        if(value) getCreatoriSopralluogo(Number(currentUserParse.id));
    }


    return (
        <IonGrid className="m-0 px-3" >
            <IonRow>
                <IonCol>
                    {dataSopralluoghiOption.listaFondi && (
                        <SelectSearchable
                            value={valueModalFund}
                            setValue={setValuesCityAddressFilterFromFund}
                            options={valuesFilteredCityAddress.listaFondi}
                            label="Fondo/cliente"
                        />
                    )}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeXl="12" sizeLg="12" sizeMd="12">
                    {dataSopralluoghiOption.listaUnitaEconomica && (
                        <SelectSearchable
                            value={valueModalEconomicUnit}
                            setValue={setValuesCityAddressFilterFromEconomicUnit}
                            options={valuesFilteredCityAddress.listaUnitaEconomica}
                            label="Sede Tecnica/UE"
                        />
                    )}
                </IonCol>
                <IonCol sizeXs="12" sizeXl="12" sizeLg="12" sizeMd="12">
                    {dataSopralluoghiOption.listaCitta && (
                        <SelectSearchable
                            value={valueModalCity}
                            setValue={setValuesCityAddressFilterFromCity}
                            options={valuesFilteredCityAddress.listaCitta}
                            label="Città"
                        />
                    )}
                </IonCol>
            </IonRow>
            <hr />
            <IonRow>
                <IonCol>
                    <SelectSearchable
                        value={valueModalCityAddress}
                        setValue={setModalValueCityAddress}
                        options={valuesFilteredCityAddress.listaCittaIndirizzo}
                        label="Seleziona immobile"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    {!!dataSopralluoghiOption.listaProgetti.length && (
                        <SelectSearchable
                            value={valueModalProject}
                            setValue={handleChangeProject}
                            options={dataSopralluoghiOption.listaProgetti}
                            label="Progetto"
                        />
                    )}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <SelectSearchable
                        disabled={!valueModalProject}
                        value={valueModalUser}
                        setValue={setModalValueUser}
                        options={assegnatariForFornitore}
                        label="Assegnatario"
                        disablePortal={false}
                    />
                </IonCol>
            </IonRow>
            { currentUserParse.profilo == RoleID.BO && (                 
                <IonRow>
                    <IonCol>
                        <SelectSearchable
                            disabled={!valueModalProject}
                            value={valueModalUserCreate}
                            setValue={setModalValueUserCreate}
                            options={creatori}
                            label="BM Responsabile"
                            disablePortal={false}
                        />
                    </IonCol>
                </IonRow>
            )}
            <IonRow>
                <IonCol>
                    <IonLabel className="label p-0 ion-text-capitalize">Seleziona Data</IonLabel>
                    <DateInput
                        id="date"
                        type="date"
                        defaultValue={ValueModalData}
                        className={classesDatepicker.textField}
                        variant="outlined"
                        onChange={OnChangeCalendarModalDate}
                    />
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end">
                <IonCol sizeXl="3" className="ion-text-end pe-0">
                    <IonButton className="ion-text-uppercase" expand="block" fill="clear" onClick={CancelButtonInsertInspection}>annulla</IonButton>
                </IonCol>
                <IonCol sizeXl="3" className="ion-text-end ps-0">
                    <IonButton
                        className="ion-text-uppercase"
                        disabled={!valueModalCityAddress
                            || valueModalCityAddress.id === 0
                            || !ValueModalData
                            || !valueModalUser
                            || valueModalUser.id === 0
                            || !valueModalFund
                            || !valueModalEconomicUnit
                            || !valueModalCity
                            || (!valueModalUserCreate && currentUserParse.profilo == RoleID.BO)
                            || ((valueModalUserCreate?.id ?? 0) === 0 && currentUserParse.profilo == RoleID.BO)
                        }
                        expand="block"
                        onClick={OnformSubmitInsertInspection}>
                        aggiungi
                    </IonButton>
                </IonCol>
                <p style={{ color: "red" }}>{error}</p>
            </IonRow>
        </IonGrid>
    );
}

export default ModalNewInspection;