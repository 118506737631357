import React, { Fragment, useMemo } from 'react'
import { IonCol, IonFabButton, IonIcon, IonFab, IonRow } from '@ionic/react';
import { Slide } from "react-slideshow-image";
import { addOutline, trashOutline } from 'ionicons/icons';
import { GeolocalizationPermitEnum, Photo } from '../../../Models/Photo';
import { useSendToApproval } from '../useSendToApproval';
import { useParams } from 'react-router';
import { ParamsInspection } from '../../../Models/ParamsInspection';
import { useEntityChecklist } from '../../../store';


interface PhotoViewProps {
    photos: Photo[],
    errorPhoto: boolean,
    handleTakePhoto: () => void,
    setPhotoToDelete: Function,
    requisitoFoto: boolean
}
export const PhotoView = ({
    photos,
    errorPhoto,
    handleTakePhoto,
    setPhotoToDelete,
    requisitoFoto = true
}: PhotoViewProps) => {
    
    const { numberInspection} = useParams<ParamsInspection>();
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);
    const {
        dataChecklist, 
    } = useEntityChecklist(({
        dataChecklist,   }) =>
        ({ dataChecklist  })
    );

    const PhotoSlides = useMemo(() =>
        photos?.length 
            ?   photos?.map((photo) => 
                    <div key={photo.idImmagineProblema} className="each-slide"  >
                        <div style={{ backgroundImage: `url(${photo.webviewPath})` }} >
                            <IonFabButton
                                disabled={
                                    dataChecklist?.stato === "Approvato" ||
                                    dataChecklist?.stato === "In Approvazione" 
                                }
                                onClick={() => setPhotoToDelete(photo)}
                                color="danger"
                                className="mt-1 ms-2"
                            >
                                <IonIcon
                                    icon={trashOutline}
                                    style={{ fontSize: "2rem" }}
                                />
                            </IonFabButton>
                        </div>
                    </div>)
            : <div className="each-slide">
                <IonFabButton
                    className="button-center"
                    color="light"
                    onClick={handleTakePhoto}
                >
                    <IonIcon icon={addOutline} style={{ fontSize: "2rem" }} />
                </IonFabButton>
            </div>
    ,[dataChecklist?.stato, handleTakePhoto, photos, setPhotoToDelete])

    return (


        <IonCol
            sizeLg="5"
            sizeSm="12"
            sizeXs="12"
            className={
                ((sendToApproval && !photos?.length) || errorPhoto) && requisitoFoto
                    ? "box-photo-error"
                    : "box-photo"
            }
        >
            {
                !!photos?.length && photos.length > 1 
                        ?  <Slide arrows={false} indicators={true} easing='ease'>{PhotoSlides}</Slide> 
                    :   PhotoSlides 
            }
   
            {
                !!photos?.length && photos?.length < 3
                && (
                    <IonFab vertical="top" horizontal="end">
                        <IonFabButton
                            className="mt-1 ms-2"
                            color="primary"
                            onClick={handleTakePhoto}
                        >
                            <IonIcon icon={addOutline} className="fs-1" />
                        </IonFabButton>
                    </IonFab>
                )
            }
            {
                !photos?.length && <p className="text-problematic-center">Aggiungi foto</p>}
            {
                requisitoFoto &&
                (((sendToApproval && !photos?.length)) || errorPhoto)
                && (
                    <p className="text-danger mt-1 mx-8 fs-7 relative-required-field">
                        Campo obbligatorio
                    </p>
            )}
        </IonCol>

    )
}
