import { MutableRefObject, useState } from "react";
import { useParams } from "react-router";
import { useForm, useWatch } from "react-hook-form";
import { useIndexedDB } from "react-indexed-db";
import { v4 as uuidv4 } from "uuid";
import { Geoposition } from "@ionic-native/geolocation";
import {
  Option,
  OptionProblematic,
  OptionQuestion,
  ProblematicFormModel,
} from "../../../Models/Checklist";
import { Photo } from "../../../Models/Photo";
import {
  ENDPOINT_CHECKLIST,
  findMoveProblematicFromDB,
  findUpdatedProblematicFromDB,
  insertProblematic,
  moveProblematic,
  updateProblematic,
} from "../../../service/api";
import { useEntityChecklist, useEntityConnectivity } from "../../../store";
import { EmptyRecAudio } from "../../../TypesUtiles";
import { convertGuidToInt } from "../../../service";
import { ParamsInspection } from "../../../Models/ParamsInspection";
import { CameraProps } from "../../../components/CameraCustom/CameraCustom";
import { UserDb } from "../../../Models/UserDb";

interface ProblematicHookType {
  cameraRef: MutableRefObject<CameraProps>;
  setCallFetchGetChecklist: Function;
  question: any;
  currentUserParse: UserDb;
  audio: {
    saveAudioBase64: string;
    setAudioDetails: any;
    audioDetails: any;
  };
  geolocation: Geoposition | undefined;
}
export const useProblematicFormHook = ({
  cameraRef,
  setCallFetchGetChecklist,
  question,
  currentUserParse,
  audio: { saveAudioBase64, setAudioDetails, audioDetails },
  geolocation,
}: ProblematicHookType) => {
  const { numberInspection } = useParams<ParamsInspection>();
  const { connectivity: isOnline } = useEntityConnectivity();
  const { moveProblematicTo, addProblematicTo, updateProblematicTo } =
    useEntityChecklist(
      ({ moveProblematicTo, addProblematicTo, updateProblematicTo }) => ({
        moveProblematicTo,
        addProblematicTo,
        updateProblematicTo,
      })
    );

  const { getAll, add, update } = useIndexedDB("checklist");

  const [modalProblematic, setModalProblematic] = useState(false);
  const [requisitoFotoState, setrequisitoFoto] = useState(true);

  const methods = useForm<ProblematicFormModel>({
    defaultValues: {
      listaImmaginiProblemi: [] as Photo[],
      immagineUrl: "",
    },
  });
  const photos = useWatch({
    name: "listaImmaginiProblemi",
    control: methods.control,
  });
  const recordedAudio = useWatch({ name: "audio", control: methods.control });

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [errorPhoto, setErrorPhoto] = useState<boolean>(false);

  const [errorRecAudio, setErrorRecAudio] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [probIsEqual, setProbIsEqual] = useState<boolean>(false);
  const [probIsMoved, setProbIsMoved] = useState<boolean>(false);
  const [photoToDelete, setPhotoToDelete] = useState<Photo>();
  const [dataMoveProblematic, setdataMoveProblematic] =
    useState<OptionProblematic>();
  const [modalMoveProblematic, setModalMoveProblematic] =
    useState<boolean>(false);
  const [openCamera, setOpenCamera] = useState(false);

  const deleteDataFromModal = () => {
    setErrorPhoto(false);
    setErrorRecAudio(false);
    methods.reset({});
  };
  const handleOnDidDismiss = () => {
    deleteDataFromModal();
    setModalProblematic(false);
  };
  const cancelButton = () => {
    deleteDataFromModal();
    setModalProblematic(false);
  };

  const onSubmitProblematic = async (data: ProblematicFormModel) => {
    console.log(question);

    if (!photos?.length && question.requisitoFoto) {
      setErrorPhoto(true);
      return;
    }

    setErrorPhoto(false);
    setShowLoading(true);

    const dataProblematic = {
      idProblema: convertGuidToInt(uuidv4()),
      idUtente: +currentUserParse.id,
      idDomanda: question.idDomanda,
      ...data,
      latitudine: geolocation?.coords?.latitude ?? -1,
      longitudine: geolocation?.coords?.longitude ?? -1,
      descrizioneAudioUrl: saveAudioBase64 || "",
    };

    const onSuccess = () => {
      methods.reset({});
      setModalProblematic(false);
      setShowLoading(false);
      setCallFetchGetChecklist();
      setAudioDetails(EmptyRecAudio);
    };
    const onError = (error) => {
      setModalProblematic(false);
      setShowLoading(false);
    };
    data?.idProblema
      ? updateProblematicForm(dataProblematic, onSuccess, onError)
      : addProblematic(dataProblematic, onSuccess, onError);
  };
  const addProblematic = (
    dataProblematic: ProblematicFormModel,
    onSuccess,
    onError
  ) => {
    isOnline
      ? insertProblematic(dataProblematic, onSuccess, onError)
      : insertProblematicOffline(dataProblematic);
  };
  const updateProblematicForm = (
    dataProblematic: ProblematicFormModel,
    onSuccess,
    onError
  ) => {
    isOnline
      ? updateProblematic(dataProblematic, onSuccess, onError)
      : updateProblematicOffline(dataProblematic);
  };
  const updateProblematicOffline = async (data: ProblematicFormModel) => {
    const record = await findUpdatedProblematicFromDB(getAll, data.idProblema);
    if (record) {
      update({ ...record, data });
    } else {
      add({ endpoint: ENDPOINT_CHECKLIST.update, data });
    }
    updateProblematicTo({ idDomanda: question.idDomanda, ...data });
    setShowLoading(false);
    setModalProblematic(false);
  };
  const onSubmitMoveProblematic = async (
    questValue: Option,
    tabValue: Option
  ) => {
    if (questValue.id === question.idDomanda) {
      setProbIsEqual(true);
      return;
    }
    const {
      idDomanda,
      descrizioneAudioUrl,
      tipologiaInterventoID,
      livelloInterventoID,
      competenzaID,
      progressivo,
      ...problematic
    } = dataMoveProblematic;

    const data = {
      ...problematic,
      tipologiaIntervento: tipologiaInterventoID,
      livelloIntervento: livelloInterventoID,
      competenza: competenzaID,
      // others
      idDomandaDestinazione: questValue.id,
      idUtente: +currentUserParse.id,
      idDomanda: question.idDomanda,
    };

    const onSuccess = () => {
      setProbIsMoved(true);
      setCallFetchGetChecklist();
    };

    isOnline
      ? moveProblematic(data, onSuccess, console.error)
      : moveProblematicOffline(data, tabValue);

    setModalMoveProblematic(false);
  };
  const moveProblematicOffline = async (data, tabTo) => {
    const record = await findMoveProblematicFromDB(getAll, data.idProblema);
    if (record) {
      update({
        id: record.id,
        endpoint: ENDPOINT_CHECKLIST.moveProblematic,
        data: { idIspezione: +numberInspection, ...record.data, ...data },
      });
    } else {
      add({ endpoint: ENDPOINT_CHECKLIST.moveProblematic, data });
    }
    const { idDomandaDestinazione, idDomanda } = data;
    moveProblematicTo({
      tabTo,
      idDomandaDestinazione,
      idDomanda,
      ...dataMoveProblematic,
    });
    setModalMoveProblematic(false);
    setProbIsMoved(true);
  };
  const insertProblematicOffline = async (data: ProblematicFormModel) => {
    add({ endpoint: ENDPOINT_CHECKLIST.insert, data });
    methods.reset({});
    setModalProblematic(false);
    setShowLoading(false);
    setAudioDetails(EmptyRecAudio);
    addProblematicTo(data);
  };
  const handleShowModalMoveProblematic = (idProblema: number) => {
    const dataModal = question?.problemi.find(
      (el) => el.idProblema === idProblema
    );
    setdataMoveProblematic(dataModal);
    setModalMoveProblematic((move) => !move);
  };
  const handleShowModalModificationProblematic = (idProblemaToFind: number) => {
    const {
      idDomanda,
      descrizioneAudioUrl,
      tipologiaInterventoID,
      livelloInterventoID,
      competenzaID,
      progressivo,
      tipologiaIntervento,
      livelloIntervento,
      requisitoFoto,
      ...problematic
    } = (question as OptionQuestion)?.problemi.find(
      (el) => el.idProblema === idProblemaToFind
    );

    console.log(question);
    console.log(question?.requisitoFoto ?? false);

    setModalProblematic((isActive) => !isActive);
    setrequisitoFoto(question?.requisitoFoto ?? false);

    const modifyForm = {
      ...problematic,
      livelloIntervento: livelloInterventoID,
      tipologiaIntervento: tipologiaInterventoID,
      competenza: competenzaID,
      audio: descrizioneAudioUrl,
    };
    methods.reset(modifyForm);
    // getLocation();
  };
  const onSavePhoto = (data: Photo) => {
    const form = methods.watch();
    methods.setValue("listaImmaginiProblemi", [
      ...(form.listaImmaginiProblemi || []),
      {
        ...data,
        latitudine: geolocation?.coords?.latitude ?? -1,
        longitudine: geolocation?.coords?.longitude ?? -1,
      },
    ]);
    !form.immagineUrl && methods.setValue("immagineUrl", data.webviewPath);
    cameraRef?.current?.deletePicture();
    cameraRef?.current?.onCloseModal();
  };
  return {
    showLoading,
    setShowLoading,
    probIsMoved,
    setProbIsMoved,
    probIsEqual,
    setProbIsEqual,
    modalProblematic,
    setModalProblematic,
    modalMoveProblematic,
    setModalMoveProblematic,
    onSubmitMoveProblematic,
    handleShowModalMoveProblematic,
    photos,
    errorPhoto,
    photoToDelete,
    setPhotoToDelete,
    openCamera,
    setOpenCamera,
    errorRecAudio,
    deleteDataFromModal,
    handleOnDidDismiss,
    cancelButton,
    showDelete,
    setShowDelete,
    onSubmitProblematic,
    handleShowModalModificationProblematic,
    methods,
    onSavePhoto,
    recordedAudio,
    requisitoFotoState,
    setrequisitoFoto
  };
};
