import { IonLabel } from "@ionic/react";
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& input": {
                backgroundColor: "white",
                borderWidth: 2
            },
            verticalAlign: 'unset !important',
        },
    })
);


export interface CustomInputProps {
    label?: string;
    value: string;
    setValue: (event: string) => void;
    type?: string;
    id?: string;
    error?: boolean;
    disabled?: boolean;
    className?: string;
}
const CustomInput: React.FC<CustomInputProps> = ({ label, value, setValue, type, id, error, disabled, className }) => {

    const classes = useStyles();


    const handleCss = (label: string) => {
        switch (label) {
            case "Tipologia Vigilanza":
                return "d-inline-flex w-88";
            case "Descrizione":
            case "Manutenzione periodica":
            case "Contratti manutenzione":
            case "Struttura":
            case "Copertura":
            case "Terrazze/logge/balconi":
            case "Aree esterne":
            case "Facciate":
                return 'w-94 w-lg-94 w-xs-82'
            case "Denominazione":
            case "Documentazione":
                return 'w-88'
            case "Studio/Società":
            case "Nome e Cognome":
            case "Indirizzo":
            case "mail":
            case "Tel.":
                return 'w-94 w-lg-94 w-xs-82'
            case "Presenza cartello con recapiti":
            case "Contatto":
                return 'w-88'
        }
    };


    return (
        <>
            <IonLabel className={`label p-0 ion-text-capitalize`}>{label}</IonLabel>
            <TextField
                disabled={disabled}
                fullWidth
                error={error}
                helperText={error ? "Campo obbligatorio" : null}
                type={type}
                id={id}
                variant="outlined"
                color="primary"
                className={`mt-2 ${handleCss(label)} ${classes.root} ${className}`}
                value={value ?? ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue(event.target.value)
                }}
            />
        </>
    );
}

export default CustomInput;