import { IonIcon } from "@ionic/react";
import { cameraOutline, checkmarkOutline, closeOutline, cutOutline, imageOutline, pencilOutline, repeatOutline, returnUpBackOutline, trashOutline } from "ionicons/icons";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Cropper } from "react-cropper";
import { isMobile, usePhotoPictureHook } from "../../hooks/usePhotoPictureHook";
import { Photo } from '../../Models/Photo';
import './camera.css'
import { Geoposition } from "@ionic-native/geolocation";


interface Props {
    callBackSavePhoto?(photo: Photo): void,
    location?: Geoposition | undefined
}

export interface CameraProps {
    openCamera(): void
    closeCamera(): void
    deletePicture(): void
    onCloseModal(): void
    onOpenModal(): void
}

const CameraCustom = forwardRef<CameraProps, Props>(({
    callBackSavePhoto,
    location
}, ref) => {

    const {
        videoRef,
        cropperRef,
        picture,
        openCamera,
        closeCamera,
        changeCamera,
        takePicture,
        deletePicture,
        takeGalleryPicture,
        zoom,
        changeZoom,
        modify,
        onModify,
        onCrop,
        handleSavePhoto
    } = usePhotoPictureHook(callBackSavePhoto, location);

    const [isOpenCamera, setIsOpenCamera] = useState<boolean>(false);

    const onCloseModal = useCallback(() => {
        if (!isMobile()) setIsOpenCamera(false);
        closeCamera();
    }, [closeCamera]);
    const onOpenModal = useCallback(() => {
        !isMobile() && setIsOpenCamera(true);
        isMobile()
            ? takeGalleryPicture()
            : openCamera()
    }, [openCamera, takeGalleryPicture]);

    useImperativeHandle(ref, () => ({
        openCamera,
        closeCamera,
        deletePicture,
        onCloseModal,
        onOpenModal
    }))

    // TODO: why not full screen????
    // TODO: work on positioning buttons css
    return (

        <div className={`fakeBackdrop ${isOpenCamera ? "show" : ""}`}>
            <div className="fakeDialog">

                {/* {isOpenCamera ?  */}
                <div className='camera-content'>
                    <div className={`camera showCamera`} ref={videoRef}></div>
                    {/* <div className="intestazioneModal">
                        <IonIcon icon={closeOutline} color="#ffffff" className="pointer fs-4" onClick={() => { onCancelCamera().finally(() => setIsOpenCamera(false)); }} />
                    </div> */}
                    {!modify
                        ? <img className='picturePreview' src={picture?.webviewPath} alt="" />
                        : <Cropper
                            // style={{ maxWidth: '100%', maxHeight:'100%' }}
                            ref={cropperRef}
                            initialAspectRatio={1}
                            src={picture?.webviewPath}
                            viewMode={2}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={0}
                            checkOrientation={true}
                            rotatable={true}
                            guides={true}
                        />}
                    {picture?.webviewPath === undefined ?
                        //SHOOTING
                        <>
                            <div className='cameraBottom'>
                                <button className='camera shoot' type='button' onClick={takeGalleryPicture}>
                                    <IonIcon icon={imageOutline} color="secondary" className="pointer fs-4" />
                                </button>
                                <button className='camera shoot' type='button' onClick={takePicture} disabled={!!picture}>
                                    <IonIcon icon={cameraOutline} color="secondary" className="pointer fs-4" />
                                </button>
                                {/* ismobile */}
                                <button className='camera shoot' type='button' onClick={changeCamera}>
                                    <IonIcon icon={repeatOutline} color="secondary" className="pointer fs-4" />
                                </button>
                            </div>
                            <div className='cameraTop end'>
                                <button className='exit shoot' type='button' onClick={() => { closeCamera(); setIsOpenCamera(false) }}>
                                    <IonIcon icon={closeOutline} color="secondary" className="pointer fs-4" />
                                </button>
                            </div>
                            <div className="cameraRight">
                                <input className="rangeZoom" min="0" max="0.7" defaultValue={zoom} onChange={(e) => changeZoom(parseFloat(e.target.value))} step="0.1" type="range"></input>
                            </div>
                        </>
                        :
                        picture?.webviewPath !== undefined ?
                            //EDIT
                            <>
                                {modify ? <div className='cameraTop'>
                                    {/* ANNULLA */}
                                    <button className='camera shoot' type='button' onClick={onModify}>
                                        <IonIcon icon={returnUpBackOutline} color="secondary" className="pointer fs-4" />
                                    </button>
                                    {/* CROP */}
                                    <button className='camera shoot' type='button' onClick={onCrop}>
                                        <IonIcon icon={cutOutline} color="secondary" className="pointer fs-4" />
                                    </button>
                                    {/* ROTATE */}
                                    {/* <button className='camera shoot' type='button' onClick={onRotate}>
                                        <IonIcon icon={cutOutline} color="secondary" className="pointer fs-4" />
                                    </button> */}
                                </div> :
                                    //ABILITA MODIFICA
                                    <div className='cameraTop end'>

                                        <button className='camera shoot' type='button' onClick={onModify}>
                                            <IonIcon icon={pencilOutline} color="secondary" className="pointer fs-4" />
                                        </button>
                                    </div>
                                }
                                {!modify ?
                                    //DELETE-SAVE
                                    <div className='cameraBottom '>
                                        <button className='camera deletePicture' type='button' onClick={deletePicture}>
                                            <IonIcon icon={trashOutline} color="white" className="pointer fs-4" />
                                        </button>
                                        <button className='camera savePicture' type='button' onClick={handleSavePhoto}>
                                            <IonIcon icon={checkmarkOutline} color="white" className="pointer fs-4" />
                                        </button>
                                    </div>
                                    : null}
                            </> : null}
                </div>
            </div>
        </div>
    )
})


export default CameraCustom