import { UserDb } from '../Models/UserDb';
import { persist } from 'zustand/middleware'
import create from 'zustand'
interface UserDBModel {
    user: UserDb | undefined;
    setUser: (user: UserDb) => void;
    resetUser: ()=> void;
}
export const useEntityUser = create<UserDBModel>(
    persist(
        (set, get) => ({
            user: undefined,
            setUser: (user: UserDb) => set(()=>({ user })),
            resetUser: () => set(()=>({user: undefined}))
        }),
        {
            name: 'user', // unique name
            getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
        }
    )
)