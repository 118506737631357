export const LIV_INTERVENTO = [
    { id: 1, nome: "Messa in sicurezza" },
    { id: 2, nome: "Mantenimento valore asset" },
    { id: 3, nome: "Accrescimento valore asset" },
]
export const T_INTERVENTO = [
    { id: 1, nome: "Manutenzione ordinaria" },
    { id: 2, nome: "Straordinaria" },
    { id: 3, nome: "Capex" },
    { id: 4, nome: "Rivalsabile" },
]
export const COMPENTANCE = [
    { id: 1, nome: "Proprietà" },
    { id: 2, nome: "Condominio" },
    { id: 3, nome: "Tenant" },
]