import React, { useRef, useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { FormProvider } from "react-hook-form";

//components
import ChildrenRecAudio from "../../../components/Modal/ChildrenModalRecAudio";
import ModalMoveProblematic from "./ModalMoveProblematic";
import Modal from "../../../components/Modal/Modal";
//icons
import { addOutline } from "ionicons/icons";
//models
import { useRecAudio } from "../../../hooks/useRecAudio";
import { NoteAudio } from "../../../Models/NoteAudio";
import { OptionQuestion } from "../../../Models/Checklist";
import { useProblematicHook } from "./useProblematicHook";
import { useProblematicFormHook } from "./useProblematicFormHook";
import { useGeolocation } from "../../../hooks/useGeolocation";
import { Problematic } from "./Problematic";
import { AlertDelete } from "./AlertDelete";
import { PhotoView } from "./PhotoView";
import { ProblematicForm } from "./ProblematicForm";
import CameraCustom, {
  CameraProps,
} from "../../../components/CameraCustom/CameraCustom";
import ChildrenModalDelete from "../../../components/Modal/ChildrenModalDelete";
import { RoleID } from "../../Detail/useResponseDetail";

export interface ProblematicProps {
  // tabList: OptionListTab[];
  question: OptionQuestion;
  stateInspection: string;
}

const ProblematicWrapper: React.FC<ProblematicProps> = ({
  question,
  stateInspection,
}) => {
  const cameraRef = useRef<CameraProps>(null);

  const {
    setIdProblematic,
    currentUserParse,
    modalDelete,
    setModalDelete,
    setCallFetchGetChecklist,
    cancelProblematic,
  } = useProblematicHook(question);

  const {
    loadingGeolocation,
    setLoadingGeolocation,
    errorGeolocation,
    setErrorGeolocation,
    geolocation,
  } = useGeolocation();

  const {
    audioDetails,
    handleAudioStop,
    handleReset,
    saveAudioBase64,
    setAudioDetails,
  } = useRecAudio();
  const {
    showLoading,
    setShowLoading,
    probIsMoved,
    setProbIsMoved,
    probIsEqual,
    setProbIsEqual,
    modalMoveProblematic,
    setModalMoveProblematic,
    modalProblematic,
    setModalProblematic,
    onSubmitMoveProblematic,
    handleShowModalMoveProblematic,
    errorPhoto,
    photoToDelete,
    setPhotoToDelete,
    errorRecAudio,
    handleOnDidDismiss,
    cancelButton,
    showDelete,
    setShowDelete,
    onSubmitProblematic,
    handleShowModalModificationProblematic,
    methods,
    onSavePhoto,
    photos,
    recordedAudio,
    requisitoFotoState,
    setrequisitoFoto
  } = useProblematicFormHook({
    cameraRef,
    setCallFetchGetChecklist,
    question,
    currentUserParse,
    audio: { saveAudioBase64, setAudioDetails, audioDetails },
    geolocation: geolocation?.position,
  });

  const handleModalProblematic = (question: OptionQuestion) => {
    setrequisitoFoto(question.requisitoFoto)
    setModalProblematic(!modalProblematic);
  };

  const [showMessageSave, setShowMessageSave] = useState(false);

  const handleModalDelete = (idProblema: number) => {
    setIdProblematic(idProblema);
    setModalDelete(true);
  };

  const [modalRecAudio, setModalRecAudio] = useState(false);
  const handleOpenRecMic = () => setModalRecAudio(!modalRecAudio);
  const onOpenModal = async () => {
    cameraRef?.current?.onOpenModal();
  };
  const handleDeletePhoto = () => {
    methods.setValue(
      "listaImmaginiProblemi",
      photos.filter(
        (photo) => photoToDelete.idImmagineProblema !== photo.idImmagineProblema
      )
    );
    setPhotoToDelete(undefined);
  };
  const handleResetAudio = () => {
    methods.resetField("audio");
    handleReset();
  };
  console.log(stateInspection);
  console.log(currentUserParse);
  return (
    <>
      {question.visualizzaAggiungi && (
        <IonRow className="ion-justify-content-end">
          <IonCol size="2" className="pb-0">
            {(stateInspection === "In corso" ||
              stateInspection === "Creato" ||
              stateInspection === "Revisionato" ||
              stateInspection === "Respinto") &&
              currentUserParse?.profilo !== RoleID.LETTORE 
              && (currentUserParse.profilo === RoleID.BME ? stateInspection === "In corso" : true)
              && (
                <IonButton
                  className="ion-text-uppercase ion-float-right fw-bold"
                  fill="clear"
                  onClick={() => handleModalProblematic(question)}
                  expand="block"
                >
                  <IonIcon slot="start" icon={addOutline} />
                  aggiungi
                </IonButton>
              )}
          </IonCol>
        </IonRow>
      )}
      {!!question?.problemi?.length &&
        question?.problemi.map((el) => {
          return (
            <Problematic
              key={el.idProblema}
              el={el}
              stateInspection={stateInspection}
              handleModalDelete={handleModalDelete}
              handleShowModalModificationProblematic={
                handleShowModalModificationProblematic
              }
              handleShowModalMoveProblematic={handleShowModalMoveProblematic}
            />
          );
        })}
      <AlertDelete
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        cancelProblematic={cancelProblematic}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Salvataggio in corso..."}
      />
      {/* <IonLoading
        isOpen={loadingGeolocation}
        onDidDismiss={() => setLoadingGeolocation(false)}
        message={"Recupero della posizione..."}
      /> */}
      <IonToast
        isOpen={errorGeolocation.showError}
        onDidDismiss={() =>
          setErrorGeolocation({ message: "", showError: false })
        }
        message={errorGeolocation.message}
        duration={3000}
      />
      {/* model add problematica */}
      <Modal
        isOpen={modalProblematic}
        title={"Aggiungi problematica"}
        onIsOpenChange={handleOnDidDismiss}
        handleOpenRecMic={handleOpenRecMic}
        errorRecAudio={errorRecAudio}
      >
        <IonGrid className="m-0 mx-3">
          <CameraCustom
            ref={cameraRef}
            callBackSavePhoto={onSavePhoto}
            location={geolocation?.position}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmitProblematic)}>
              <ProblematicForm enableSave={currentUserParse.profilo === RoleID.BME ? stateInspection === "In corso" : stateInspection !== "Approvato"} cancelButton={cancelButton} photos={photos} requisitoFoto={requisitoFotoState}>
                <PhotoView
                  photos={photos}
                  errorPhoto={errorPhoto}
                  handleTakePhoto={onOpenModal}
                  setPhotoToDelete={setPhotoToDelete}
                  requisitoFoto={requisitoFotoState}
                />
              </ProblematicForm>
            </form>
          </FormProvider>
        </IonGrid>
      </Modal>
      <Modal
        isOpen={modalRecAudio}
        title="Registrazione audio"
        onIsOpenChange={setModalRecAudio}
      >
        <ChildrenRecAudio
          audioDetails={audioDetails.url}
          handleAudioStop={(data: NoteAudio) => handleAudioStop(data)}
          handleReset={handleResetAudio}
          audioBase64DB={recordedAudio}
        />
      </Modal>
      <Modal
        isOpen={modalMoveProblematic}
        title={"Sposta Problematica in:"}
        onIsOpenChange={setModalMoveProblematic}
      >
        <ModalMoveProblematic
          setProbIsEqual={setProbIsEqual}
          question={question}
          onSubmitMoveProblematic={onSubmitMoveProblematic}
        />
      </Modal>

      <Modal
        isOpen={!!photoToDelete}
        title={"Elimina foto"}
        onIsOpenChange={() => setPhotoToDelete(undefined)}
      >
        <ChildrenModalDelete
          handleClickNo={() => setPhotoToDelete(undefined)}
          handleClickYes={handleDeletePhoto}
          text="Stai cancellando questa immagine definitivamente, continuare?"
        />
      </Modal>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showDelete}
        onDidDismiss={() => setShowDelete(false)}
        message={"Eliminazione in corso..."}
        duration={5000}
      />
      <IonToast
        duration={3000}
        color="success"
        isOpen={showMessageSave}
        onDidDismiss={() => setShowMessageSave(false)}
        message="Salvataggio avvenuto con successo"
        position="top"
        buttons={[
          {
            side: "start",
            text: "Aggiungi problematica",
          },
        ]}
      />
      <IonToast
        isOpen={probIsMoved}
        color="success"
        duration={3000}
        onDidDismiss={() => setProbIsMoved(false)}
        message="La problematica è stata spostata con successo"
        position="top"
      />
      <IonToast
        isOpen={probIsEqual}
        color="danger"
        duration={5000}
        onDidDismiss={() => setProbIsEqual(false)}
        message="La problematica si trova già in questa domanda"
        position="top"
      />
    </>
  );
};

export default ProblematicWrapper;
