import { IonButton, IonCol, IonFabButton, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { addOutline, trashOutline } from 'ionicons/icons';
import { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { AssociateBUSelect } from "../../../components/AssociateBUSelect";
import CustomInput from "../../../components/CustomInput/CustomInput";
import SelectSearchableArray from '../../../components/SelectSearchable/SelectSearchableArray';
import { ShowIf } from "../../../components/ShowIf";
import { UtenteFornitore } from "../../../Models/Fornitori";
import { useEntitySettings, useEntityUser } from "../../../store";
import { RoleID } from "../../Detail/useResponseDetail";
import { AddFornitoreType } from "./useCRUDFornitore";

interface FormFornitoriProps {
    onClose(): void;
    onCreate(data: AddFornitoreType): void;
    onUpdate(data: AddFornitoreType): void;
    asBu: number | null;
}

interface SelectUserModel {
    listaUtenti: (Omit<UtenteFornitore, "isOwner"> & {isOwner: "SI" | "NO"})[]
}

interface FormFornitoreData extends Omit<AddFornitoreType, "listaUtenti">, SelectUserModel {
}

export const FormFornitori = ({ onCreate, onClose, onUpdate, asBu }: FormFornitoriProps) => {
    const { user: currentUser } = useEntityUser();
    const isCurrentUserAmmSist = currentUser.profilo === RoleID.AMM_SIST;

    const { userForFornitori, getUserForFornitori } = useEntitySettings(({ userForFornitori, getUserForFornitori }) => ({ userForFornitori, getUserForFornitori }));
    const { control, formState:{ errors }, handleSubmit, getValues, setValue } = useFormContext();

    const fornitoriId = useWatch({name: 'id'});
    const buAssociate = useWatch({name: 'buAssociate'});

    useEffect(() =>{
        if(currentUser?.id) getUserForFornitori(+currentUser.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(asBu && !getValues().id) setValue("buAssociate", [asBu], { shouldDirty: true });
    }, [asBu, setValue, getValues])

    const usersForAdd = useMemo(() => {       
        return userForFornitori.map(({ id, nome, cognome, mail }) => {
            return {
                id,
                nome: nome.trim() + " " + cognome.trim() + " - " + mail.trim()?.toLowerCase(),
            }
        })
    }, [userForFornitori]);

    const { fields, append, remove, update } = useFieldArray<SelectUserModel, any, keyof SelectUserModel["listaUtenti"][0]>({
        name: "listaUtenti",
    });

    const canSubmit = isCurrentUserAmmSist ? buAssociate?.length : true;

    /*vedo quali utenti sono già stati inseriti onde evitare che lo stesso utente possa
    essere inserito più volte causando un problema di key*/
    const insertedUsers = useWatch({name: 'listaUtenti'});
    const insertedUsersIDS: number[] = insertedUsers?.map(user => user.id);
    const usersForAddFiltered = usersForAdd.filter(user => !insertedUsersIDS?.includes(+user.id));

    const onSubmit = (data: FormFornitoreData) => {
        const mappedData = {...data, listaUtenti: data.listaUtenti.map(user => ({...user, isOwner: user.isOwner === "SI"}))}
        if(fornitoriId) onUpdate(mappedData)
        else onCreate(mappedData);
    } 

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <IonGrid className="m-0 mx-3 px-0">
                <IonRow>
                    <IonCol>
                        <Controller 
                            control={control}
                            name='nomeFornitore'
                            rules={{required: true}}
                            render={({ field: { onChange, value, } }) =>
                                <CustomInput
                                    label="Nome Fornitore"
                                    setValue={onChange}
                                    value={value}
                                    error={!!errors?.nomeFornitore}
                                />
                            }

                        />
                    </IonCol>
                </IonRow>
                
                <ShowIf condition={isCurrentUserAmmSist && !asBu}> 
                    <IonRow>
                        <IonCol size="12" className="pe-0">
                            <AssociateBUSelect formName='buAssociate' label="BU Associate" isMulti />
                        </IonCol>
                    </IonRow>
                </ShowIf>
                
                { (fields as unknown as UtenteFornitore[])?.map(({ nome, cognome, mail, id, }, i) => {
                    return (
                    <IonRow key={id} className="ion-align-items-center mt-3">
                        <IonCol size="11" className="pe-0">
                            <Controller 
                                control={control}
                                name={`listaUtenti.${i}`}
                                rules={{required: true}}
                                render={() =>
                                    <SelectSearchableArray
                                        value={{id, nome: `${nome.trim()} ${cognome.trim()} ${mail.trim()}`}}
                                        setValue={(value) => {
                                            if(!value) return;
                                            const {id, ...rest} = value; 
                                            const [nome, cognome, _, mail] = rest?.nome.split(' ');
                                            console.log({value, id, nome, cognome, mail, isOwner: "NO"})
                                            update(i, { id, nome, cognome, mail, isOwner: "NO" })}
                                        }
                                        options={usersForAddFiltered}
                                        label={`Utente ${i + 1}`}
                                        error={!!errors?.listaUtenti?.[i]}
                                        disableClearable
                                    />
                                }
                            />
                        </IonCol>
                        <IonCol size="1" className="mt-4 ps-0">
                            <IonFabButton
                                color="danger"
                                size="small"
                                onClick={() => remove(i)}
                            >
                                <IonIcon icon={trashOutline} />
                            </IonFabButton>
                        </IonCol>
                        <IonCol size="11" className="pe-0 pb-3 border-bottom ">
                            <Controller 
                                control={control}
                                name={`listaUtenti.${i}.isOwner`}
                                rules={{required: true}}
                                render={({fieldState: { error }, field: { value, onChange }}) =>
                                    <div className="d-flex w-100 ion-align-items-center" >
                                        <label style={{fontWeight: 600}}>Responsabile</label>
                                        <RadioGroup row className="ms-4 w-100" aria-label={"Responsabile"} name={`Responsabile`} value={value} onChange={onChange}>
                                            <IonCol size="4" className="p-0 ms-1 me-0">
                                                <FormControlLabel
                                                    value="SI"
                                                    control={<Radio color="primary" className="p-0 me-2 my-2"  />}
                                                    label="Si"
                                                    className={`bg-white ps-1 py-1 rounded-1 ${error ? "border-error" : "border"}  w-100 me-0`} 
                                                />
                                            </IonCol>
                                            <IonCol size="4" className="py-0 me-0 pe-0">
                                                <FormControlLabel
                                                    value="NO"
                                                    control={<Radio color="primary" className="p-0 me-2 my-2" />}
                                                    label="No"
                                                    className={`bg-white ps-1 py-1 rounded-1 ${error ? "border-error" : "border"} w-100 mx-0`} 
                                                />
                                            </IonCol>
                                        </RadioGroup>
                                    </div>
                                }
                            />
                        </IonCol>
                    </IonRow>
                    )}
                )}
                <IonRow>
                    <IonCol>
                        <IonButton
                            type="button"
                            className="ion-text-uppercase fw-bolder"
                            fill="clear"
                            onClick={() => append({
                                id: -1,
                                nome: '',
                                cognome: '',
                                mail: '',
                                isOwner: "NO",
                            })}
                        >
                            <IonIcon slot="start" icon={addOutline} className="fw-bold" />
                            aggiungi utente
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-end">
                    <IonCol sizeXl="3" className="ion-text-end pe-0">
                        <IonButton expand="block" fill="clear" className="fw-bold ion-text-uppercase" onClick={onClose}>ANNULLA</IonButton>
                    </IonCol>
                    <IonCol sizeXl="3" className="ion-text-end ps-0">
                        <IonButton type="submit" expand="block" fill="solid" className="fw-bold ion-text-uppercase" disabled={!canSubmit} >SALVA</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </form>
    )
}
