import { IonCol, IonButton, IonIcon } from "@ionic/react";
import { eyeOffOutline } from "ionicons/icons";
import { useEntityUser } from "../../../store";
import { RoleID } from "../../Detail/useResponseDetail";
import { ResponseTypeEnum } from "./useQuestionHook";

interface SelectResponseProps {
    presenzaRisposte: boolean, clickedButton: number, onSelectQuestionResponse: (select: ResponseTypeEnum) => () => void, stateInspection: string
}
export const SelectResponse = ({ presenzaRisposte , clickedButton, onSelectQuestionResponse, stateInspection}: SelectResponseProps) => {
    
    const { user: currentUserParse } = useEntityUser();

    const shouldBeDisabled = 
    stateInspection=== "Approvato" 
    || stateInspection === "In Approvazione"  
    || stateInspection === "In Revisione"
    || currentUserParse?.profilo === RoleID.LETTORE
    || (stateInspection === "Revisionato" && currentUserParse?.profilo === RoleID.BME);


    
    return presenzaRisposte && (
        <IonCol sizeXl="4" sizeLg="4" sizeMd="5" sizeXs="12">
            <IonButton
                className="ion-float-right ion-text-uppercase"
                fill={clickedButton === ResponseTypeEnum.NA ? "solid" : "outline"}
                color="secondary"
                onClick={onSelectQuestionResponse(ResponseTypeEnum.NA)}
                expand="block"
                disabled={shouldBeDisabled}
            >
                <span className="mx-1">n/a</span>
            </IonButton>
            <IonButton
                className="ion-float-right ion-text-uppercase"
                fill={clickedButton === ResponseTypeEnum.NotView ? "solid" : "outline"}
                color="secondary"
                onClick={onSelectQuestionResponse(ResponseTypeEnum.NotView)}
                expand="block"
                disabled={shouldBeDisabled}
            >
                <IonIcon slot="icon-only" icon={eyeOffOutline} className="mx-1" />
            </IonButton>
            <IonButton
                className="ion-float-right ion-text-uppercase"
                fill={clickedButton === ResponseTypeEnum.Yes ? "solid" : "outline"}
                color="secondary"
                onClick={onSelectQuestionResponse(ResponseTypeEnum.Yes)}
                expand="block"
                disabled={shouldBeDisabled}
            >
                <span className="mx-2">si</span>
            </IonButton>
            <IonButton
                className="ion-float-right ion-text-uppercase"
                fill={clickedButton === ResponseTypeEnum.No ? "solid" : "outline"}
                color="secondary"
                onClick={onSelectQuestionResponse(ResponseTypeEnum.No)}
                expand="block"
                disabled={shouldBeDisabled}
            >
                <span className="mx-1">no</span>
            </IonButton>
        </IonCol>
    );
}
