import { IonRow, IonCol } from '@ionic/react'

export const UserHeadingList = () => {
  return (
    <IonRow className="ps-3">
        <IonCol size="2">
            <h6>Nome e Cognome</h6>
        </IonCol>
        <IonCol size="3">
            <h6>Email</h6>
        </IonCol>
        <IonCol size="3">
            <h6>Ruolo</h6>
        </IonCol>
        <IonCol size="2">
            <h6>Gruppo</h6>
        </IonCol>
    </IonRow>
  )
}
