import React from "react";
import { IonRow, IonCol, IonImg, IonFabButton, IonIcon } from "@ionic/react";
import { trashOutline, pencilOutline, arrowRedoOutline } from "ionicons/icons";
import { OptionProblematic } from "../../../Models/Checklist";
import placeholderSquare from "../../../assets/img/placeholder-square.jpg";
import { useParams } from "react-router";
import { ParamsInspection } from "../../../Models/ParamsInspection";
import { useSendToApproval } from "../useSendToApproval";
import { RoleID } from "../../Detail/useResponseDetail";
import { useEntityUser } from "../../../store";

interface ProblematicProps{
    el: OptionProblematic, 
    stateInspection: string, 
    handleModalDelete: (idProblema: number) => void, 
    handleShowModalModificationProblematic: (idProblema: number) => void, 
    handleShowModalMoveProblematic: (idProblema: number) => void
}
export const  Problematic = ({el, stateInspection, handleModalDelete, handleShowModalModificationProblematic, handleShowModalMoveProblematic}: ProblematicProps): JSX.Element => {

    const { numberInspection } = useParams<ParamsInspection>();
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);

    const { user: currentUserParse } = useEntityUser();

   return <React.Fragment key={el.idProblema}>
        <IonRow key={el.idProblema}>
            <IonCol className="p-0">
                <hr />
            </IonCol>
        </IonRow>
        {/* desktop */}
        <IonRow className="ion-align-items-center ms-4_5 ion-hide-md-down ion-justify-content-evenly py-3">
            <IonCol size="1" className="my-thin-col-problematic-img">
                <h6
                    className={sendToApproval &&
                        (el.competenza === "" ||
                            el.stimaCosti === "" ||
                            el.intervento === "" ||
                            el.possibiliCause === "" ||
                            el.stimaCosti.indexOf("-") > -1)
                        ? "me-3 text-danger"
                        : "me-3"}
                >
                    {el.progressivo}
                </h6>
            </IonCol>
            <IonCol size="1" className="p-0">
                {el.immagineUrl === "" ? (
                    <IonImg src={placeholderSquare} />
                ) : (
                    <IonImg
                        src={el.immagineUrl}
                        className="img-list-problematic" />
                )}
            </IonCol>
            <IonCol size="7" className="ps-4">
                {
                    !(el.immagineUrl === "" && (el.didascalia == null || el.didascalia === "" || el.didascalia == undefined)) &&
                    <p className="my-2">
                        <span className="fw-bold">Descrizione breve:</span>{" "}
                        {el.didascalia}
                    </p>
                }
                <p className="my-2">
                    <span className="fw-bold">Localizzazione:</span>{" "}
                    {el.localizzazione}
                </p>
                <p className="my-2">
                    <span className="fw-bold">Tipologia intervento:</span>{" "}
                    {el.tipologiaIntervento}
                </p>
                <p className="my-2">
                    <span className="fw-bold">Livello di intervento:</span>{" "}
                    {el.livelloIntervento}
                </p>
            </IonCol>
            <IonCol size="3" className="ion-align-self-start">
                {currentUserParse?.profilo !== RoleID.LETTORE && (
                <>
                <IonFabButton
                    color="danger"
                    size="small"
                    className="ion-float-end"
                    disabled={stateInspection === "Approvato" || stateInspection === "In Approvazione" || stateInspection === "In Revisione" || ( stateInspection === "Revisionato" && currentUserParse.profilo === RoleID.BME )}
                >
                    <IonIcon
                        icon={trashOutline}
                        onClick={() => handleModalDelete(el.idProblema)} />
                </IonFabButton>
                <IonFabButton size="small" className="ion-float-end">
                    <IonIcon
                        icon={pencilOutline}
                        onClick={() => handleShowModalModificationProblematic(el.idProblema)} />
                </IonFabButton>
                <IonFabButton
                    color="dark"
                    size="small"
                    className="ion-float-end"
                    aria-label="Sposta"
                >
                    <IonIcon
                        icon={arrowRedoOutline}
                        onClick={() => handleShowModalMoveProblematic(el.idProblema)} />
                </IonFabButton>
                </>)}
            </IonCol>
        </IonRow>
        {/* mobile */}
        <IonRow className="ion-justify-content-evenly ion-align-items-center ion-hide-xl-up ion-hide-md-up">
            <IonCol size="6" className="p-0">
                {el.immagineUrl === "" ? (
                    <IonImg src={placeholderSquare} />
                ) : (
                    <IonImg
                        src={el.immagineUrl}
                        className="img-list-problematic" />
                )}
            </IonCol>
            <IonCol size="4" className="ion-align-self-start">
                {currentUserParse?.profilo !== RoleID.LETTORE 
                && (
                <>
                <IonFabButton
                    color="danger"
                    size="small"
                    className="ion-float-end"
                    disabled={ stateInspection === "Approvato" || stateInspection === "In Approvazione" || stateInspection === "In Revisione" || ( stateInspection === "Revisionato" && currentUserParse.profilo === RoleID.BME ) }
                    onClick={() => handleModalDelete(el.idProblema)}
                >
                    <IonIcon icon={trashOutline} />
                </IonFabButton>
                <IonFabButton
                    size="small"
                    className="ion-float-end"
                    onClick={() => handleShowModalModificationProblematic(el.idProblema)}
                >
                    <IonIcon icon={pencilOutline} />
                </IonFabButton>
                <IonFabButton
                    color="dark"
                    size="small"
                    className="ion-float-end"
                >
                    <IonIcon
                        icon={arrowRedoOutline}
                        onClick={() => handleShowModalMoveProblematic(el.idProblema)} />
                </IonFabButton>
                </>)}
            </IonCol>
        </IonRow>

        <IonRow className="ion-hide-xl-up ion-hide-md-up">
            <IonCol size="12">
                <h6>Problematica {el.progressivo}</h6>
                <p className="my-2">
                    <span className="fw-bold">Descrizione breve:</span>{" "}
                    {el.didascalia}{" "}
                </p>
                <p className="my-2">
                    <span className="fw-bold">Localizzazione:</span>{" "}
                    {el.localizzazione}{" "}
                </p>
                <p className="my-2">
                    <span className="fw-bold">Tipologia intervento:</span>{" "}
                    {el.tipologiaIntervento}
                </p>
                <p className="my-2">
                    <span className="fw-bold">Livello di intervento:</span>{" "}
                    {el.livelloIntervento}
                </p>
            </IonCol>
        </IonRow>
    </React.Fragment>;
}