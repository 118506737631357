import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Option, OptionQuestion } from "../../../Models/Checklist";
import { useEntityChecklist } from "../../../store";
import SelectSearchable from "../../../components/SelectSearchable/SelectSearchable";

 
export interface ModalMoveProblematicProps {
  // clearModalData: (modal: boolean) => void;
  setProbIsEqual: (isEqual: boolean) => void;
  question: OptionQuestion
  onSubmitMoveProblematic: (questValue, tabValue) => void;
}
const INITIAL_VALUE = { id: null, nome: "" };

const ModalMoveProblematic: React.FC<ModalMoveProblematicProps> = ({
  // clearModalData,
  setProbIsEqual,
  question,
  onSubmitMoveProblematic,
}) => {

  const { tabList, dataChecklist, valueTab: currentTab } = useEntityChecklist(({tabList, dataChecklist, valueTab}) => ({tabList, dataChecklist, valueTab}));
  
  const {control, handleSubmit,reset, resetField, formState:{errors}} = useForm({
    mode: 'onSubmit',
    defaultValues:{
      valueTab: currentTab ? tabList?.[currentTab] : [INITIAL_VALUE],
      questionDestinazione: undefined
    }
  });
  
  const valueTab = useWatch({name: 'valueTab', control});

  const onSubmit = (data: { valueTab: Option, questionDestinazione: Option }) => {
    if(question.idDomanda === data.questionDestinazione.id){
      return setProbIsEqual(true)
    }
    onSubmitMoveProblematic(data.questionDestinazione, data.valueTab)
  }
   
  const optionsQuest = useMemo(() => {
    resetField('questionDestinazione', { defaultValue: []});
 
    const domande = dataChecklist?.listaTab?.[valueTab?.id]?.domande;

    return domande?.reduce(
      (acc, el) =>
        el.visualizzaAggiungi
          ? acc.concat({
            id: el.idDomanda,
            nome: el.descrizione,
          })
          : acc,
      []
    ) || []
  }, [dataChecklist?.listaTab, resetField, valueTab?.id]);

  return (
    <React.Fragment>
      <IonGrid className="m-0 mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonRow>
            <IonCol sizeLg="6" sizeSm="12" sizeXs="12" className="ps-0">
              <Controller
                control={control}
                name='valueTab'
                rules={{ required: true }}
                defaultValue={INITIAL_VALUE}
                render={({ field: { onChange, value, } }) =>
                  <SelectSearchable
                    label="Seleziona Tab:*"
                    options={tabList}
                    setValue={x => onChange(x ?? INITIAL_VALUE)}
                    value={value}
                    error={errors?.valueTab}
                    disabled={false}
                  />
                }
              />
            </IonCol>
            <IonCol sizeLg="6" sizeSm="12" sizeXs="12" className="ps-0">
              <Controller
                control={control}
                name='questionDestinazione'
                rules={{ required: true }}
                // defaultValue={INITIAL_VALUE}
                render={({ field: { onChange, value, } }) =>
                  <SelectSearchable
                    label="Seleziona Domanda:*"
                    options={optionsQuest}
                    setValue={x => x && Object.keys(x)?.length && onChange(x)}
                    value={value}
                    error={errors.questionDestinazione}
                  />
                }
              />
             
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-end mt-auto">
            <IonCol sizeXl="3" className="ion-text-end pe-0">
              <IonButton
                expand="block"
                fill="clear"
                className="fw-bold"
                onClick={()=>reset({})}
              >
                ANNULLA
              </IonButton>
            </IonCol>
            <IonCol sizeXl="3" className="ion-text-end ps-0">
              <IonButton
                type="submit"
                expand="block"
                fill="solid"
                className="fw-bold"
              >
                AGGIUNGI
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
    </React.Fragment>
  );
};

export default ModalMoveProblematic;
 

