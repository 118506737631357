import React from "react";
import {
    IonCol,
    IonRow
} from "@ionic/react";

interface ChecklistNotCreatedProps {
    showSidebar: boolean;
}

const ChecklistNotCreated = ({showSidebar}:ChecklistNotCreatedProps) => {
    return(
        <IonCol
            offsetLg={showSidebar ? "0" : "1"}
            sizeXs="12"
            sizeXl={showSidebar ? "12" : "11"}
            sizeLg={showSidebar ? "12" : "11"}
        >
            <IonRow className="ion-align-items-center vh-100">
                <IonCol size="12">
                    <h2 className="ion-text-center">Checklist non creata</h2>
                    <p className="ion-text-center">
                    Per creare la checklist indicare il valore dei campi{" "}
                    <span className="fw-bold">Occupazione</span> e{" "}
                    <span className="fw-bold">Tipologia UE</span> e cliccare
                    il bottone "SALVA"
                    </p>
                </IonCol>
            </IonRow>
        </IonCol>
    );
};

export default ChecklistNotCreated;