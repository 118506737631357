import { IonButton, IonCol, IonFabButton, IonIcon, IonRow, IonToast } from '@ionic/react';
import { roleForSettings } from '../../../utiles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { pencilOutline, trashOutline } from 'ionicons/icons';
import { Users } from '../../../Models/Users';
import { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useEntityUser } from '../../../store';
import { RoleID } from '../../Detail/useResponseDetail';

export interface RowListSettingsProps {
    items: Users;
    id: string;
    onDelete(): void;
    onUpdateUserRole(data: {
        valueRole: number;
    }): void;
    canEditRole: boolean;
}

const RowListSettings: React.FC<RowListSettingsProps> = ({ items, id, onDelete, onUpdateUserRole, canEditRole }) => {

    const [showModificationRole, setShowModificationRole] = useState<boolean>(false);

    const { user: currentUserParse } = useEntityUser();

    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
          valueRole: 0,
        }
    });

    useEffect(() => {
        items && setValue('valueRole', items.profiloID);
    }, [items.profiloID])
    
    return (
        <>
            <form onSubmit={handleSubmit(onUpdateUserRole)}>
                <IonRow className="ion-align-items-center bg-list-white-settings ps-3">
                    <IonCol size="2">
                        <p>{items.nome + " " + items.cognome}</p>
                    </IonCol>
                    <IonCol size="3" >
                        <p className="ion-text-lowercase">{items.mail}</p>
                    </IonCol>
                    <IonCol size="3">
                        {showModificationRole && canEditRole ? (
                            <Controller 
                                control={control}
                                name='valueRole'
                                rules={{required: true}}
                                render={({ field: { onChange, value, } }) =>
                                    <Select
                                        label="Ruolo"
                                        onChange={onChange}
                                        value={value}
                                        id={id}
                                        variant="outlined"
                                        style={{ 'width': '94%' }}
                                    >
                                        <MenuItem value={RoleID.AMM_SIST}>Amministratore Sistema</MenuItem>
                                        <MenuItem value={RoleID.AMM_BU}>Amministratore BU</MenuItem>
                                        <MenuItem value={RoleID.BM}>Building Manager</MenuItem>
                                        <MenuItem value={RoleID.BO}>Back Office</MenuItem>
                                        <MenuItem value={RoleID.APPROVATORE}>Approvatore</MenuItem>
                                        {/* <MenuItem value={RoleID.BME}>Building Manager Esterno</MenuItem> */}
                                        <MenuItem value={RoleID.LETTORE}>Lettore</MenuItem>
                                    </Select>   
                                }
                            />
                        ) : (
                            <p dangerouslySetInnerHTML={{__html: roleForSettings(items)}} />
                        )}
                    </IonCol>
                    <IonCol size="2">
                        <p>{items.gruppo}</p>
                    </IonCol>
                    <IonCol size="2" className="ion-align-self-start">
                        {showModificationRole ? (
                            <IonRow className="ion-justify-content-end mt-2 me-2">
                                <IonButton fill="clear" 
                                    className="fw-bold ion-text-uppercase" 
                                    onClick={() => setShowModificationRole(false)}>annulla</IonButton>
                                <IonButton
                                    fill="solid"
                                    type='submit'
                                    className="fw-bold ion-text-uppercase"
                                >
                                    modifica
                                </IonButton>
                            </IonRow>
                        ) : (
                            <>
                                { currentUserParse?.profilo === RoleID.LETTORE || currentUserParse?.profilo === RoleID.BO || items.profiloID === RoleID.BME ? "" :
                                    <IonFabButton
                                        size="small"
                                        className="ion-float-end"
                                        onClick={() => setShowModificationRole(true)}
                                    >
                                    <IonIcon icon={pencilOutline} />
                                    </IonFabButton>
                                    /*
                                    <IonFabButton
                                        color="danger"
                                        size="small"
                                        className="ion-float-end"
                                        onClick={onDelete}
                                    >
                                        <IonIcon icon={trashOutline} />
                                    </IonFabButton>
                                    */
                                }
                            </>
                                
                        )}

                    </IonCol>
                </IonRow>
            </form>
        </>
    );
}

export default RowListSettings;