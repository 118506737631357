import { IonLabel } from "@ionic/react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useEntitySettings } from '../store';
import { MultiselectOption } from './Multiselect';

interface Props {
    formName: string, 
    label: string;
    filterBUId?: number | null;
    disabled?: boolean;
}

export const AssignFornitoreSelect = ({ formName, label, filterBUId, disabled }: Props) => {
    const { fornitori: allFornitori } = useEntitySettings();
    const { control, formState:{ errors }, setValue } = useFormContext();
    const fornitoreFormValues = useWatch({name: formName, control});

    const { FornitoreOptions, SelectedFornitore } = useMemo(() => {
        const fornitori = filterBUId ? allFornitori.filter(el => el.buAssociate.includes(filterBUId)) : allFornitori;
        const mappedFornitori = fornitori.map(f => ({ label: f.nomeFornitore, value: f.id}))
        return {
            FornitoreOptions: mappedFornitori,
            SelectedFornitore: mappedFornitori.find(f => f.value === fornitoreFormValues)
        };
    }, [filterBUId, allFornitori, fornitoreFormValues]);

  return (
    <Controller 
        control={control}
        name={formName}
        rules={{required: true}}
        render={() => (
            <>
                <IonLabel className="label p-0 ion-text-capitalize">{label}</IonLabel>
                <Autocomplete
                    disabled={disabled}
                    value={SelectedFornitore as MultiselectOption}
                    onChange={(_, value) => setValue(formName, (value as MultiselectOption)?.value, { shouldDirty: true })}
                    options={FornitoreOptions}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => {
                        return (
                        <TextField
                            {...params}
                            name={formName}
                            variant="outlined"
                            error={!!errors?.[formName]}
                            helperText={!!errors?.[formName] ? "Campo obbligatorio" : null}
                        />
                        );
                    }}
                    disablePortal={false}
                />
            </>
        )}
    />
)}


    