import React from "react";

export interface IconTagDataProps {
    width: number;
    className: string;
}

const IconTagData: React.FC<IconTagDataProps> = ({ width, className }) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 15 15" xmlSpace="preserve" width={width} >
            <path
                id="tag"
                className={className}
                d="M7.8,0C8,0,8.1,0.1,8.2,0.2l0,0l6.2,6.2c0.8,0.8,0.8,2.1,0,2.9l0,0l-5.1,5.1
						C8.9,14.8,8.3,15,7.8,15s-1.1-0.2-1.5-0.6l0,0L0.2,8.2C0.1,8.1,0,8,0,7.8l0,0V0.6C0,0.3,0.3,0,0.6,0l0,0H7.8z M7.5,1.2H1.2v6.3
						l6,6c0.1,0.1,0.3,0.2,0.5,0.2l0,0l0.1,0c0.2,0,0.4-0.1,0.6-0.2l0,0l5.1-5.1c0.3-0.3,0.3-0.8,0-1.2l0,0L7.5,1.2z M5,4
						c0.6,0,1,0.4,1,1S5.6,6,5,6S4,5.6,4,5S4.4,4,5,4z"/>
        </svg>
    );
}

export default IconTagData;