import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import "./report.css";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
//components
import Modal from "../../components/Modal/Modal";
import MenuMobile from "../../components/MenuMobile/MenuMobile";
import Footer from "../../components/Footer/Footer";
import ScrollToUp from "../../components/ScrollToUp/ScrollToUp";
import { ParamsInspection } from "../../Models/ParamsInspection";
import SelectSearchable from "../../components/SelectSearchable/SelectSearchable";
import Badge from "../../components/Badge/Badge";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";

//icons
import { documentOutline, downloadOutline, menuOutline } from "ionicons/icons";

//hooks
import { useExportCSV } from "../../hooks/useExportCSV";

import ChildrenModalDelete from "../../components/Modal/ChildrenModalDelete";
import { useEntityConnectivity, useEntityUser } from "../../store";

import { useResponseReport } from './useResponseReport';
import { setSessionStorage } from "../../service";
import { ReactElement } from 'react';
import { RoleID } from "../Detail/useResponseDetail";

const useStylesForTextMultiLine = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "0.5rem",
    },
  })
);

export interface ReportProps {
  showSidebar: boolean;
  handleShowSidebar(): any
}

const Report: React.FC<ReportProps> = ({ showSidebar = true, handleShowSidebar, children }) => {

  const isOnline = useEntityConnectivity(store => store.connectivity);
  const classes = useStylesForTextMultiLine();

  // scroll to top
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => contentRef.current && contentRef.current.scrollToTop(1000);

  const { numberInspection } = useParams<ParamsInspection>();
  const { user: currentUserParse } = useEntityUser(); 

  const { 
    isLoading,
    dataReport,
    dataForExport,
    approvers,
    showModalSendForApproval,
    setShowModalSendForApproval,
    showModalAlertForApproval,
    showModalAlertForRevision,
    setShowModalAlertForApproval,
    setShowModalAlertForRevision,
    valueApprover,
    setValueApprover,
    responseForApproval,
    loadingSendToApproval,
    loadingSendToRevision,
    setLoadingSendToApproval,
    setLoadingSendToRevision,
    handleSubmitForApprovalYES,
    handleSubmitForRevisionYES,
    errorValueNote,
    valueNote,
    setValueNote,
    loadingRefuse,
    setLoadingRefuse,
    showModalReject,
    setShowModalReject,
    handleSubmitConfirmReject,
    successTextApproval,
    loadingApproval,
    setLoadingApproval,
    showSuccessMessageApproval,
    setShowSuccessMessageApproval,
    errorTextConfirmApproval,
    showErrorMessageConfirmApproval,
    setShowErrorMessageConfirmApproval,
    showModalAlertConfirm,
    setShowModalAlertConfirm,


    successTextRegenerateReportsOnNewerTemplate,
    loadingRegenerateReportsOnNewerTemplate,
    setLoadingRegenerateReportsOnNewerTemplate,
    showSuccessMessageRegenerateReportsOnNewerTemplate,
    setShowSuccessMessageRegenerateReportsOnNewerTemplate,
    errorTextRegenerateReportsOnNewerTemplate,
    showErrorMessageRegenerateReportsOnNewerTemplate,

    handleSubmitConfirmApproval,
    showModalSendForNewVersion,
    setShowModalSendForNewVersion,
    showSuccessMessageNewVersion,
    setShowSuccessMessageNewVersion,
    showErrorMessageNewVersion,
    setShowErrorMessageNewVersion,
    handleNewVersion,
    loadingNotifyToBM,
    setLoadingNotifyToBM,
    successTextNotifyToBM,
    showSuccessMessageNotifyToBM,
    setShowSuccessMessageNotifyToBM,
    errorTextNotifyToBM,
    showErrorMessageNotifyToBM,
    setShowErrorMessageNotifyToBM,
    handleSubmitSendMailToBM,
    loadingTranslate,
    setLoadingTranslate,
    successTextTranslate,
    showSuccessMessageTranslate,
    setShowSuccessMessageTranslate,
    errorTextTranslate,
    showErrorMessageTranslate,
    setShowErrorMessageTranslate,
    showModalTranslate,
    setShowModalTranslate,
    handleSubmitTranslate,
    handleReportOnClick
  } = useResponseReport();

  const listaProblemiIsEmpty = useMemo(() => !dataReport?.listaProblemi?.length ?? 0, [dataReport]);
   
  const [errorValueApprover, setErrorValueApprover] = useState<boolean>(false);
   
  const { exportToCSV } = useExportCSV("capex");
 
  //BM
  const confirmButton = () => {
    setErrorValueApprover(valueApprover === null);
    if (valueApprover !== null) {
      setShowModalAlertForApproval(true);
      setSessionStorage(`SendToApproval_${numberInspection}`, true);
    }
  };

  const successTextNewVersion = useMemo(() => "Nuova versione creata, il sopralluogo è nuovamente modificabile", []);
  
  const totalCostEstimate = (typology: string): string =>
    formatNumber(
      dataReport?.listaProblemi.reduce(
        (acc, curr): number => acc + curr[typology],
        0
      )
    );

  const totalForLevel = (typology: string): string =>
    formatNumber(
      dataReport?.listaProblemi.reduce(
        (acc, curr): number =>
          acc + (curr.livelloIntervento.includes(typology) && curr.stimaCosti),
        0
      )
    );

  const formatNumber = (num: number): string => {
    const output = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(num);
    return output.substring(0, output.length - 2);
  };

  console.log(dataReport);
  console.log(currentUserParse);

  console.log((dataReport?.stato === "In Approvazione" && (currentUserParse.profilo === 3 || currentUserParse.profilo === 1)));
  console.log((dataReport?.stato.toLocaleLowerCase() === "in revisione" && currentUserParse.id === dataReport.creatoreID));

   return (
    <>
        <MenuMobile disableLink={!isOnline} />
        <IonContent ref={contentRef} scrollEvents={true} scrollX={true}>
          <IonGrid className="p-0 mb-6">
            <IonRow id="report">
              {children}
              { listaProblemiIsEmpty ? (
                <IonCol
                  offsetLg={showSidebar ? "0" : "1"}
                  sizeXs="12"
                  sizeXl={showSidebar ? "12" : "11"}
                  sizeLg={showSidebar ? "12" : "11"}
                >
                  <IonRow className="ion-align-items-center vh-100">
                    <IonCol size="12">
                      <h2 className="ion-text-center">Report non creato</h2>
                      <p className="ion-text-center">
                        Inserire almeno una problematica
                      </p>
                    </IonCol>
                  </IonRow>
                </IonCol>
              ) : (
                <IonCol
                  offsetLg={showSidebar ? "0" : "1"}
                  sizeXs="12"
                  sizeXl={showSidebar ? "12" : "11"}
                  sizeLg={showSidebar ? "12" : "11"}
                >
                  <IonRow className="ion-justify-content-end ion-align-items-center">
                    <IonCol>
                      {showSidebar && (
                        <IonIcon
                          icon={menuOutline}
                          className="pointer me-1 fs-5"
                          onClick={()=> handleShowSidebar()}
                          color="primary"
                        />
                      )}
                      <h1 className="my-0 d-inline-block">
                        {dataReport?.fondo}
                      </h1>
                    </IonCol>
                    <IonCol className="ion-text-end pb-0">
                      <div>
                        {dataReport?.stato && (
                          <Badge
                            className="badge-report"
                            stato={dataReport?.stato}
                          />
                        )}
                      </div>

                      {/* NOTIFICA BM: assegnatario se diverso da creatore */}
                      {/* Rimosso a seguito di richiesta di Pastorelli */}
                      {/* {(
                          dataReport?.stato === "In corso" ||
                          dataReport?.stato === "Revisionato" ||
                          dataReport?.stato === "Respinto") &&
                          dataReport?.assegnatarioID !== dataReport?.creatoreID &&
                          currentUserParse.id === dataReport?.assegnatarioID && 
                          (
                            <IonButton
                              fill="solid"
                              className="ion-text-uppercase"
                              onClick={handleSubmitSendMailToBM}
                              disabled={!isOnline}
                            >
                              Notifica BM
                            </IonButton>
                          )
                        } */}

                      {((
                        (dataReport?.stato === "In corso" || dataReport?.stato === "Respinto") 
                        && dataReport?.assegnatarioID !== dataReport?.creatoreID
                        && currentUserParse.id === dataReport?.assegnatarioID 
                        && currentUserParse.profilo === 4 
                      ) 
                      || (currentUserParse.profilo === 1 && dataReport?.stato !== "In Revisione" 
                      && dataReport?.stato !== "Revisionato"
                    && dataReport?.stato != "Approvato"
                  && dataReport?.stato != "In Approvazione"
                  && dataReport?.stato != "Respinto"))
                      && (
                          <IonButton
                            fill="solid"
                            className="ion-text-uppercase"
                            onClick={() => setShowModalAlertForRevision(true)}
                            disabled={!isOnline}
                          >
                            Manda in Revisione
                          </IonButton>
                        )}
                      {/* MANDA IN APPROVAZIONE: admin o creatore */}
                        { (dataReport?.stato === "In corso" || dataReport?.stato === "Respinto" || dataReport?.stato === "Revisionato") &&
                          (currentUserParse.id === dataReport?.creatoreID || currentUserParse.profilo === 1) &&
                           currentUserParse.profilo != 4 && 
                          (
                            <IonButton
                              fill="solid"
                              className="ion-text-uppercase"
                              onClick={() => setShowModalSendForApproval(true)}
                              disabled={!isOnline}
                            >
                              manda in approvazione
                            </IonButton>
                          )}

                      {/* link al report PDF */}
                      {dataReport?.stato.toLocaleLowerCase() === "approvato" &&
                        dataReport?.pdfUrl && (
                          <>
                            <IonButton
                              fill="solid"
                              color="primary"
                              className="ion-text-uppercase"

                              target="_blank"
                              rel="noreferrer"
                              onClick={() => handleReportOnClick('Sopralluogo') }
                            >
                              <IonIcon icon={documentOutline} slot="start" />
                              Site Report
                            </IonButton>
                          </>
                        )}
                      {dataReport?.stato.toLocaleLowerCase() === "approvato" &&
                        dataReport?.rischiSpecificiUrl && (
                          <>
                            <IonButton
                              fill="solid"
                              color="primary"
                              className="ion-text-uppercase"

                              target="_blank"
                              rel="noreferrer"
                              onClick={() => { handleReportOnClick('RischiSpecifici') } }
                            >
                              <IonIcon icon={documentOutline} slot="start" />
                              Rischi Specifici
                            </IonButton>
                          </>
                        )}
                      {dataReport?.stato.toLocaleLowerCase() === "approvato" &&
                        dataReport?.reportClienteUrl && (
                          <>
                            <IonButton
                              fill="solid"
                              color="primary"
                              className="ion-text-uppercase"
                             
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => { handleReportOnClick('SopralluogoLight') } }
                            >
                              <IonIcon icon={documentOutline} slot="start" />
                              Site Report Light
                            </IonButton>
                          </>
                        )}

                      {dataReport?.stato === "Approvato" &&
                        (currentUserParse.profilo === 3 ||
                          currentUserParse.profilo === 1) && (
                          <IonButton
                            fill="solid"
                            className="ion-text-uppercase"
                            onClick={() => setShowModalSendForNewVersion(true)}
                            disabled={!isOnline}
                          >
                            nuova versione
                          </IonButton>
                        )}

                      {
                        (
                          (dataReport?.stato === "In Approvazione" && (currentUserParse.profilo === 3 || currentUserParse.profilo === 1)) || 
                          (dataReport?.stato.toLocaleLowerCase() === "in revisione" && currentUserParse.id === dataReport.creatoreID)
                        ) && 
                        (
                          <>
                            <IonButton
                              fill="outline"
                              className="ion-text-uppercase fw-bold"
                              color="danger"
                              onClick={() => setShowModalReject(true)}
                              disabled={!isOnline}
                            >
                              rifiuta
                            </IonButton>
                            <IonButton
                              fill="solid"
                              className="ion-text-uppercase fw-bold"
                              onClick={() => setShowModalAlertConfirm(true)}
                              disabled={!isOnline}
                            >
                              approva
                            </IonButton>
                          </>
                        )}
                      {/* amministratore */}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <p className="m-0">
                        Sintesi interventi proposti: mappatura dei rischi e
                        rilievo delle situazioni di pericolo
                      </p>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    {(currentUserParse.profilo !== 5) && (
                        <>
                      <IonButton
                        fill="solid"
                        color="secondary"
                        className="ion-text-uppercase"
                        onClick={() => exportToCSV(dataForExport, "report")}
                      >
                        <IonIcon icon={downloadOutline} slot="start" />
                          ESPORTA COSTI
                        </IonButton>
                        </>
                        )
                      }

                      {(dataReport?.stato.toLocaleLowerCase() === "in corso" || (dataReport?.stato.toLocaleLowerCase() === "revisionato" && currentUserParse.profilo !== RoleID.BME)) && currentUserParse.profilo !== RoleID.LETTORE && (
                        <IonButton
                          fill="solid"
                          color="secondary"
                          className="ion-text-uppercase"
                          onClick={ () => setShowModalTranslate(true)}
                          disabled={!isOnline}
                        >
                          traduci
                        </IonButton>
                      )}
                    </IonCol>
                  </IonRow>
                  <Modal
                    isOpen={showModalTranslate}
                    title={"Attenzione!"}
                    onIsOpenChange={setShowModalTranslate}
                  >
                    <IonGrid className="mx-3">
                      <IonRow>
                        <IonCol>
                          <p className="m-0">
                          La traduzione in Inglese è PERMANENTE: i testi originali saranno sovrascritti e non potranno essere recuperati.
                          <br/><br/>
                          VUOI CONTINUARE?
                          </p>
                        </IonCol>
                      </IonRow>
                      
                      <IonRow className="ion-justify-content-end">
                        <IonButton
                          fill="clear"
                          className="fw-bold ion-text-uppercase"
                          onClick={() => setShowModalTranslate(false)}
                        >
                          annulla
                        </IonButton>
                        <IonButton
                          fill="solid"
                          className="fw-bold ion-text-uppercase"
                          onClick={handleSubmitTranslate}
                          disabled={
                          responseForApproval?.inApprovazione === false ? true : false
                          }
                        >
                          conferma
                        </IonButton>
                      </IonRow>
                    </IonGrid>
                  </Modal>
                  <IonRow className="ps-1 ion-align-items-end">
                    <IonCol
                      sizeXl="2"
                      sizeLg="3"
                      sizeMd="2"
                      sizeXs="3"
                      className="p-0"
                    >
                      <h6 className="fs-7">Tipologia</h6>
                    </IonCol>
                    <IonCol
                      sizeXl="2"
                      sizeLg="2"
                      sizeMd="2"
                      sizeXs="2"
                      className="p-0"
                    >
                      <h6 className="fs-7">Competenza</h6>
                    </IonCol>
                    <IonCol
                      sizeXl="4"
                      sizeLg="3"
                      sizeMd="2"
                      sizeXs="4"
                      className="p-0"
                    >
                      <h6 className="fs-7">Descrizione breve</h6>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      sizeMd="2"
                      sizeXs="3"
                      className="p-0 ion-text-center ion-text-uppercase"
                    >
                      <h6 className="fs-7">
                        Stima
                        <br />
                        massima
                        <br />
                        costi
                      </h6>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      sizeMd="2"
                      className="p-0 ion-text-center ion-hide-lg-down"
                    >
                      <h6 className="fs-7">Livello 1</h6>
                      <p className="fw-bolder fs-7 mt-0">
                        Messa in <br /> Sicurezza
                      </p>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      sizeMd="2"
                      className="p-0 ion-text-center ion-hide-lg-down"
                    >
                      <h6 className="fs-7">Livello 2</h6>
                      <p className="fw-bolder fs-7 mt-0">
                        Mantenimento <br /> Valore Asset
                      </p>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      sizeMd="2"
                      className="p-0 ion-text-center ion-hide-lg-down"
                    >
                      <h6 className="fs-7">Livello 3</h6>
                      <p className="fw-bolder fs-7 mt-0">
                        Accrescimento <br /> Valore Asset
                      </p>
                    </IonCol>
                  </IonRow>
                  {dataReport?.listaProblemi.map((el, index) => {
                    return (
                      <IonRow className="ps-1 bg-white" key={index}>
                        <React.Fragment key={index}>
                          <IonCol
                            sizeXl="2"
                            sizeLg="3"
                            sizeMd="1"
                            sizeXs="3"
                            className="p-1"
                          >
                            <p>{el.tipologia}</p>
                          </IonCol>
                          <IonCol
                            sizeXl="2"
                            sizeLg="2"
                            sizeMd="1"
                            sizeXs="2"
                            className="p-1"
                          >
                            <p>{el.competenza}</p>
                          </IonCol>
                          <IonCol
                            sizeXl="4"
                            sizeLg="3"
                            sizeMd="1"
                            sizeXs="4"
                            className="p-1"
                          >
                            <p>{el.didascalia}</p>
                          </IonCol>
                          <IonCol
                            sizeXl="1"
                            sizeLg="1"
                            sizeMd="2"
                            sizeXs="3"
                            className="p-0 ion-text-center ion-text-uppercase bg-greyMedium border"
                          >
                            <p className="d-inline-flex ion-float-start ms-1">
                              €
                            </p>
                            <p
                              style={{ overflowWrap: "anywhere" }}
                              className="d-inline-flex ion-float-end fw-bold me-1"
                            >
                              {formatNumber(el.stimaCosti)}
                            </p>
                          </IonCol>
                          <IonCol
                            sizeXl="1"
                            sizeLg="1"
                            sizeMd="2"
                            sizeXs="1"
                            className="p-0 ion-text-center bg-white border ion-hide-lg-down"
                          >
                            <p className="d-inline-flex ion-float-start ms-1">
                              €
                            </p>
                            <p
                              style={{ overflowWrap: "anywhere" }}
                              className="d-inline-flex ion-float-end fw-bold me-1"
                            >
                              {el.livelloIntervento.includes(
                                "Messa in sicurezza"
                              )
                                ? formatNumber(el.stimaCosti)
                                : "0,00"}
                            </p>
                          </IonCol>
                          <IonCol
                            sizeXl="1"
                            sizeLg="1"
                            sizeMd="2"
                            sizeXs="1"
                            className="p-0 ion-text-center bg-white border ion-hide-lg-down"
                          >
                            <p className="d-inline-flex ion-float-start ms-1">
                              €
                            </p>
                            <p
                              style={{ overflowWrap: "anywhere" }}
                              className="d-inline-flex ion-float-end fw-bold me-1"
                            >
                              {el.livelloIntervento.includes(
                                "Mantenimento valore asset"
                              )
                                ? formatNumber(el.stimaCosti)
                                : "0,00"}
                            </p>
                          </IonCol>
                          <IonCol
                            sizeXl="1"
                            sizeLg="1"
                            sizeMd="2"
                            sizeXs="1"
                            className="p-0 ion-text-center bg-white border ion-hide-lg-down"
                          >
                            <p className="d-inline-flex ion-float-start ms-1">
                              €
                            </p>
                            <p
                              style={{ overflowWrap: "anywhere" }}
                              className="d-inline-flex ion-float-end fw-bold me-1"
                            >
                              {el.livelloIntervento.includes(
                                "Accrescimento valore asset"
                              )
                                ? formatNumber(el.stimaCosti)
                                : "0,00"}
                            </p>
                          </IonCol>
                        </React.Fragment>
                      </IonRow>
                    );
                  })}
                  <IonRow className="ps-1 ion-align-items-center">
                    <IonCol
                      sizeXl="8"
                      sizeLg="8"
                      sizeXs="9"
                      className="ion-text-right"
                    >
                      <p className="fw-bolder">Totale</p>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      className="ion-text-uppercase bg-greyVeryDark border px-0"
                    >
                      <p className="text-white d-inline-flex ion-float-start ms-1">
                        €
                      </p>
                      <p
                        style={{ overflowWrap: "anywhere" }}
                        className="d-inline-flex ion-float-end text-white fw-bold me-1"
                      >
                        {totalCostEstimate("stimaCosti")}
                      </p>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      sizeXs="1"
                      className="bg-greyMedium border px-0 ion-hide-lg-down"
                    >
                      <p className="d-inline-flex ion-float-start ms-1">€</p>
                      <p
                        style={{ overflowWrap: "anywhere" }}
                        className="d-inline-flex ion-float-end fw-bold me-1"
                      >
                        {totalForLevel("Messa in sicurezza")}
                      </p>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      sizeXs="1"
                      className="bg-greyMedium border px-0 ion-hide-lg-down"
                    >
                      <p className=" d-inline-flex ion-float-start ms-1">€</p>
                      <p
                        style={{ overflowWrap: "anywhere" }}
                        className="d-inline-flex ion-float-end  fw-bold me-1"
                      >
                        {totalForLevel("Mantenimento valore asset")}
                      </p>
                    </IonCol>
                    <IonCol
                      sizeXl="1"
                      sizeLg="1"
                      sizeXs="1"
                      className="bg-greyMedium border px-0 ion-hide-lg-down"
                    >
                      <p className=" d-inline-flex ion-float-start ms-1">€</p>
                      <p
                        style={{ overflowWrap: "anywhere" }}
                        className="d-inline-flex ion-float-end  fw-bold me-1"
                      >
                        {totalForLevel("Accrescimento valore asset")}
                      </p>
                    </IonCol>
                  </IonRow>
                </IonCol>
              )}
              <ScrollToUp up={scrollToTop} />
              <Footer />
            </IonRow>
          </IonGrid>
        </IonContent>
      <Modal
        isOpen={showModalSendForApproval}
        title={"Manda in approvazione"}
        onIsOpenChange={setShowModalSendForApproval}
      >
        <IonGrid className="mx-3">
          <IonRow>
            <IonCol className="px-0">
              {approvers?.length > 0 && (
                <SelectSearchable
                  label="Seleziona approvatore"
                  value={
                    (valueApprover?.id === -1 && valueApprover?.nome === "") ||
                    valueApprover === null
                      ? null
                      : { id: valueApprover?.id, nome: valueApprover?.nome }
                  }
                  setValue={setValueApprover}
                  options={approvers}
                  error={errorValueApprover}
                  disablePortal={false}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {/* <p className="text-danger">{responseForApproval?.messaggio}</p> */}
              <p className="text-danger">
                {responseForApproval && 'Compilare tutti i campi prima di procedere (' + responseForApproval.messaggio + ').'}
              </p>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-end">
            <IonButton
              fill="clear"
              className="fw-bold ion-text-uppercase"
              onClick={() => setShowModalSendForApproval(false)}
            >
              annulla
            </IonButton>
            <IonButton
              fill="solid"
              className="fw-bold ion-text-uppercase"
              onClick={confirmButton}
              disabled={
                responseForApproval?.inApprovazione === false ? true : false
              }
            >
              conferma
            </IonButton>
          </IonRow>
        </IonGrid>
      </Modal>
      <Modal
        isOpen={showModalAlertForApproval}
        title={"Manda in approvazione"}
        onIsOpenChange={() => setShowModalAlertForApproval(false)}
      >
        <ChildrenModalDelete
          handleClickNo={() => setShowModalAlertForApproval(false)}
          handleClickYes={handleSubmitForApprovalYES}
          text="Stai mandando in approvazione il sopralluogo, non potrai più modificare, vuoi continuare?"
        />
      </Modal>
      
      <Modal
        isOpen={showModalAlertForRevision}
        title={"Manda in revisione"}
        onIsOpenChange={() => setShowModalAlertForRevision(false)}
      >
        <ChildrenModalDelete
          handleClickNo={() => setShowModalAlertForRevision(false)}
          handleClickYes={handleSubmitForRevisionYES}
          text="Stai mandando in revisione il sopralluogo, non potrai più modificare, vuoi continuare?"
        />
        <p className="text-danger m-0 mx-3">
          {responseForApproval && 'Compilare tutti i campi prima di procedere (' + responseForApproval.messaggio + ').'}
        </p>
      </Modal>

      <Modal
        isOpen={showModalSendForNewVersion}
        title={"Nuova versione"}
        onIsOpenChange={() => setShowModalSendForNewVersion(false)}
      >
        <ChildrenModalDelete
          handleClickNo={() => setShowModalSendForNewVersion(false)}
          handleClickYes={handleNewVersion}
          text="Stai creando una nuova versione del sopralluogo, che tornerà modificabile. Vuoi continuare?"
        />
      </Modal>
      {dataReport?.stato.toLocaleLowerCase() === "in revisione" && dataReport.creatoreID === currentUserParse.id && (
        <Modal
          isOpen={showModalAlertConfirm}
          title={"Approva"}
          // onIsOpenChange={handleOnDidDismissAlertForApproval}
          onIsOpenChange={() => setShowModalAlertConfirm(false)}
        >
          <ChildrenModalDelete
            // handleClickNo={handleOnDidDismissAlertForApproval}
            handleClickNo={() => setShowModalAlertConfirm(false)}
            handleClickYes={handleSubmitConfirmApproval}
            text="Stai approvando la revisione del sopralluogo, vuoi continuare?"
          />
        </Modal>
      )}

      {dataReport?.stato.toLocaleLowerCase() != "in revisione" &&
              (
            <Modal
              isOpen={showModalAlertConfirm}
              title={"Approva"}
              // onIsOpenChange={handleOnDidDismissAlertForApproval}
              onIsOpenChange={() => setShowModalAlertConfirm(false)}
            >
              <ChildrenModalDelete
                // handleClickNo={handleOnDidDismissAlertForApproval}
                handleClickNo={() => setShowModalAlertConfirm(false)}
                handleClickYes={handleSubmitConfirmApproval}
                text="Stai approvando il sopralluogo, non potrai più modificare, vuoi continuare?"
              />
            </Modal>)
      }
      <Modal
        isOpen={showModalReject}
        title={"Rifiuta"}
        onIsOpenChange={setShowModalReject}>
        <IonGrid className="m-0 mx-3">
          <IonRow>
            <IonCol className="px-0">
              <p className="m-0">
                Il sopralluogo andrà in stato Respinto e sarà nuovamente
                modificabile
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="px-0">
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                variant="outlined"
                className={classes.root}
                value={valueNote}
                placeholder="Inserisci le tue note..."
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setValueNote(event.target.value);
                }}
                error={errorValueNote}
                helperText={errorValueNote ? "Campo obbligatorio" : ""}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-end ion-align-items-end">
            <IonCol sizeXl="6" className="ion-text-end pe-0">
              <IonButton
                expand="block"
                fill="clear"
                className="fw-bold ion-text-uppercase"
                onClick={() => setShowModalReject(false)}
              >
                annulla
              </IonButton>
            </IonCol>
            <IonCol sizeXl="6" className="ion-text-end ps-0">
              <IonButton
                expand="block"
                fill="solid"
                color="danger"
                className="fw-bold ion-text-uppercase"
                onClick={handleSubmitConfirmReject}
              >
                conferma rifiuto
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </Modal>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoading}
        message={"Sincronizzazione Data with Server"}
      />
      <IonToast
        duration={1000}
        color="danger"
        isOpen={showErrorMessageConfirmApproval}
        onDidDismiss={() => setShowErrorMessageConfirmApproval(false)}
        message={errorTextConfirmApproval}
        position="top"
        buttons={[
          {
            side: "start",
            text: "Creazione Report",
          },
        ]}
      />

      <IonToast
        duration={1000}
        color="danger"
        isOpen={showErrorMessageNotifyToBM}
        onDidDismiss={() => setShowErrorMessageNotifyToBM(false)}
        message={errorTextNotifyToBM}
        position="top"
        buttons={[
          {
            side: "start",
            text: "invio mail",
          },
        ]}
      />
      <IonToast
        duration={1000}
        color="danger"
        isOpen={showErrorMessageTranslate}
        onDidDismiss={() => setShowErrorMessageTranslate(false)}
        message={errorTextTranslate}
        position="top"
        buttons={[
          {
            side: "start",
            text: "Traduzione",
          },
        ]}
      />
      <IonToast
        duration={1000}
        color="success"
        isOpen={showSuccessMessageNotifyToBM}
        onDidDismiss={() => setShowSuccessMessageNotifyToBM(false)}
        message={successTextNotifyToBM}
        position="top"
        buttons={[
          {
            side: "start",
            text: "INVIO MAIL",
          },
        ]}
      />
      <IonToast
        duration={1000}
        color="success"
        isOpen={showSuccessMessageTranslate}
        onDidDismiss={() => setShowSuccessMessageTranslate(false)}
        message={successTextTranslate}
        position="top"
        buttons={[
          {
            side: "start",
            text: "TRADUZIONE",
          },
        ]}
      />
      <IonToast
        duration={2000}
        color="success"
        isOpen={showSuccessMessageNewVersion}
        onDidDismiss={() => setShowSuccessMessageNewVersion(false)}
        message={successTextNewVersion}
        position="top"
        buttons={[
          {
            side: "start",
            text: "NUOVA VERSIONE",
          },
        ]}
      />
      <IonToast
        duration={2000}
        color="danger"
        isOpen={showErrorMessageNewVersion}
        onDidDismiss={() => setShowErrorMessageNewVersion(false)}
        message={"errore"}
        position="top"
        buttons={[
          {
            side: "start",
            text: "NUOVA VERSIONE",
          },
        ]}
      />
      <IonToast
        duration={1000}
        color="success"
        isOpen={showSuccessMessageApproval}
        onDidDismiss={() => setShowSuccessMessageApproval(false)}
        message={successTextApproval}
        position="top"
        buttons={[
          {
            side: "start",
            text: "APPROVAZIONE",
          },
        ]}
      />

    <IonToast
        duration={1000}
        color="success"
        isOpen={showSuccessMessageApproval}
        onDidDismiss={() => setShowSuccessMessageApproval(false)}
        message={successTextApproval}
        position="top"
        buttons={[
          {
            side: "start",
            text: "REPORT DOWNLOAD",
          },
        ]}
      />

      <Loading
        open={loadingNotifyToBM}
        setOpen={setLoadingNotifyToBM}
        message="Invio mail in corso..."
        duration={0}
      />
      <Loading
        open={loadingTranslate}
        setOpen={setLoadingTranslate}
        message="Traduzione in corso..."
        duration={0}
      />
      <Loading
        open={loadingApproval}
        setOpen={setLoadingApproval}
        message="Approvazione in corso..."
        duration={0}
      />
      
      <Loading
      open={loadingRegenerateReportsOnNewerTemplate}
      setOpen={setLoadingRegenerateReportsOnNewerTemplate}
      message="Reperimento del Report in corso..."
      duration={0}
    />


      <Loading
        open={loadingSendToApproval}
        setOpen={setLoadingSendToApproval}
        message="Manda in approvazione in corso..."
        duration={0}
      />
      <Loading
        open={loadingSendToRevision}
        setOpen={setLoadingSendToRevision}
        message="Manda in revisione in corso..."
        duration={0}
      />
      <Loading
        open={loadingRefuse}
        setOpen={setLoadingRefuse}
        message="Respingimento in corso..."
        duration={0}
      />
    </>
  );
};

export default Report;
