import React, { useRef } from 'react';
import { IonCol, IonFabButton, IonIcon } from '@ionic/react';
import { addOutline, trashOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { useSendToApproval } from '../../Checklist/useSendToApproval';
//models
import { ParamsInspection } from '../../../Models/ParamsInspection';
import { useEntityDetail, useEntityUser } from '../../../store';
import CameraCustom, { CameraProps } from '../../../components/CameraCustom/CameraCustom';
import { Photo } from '../../../Models/Photo';
import { bridgeFormToDetail, RoleID } from '../useResponseDetail';

export interface CoverImageProps {
    setShowModalDeletePhoto: (val: boolean) => void;
    methods: any
}

const CoverImage: React.FC<CoverImageProps> = ({ setShowModalDeletePhoto, methods }) => {

    const {
        dataDetail,
        setPhoto
    } = useEntityDetail(({
        dataDetail,
        setPhoto
    }) => ({
        dataDetail,
        setPhoto
    }));
    const cameraRef = useRef<CameraProps>(null);


    const photo = !!dataDetail?.immagineSave?.[0] || !!dataDetail?.immagineUrl;
    const showPhoto = dataDetail?.immagineSave?.[0] ? dataDetail?.immagineSave?.[0].webviewPath : dataDetail.immagineUrl;

    const { numberInspection } = useParams<ParamsInspection>();
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);
    const error = sendToApproval && photo;

    const onSavePhoto = (data: Photo) => {
        const formData = methods.watch();
        setPhoto([data], bridgeFormToDetail(formData));
        cameraRef?.current?.deletePicture();
        setTimeout(()=>{
            cameraRef?.current?.onCloseModal()
        },0)
    }
    const onOpenModal = () => {
        cameraRef?.current?.onOpenModal();
        // setTimeout(() => {
        //     cameraRef?.current?.openCamera();
        // }, 100);
    }

    const { user: currentUserParse } = useEntityUser();

    return (
        <>
            {!photo ? (
                <IonCol sizeLg="3" sizeMd="12" sizeSm="12" sizeXs="12" className={error ? "box-photo-error" : "box-photo"}>
                    { currentUserParse?.profilo === RoleID.LETTORE ? "" : <>
                    <IonFabButton className="button-center" color="light" onClick={onOpenModal}>
                        <IonIcon icon={addOutline} style={{ fontSize: "2rem" }} />
                    </IonFabButton>
                    <p className="text-problematic-center ion-text-center">Aggiungi foto di copertina</p>
                    </> }
                    {error && (
                        <p className="text-danger mt-0 mx-8 fs-7 absolute-required-field-detail">Campo obbligatorio</p>
                    )}
                </IonCol>
            ) :
                <IonCol sizeLg="3" sizeMd="12" sizeSm="12" sizeXs="12">
                    <div className="each-slide">
                        <div style={{ 'backgroundImage': `url(${showPhoto})`, 'height': '300px' }}>
                            {(dataDetail.stato === "In Approvazione") || currentUserParse?.profilo === RoleID.LETTORE ? null : (
                                <IonFabButton color="danger" className="mt-1 ms-2" onClick={() => setShowModalDeletePhoto(true)}>
                                    <IonIcon icon={trashOutline} style={{ fontSize: "2rem" }} />
                                </IonFabButton>
                            )}
                        </div>
                    </div>
                </IonCol>
            }
            <CameraCustom ref={cameraRef} callBackSavePhoto={onSavePhoto} />
        </>
    );
}

export default CoverImage;

