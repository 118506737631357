import { v4 as uuidv4 } from "uuid";

import { useCallback, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import {
    deleteUtente,
    ENDPOINT_SETTINGS,
    insertUtente,
    updateUtente,
} from "../../service/api";

import { FieldValues, UseFormReset } from "react-hook-form";
import {
    useEntityConnectivity,
    useEntitySettings,
    useEntityUser,
} from "../../store";
import {
    ModificationRoleType,
    SettingFormModalType,
} from "./useSettingsFormHook";
import { RoleID } from "../Detail/useResponseDetail";

const INITIAL_STATE_USER = {
    nome: "",
    cognome: "",
    ruolo: "",
    mail: "",
};
export type AddUserType = typeof INITIAL_STATE_USER;

interface Props {
    reset: UseFormReset<FieldValues>;
    setMessage: (message: string, isErrorMessage: boolean) => void;
    setShowLoader: (isLoading: boolean) => void;
    onClose: Function;
    modal: SettingFormModalType;
    asBu: number | null;
}

export const useCRUDUser = ({ reset, modal, onClose, setMessage, setShowLoader, asBu }: Props) => {
    const { user: currentUserParse } = useEntityUser();
    const { add, getAll, deleteRecord, update } = useIndexedDB("settings");
    const [isUserUpdated, setIsUserUpdated] = useState<boolean>(false);

    const {
        users,
        getAllUsers,
        userLoading,
        userLoadingError,
        setUserLoading,
        setUserLoadingError,
        addToUsers,
        deleteUser,
        updateUser,
        getFornitori
    } = useEntitySettings((store) => store);
    const { connectivity: isOnline } = useEntityConnectivity();

    const onDeleteUser = useCallback(() => {
        setShowLoader(true);
        const data = {
            idUtente: +currentUserParse.id,
            id: modal.isDeleting,
        };

        const checkIfDataAlreadyExist = async () => {
            const record = await getAll();
            const sameID = record.find(
                (lastUpdate) =>
                    lastUpdate.data.id === modal.isDeleting &&
                    lastUpdate.endpoint !== ENDPOINT_SETTINGS.updateUtente
            );

            sameID
                ? await deleteRecord(sameID.id)
                : await add({ endpoint: ENDPOINT_SETTINGS.deleteUtente, data });

            deleteUser(modal.isDeleting);
        };

        const onError = () => {
            setMessage("Errore Elimina Utente", true);
            setUserLoadingError(true);
        };

        isOnline
            ? deleteUtente(
                  data,
                  () => {
                      setMessage("Elimina utente con successo", false);
                      setUserLoading(true);
                      getAllUsers(+currentUserParse.id, asBu);
                  },
                  onError
              )
            : checkIfDataAlreadyExist();

        onClose();
        setShowLoader(false);
    }, [add, currentUserParse.id, deleteRecord, deleteUser, getAll, getAllUsers, isOnline, modal.isDeleting, onClose, setMessage, setShowLoader, setUserLoading, setUserLoadingError, asBu]);

    const onUpdateUserRole = useCallback(
        async ({ id, valueRole }: ModificationRoleType) => {
            setShowLoader(true);
            setIsUserUpdated(true);

            const data = {
                idUtente: +currentUserParse.id,
                id,
                ruolo: +valueRole,
            };

            const checkIfDataAlreadyExist = async () => {
                const record = await getAll();
                const sameID = record.find(
                    (lastUpdate) => lastUpdate.data.id === id
                );

                sameID
                    ? update({
                          ...sameID,
                          data: { ...sameID.data, ruolo: +valueRole },
                      })
                    : await add({
                          endpoint: ENDPOINT_SETTINGS.updateUtente,
                          data,
                      });

                updateUser(id, +valueRole);
            };

            const onError = () => {
                setMessage("Errore Modifica Utente", true);
                setUserLoadingError(true);
            };

            isOnline
                ? await updateUtente(
                      data,
                      () => {
                          setMessage("Modifica Utente con successo", false);
                          setUserLoading(true);
                          getAllUsers(+currentUserParse.id, asBu);
                      },
                      onError
                  )
                : checkIfDataAlreadyExist();

            setIsUserUpdated(false);
            onClose();
            setShowLoader(false);
        },
        [setShowLoader, currentUserParse.id, isOnline, onClose, getAll, update, add, updateUser, setMessage, setUserLoadingError, setUserLoading, getAllUsers, asBu]
    );

    const submitAddUser = useCallback(
        async (formData: AddUserType) => {
            setShowLoader(true);
            const data = {
                id: uuidv4(),
                idUtente: +currentUserParse.id,
                ...formData,
            };

            const onSuccess = (message: string) => {
                setMessage(message, false);
                setUserLoading(true);
                //reset();
                getAllUsers(+currentUserParse.id,asBu);
            };

            const onError = (message:string) => {
                setMessage(message, true);
                setUserLoadingError(true);
            };

            //todo inserisco N utenti però la get me torna solo una (l'ultimo inserito).
            const addToDB = async () => {
                await add({
                    endpoint: ENDPOINT_SETTINGS.insertUtente,
                    data: data,
                });

                const { idUtente, ruolo, ...user } = data;

                addToUsers({
                    gruppo: null,
                    profiloID: +ruolo,
                    ...user,
                });
                reset();
            };
            isOnline ? await insertUtente(data, onSuccess, onError) : addToDB();

            if(Number(formData.ruolo) === RoleID.BME) {
                getFornitori(+currentUserParse.id);
            }
            onClose();
            setShowLoader(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [setShowLoader, currentUserParse.id, isOnline, onClose, setMessage, setUserLoading, getAllUsers, asBu, setUserLoadingError, add, addToUsers, reset, getFornitori]
    );

    return {
        users,
        submitAddUser,
        onUpdateUserRole,
        onDeleteUser,
        userLoading,
        userLoadingError,
        isUserUpdated,
    };
};
