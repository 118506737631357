import { IonButton, IonCol, IonContent, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonLabel, IonPage, IonRow, IonToast, IonToolbar } from '@ionic/react';
import IconTagData from '../../assets/icon/sideBar/icon-tag.svg';
import IconChecklist from '../../assets/icon/sideBar/icon-checklist.svg';
import IconGallery from '../../assets/icon/sideBar/icon-photo.svg';
import IconReport from '../../assets/icon/sideBar/icon-report.svg';
import { NavLink, useParams } from 'react-router-dom';
import { ParamsInspection } from '../../Models/ParamsInspection';

export interface MenuMobileProps {
    orientation?: string;
    disableLink: boolean;
}

const MenuMobile: React.FC<MenuMobileProps> = ({ orientation, disableLink }) => {

    let { id, numberInspection } = useParams<ParamsInspection>();
    
    return (!disableLink &&
        // <div style={{borderTop: "1px solid #ededed"}} id={orientation}>
        <IonHeader className="border-top ion-hide-lg-up" id={orientation}>
            <IonToolbar > 
                <IonRow className="ion-hide-lg-up ion-text-center py-2 bg-white">
                    <IonCol size="3">
                        <NavLink to={disableLink ? "#" : `/dati/${id}/${numberInspection}`} >
                            <img className="icon-sidebar" src={IconTagData} alt="dati" />
                            <h6 className="m-0 title-sidebar ion-text-uppercase icon-svg">Dati</h6>
                        </NavLink>
                    </IonCol>
                    <IonCol size="3">
                        <NavLink to={disableLink ? "#" :`/checklist/${id}/${numberInspection}`} >
                            <img className="icon-sidebar" src={IconChecklist} alt="checklist" />
                            <h6 className="m-0 title-sidebar ion-text-uppercase">Checklist</h6>
                        </NavLink>
                    </IonCol>
                    <IonCol size="3">
                        <NavLink to={disableLink ? "#" :`/gallery/${id}/${numberInspection}`} >
                            <img className="icon-sidebar" src={IconGallery} alt="gallery" />
                            <h6 className="m-0 title-sidebar ion-text-uppercase">Gallery</h6>
                        </NavLink>
                    </IonCol>
                    <IonCol size="3">
                        <NavLink to={disableLink ? "#" :`/report/${id}/${numberInspection}`} >
                            <img className="icon-sidebar" src={IconReport} alt="report" />
                            <h6 className="m-0 title-sidebar ion-text-uppercase">Report</h6>
                        </NavLink>
                    </IonCol>
                </IonRow>
             </IonToolbar>
        </IonHeader>
// </div>

    );
}

export default MenuMobile;