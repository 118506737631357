import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import Modal from "../../../components/Modal/Modal";

interface AlertDeleteProps{
    modalDelete: boolean, 
    setModalDelete: React.Dispatch<React.SetStateAction<boolean>>, 
    cancelProblematic: () => void, 
}

export const AlertDelete = ({
    modalDelete,
    setModalDelete,
    cancelProblematic,
}: AlertDeleteProps) => {
    return <Modal
        isOpen={modalDelete}
        title={"Elimina definitivamente?"}
        onIsOpenChange={setModalDelete}
    >
        <IonGrid className="m-0 mx-3">
            <IonRow>
                <IonCol>
                    <p className="m-0">
                        Attenzione questa operazione e irreversibile
                    </p>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end ion-align-items-end">
                <IonCol sizeXl="3" className="ion-text-end pe-0">
                    <IonButton
                        expand="block"
                        fill="solid"
                        className="fw-bold ion-text-uppercase"
                        onClick={()=> setModalDelete(false)}
                    >
                        no
                    </IonButton>
                </IonCol>
                <IonCol sizeXl="3" className="ion-text-end ps-0">
                    <IonButton
                        expand="block"
                        fill="solid"
                        color="danger"
                        className="fw-bold ion-text-uppercase"
                        onClick={cancelProblematic}
                    >
                        si
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    </Modal>;
}