import { IonRow, IonCol, IonLabel, IonButton } from '@ionic/react';
import TextField from "@material-ui/core/TextField";
import { Controller, useFormContext } from "react-hook-form";
import CustomInput from '../../../components/CustomInput/CustomInput';
import CustomInputNumberFormat from '../../../components/CustomInput/CustomInputNumberFormat';
import SelectSearchable from '../../../components/SelectSearchable/SelectSearchable';
import { onChangeValue, result } from '../../../utiles';
import { T_INTERVENTO, LIV_INTERVENTO, COMPENTANCE } from '../constants';
import { makeStyles, createStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useSendToApproval } from '../useSendToApproval';
import { useParams } from 'react-router';
import { ParamsInspection } from '../../../Models/ParamsInspection';
import { Photo } from '../../../Models/Photo';

const useStylesForTextMultiLine = makeStyles(() =>
    createStyles({
        root: {
            width: "100%",
            marginTop: "0.5rem",
        },
    })
);
interface ProblematicFormProps{
    cancelButton(): void,
    photos: Photo[],
    requisitoFoto: boolean,
    enableSave: boolean
}
export const ProblematicForm:React.FC<ProblematicFormProps> = ({ enableSave, cancelButton, photos, requisitoFoto, children }) => {
    const { numberInspection } = useParams<ParamsInspection>()
    const classes = useStylesForTextMultiLine();
    const { control, trigger, formState: { errors } } = useFormContext();
    const sendToApproval = useSendToApproval(numberInspection);
    
    useEffect(()=>{
        !!sendToApproval && trigger();
    }, [sendToApproval, trigger])

    console.log(requisitoFoto);
    console.log((photos?.length ?? 0) > 0);

    return (
        <>
            <IonRow>
                {children}
                <IonCol sizeLg="7" sizeSm="12" sizeXs="12" className="pt-0">
                    <IonRow>
                        <IonCol className="px-0">
                            <Controller
                                control={control}
                                name='didascalia'
                                rules={{ required: requisitoFoto || (photos?.length ?? 0) > 0 }}
                                render={({ field: { onChange, value, } }) =>
                                    <CustomInput
                                        label={"Descrizione breve" + ((requisitoFoto || (photos?.length ?? 0) > 0) ? "*" : "")}
                                        value={value}
                                        setValue={onChange}
                                        error={!!errors?.didascalia}
                                        //disabled={(photos?.length ?? 0) < 1}
                                    />
                                }
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="px-0">
                            <Controller
                                control={control}
                                name='localizzazione'
                                rules={{ required: true }}
                                render={({ field: { onChange, value, } }) =>
                                    <CustomInput
                                        label="Localizzazione*"
                                        value={value}
                                        setValue={onChange}
                                        error={!!errors?.localizzazione}
                                    />
                                }
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeLg="6" sizeSm="12" sizeXs="12" className="ps-0">
                            <Controller
                                control={control}
                                name='tipologiaIntervento'
                                rules={{ required: true }}
                                render={({ field: { onChange, value, } }) =>
                                    <SelectSearchable
                                        label="tipologia intervento*"
                                        options={T_INTERVENTO}
                                        setValue={onChangeValue(onChange)}
                                        value={result(T_INTERVENTO, value)}
                                        error={!!errors?.tipologiaIntervento}
                                    />
                                }
                            />

                        </IonCol>
                        <IonCol sizeLg="6" sizeSm="12" sizeXs="12" className="pe-0">
                            <Controller
                                control={control}
                                name='livelloIntervento'
                                rules={{ required: true }}
                                render={({ field: { onChange, value, } }) =>
                                    <SelectSearchable
                                        label="Livello di intervento*"
                                        options={LIV_INTERVENTO}

                                        setValue={onChangeValue(onChange)}
                                        value={result(LIV_INTERVENTO, value)}

                                        error={!!errors?.livelloIntervento}
                                    />
                                }
                            />

                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonLabel className="label p-0 ion-text-capitalize">
                        Descrizione*
                    </IonLabel>
                    <Controller
                        control={control}
                        name='descrizione'
                        rules={{ required: true }}
                        render={({ field: { onChange, value, } }) =>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                variant="outlined"
                                className={classes.root}
                                value={value || ''}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onChange(event.target.value);
                                }}
                                error={!!errors?.descrizione}
                                helperText={!!errors?.descrizione ? "Campo obbligatorio" : ""}
                            />
                        }
                    />

                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='competenza'
                        rules={{required: true}}
                        render={({ field: { onChange, value, } }) =>{
                           return  <SelectSearchable
                                label="Competenza*"
                                options={COMPENTANCE}
                                setValue={onChangeValue(onChange)}
                                value={result(COMPENTANCE, value)}
                                error={!!errors?.competenza}

                            />
                       } }
                    />

                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='stimaCosti'
                        rules={{
                            required: !!sendToApproval && 'Campo Obbligatorio',
                            validate: (value) => {
                                return value.length < 12 || 'Valore massimo raggiunto'
                            }
                           
                        }}
                        defaultValue=''
                        render={({ field: { onChange, value, } }) =>{
                            return <CustomInputNumberFormat
                                label="stima costi"
                                symbol="€"
                                number={value}
                                setValue={ (event: React.ChangeEvent<HTMLInputElement>) =>
                                        onChange(event.target.value)
                                }
                                error={errors?.stimaCosti?.message}
                            />
                        }}
                    />

                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='possibiliCause'
                        rules={{ required: !!sendToApproval }}
                        defaultValue=''
                        render={({ field: { onChange, value, } }) =>
                            <CustomInput
                                label="Possibili cause"
                                value={value}
                                setValue={onChange}
                                error={!!errors?.possibiliCause}
                            />
                        }
                    />

                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='intervento'
                        defaultValue=''
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) =>
                            <CustomInput
                                label="Intervento"
                                value={value}
                                setValue={onChange}
                                error={!!errors?.intervento}
                            />
                        }
                    />

                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end">
                <IonCol sizeXl="3" className="ion-text-end pe-0">
                    <IonButton
                        expand="block"
                        fill="clear"
                        className="fw-bold"
                        onClick={cancelButton}
                    >
                        ANNULLA
                    </IonButton>
                </IonCol>
                {   enableSave
                    && (
                        <IonCol sizeXl="3" className="ion-text-end ps-0">
                            <IonButton
                                type='submit'
                                expand="block"
                                fill="solid"
                                className="fw-bold"
                            // onClick={onSubmitAddProblematic}
                            >
                                AGGIUNGI
                            </IonButton>
                        </IonCol>
                        )
                }
            </IonRow>
        </>
    )
}
