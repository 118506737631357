import { CreateFornitore, ToggleAssosiationFornitore, ToggleAssosiationFornitoreDTO, UpdateFornitore } from "../../Models/Fornitori";
import { CreateGroupDTO, UpdateGroupDTO } from "../../Models/Group";
import { requestPostOptions } from "../crud";

interface InsertUserResponseDto {
    esito: boolean;
    messaggio: string;
}

const BaseUrl: string = process.env.REACT_APP_BASE_URL_API; 

export const ENDPOINT_SETTINGS = {
    insertGruppo: `${BaseUrl}/Gruppi/InsertGruppo`,
    insertUtente: `${BaseUrl}/Utenti/InsertUtente`,
    getGruppi: `${BaseUrl}/Gruppi/GetGruppi`,
    getAttivi: `${BaseUrl}/Utenti/GetAttivi`,
    deleteGruppo: `${BaseUrl}/Gruppi/DeleteGruppo`,
    updateGruppo: `${BaseUrl}/Gruppi/UpdateGruppo`,
    updateUtente: `${BaseUrl}/Utenti/UpdateUtente`,
    deleteUtente: `${BaseUrl}/Utenti/DeleteUtente`,

    getUserForFornitori: `${BaseUrl}/Utenti/GetUtentiAggiungibiliFornitore`,
    getFornitori: `${BaseUrl}/Fornitori/GetFornitori`,
    insertFornitore: `${BaseUrl}/Fornitori/InsertFornitore`,
    deleteFornitore: `${BaseUrl}/Fornitori/DeleteFornitore`,
    updateFornitore: `${BaseUrl}/Fornitori/UpdateFornitore`,    
    associateFornitore: `${BaseUrl}/Fornitori/AssociaFornitoreBusinessUnit`,
    disassociateFornitore: `${BaseUrl}/Fornitori/DisassociaFornitoreBusinessUnit`    
}

export const requestGetGruppi = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.getGruppi, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const requestGetAllUsers = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.getAttivi, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const insertUtente = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.insertUtente, options).then(response => response.json()) as InsertUserResponseDto;
        promise.esito ? onSuccess(promise.messaggio) : onError(promise.messaggio);
    } catch (error) {
        onError(error.message);
    }
};
export const insertGruppo = async (data: CreateGroupDTO, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<CreateGroupDTO>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.insertGruppo, options).then(response => response.json());
        // const result = await promise.json();
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const deleteGruppo = async <T>(data: T, onSuccess: Function, onError: Function, onFinal?: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.deleteGruppo, options).then(response => response.json());
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    } finally {
        onFinal?.();
    }
};
export const updateGruppo = async (data: UpdateGroupDTO, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<UpdateGroupDTO>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.updateGruppo, options).then(response => response.json());
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const updateUtente = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.updateUtente, options).then(response => response.json());
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const deleteUtente = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.deleteUtente, options);
        if (promise) onSuccess().then(response => response.json());
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};

export const requestGetUserForFornitori = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.getUserForFornitori, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const requestGetFornitori = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.getFornitori, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const insertFornitoreAPI = async (data: CreateFornitore, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<CreateFornitore>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.insertFornitore, options).then(response => response.json());
        // const result = await promise.json();
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
export const deleteFornitoreAPI = async <T>(data: T, onSuccess: Function, onError: Function, onFinal?: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.deleteFornitore, options).then(response => response.json());
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    } finally {
        onFinal?.();
    }
};
export const updateFornitoreAPI = async (data: UpdateFornitore, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<UpdateFornitore>(data);
    try {
        const promise = await fetch(ENDPOINT_SETTINGS.updateFornitore, options).then(response => response.json());
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};

export const toggleAssosiationFornitoreAPI = async (data: ToggleAssosiationFornitore, onSuccess: Function, onError: Function) => {
    const { associate, ...apiData } = data;
    const url = associate ? ENDPOINT_SETTINGS.associateFornitore : ENDPOINT_SETTINGS.disassociateFornitore;

    const options = requestPostOptions<ToggleAssosiationFornitoreDTO>(apiData);
    try {
        const promise = await fetch(url, options).then(response => response.json());
        if (promise) onSuccess();
        if (!promise) onError(promise);
    } catch (error) {
        onError(error.message)
    }
};