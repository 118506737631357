import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { IonLabel } from '@ionic/react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                marginTop: theme.spacing(1),
            },
        },
    }),
);


interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={"."}
            decimalSeparator={","}
        />
    );
}


export interface CustomInputNumberFormatProps {
    label: string;
    symbol: string;
    number: string;
    error?: boolean;
    disabled?: boolean;
    className?: string;
    setValue: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomInputNumberFormat: React.VFC<CustomInputNumberFormatProps> = ({ label, symbol, number, error, disabled, className, setValue }) => {

    const classes = useStyles();

    return (
        <>
            <IonLabel className="label p-0 ion-text-capitalize">{label}</IonLabel>
            <TextField
                value={number}
                disabled={disabled}
                onChange={setValue}
                className={className}
                name="numberformat"
                id="formatted-numberformat-input"
                variant="outlined"
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    endAdornment: <InputAdornment position="end">{symbol}</InputAdornment>
                }}
                classes={classes}
                fullWidth
                error={!!error}
                helperText={!!error && (error || "Campo obbligatorio")}
            />
        </>
    );
}

export default CustomInputNumberFormat;