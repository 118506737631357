import create from "zustand";
import { CreateFornitore, Fornitore, UpdateFornitore, UsersForFornitori } from "../Models/Fornitori";
import { GetGroupDTO, ListaUtentiGroupModel } from "../Models/Group";
import { Users } from "../Models/Users";
import { requestGetAllUsers, requestGetFornitori, requestGetGruppi, requestGetUserForFornitori } from "../service/api";

function getUserEntitySlice(getStore: Function, setStore:Function){
    return {
        users: [] as Users[],
        errorTextUsers: '',
        userLoading: false,
        setUserLoading:(state)=> setStore({...getStore(), userLoading: state}),
        userLoadingError: false,
        setUserLoadingError:(state)=> setStore({...getStore(), userLoadingError: state}),
        getAllUsers: (idUser: number, asBuId: number | null)=> {
            const onSuccess = (users: Users[]) => setStore({ ...getStore(), users, errorTextUsers: ''});
            const onError = (errorTextUsers) => setStore({ ...getStore(), errorTextUsers});
            requestGetAllUsers({idUtente: idUser, businessUnitId: asBuId}, onSuccess, onError)
        },
        addToUsers: (userToAdd: Users) => {
            setStore({
                ...getStore(),
                users: [
                    ...getStore().users,
                    userToAdd
                ]
            })
        },
        deleteUser: (idUser: string | number) => {
            setStore({
                ...getStore(),
                users: getStore().users.filter((user: Users) => user.id !== idUser)        
            })
        },
        updateUser: (idUser: string | number, newRole: number) => {
            const users: Users[] = getStore().users;
            setStore({
                ...getStore(),
                users: users.map((user: Users) => user.id === idUser ? { ...user, profiloID: newRole } : user )
            })
        },
    }

}

function getGroupEntitySlice(getStore: Function, setStore:Function){
    return {
        group: [] as GetGroupDTO[],
        errorTextGroup: '',
        groupLoading: false,
        setGroupLoading:(state)=> setStore({...getStore(), groupLoading: state}),
        groupLoadingError: false,
        setGroupLoadingError:(state)=> setStore({...getStore(), groupLoadingError: state}),
        getGroup: (idUser:number, asBuId: number | null) => {
            const onSuccess = (group: GetGroupDTO[]) => setStore({...getStore(), group,errorTextGroup: ''});
            const onError = (errorTextGroup) => setStore({ ...getStore(), errorTextGroup});
            requestGetGruppi({idUtente: idUser, businessUnitId: asBuId}, onSuccess, onError)
        },
        addToGroup: (groupName: string, usersToAdd: ListaUtentiGroupModel[], id: number) => {
            const groupToAdd: Omit<GetGroupDTO, "idUtente"> = {
            nome: groupName,
            id: id,
            listaUtenti: usersToAdd
            }
            setStore({
                ...getStore(),
                group: [
                    ...getStore().group,
                    groupToAdd
                ]
            })
        },
        deleteFromGroup: (groupID: string | number) => {
            setStore({
                ...getStore(),
                group: getStore().group.filter((group: GetGroupDTO) => group.id !== groupID)   
            })
        },
        updateGroup: (groupId: string | number, newName: string, usersToAdd: ListaUtentiGroupModel[]) => {
            const groups: GetGroupDTO[] = getStore().group;
            setStore({
                ...getStore(),
                group: groups.map((currentGroup: GetGroupDTO) => currentGroup.id === groupId ? { ...currentGroup, nome: newName, listaUtenti: usersToAdd } : currentGroup )
            })
        },
    
    }

}

function getFornitoriEntitySlice(getStore: Function, setStore:Function){
    return {
        fornitori: [] as Fornitore[],
        userForFornitori:[] as UsersForFornitori[],
        errorTextfornitori: '',
        fornitoriLoading: false,
        setFornitoriLoading:(state)=> setStore({...getStore(), fornitoriLoading: state}),
        fornitoriLoadingError: false,
        setFornitoriLoadingError:(state)=> setStore({...getStore(), fornitoriLoadingError: state}),
        getUserForFornitori: (idUser:number) => {
            const onSuccess = (userForFornitori: Users[]) => setStore({...getStore(), userForFornitori, errorTextfornitori: ''});
            const onError = (errorTextfornitori) => setStore({ ...getStore(), errorTextfornitori});
            requestGetUserForFornitori({idUtente: idUser }, onSuccess, onError)
        },
        getFornitori: (idUser:number) => {
            const onSuccess = (fornitori: Fornitore[]) => setStore({...getStore(), fornitori, errorTextfornitori: ''});
            const onError = (errorTextfornitori) => setStore({ ...getStore(), errorTextfornitori});
            requestGetFornitori({idUtente: idUser}, onSuccess, onError)
        },
        addFornitore: (fornitoriToAdd: CreateFornitore) => {
            setStore({
                ...getStore(),
                fornitori: [
                    ...getStore().fornitori,
                    fornitoriToAdd
                ]
            })
        },
        deleteFornitore: (fornitoriID: string | number) => {
            setStore({
                ...getStore(),
                fornitori: getStore().fornitori.filter((fornitori: Fornitore) => fornitori.id !== fornitoriID)   
            })
        },
        updateFornitore: (data: Partial<UpdateFornitore> & Required<Pick<UpdateFornitore, "idFornitore">>) => {
            const fornitoris: Fornitore[] = getStore().fornitori;
            setStore({
                ...getStore(),
                fornitori: fornitoris.map((currentfornitori: Fornitore) => currentfornitori.id === data.idFornitore ? { ...currentfornitori, ...data } : currentfornitori )
            })
        },
    
    }

}

export const useEntitySettings = create((set: Function, get: Function) => ({
    ...getUserEntitySlice(get, set),
    ...getGroupEntitySlice(get, set),
    ...getFornitoriEntitySlice(get, set),
}));