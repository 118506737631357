import { useState } from "react";

import { NoteAudio } from '../Models/NoteAudio';

import { base64FromPath } from '@ionic/react-hooks/filesystem';
import { EmptyRecAudio } from "../TypesUtiles";

export function useRecAudio() {


    const [audioDetails, setAudioDetails] = useState<NoteAudio>(EmptyRecAudio())
    const [saveAudioBase64, setSaveAudioBase64] = useState<string>();

    const saveAudio = async (data: NoteAudio) => {
        const base64Data = await base64FromPath(data.url);
        setSaveAudioBase64(base64Data)
    };


    const handleAudioStop = (data: NoteAudio) => {
        setAudioDetails(data);
        saveAudio(data);
    }


    const handleReset = () => {
        setAudioDetails(EmptyRecAudio());
    }


    return { audioDetails, handleAudioStop, saveAudioBase64, handleReset, setAudioDetails }

}