import { v4 as uuidv4 } from "uuid";

import { useCallback } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useIndexedDB } from "react-indexed-db";
import {
    CreateFornitore,
    ToggleAssosiationFornitore,
    UpdateFornitore,
    UtenteFornitore
} from "../../../Models/Fornitori";
import {
    deleteFornitoreAPI,
    ENDPOINT_SETTINGS,
    insertFornitoreAPI,
    toggleAssosiationFornitoreAPI,
    updateFornitoreAPI,
} from "../../../service/api";
import {
    useEntityConnectivity,
    useEntitySettings,
    useEntityUser,
} from "../../../store";
import { isAssociateWCurrentUserBU } from "../../../utiles";
import { SettingFormModalType } from "../useSettingsFormHook";

const INITIAL_STATE_FORNITORE = {
    id: undefined,
    nomeFornitore: "",
    listaUtenti: [] as UtenteFornitore[],
    buAssociate: [] as number[],
};

export type AddFornitoreType = typeof INITIAL_STATE_FORNITORE;

interface Props {
    reset: UseFormReset<FieldValues>;
    setMessage: (message: string, isErrorMessage: boolean) => void;
    setShowLoader: (isLoading: boolean) => void;
    onClose: Function;
    modal: SettingFormModalType;
    asBu: number | null;
}

export const useCRUDFornitori = ({
    reset,
    modal,
    onClose,
    setMessage,
    setShowLoader,
    asBu
}: Props) => {
    const { user: currentUserParse } = useEntityUser();
    const { add, getAll, deleteRecord, update } = useIndexedDB("settings");

    const {
        fornitori,
        fornitoriLoading,
        fornitoriLoadingError,
        getFornitori,
        setFornitoriLoading,
        setFornitoriLoadingError,
        updateFornitore,
        addFornitore,
        deleteFornitore,
    } = useEntitySettings((store) => store);
    const { connectivity: isOnline } = useEntityConnectivity();

    const toggleAssociateBU = (idFornitore:number) => {
        const fornitore = fornitori.find(el => el.id === idFornitore)!;
        let associate = true;
        if(isAssociateWCurrentUserBU(fornitore, currentUserParse.businessUnitId, asBu)) associate = false;

        const url = associate ? ENDPOINT_SETTINGS.associateFornitore : ENDPOINT_SETTINGS.disassociateFornitore;

        const data: ToggleAssosiationFornitore = {
            idUtente: +currentUserParse.id,
            idFornitore,
            associate,
            businessUnitId: asBu,
        };

        const checkIfDataAlreadyExist = async () => {
            const record = await getAll();
            const sameID = record.find(
                (lastUpdate) => lastUpdate.data.idFornitore === idFornitore && lastUpdate.endpoint === url
            );

            if (sameID) await deleteRecord(sameID.id);
            else {
                await add({
                    endpoint: url,
                    data,
                });
            }

            const newBuAssociateList = associate ? [...fornitore.buAssociate, idFornitore] : fornitore.buAssociate.filter(el => el !== idFornitore);

            updateFornitore({
                idFornitore,
                buAssociate: newBuAssociateList
            });
            setFornitoriLoading(false);
        };

        const onError = () => {
            setMessage("Errore nell'operazione", true);
            setFornitoriLoadingError(true);
        };

        if (isOnline) {
            const onSuccess = () => {
                setMessage("Operazione eseguita con successo", false);
                setFornitoriLoading(true);
                getFornitori(+currentUserParse.id);
            };
            toggleAssosiationFornitoreAPI(data, onSuccess, onError);
        } else {
            checkIfDataAlreadyExist();
        }

        setShowLoader(false);
        onClose();
    }

    const onCreateFornitori = useCallback(
        async (dataSubmitted: AddFornitoreType) => {
            setShowLoader(true);
            const { listaUtenti, ...form } = dataSubmitted;

            const temporaryID = uuidv4();

            const data: CreateFornitore & { idFornitore: string } = {
                idFornitore: temporaryID,
                idUtente: +currentUserParse.id,
                ...form,
                listaUtenti: listaUtenti.map(({ id, isOwner}) => ({ id, isOwner })),
            };

            const addToDB = async () => {
                await add({
                    endpoint: ENDPOINT_SETTINGS.insertFornitore,
                    data,
                });
                addFornitore(data);
            };

            const onError = () => {
                setMessage("Errore Aggiunta Fornitore", true);
                setFornitoriLoadingError(true);
            };

            if (isOnline) {
                const onSuccess = () => {
                    setMessage("Aggiunta Fornitore con successo", false);
                    getFornitori(+currentUserParse.id);
                };
                setFornitoriLoading(true);

                await insertFornitoreAPI(data, onSuccess, onError);
            } else addToDB();

            setShowLoader(false);
            onClose();
            reset(INITIAL_STATE_FORNITORE);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [setShowLoader, currentUserParse.id, isOnline, onClose, reset, add, addFornitore, setMessage, setFornitoriLoadingError, setFornitoriLoading, getFornitori]
    );

    const onUpdateFornitori = useCallback(
        (dataSubmitted: AddFornitoreType) => {
            setShowLoader(true);
            const { id, nomeFornitore, listaUtenti, buAssociate } = dataSubmitted;

            const data: UpdateFornitore = {
                idUtente: +currentUserParse.id,
                idFornitore: id,
                nomeFornitore,
                buAssociate,
                listaUtenti: listaUtenti.map(({ id, isOwner }) => ({ id, isOwner })),
            };

            const checkIfDataAlreadyExist = async () => {
                const record = await getAll();
                const sameID = record.find(
                    (lastUpdate) => lastUpdate.data.idFornitore === id
                );

                if (sameID) {
                    await update({
                        ...sameID,
                        data: {
                            ...sameID.data,
                            nome: dataSubmitted.nomeFornitore,
                            listaUtenti: dataSubmitted.listaUtenti,
                        },
                    });
                } else
                    await add({
                        endpoint: ENDPOINT_SETTINGS.updateFornitore,
                        data,
                    });

                updateFornitore({
                    idFornitore: id,
                    nomeFornitore,
                    listaUtenti,
                });
                setFornitoriLoading(false);
            };

            const onError = () => {
                setMessage("Errore Modifica Fornitore", true);
                setFornitoriLoadingError(true);
            };

            if (isOnline) {
                const onSuccess = () => {
                    setMessage(
                        "Modifica Fornitore eseguita con successo",
                        false
                    );
                    setFornitoriLoading(true);
                    getFornitori(+currentUserParse.id);
                };
                updateFornitoreAPI(data, onSuccess, onError);
            } else {
                checkIfDataAlreadyExist();
            }

            setShowLoader(false);
            onClose();
            reset(INITIAL_STATE_FORNITORE);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [add, currentUserParse.id, getAll, getFornitori, isOnline, onClose, reset, setFornitoriLoading, setFornitoriLoadingError, setMessage, setShowLoader, update, updateFornitore]
    );

    const onDeleteFornitori = useCallback(() => {
        setShowLoader(true);
        const data = {
            idUtente: +currentUserParse.id,
            id: modal.isDeleting,
            idFornitore: modal.isDeleting,
        };

        const checkIfDataAlreadyExist = async () => {
            const record = await getAll();
            const sameID = record.find(
                (lastUpdate) =>
                    lastUpdate.data.idFornitore === modal.isDeleting &&
                    lastUpdate.endpoint !== ENDPOINT_SETTINGS.updateFornitore
            );

            sameID
                ? await deleteRecord(sameID.id)
                : await add({
                      endpoint: ENDPOINT_SETTINGS.deleteFornitore,
                      data,
                  });

            deleteFornitore(modal.isDeleting);
        };

        const onError = () => {
            setMessage("Errore Elimina Fornitore", true);
            setFornitoriLoadingError(true);
        };

        if(isOnline) {
            const onSuccess = () => {
                setMessage("Fornitore eliminato con successo", false);
                setFornitoriLoading(true);
                getFornitori(+currentUserParse.id);
            }
            deleteFornitoreAPI(data, onSuccess, onError)
        } else checkIfDataAlreadyExist();

        onClose();
        setShowLoader(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentUserParse.id,
        deleteFornitore,
        getFornitori,
        isOnline,
        modal.isDeleting,
        setFornitoriLoading,
    ]);

    return {
        fornitori,
        onCreateFornitori,
        onUpdateFornitori,
        onDeleteFornitori,
        toggleAssociateBU,
        fornitoriLoading,
        fornitoriLoadingError,
    };
};
