import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import { MouseEventHandler } from 'react';


export interface ChildrenModalDeleteAllProblematicProps {
    handleClickNo: () => void;
    handleClickYes: () => void;
    text: string;
}

const ChildrenModalDeleteAllProblematic: React.FC<ChildrenModalDeleteAllProblematicProps> = ({handleClickNo,handleClickYes, text}) => {
    return (
        <IonGrid className="m-0 mx-3">
            <IonRow>
                <IonCol>
                    <p className="m-0">{text}</p>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end ion-align-items-end">
                <IonCol sizeXl="3" className="ion-text-end pe-0">
                    <IonButton expand="block" fill="solid" className="fw-bold" onClick={handleClickNo}>no</IonButton>
                </IonCol>
                <IonCol sizeXl="3" className="ion-text-end ps-0">
                    <IonButton expand="block" fill="solid" color="danger" className="fw-bold" onClick={handleClickYes}>si</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}

export default ChildrenModalDeleteAllProblematic;