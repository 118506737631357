import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from "@material-ui/core"
import { ChangeEvent } from 'react';

export interface MultiselectOption {
  label: string;
  value: any;
}

interface MultiselectProps {
  id?:string;
  options: MultiselectOption[];
  defaultValues?: MultiselectOption[];
  label: string;
  placeholder?: string;
  onChange?: (event: ChangeEvent<{}>, newValues: MultiselectOption[]) => void;
  value: MultiselectOption[];
  errors?: boolean;
}

export const Multiselect = ({ id, options, defaultValues, label, placeholder, onChange, value, errors = false }: MultiselectProps) => {

  return (
    <Autocomplete
      multiple
      id={id}
      options={options}
      getOptionLabel={(option) => option.label}
      defaultValue={defaultValues}
      onChange={onChange}
      value={value}  
      renderInput={(params) => (
      <TextField
          {...params}
          variant="outlined"
          label={label}
          InputLabelProps={{style: {fontWeight: 600}}}
          placeholder={placeholder}
          error={errors} 
          helperText={errors ? "Campo obbligatorio" : null}
      />  
    )}
  />  
)}
