import { useState } from "react";

export function useTooltip() {
    const [tooltip, setTooltip] = useState<string>("");

    const closeTooltip = () => {
        setTooltip("")
    }
    const openTooltip = (prop: string) => {
        setTooltip(prop)
    }
    const toggleTooltip = (prop: string) => {
        if(tooltip === prop){
            setTooltip("")
        }else{
            setTooltip(prop)
        }
    }

    return {tooltip, closeTooltip, openTooltip, toggleTooltip}
}