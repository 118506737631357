import create from 'zustand';
import { getApprovatori, getExportCosti, getReport } from '../service/api';
import { EmptyReport, EmptyExportReport, EmptyApprovers } from '../TypesUtiles';

export const useEntityReport = create((set: Function, get: Function) => ({
    dataReport: EmptyReport(),
    dataForExport: EmptyExportReport(),
    approvers: EmptyApprovers(),
    refreshReport: async <T>(data: T) => getReport(data, onSucces(set, get, 'dataReport'), onError(set, get, 'dataReport')),
    refreshExportReport: async <T>(data: T) => getExportCosti(data, onSucces(set, get, 'dataForExport'), onError(set, get, 'dataForExport')),
    refreshReportApprovers: async <T>(data: T) => getApprovatori(data, onSucces(set, get, 'approvers'), onError(set, get, 'approvers')),
}));

const onSucces = (set: Function, get: Function, stateToUpdate: string) => <T>(data: T) => {
    set({ 
        ...get(),
        [stateToUpdate]: data 
    });
} 

const onError = (set: Function, get: Function, stateToUpdate: string) => () => {
    set({ 
        ...get(),
        [stateToUpdate]: get().stateToUpdate
    });
} 

// const onSucces = (set: Function) => (dataReport: Report) => set({ dataReport });
// const onError = (set: Function, get: Function) => (error: Error) => set({ dataReport: get().dataReport });
