import { IonIcon, IonFabButton, IonFab, IonContent, IonCol, IonGrid, IonRow, IonModal } from '@ionic/react';
import { closeOutline, mic } from 'ionicons/icons';
import InfoIcon from '@material-ui/icons/Info';
import { useMemo } from 'react';
import { Theme, withStyles } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { useTooltip } from "../../hooks/useTooltip";
import './modal.css';
export interface ModalProps {
  title: string;
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
  handleOpenRecMic?: () => void;
  audioBase64DB?: string;
  errorRecAudio?: boolean;
}
export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
  },
}))(Tooltip);

const Modal: React.FC<ModalProps> = ({ isOpen, title, onIsOpenChange, children, handleOpenRecMic,audioBase64DB, errorRecAudio }) => {

  const onDidDismiss = () => onIsOpenChange(false);
  const { tooltip, closeTooltip, openTooltip, toggleTooltip } = useTooltip();
  const handleCss = useMemo((): string => {
    switch (title) {
      case "Aggiungi problematica":
      case "Modifica Problematica":
        return "my-modal-add-problematic"
      case "Aggiungi sopralluogo":
        return "my-modal-add-inspection"
      case "Filtri":
        return "my-modal-filter"
      case "Aggiungi foto":
        return "my-modal-addPhoto"
      case "Manda in approvazione":
        return "my-modal-approve"
      case "Riassegna soppraluogo":
        return "my-modal-approve"
        case "Manda in revisione":
          return "my-modal-approve"
      case "Rifiuta":
        return "my-modal-delete"
      case "Alert":
      case "Attenzione":
      case "Elimina definitivamente?":
      case "Conferma approvazione":
      case "Elimina foto":
      case "Approva":
      case "Cancella Utente":
      case "Cancella Gruppo":
      case "Nuova versione":
        return "my-modal-alert"
      case "Descrizione sommaria audio":
      case "Registrazione audio":
        return "my-modal-rec-audio"
      case "Aggiungi Utente":
        return "my-modal-add-user"
      case "Aggiungi Gruppo":
      case "Modifica gruppo":
        return "my-modal-group"
      case "Attenzione!":
        return "modal-translate";
      default:
        return ''
    }
  },[title])
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const className = `${handleCss} modal-body`;
  return (
    <IonModal isOpen={isOpen} className={className} onDidDismiss={onDidDismiss} >
      <IonContent>
        <IonGrid>
          <IonRow className="ion-align-items-center ion-justify-content-between mx-3">
            <IonCol size="8" className="p-0">
              {title.toLowerCase() === "aggiungi problematica" || title.toLowerCase() === "modifica problematica" ? (
                <>
                  <h2 className="d-inline-block">{title}</h2>
                  <IonFab className="d-inline-block ms-2 mt-2">
                    <IonFabButton size="small" color={audioBase64DB ? "secondary" : "primary"} onClick={handleOpenRecMic} className="m-0">
                      <IonIcon icon={mic} className="pointer fs-3" />
                    </IonFabButton>
                  </IonFab>
                  {errorRecAudio && <p className="d-inline ms-5_5 text-danger fs-6">Nota audio obbligatoria</p>}
                </>
              ) : (
                <h2>{title}
                {title.toLowerCase() == "aggiungi sopralluogo" ? (
                <HtmlTooltip
                placement="right"
                onClick={() => toggleTooltip("stato")}
                onOpen={() => openTooltip("stato")}
                onClose={() => closeTooltip()}
                open={tooltip == "stato"}
                title={
                    <p>
                        <p><span className="fw-bold">{"Le società/UE devono essere presenti in SAP e soddisfare i seguenti requisiti:"}</span></p>
                        <ol>
                          <li>{`Società con date di inizio/fine gestione compatibili`}</li>
                          <li>{`UE non vendute (cioè senza data vendita e con almeno una sede tecnica non venduta)`}</li>
                          <li>{`I seguenti dati valorizzati`}
                          <ul>
                            <li>{`Indirizzo`}</li>
                            <li>{`Centro di lavoro`}</li>
                            <li>{`WBS (solo per commesse di terzi/global censite su Integra0017)`}</li>
                          </ul>
                          </li>
                        </ol>
                    </p>
                }
                >
                <InfoIcon color="action" fontSize="large" style={{ 'verticalAlign': 'middle', 'marginLeft': '0.5rem', 'marginRight': '0px' }} />

                </HtmlTooltip>


              ) : null}
                </h2>
              )}
              
            </IonCol>
            <IonCol size="2" className="ion-text-end p-0">
              <IonIcon icon={closeOutline} color="secondary" className="pointer fs-4" onClick={() => onIsOpenChange(false)} />
            </IonCol>
          </IonRow>
        </IonGrid>
        {children}
      </IonContent>
       
    </IonModal>
  );
}
export default Modal;