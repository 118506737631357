import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomRadioButton from "../../components/CustomRadioButton/CustomRadioButton";
import CustomInputEmail from "../../components/CustomInput/CustomInputEmail";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { useEntityDetail, useEntityUser } from "../../store";
import { useSendToApproval } from "../Checklist/useSendToApproval";
import { RoleID } from "./useResponseDetail";

const Condominio: React.FC = () => {

    const { control, formState:{ errors}} = useFormContext();

    const presenzaCondominio = useWatch({name: 'presenzaCondominio'});

    const { numberInspection } = useParams<ParamsInspection>();
    const dataDetail = useEntityDetail(store => store.dataDetail);
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);

    const { user: currentUserParse } = useEntityUser();

    const shouldBeDisabled = dataDetail.stato === "Approvato" || dataDetail.stato === "In Approvazione" || presenzaCondominio === "NO"  || currentUserParse?.profilo === RoleID.LETTORE;
    const validationRequired = presenzaCondominio !== 'NO';
    return (
        <IonGrid>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='presenzaCondominio'
                        rules={{ required:  !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={dataDetail.stato === "Approvato" || dataDetail.stato === "In Approvazione"  || currentUserParse?.profilo === RoleID.LETTORE}
                                label="Presenza Condominio"
                                value={value || ''}
                                handleChangeValue={onChange}
                                classNO="w-75"
                                classSI="w-75"
                                error={!!errors?.presenzaCondominio}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='denominazione'
                        rules={{ required: validationRequired && !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Denominazione"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.denominazione && presenzaCondominio === "SI"}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='studioSocieta'
                        rules={{ required: validationRequired && !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Studio/Società"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.studioSocieta && presenzaCondominio === "SI"}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='nomeCognome'
                        rules={{ required: validationRequired && !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Nome e Cognome"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.nomeCognome && presenzaCondominio === "SI"}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='indirizzoCondominio'
                        rules={{ required: validationRequired && !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Indirizzo"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.indirizzoCondominio && presenzaCondominio === "SI"}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='mail'
                        rules={{ 
                            required: validationRequired && !!sendToApproval,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Inserire un indirizzo email valido"
                              }
                         }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputEmail
                                disabled={shouldBeDisabled}
                                label="mail"
                                value={value || ''}
                                setValue={onChange}
                                type="email"
                                error={ !!errors?.mail && presenzaCondominio === "SI"}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='telefono'
                        rules={{ required: validationRequired && !!sendToApproval }}
                        render={({ field: { onChange, value } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Tel."
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.telefono && presenzaCondominio === "SI"}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default Condominio