import { IonCol, IonLabel } from '@ionic/react';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControlLabel, Radio } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
export interface CustomRadioButtonProps {
    value: string;
    handleChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    classNO?: string;
    classSI?: string;
    disabled?: boolean;
    error?: boolean;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({ value, handleChangeValue, label, classNO, classSI, disabled, error}) => {
    return (
        <>
            <IonLabel className="label p-0 ion-text-capitalize ms-1">{label}</IonLabel>
            <RadioGroup row className="ms-2 mt-2" aria-label={label} name={label} value={value} onChange={handleChangeValue}>
                <IonCol className="p-0 ms-1 me-0">
                    <FormControlLabel
                        disabled={disabled}
                        value="SI"
                        control={<Radio color="primary" className="p-0 me-1"  />}
                        label="Si"
                        className={`bg-white ps-3 py-3 rounded-1 ${error ? "border-error" : "border"}  w-100 me-0 ${classSI}`} 
                     
                        />
                </IonCol>
                <IonCol  className="py-0 me-0 pe-0">
                    <FormControlLabel
                        disabled={disabled}
                        value="NO"
                        control={<Radio color="primary" className="p-0 me-1" />}
                        label="No"
                        className={`bg-white ps-3 py-3 rounded-1 ${error ? "border-error" : "border"} w-100 mx-0 ${classNO}`} />
                </IonCol>
            </RadioGroup>
            {error && <FormHelperText style={{'color': '#eb445a', 'marginLeft': "14px"}}>Campo obbligatorio</FormHelperText>}
        </>
    );
}

export default CustomRadioButton;