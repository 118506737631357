import { IonButton, IonCol, IonIcon, IonRow, IonSearchbar } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { ReactElement, useMemo, useState } from "react";
import { NoSearchResult } from "../../components/NoSearchResult";
import { useEntityUser } from "../../store";
import { RoleID } from "../Detail/useResponseDetail";

interface SettingListProps<T> {
  addBtnText: string;
  addBtnEnabled: boolean;
  items: T[];
  heading?: ReactElement;
  onAdd(): void;
  validateFilteredItem(item: T, filterValue: string): boolean;
  RowComponent: (item: T) => ReactElement;
}
export const SettingList = <T,>({
  addBtnText,
  addBtnEnabled,
  items,
  validateFilteredItem,
  RowComponent,
  heading = null,
  onAdd,
}: SettingListProps<T>) => {
  const [textToFilter, setTextToFilter] = useState<string>("");

  const filteredItems = useMemo(() => {
    return items.filter((val) => validateFilteredItem(val, textToFilter));
  }, [items, textToFilter, validateFilteredItem]);

  const { user: currentUserParse } = useEntityUser();
  const isCurrentUserLettore = currentUserParse?.profilo === RoleID.LETTORE;

  return (
    <>
      <IonRow className="border-top ion-align-items-center ion-justify-content-between w-100">
        <IonCol size="5">
          <IonSearchbar
            value={textToFilter}
            onIonChange={(e) => setTextToFilter(e.detail.value)}
            color="light"
            placeholder="Cerca..."
          />
        </IonCol>

        <IonCol size="2">
          {!isCurrentUserLettore && addBtnEnabled && (
            <IonButton
              className="ion-text-uppercase ion-float-end"
              fill="solid"
              onClick={onAdd}
            >
              <IonIcon slot="start" icon={addOutline} />
              {addBtnText}
            </IonButton>
          )}
        </IonCol>
      </IonRow>

      {heading}
      
      {filteredItems.length === 0 
        ? <NoSearchResult />
        : filteredItems.map((item) => RowComponent(item))
      }
    </>
  );
};
