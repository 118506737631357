import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';

import './index.css';
//models
//pages
import { IonLoading, IonPage } from '@ionic/react';
import { useEffect } from 'react';
import { initDB } from 'react-indexed-db';
import NavBar from './components/NavBar/NavBar';
import Sidebar from './components/Sidebar/Sidebar';
import { useIsLoading } from './hooks/useIsLoading';
import { useSidebar } from './hooks/useSidebar';
import Checklist from './pages/Checklist/Checklist';
import Detail from './pages/Detail/Detail';
import Gallery from './pages/Gallery/Gallery';
import HomePage from './pages/Homepage/HomePage';
import Login from './pages/Login/Login';
import Report from './pages/Report/Report';
import Settings from './pages/Settings/Settings';
import { useEntityConnectivity, useEntityUser } from './store';
import { useEntityBU } from './store/Bu';
import { DBConfig } from './store/db';


const BaseUrl: string = process.env.REACT_APP_BASE_URL;


initDB(DBConfig);

const App: React.FC = () => {

  const { showSidebar, handleShowSidebar } = useSidebar();

  const { setConnectivity, connectivity: isOnline } = useEntityConnectivity(store => store);
  const { getBuList } = useEntityBU();
  const [isLoading, setIsLoading] = useIsLoading();
  
  const { user } = useEntityUser();

  const routesNotLogged: RouteProps[] = [
    { path: "/login", render: () => <Login /> },
    // { path: "/*/**", render: () => <Redirect to="/login" /> }
  ];

  const routesLogged: RouteProps[] = [
    { path: "/", render: () => <HomePage />, exact: true },
    {
      path: "/dati/:id/:numberInspection", render: () =>
        <Detail showSidebar={showSidebar} handleShowSidebar={handleShowSidebar}>
          <Sidebar showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} />
        </Detail>
    },
    {
      path: "/checklist/:id/:numberInspection", render: () =>
        <Checklist showSidebar={showSidebar} handleShowSidebar={handleShowSidebar}>
          <Sidebar showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} />
        </Checklist>
    },
    {
      path: "/gallery/:id/:numberInspection", render: () =>
        <Gallery showSidebar={showSidebar} handleShowSidebar={handleShowSidebar}>
          <Sidebar showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} />
        </Gallery>
    },
    {
      path: "/report/:id/:numberInspection", render: () =>
        <Report showSidebar={showSidebar} handleShowSidebar={handleShowSidebar}>
          <Sidebar showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} />
        </Report>
    },
    { path: "/impostazioni", render: () => <Settings /> },
    // {
    //   path: "*", render: () => {
    //     return <Redirect to="/" />
    //   }
    // }
  ];

  // let { id, numberInspection } = useParams<ParamsInspection>();

  useEffect(() => {
    if(user?.id) getBuList(user.id as number);
  }, [user?.id, getBuList])


  useEffect(() => {
    window.addEventListener('online', () => {
      setConnectivity(true)
      setIsLoading(true)
    });
    window.addEventListener('offline', () => {
      setConnectivity(false)
      setIsLoading(true)
    });
    return () => {
      window.removeEventListener('online', () => { });
      window.removeEventListener('offline', () => { });
    }
  }, [setConnectivity, setIsLoading]);
  
  // { !user && window.location.href !== BaseUrl + "/" && window.location.href.indexOf("/login") === -1 && sessionStorage.setItem("RedirectUrl", window.location.href) }

  return (
    <IonPage>
      {user && <NavBar user={user} showOfflineMessage={!isOnline} />}
      {/* {user &&  <MenuMobile disableLink={!isOnline} />} */}
      <Switch>
        { routesNotLogged.map((route, index) => <Route key={index} path={route.path} render={route.render} exact={route.exact || false} />)}
        {user &&  routesLogged.map((route, index) => <Route key={index} path={route.path} render={route.render} exact={route.exact || false} />)}
        <Route path={'**/*'} render={()=> <Redirect to="/login" />} />
      </Switch>
      {/* <Routes user={state} /> */}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoading}
        message={"Cambio Stato Connessione"}
      />
    </IonPage >
  )
}
export default App;
