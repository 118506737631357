import { IonFooter, IonToolbar } from "@ionic/react";
import React from "react";
import pckg from '../../../package.json';
import './footer.css'

export interface FooterProps {

}

let numberVersion = pckg.version; //'2.0.0.15';


const Footer: React.FC<FooterProps> = () => {
    return (
        <>
            <IonFooter className="footer">
                <IonToolbar>
                    <p className="footer-text ion-text-center">© 2022 Prelios s.p.a. P.iva 02473170153&nbsp;|&nbsp; 
                    <a href="https://prelios.com/it/legal-disclaimer" target="_blank" rel="noreferrer">Disclaimer</a>&nbsp;-&nbsp; 
                    <a href="https://prelios.com/it/company-info" target="_blank" rel="noreferrer">Company info</a>
                    &nbsp;| v. {numberVersion}</p>
                </IonToolbar>
            </IonFooter>
        </>
    );
}

export default Footer;