import create from 'zustand'
import { Gallery, RequestGalleryDTO } from '../Models/Gallery';
import { OptionGallery } from '../Models/OptionGallery';
import { EditPhotoDTO, SavePhotoDTO } from '../Models/Photo';
import { bridgeFromDBToOptionGallery, requestGetFoto } from '../service/api';
import { EmptyAddPhoto } from '../TypesUtiles';

export const useEntityGallery = create((set: Function, get: Function) => ({
    dataPhotoGallery: EmptyAddPhoto() as Gallery,
    errorText: '',
    refreshGallery: async (data: RequestGalleryDTO) => {
        requestGetFoto<RequestGalleryDTO>(data, onSucces(set), onError(set, get));
    },
    deletePhotoGallery: (idFoto: number) => {
        const dataPhotoGallery = get().dataPhotoGallery;
        const listaImmagini = dataPhotoGallery.listaImmagini;
        set({
            ...get(),
            errorText: '',
            dataPhotoGallery: {
                ...dataPhotoGallery,
                listaImmagini: listaImmagini.filter(photo => photo.idFoto !== idFoto)
            }
        })
    },
    updateGallery: (editedPhoto: EditPhotoDTO) => {
        const dataPhotoGallery = get().dataPhotoGallery;
        const listaImmagini = dataPhotoGallery.listaImmagini
        set({
            ...get(),
            errorText: '',
            dataPhotoGallery: {
                ...dataPhotoGallery,
                listaImmagini: listaImmagini.map(photo => photo.idFoto === editedPhoto.id ? { ...photo, didascalia: editedPhoto.didascalia } : photo)
            }
        })
    },
    getGalleryFromDB: (listaImmaginiFromDB: OptionGallery[]) => {
        const data: Gallery = get().dataPhotoGallery;
        const listaImmagini = listaImmaginiFromDB.concat(data?.listaImmagini);
        set({
            ...get(),
            errorText: '',
            dataPhotoGallery: {
                ...data,
                listaImmagini
            }
        })
    },
    addPhotoGallery: (data: SavePhotoDTO) => {
        const photoGallery = get().dataPhotoGallery;
        const listaImmaginiFromDB = bridgeFromDBToOptionGallery(data);
        const listaImmagini = [listaImmaginiFromDB].concat(photoGallery?.listaImmagini || [])
        set({
            ...get(),
            errorText: '',
            dataPhotoGallery: {
                ...photoGallery,
                listaImmagini
            }
        })
    },
}));

const onSucces = (set: Function) => (dataPhotoGallery: Gallery) => {
    const listaImmagini =
        dataPhotoGallery?.listaImmagini?.length
            && dataPhotoGallery?.listaImmagini[0].idFoto >= 0
            ? dataPhotoGallery?.listaImmagini
            : undefined
    set({
        dataPhotoGallery: {
            ...dataPhotoGallery,
            listaImmagini
        },
        errorText: ''
    })
}
const onError = (set: Function, get: Function) => (error: Error) => set({ dataPhotoGallery: get().dataPhotoGallery, errorText: error.message })