import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonFabButton,
  IonGrid,
  IonRow,
  IonBadge,
  IonCheckbox,
} from "@ionic/react";
import PlaceOutlinedIcon from "@material-ui/icons/PlaceOutlined";
import { MouseEvent, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Gallery } from "../../Models/Gallery";
import { EditPhotoDTO } from "../../Models/Photo";
import { requestEditFoto } from "../../service/api";
import { useEntityConnectivity, useEntityUser } from "../../store";
import CustomInput from "../CustomInput/CustomInput";
import "./cardGallery.css";
import { pencilOutline, trashOutline } from "ionicons/icons";
import { RoleID } from "../../pages/Detail/useResponseDetail";
import { formatDate } from "../../utiles";

export interface CardGalleryProps {
  deletePhoto: (idFoto: number) => void;
  setCallFetchGetGallery: Function;
  updatePhoto: Function;
  idUser: number;
  dataPhotoGallery: Gallery;
  handleZipUrls: (type: "add" | "remove", {id, url}: {id: string, url: string}) => void;
  isSelectable: boolean;
  photo: {
    idFoto?: number;
    dataFoto?: string;
    didascalia?: string;
    immagineUrl?: string;
    latitudine?: number;
    longitudine?: number;
  };
}

const CardGallery: React.FC<CardGalleryProps> = ({
  photo,
  deletePhoto,
  idUser,
  dataPhotoGallery,
  setCallFetchGetGallery,
  updatePhoto,
  isSelectable,
  handleZipUrls
}) => {
  const { connectivity: isOnline } = useEntityConnectivity();

  const [clickOnModification, setClickOnModification] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      didascalia: photo.didascalia,
    },
  });

  const onSubmitModificationPhoto = ({
    didascalia,
  }: {
    didascalia: string;
  }) => {
    const data = {
      idUtente: idUser,
      id: photo.idFoto,
      didascalia,
    };
    isOnline
      ? requestEditFoto<EditPhotoDTO>(
          data,
          setCallFetchGetGallery,
          console.error
        )
      : updatePhoto(data);
    setClickOnModification(false);
  };

  const { user: currentUserParse } = useEntityUser();

  const shouldBeDisabled =
    dataPhotoGallery.stato === "Approvato" ||
    dataPhotoGallery.stato === "In Approvazione" ||
    dataPhotoGallery?.stato === "In Revisione" ||
    (dataPhotoGallery?.stato === "Revisionato" &&
      currentUserParse?.profilo === RoleID.BME) ||
    currentUserParse?.profilo === RoleID.LETTORE;

  const onCheckboxSet = (event: MouseEvent<HTMLIonCheckboxElement>)=> {
    const type = event.currentTarget.checked ? 'add': 'remove'
    handleZipUrls( type, {id: photo.idFoto.toString(), url: photo.immagineUrl})
  }
  return (
    <>
      <IonCol
        sizeLg="4"
        sizeMd="4"
        sizeSm="12"
        sizeXs="12"
        className="p-0"
        id={`card-gallery-${photo?.idFoto}`}
      >
        <IonCard>
          <IonFabButton
            onClick={() => deletePhoto(photo.idFoto)}
            color="danger"
            className="mt-3 me-2"
            disabled={shouldBeDisabled}
            style={{ float: "right", width: 32, height: 32 }}
          >
            <IonIcon icon={trashOutline} style={{ fontSize: "1rem" }} />
          </IonFabButton>
          <img src={photo?.immagineUrl || ""} className="img-gallery" alt="" />

          {isSelectable && <IonCheckbox className="gallery-checkbox" onClick={onCheckboxSet}/>}

          <IonBadge className={`badge-gallery ion-text-uppercase`}>
            <span>
              {photo.dataFoto !== "" && photo.dataFoto !== null
                ? `${formatDate(photo.dataFoto)}`
                : "Data N/D"}
            </span>
          </IonBadge>

          <IonCardContent className="bg-white">
            <IonGrid className="p-0">
              <IonRow className="ion-align-items-center">
                <IonCol
                  className="p-0"
                  style={{
                    alignItems: "center",
                    display: "inline-flex",
                    width: "60%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: "70%",
                    }}
                  >
                    {photo?.latitudine > 0 &&
                      photo?.longitudine > 0 &&
                      !clickOnModification && (
                        <a
                          href={`http://www.google.com/maps/place/${photo?.latitudine},${photo?.longitudine}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PlaceOutlinedIcon
                            className="pointer d-inline align-middle me-3"
                            color="primary"
                          />
                        </a>
                      )}
                    {!clickOnModification && (
                      <p className="d-inline align-middle">
                        {photo?.didascalia}
                      </p>
                    )}
                  </div>

                  {!clickOnModification ? (
                    <>
                      <IonFabButton
                        size="small"
                        disabled={shouldBeDisabled}
                        className="ion-float-end"
                        style={{ width: 28, height: 28 }}
                      >
                        <IonIcon
                          style={{ fontSize: "1rem" }}
                          icon={pencilOutline}
                          onClick={() =>
                            !(
                              dataPhotoGallery.stato === "In Approvazione" ||
                              dataPhotoGallery.stato === "Approvato"
                            ) && setClickOnModification(true)
                          }
                        />
                      </IonFabButton>
                    </>
                  ) : (
                    <form onSubmit={handleSubmit(onSubmitModificationPhoto)}>
                      <Controller
                        control={control}
                        name="didascalia"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomInput value={value} setValue={onChange} />
                          );
                        }}
                      />
                      <IonRow className="ion-justify-content-end">
                        <IonButton
                          fill="clear"
                          size="small"
                          className="fw-bold ion-text-uppercase"
                          onClick={() => setClickOnModification(false)}
                        >
                          annulla
                        </IonButton>
                        <IonButton
                          type="submit"
                          fill="solid"
                          size="small"
                          className="fw-bold ion-text-uppercase"
                        >
                          modifica
                        </IonButton>
                      </IonRow>
                    </form>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </>
  );
};

export default CardGallery;
