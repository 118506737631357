import { useState, useEffect } from "react";
import { getSessionStorage } from "../../service";

export const useSendToApproval = (numberInspection: string) => {

    const [state, setState] = useState();

    useEffect(() => {
        const getApproval = async () => await getSessionStorage(`SendToApproval_${numberInspection}`);
        getApproval().then(setState);
    }, [numberInspection]);

    return state;
};
