import { IonLabel } from "@ionic/react";
import { Box, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Option } from "../../Models/Checklist";
import "./selectSearchable.css";

const useStyles = makeStyles(() => ({
  inputRoot: {
    backgroundColor: "white",
  },
}));

export interface SelectSearchableProps {
  value: Option;
  setValue: (newValue: Option) => void;
  options: Option[];
  label: string;
  disableClearable?: boolean;
  error?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
}

const SelectSearchable: React.FC<SelectSearchableProps> = ({
  value,
  setValue,
  options,
  label,
  disableClearable,
  error,
  disabled,
  disablePortal = true
}) => {
  const classes = useStyles();

  const handleCss = (label: string) => {
    switch (label) {
      case "tipologia UE*":
      case "occupazione*":
      case "stato":
      case "Stato manutentivo generale":
        return "d-inline-flex w-88";
      default:
        break;
    }
  };


  if(label === "Assegnatario") {
    return (
      <>
        <IonLabel className="label p-0 ion-text-capitalize">{label}</IonLabel>
        <Autocomplete
          disableClearable={disableClearable}
          disabled={disabled}
          className={`mt-2 ${handleCss(label)}`}
          classes={classes}
          getOptionLabel={(option: Option) => option.nome ?? ""}
          value={value || ''}
          onChange={(event: any, newValue: Option) => {
            if (newValue) {
              setValue(newValue);
            } else if (newValue === null) {
              setValue(null);
            }
          }}
          options={options}
          getOptionSelected={(option: Option, value: Option) => {
            return value.id >= 0 && option.id === value.id;
          }}
          renderOption={(option: any) => {
            console.log("Assegnatario");
              return (
                  <Box
                    sx={{
                      flexGrow: 1
                    }}
                  >
                    {option.profiloID === 2 
                    ? <span className="label">{option.nome}</span>
                    : <span>{option.nome}</span>
                    }
                  </Box>
              ) as React.ReactNode;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name={`${label}${Date()}`}
                variant="outlined"
                error={error}
                helperText={error ? "Campo obbligatorio" : null}
              />
            );
          }}
          disablePortal={disablePortal}
        />
      </>
    );
  } else {
    return (
      <>
        <IonLabel className="label p-0 ion-text-capitalize">{label}</IonLabel>
        <Autocomplete
          disableClearable={disableClearable}
          disabled={disabled}
          className={`mt-2 ${handleCss(label)}`}
          classes={classes}
          getOptionLabel={(option: Option) => option.nome ?? ""}
          value={value || ''}
          onChange={(event: any, newValue: Option) => {
            if (newValue) {
              setValue(newValue);
            } else if (newValue === null) {
              setValue(null);
            }
          }}
          options={options}
          getOptionSelected={(option: Option, value: Option) => {
            return value.id >= 0 && option.id === value.id;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name={`${label}${Date()}`}
                variant="outlined"
                error={error}
                helperText={error ? "Campo obbligatorio" : null}
              />
            );
          }}
          disablePortal={disablePortal}
        />
      </>
    );
  }
};

export default SelectSearchable;
