import { IonCol, IonRow } from '@ionic/react';


export interface AboveListProps {

}

const AboveList: React.FC<AboveListProps> = () => {
    return (
        <IonRow className="text-row-list">
            <IonCol size="3">
                <h6 className="text-row-list ion-text-center">Fondo/cliente</h6>
            </IonCol>
            <IonCol size="1">
                <h6 className="text-row-list">Città</h6>
            </IonCol>
            <IonCol size="2">
                <h6 className="text-row-list">Indirizzo</h6>
            </IonCol>
            <IonCol size="2">
                <h6 className="text-row-list">Sede Tecnica/UE</h6>
            </IonCol>
            <IonCol size="1">
                <h6 className="text-row-list">Data</h6>
            </IonCol>
            <IonCol size="1">
                <h6 className="text-row-list">BM</h6>
            </IonCol>
            <IonCol size="2">
                <h6 className="text-row-list">Stato</h6>
            </IonCol>
        </IonRow>
    );
}

export default AboveList;