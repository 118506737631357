import { IonLoading } from "@ionic/react";

export interface LoadingProps {
    message: string;
    open: boolean;
    setOpen?: (bool: boolean) => void;
    duration: number;
}

const Loading: React.FC<LoadingProps> = ({open, setOpen, message, duration}) => {
    return (
        <IonLoading
            cssClass='my-custom-class'
            isOpen={open}
            onDidDismiss={ setOpen ? () => setOpen(false) : null}
            message={message}
            duration={duration}
        />
    );
}

export default Loading;