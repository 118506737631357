import create from "zustand";
import { DataDetail } from '../Models/DataDetail';
import { Photo } from "../Models/Photo";
import { getAssegnatariForFornitore, getSopralluogo } from "../service/api/details";
import { EmptyDataDetail } from '../TypesUtiles';

export const useEntityDetail = create((set: Function, get: Function) => ({
    dataDetail: EmptyDataDetail(),
    dataDetailLoading: true,
    dataDetailLoadingMessage: 'Caricamento in corso...',
    errorDataDetail: '',
    getDataDetail: (idUser:number, idImmobile: number, idIspezione: number ) => {
        const onSuccess = (dataDetail: DataDetail) => set({...get(), dataDetail, errorDataDetail: '', dataDetailLoading: false, dataDetailLoadingMessage: ''});
        const onError = (errorDataDetail) => set({ ...get(), errorDataDetail, dataDetailLoading: false});
        getSopralluogo({
            idUtente: idUser,
            idImmobile: idImmobile,
            idIspezione: idIspezione
        }, onSuccess, onError);
    },
    updateDataDetail:(dataDetail: DataDetail) => set({...get, dataDetail: dataDetail}),
    setDataDetailLoading:(state: boolean) => set({...get(), dataDetailLoading: state}),
    setDataDetailLoadingMessage:(message: string) => set({...get, dataDetailLoadingMessage: message}),
    setPhoto:(photos: Photo[], dataDetail: DataDetail) => set({...get, dataDetail: {...dataDetail, immagineSave: photos }}),
    setPhotoimmageURL:(photos: "", dataDetail: DataDetail) => set({...get, dataDetail: {...dataDetail, immagineUrl: photos }}),
    assegnatariForFornitore: [],
    getAssegnatariFromFornitore: async (idUtente: number, idProgetto: number) => {
        const onSuccess = (listaAssegnatari: any[]) => set({ ...get(), assegnatariForFornitore: listaAssegnatari});
        const onError = (errorGetAssegnatari) => set({ ...get(), errorGetAssegnatari });

        await getAssegnatariForFornitore({ idUtente, idProgetto }, onSuccess, onError);
    },
}));

