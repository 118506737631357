import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading, IonRow } from '@ionic/react';
import { MouseEvent, useRef, useState } from 'react';

import './homepage.css';

//icon
import { addOutline, downloadOutline } from 'ionicons/icons';
import IconCard from '../../assets/icon/icon-card-active.svg';
import IconList from '../../assets/icon/icon-checklist.svg';

// components
import ScrollToUp from '../../components/ScrollToUp/ScrollToUp';
import AboveList from '../../components/List/AboveList';
import Modal from '../../components/Modal/Modal';
import Footer from '../../components/Footer/Footer';
import Card from '../../components/Card/Card';
import ModalNewInspection from '../../components/Modal/ModalNewInspection';

//hooks
import List from '../../components/List/List';
//models
import { ViewType } from '../../Models/ViewType';
import { useExportCSV } from '../../hooks/useExportCSV';
import useHomePage from './useHomePage';
import { FormProvider } from 'react-hook-form';
import FormFilters from './FormFilters';
import { RoleID } from '../Detail/useResponseDetail';

const HomePage: React.FC = () => {

    const {
        isLoading,
        addIspezione,
        addModal,
        setAddModal,
        error,
        showFilter,
        setShowFilter,
        currentUserParse,
        dataInspections,
        searchNext,
        disableInfiniteScroll,
        methods,
        handleUpdateDataInspections,
        handleResetForm,
        dataFilter,
        dataForExport
    } = useHomePage();

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop = () => contentRef.current && contentRef.current.scrollToTop(1000);

    const [view, setView] = useState("card");
    const handlerView = (view: ViewType) => (event: MouseEvent<HTMLIonButtonElement, globalThis.MouseEvent>) => setView(view);

    const [filterModal, setFilterModal] = useState(false);

    const { exportToCSVWithFilter } = useExportCSV("sopralluoghi");

    return (
        <>
            <IonContent ref={contentRef} scrollEvents={true}>
           
                <IonGrid className="px-30">
                    <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonCol sizeXl="8" className="ps-0">
                            <h1> {view === ViewType.LIST ? dataInspections != null && dataInspections != undefined
                             && dataInspections.length > 0 && `Sopralluoghi: ${dataInspections[0].totale}` : ViewType.CARD && 'I tuoi sopralluoghi'} </h1>
                        </IonCol>
                        <IonCol className="ion-text-end ion-hide-md-down" sizeXl="4"  >
                            {(currentUserParse?.profilo === 4 || currentUserParse?.profilo === RoleID.LETTORE)? "" : (
                                <IonButton className="ion-text-uppercase" onClick={() => setAddModal(true)}>
                                    <IonIcon slot="start" icon={addOutline} />
                                    aggiungi
                                </IonButton>
                            )}
                        </IonCol>
                    </IonRow>

                    {/* *only desktop and tablet */}
                    <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonCol sizeMd="8" sizeSm="12" className="ps-0 ion-hide-md-down">
                            <IonButton fill="outline" color="tertiary" className="btn fw-bold" onClick={() => setShowFilter(!showFilter)}>
                                FILTRI
                            </IonButton>
                            {showFilter &&
                                <>
                                    <IonButton fill="solid" color="secondary" className="btn" onClick={handleUpdateDataInspections}>
                                        APPLICA
                                    </IonButton>
                                    <IonButton fill="clear" color="secondary" className="btn" onClick={handleResetForm}>
                                        AZZERA
                                    </IonButton>
                                </>
                            }
                        </IonCol>

                        <IonCol sizeMd="4" className="ion-text-end ion-hide-sm-down">
                            {view === ViewType.LIST && (
                                <IonButton
                                    onClick={() => exportToCSVWithFilter(dataForExport, "lista", dataFilter)}
                                    fill="solid"
                                    color="secondary"
                                    className="ion-text-uppercase"
                                    disabled={dataInspections != null && dataInspections != undefined ? !dataForExport?.length : true}
                                >
                                    <IonIcon icon={downloadOutline} slot="start" />
                                    ESPORTA
                                </IonButton>
                            )}

                            <IonButton fill="outline" onClick={handlerView(ViewType.CARD)}  >
                                <img src={IconCard} alt="icon card" />
                            </IonButton>
                            <IonButton fill="outline" onClick={handlerView(ViewType.LIST)} >
                                <img src={IconList} alt="icon list" />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className='ion-hide-md-down'>
                        {
                            showFilter && !filterModal &&
                            <FormProvider {...methods} >
                                <FormFilters />
                            </FormProvider>
                        }
                    </div>
                    <Modal isOpen={addModal} title={'Aggiungi sopralluogo'} onIsOpenChange={setAddModal}>
                        {addModal ?
                            <ModalNewInspection
                                currentUserParse={currentUserParse}
                                CancelButtonInsertInspection={() => setAddModal(false)}
                                addIspezione={addIspezione}
                                error={error}
                            />
                            : null}
                    </Modal>

                    {/* only mobile */}
                    <IonRow className="ion-justify-content-end ion-align-items-center ion-hide-md-up">
                        <IonCol size="6">
                            <IonButton fill="outline" color="tertiary" className="btn fw-bold" expand="block" onClick={() => setFilterModal(!filterModal)} >
                                FILTRI
                            </IonButton>
                        </IonCol>
                        <IonCol size="6">
                            {currentUserParse?.profilo === 4 || currentUserParse?.profilo === RoleID.LETTORE ? "" : (
                                <IonButton className="ion-text-uppercase" onClick={() => setAddModal(!addModal)} expand="block">
                                    <IonIcon slot="start" icon={addOutline} />
                                    aggiungi
                                </IonButton>
                            )}
                        </IonCol>
                    </IonRow>

                    {view === ViewType.CARD && (
                        <IonRow>
                            {dataInspections && dataInspections.length > 0 ? <Card cardData={dataInspections} /> : <p>Nessun sopralluogo trovato, modificare la selezione filtri</p>}

                        </IonRow>
                    )}
                </IonGrid>
                {view === ViewType.LIST && (
                    <>
                        <IonGrid className="px-30">
                            <AboveList />
                            {dataInspections && <List listData={dataInspections} />}
                        </IonGrid>
                    </>
                )}
                <ScrollToUp up={scrollToTop} />
                <IonInfiniteScroll threshold="10%"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                    <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText="Loading...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
                <Footer />
            </IonContent>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={isLoading}
                message={"Sincronizzazione Data with Server"}
            />
            {/* mobile filter */}
            <Modal isOpen={filterModal} title={'Filtri'} onIsOpenChange={setFilterModal}>
                <FormProvider {...methods} >
                    <FormFilters >
                        <IonRow>
                            <IonCol size="6">
                                <IonButton fill="clear" className="fw-bold" color="danger" expand="block" onClick={() => {
                                    handleResetForm()
                                    setFilterModal(false)
                                }}>
                                    AZZERA FILTRI
                                </IonButton>
                            </IonCol>
                            <IonCol size="6">
                                <IonButton fill="solid" className="fw-bold" color="primary" expand="block" onClick={() => {
                                    handleUpdateDataInspections()
                                    setFilterModal(false)
                                }}>
                                    APPLICA
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </FormFilters>
     
               </FormProvider>
            </Modal>

        </>
    );
}

export default HomePage;
