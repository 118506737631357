import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  IonButton,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
//models
import { ParamsInspection } from "../../Models/ParamsInspection";
import {
  DeletePhotoDTO,
  GeolocalizationPermitEnum,
  Photo,
  SavePhotoDTO,
} from "../../Models/Photo";
//icons
import { addOutline, menuOutline, trashOutline } from "ionicons/icons";
//hooks
import { useGeolocation } from "../../hooks/useGeolocation";
import { useResponsePhotoGallery } from "./useResponseGallery";
//components
import CardGallery from "../../components/CardGallery/CardGallery";
import Footer from "../../components/Footer/Footer";
import ScrollToUp from "../../components/ScrollToUp/ScrollToUp";
import Badge from "../../components/Badge/Badge";
import Loading from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
// service
import { useEntityConnectivity, useEntityUser } from "../../store";
import { insertFoto, requestDeleteFoto } from "../../service/api";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import "./camera.css";
import "cropperjs/dist/cropper.css";
import CameraCustom, {
  CameraProps,
} from "../../components/CameraCustom/CameraCustom";
import MenuMobile from "../../components/MenuMobile/MenuMobile";
import ChildrenModalDelete from "../../components/Modal/ChildrenModalDelete";
import CustomInput from "../../components/CustomInput/CustomInput";
import { RoleID } from "../Detail/useResponseDetail";
import JSZip from "./jszip/dist/jszip.min.js";
export interface GalleryProps {
  showSidebar: boolean;
  handleShowSidebar(): any;
}

const Gallery: React.FC<GalleryProps> = ({
  showSidebar = true,
  handleShowSidebar,
  children,
}) => {
  const isOnline = useEntityConnectivity((store) => store.connectivity);
  const { numberInspection } = useParams<ParamsInspection>();
  const { user: currentUserParse } = useEntityUser(); // scroll to top
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<{ didascalia: string }>({ mode: "onSubmit" });
  // scroll to top
  const [contentRef, scrollToTop] = useScrollToTop();
  const cameraRef = useRef<CameraProps>(null);
  const [errorTextSave, setErrorTextSave] = useState<string>("");
  const [showErrorMessageSave, setShowErrorMessageSave] =
    useState<boolean>(false);
  const [photoToAdd, setPhotoToAdd] = useState<Photo>();

  // logic
  const {
    isLoading,
    dataPhotoGallery,
    getCurrentUserGallery,
    addRecordToState,
    removeFromDB,
    updateFromDB,
    errorText,
    loadingSave,
    setLoadingSave,
  } = useResponsePhotoGallery();

  //geoloc
  const { errorGeolocation, setErrorGeolocation, geolocation } =
    useGeolocation();

  const handleDismissPhotoToAdd = () => {
    setPhotoToAdd(undefined);
    resetField("didascalia");
  };
  const onSubmitAddPhoto = (photo: Photo) => {
    setPhotoToAdd(photo);
    cameraRef?.current?.deletePicture();
    cameraRef.current.onCloseModal();
  };

  const handleConfirmSubmitPhoto = ({ didascalia }) => {
    if (!photoToAdd) return;
    setLoadingSave(true);
    const data: SavePhotoDTO = {
      idUtente: Number(currentUserParse.id),
      idIspezione: +numberInspection,
      didascalia,
      listaImmaginiIspezioni: [photoToAdd],
    };

    isOnline
      ? insertFoto<SavePhotoDTO>(data, onSuccess, onError)
      : addToDB(data);

    handleDismissPhotoToAdd();
  };
  const onSuccess = () => {
    setLoadingSave(false);
    getCurrentUserGallery();
    // cameraRef?.current?.deletePicture();
  };
  const onError = () => {
    setErrorTextSave("Errore durante il salvataggio della foto");
    setShowErrorMessageSave(true);
    setLoadingSave(false);
    // cameraRef?.current?.deletePicture();
  };
  const addToDB = async (data: SavePhotoDTO) => {
    addRecordToState(data);
    setLoadingSave(false);
  };
  // deleteFoto
  const [idPhoto, setIdPhoto] = useState<number>();
  const onSubmitDeletePhoto = (idPhoto: number) => {
    setIdPhoto(idPhoto);
  };
  const handleDeletePhoto = () => {
    setLoadingSave(true);
    setIdPhoto(undefined);
    const onSuccess = () => {
      setLoadingSave(false);
      getCurrentUserGallery();
    };
    isOnline
      ? requestDeleteFoto<DeletePhotoDTO>(
          {
            idUtente: Number(currentUserParse.id),
            idFoto: idPhoto,
          },
          onSuccess,
          () => setLoadingSave(false)
        )
      : removeFromDB(idPhoto);
  };
  // modal utils
  const onTakePhoto = async () => {
    if (geolocation?.permission === GeolocalizationPermitEnum.PERMISSION_DENIED)
      return;
    cameraRef?.current?.onOpenModal();
  };
  // selection photos
  const [photoSelection, setPhotoSelection] = useState({
    active: false,
    urls: [] as { id: string; url: string }[],
  });
  const handlePhotoSelection = () => {
    setPhotoSelection(({ active, urls }) => ({ urls, active: !active }));
  };
  const handleUrlReset = () => {
    setPhotoSelection({ urls: [], active: false });
  };
  const handleZipDispatch = useCallback(
    (type: "add" | "remove", url: { id: string; url: string }) => {
      const addToUrls = (url: { id: string; url: string }) => {
        setPhotoSelection(({ active, urls }) => ({
          active,
          urls: urls.concat(url),
        }));
      };
      const removeFromUrls = (urlToRemove: { id: string; url: string }) => {
        setPhotoSelection(({ active, urls }) => ({
          active,
          urls: urls.filter(({ url }) => url != urlToRemove.url),
        }));
      };
      const action = type == "add" ? addToUrls : removeFromUrls;
      action(url);
    },
    []
  );
  const handleSelectDeselectAll = (status: boolean) => () => {
    const allList = status
      ? dataPhotoGallery?.listaImmagini?.map((el) => ({
          url: el.immagineUrl,
          id: el.idFoto.toString(),
        }))
      : [];

    setPhotoSelection(({ active, urls }) => ({
      active,
      urls: allList,
    }));
    document
      .querySelectorAll(".gallery-checkbox")
      .forEach((el: HTMLInputElement) => {
        el.checked = status;
      });
  };
  const downloadZip = async () => {
    if (!photoSelection.urls?.length) return alert("No photo selected");
    if (window.Worker) {
      const myWorker = new Worker("worker.js");
      myWorker.postMessage(photoSelection.urls);
      myWorker.onmessage = function ({ data: content }) {
        const a = document.createElement("a");
        a.href = "data:application/zip;base64," + content;
        a.download = "download.zip";
        a.click();
        handleUrlReset();
      };
      return;
    }
    const promiseList = photoSelection.urls.map((photo) => fetch(photo.url, {
      headers: { 
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "mode": "no-cors"
    },
    }));
    const result = await Promise.allSettled(promiseList);

    const zip = new JSZip();

    result.forEach((res, index) => {
      const url = photoSelection.urls[index].url.split("?");
      const path = url[0].split("/");
      const type = path[path.length - 1].split(".");
      const format = type[type.length - 1];
      const file = res.status == "fulfilled" && res.value.blob();
      zip.file(`${photoSelection.urls[index].id}.${format}`, file);
    });

    const a = document.createElement("a");

    zip.generateAsync({ type: "base64" }).then(function (content) {
      a.href = "data:application/zip;base64," + content;
      a.download = "download.zip";
      a.click();
      handleUrlReset();
    });
  };
  return (
    <>
      <MenuMobile disableLink={!isOnline} />
      <CameraCustom
        ref={cameraRef}
        callBackSavePhoto={onSubmitAddPhoto}
        location={geolocation?.position}
      />
      <IonContent ref={contentRef} scrollEvents={true}>
        <IonGrid className="p-0 mb-6">
          <IonRow>
            {children}
            {isOnline && !!errorText && (
              <IonCol
                offsetLg={showSidebar ? "0" : "1"}
                sizeXs="12"
                sizeXl={showSidebar ? "12" : "11"}
                sizeLg={showSidebar ? "12" : "11"}
              >
                <IonRow className="ion-align-items-center vh-100">
                  <IonCol size="12">
                    <h1 className="ion-text-center text-danger">ATTENZIONE:</h1>
                    <p className="ion-text-center text-danger">{errorText}</p>
                  </IonCol>
                </IonRow>
              </IonCol>
            )}
            <IonCol
              sizeXs="12"
              offsetLg={showSidebar ? "0" : "1"}
              sizeXl={showSidebar ? "12" : "11"}
              sizeLg={showSidebar ? "12" : "11"}
            >
              <IonRow className="ion-justify-content-end ion-align-items-center px-1">
                {showSidebar && (
                  <IonCol size="2">
                    <IonIcon
                      icon={menuOutline}
                      className="pointer me-1 fs-5"
                      onClick={handleShowSidebar}
                      color="primary"
                    />
                  </IonCol>
                )}
                <IonCol className="d-flex align-center ms-0">
                  <h1 className="my-0 me-5 d-inline">
                    {dataPhotoGallery?.fondo !== "" && dataPhotoGallery?.fondo}
                  </h1>
                  {dataPhotoGallery?.stato && (
                    <Badge
                      className="badge-report"
                      stato={dataPhotoGallery?.stato}
                    />
                  )}
                </IonCol>
                <IonCol  
                className="ms-0"
                sizeXs="12"

              sizeMd={photoSelection.active  ?"12" : "3" }
              sizeLg={photoSelection.active  ?"7" : "3" }
              sizeXl={photoSelection.active ?"5" : "3" }
              >
                {photoSelection.active && (
                      <div style={{ display: "flex", flexWrap: 'wrap'}}>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handleUrlReset}
                          expand="block"
                        >
                          Annulla
                        </IonButton>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handleSelectDeselectAll(true)}
                          expand="block"
                        >
                          Seleziona Tutto
                        </IonButton>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handleSelectDeselectAll(false)}
                          expand="block"
                        >
                          Deseleziona Tutto
                        </IonButton>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={downloadZip}
                          expand="block"
                        >
                          Scarica Zip
                        </IonButton>
                      </div>
                    )}
                    <div style={{ display: "flex" }}>
                      {!photoSelection.active && (
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handlePhotoSelection}
                          expand="block"
                        >
                          Seleziona
                        </IonButton>
                      )}

                      {currentUserParse?.profilo !== RoleID.LETTORE && (
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={onTakePhoto}
                          expand="block"
                          disabled={
                            dataPhotoGallery?.stato === "In Approvazione" ||
                            dataPhotoGallery?.stato === "In Revisione" ||
                            (dataPhotoGallery?.stato === "Revisionato" &&
                              currentUserParse?.profilo === RoleID.BME) ||
                            dataPhotoGallery?.stato === "Approvato" ||
                            geolocation?.permission ===
                              GeolocalizationPermitEnum.PERMISSION_DENIED
                          }
                        >
                          <IonIcon slot="start" icon={addOutline} />
                          aggiungi foto
                        </IonButton>
                      )}
                    </div>
                </IonCol>
                {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {showSidebar && (
                      <IonIcon
                        icon={menuOutline}
                        className="pointer me-1 fs-5"
                        onClick={handleShowSidebar}
                        color="primary"
                      />
                    )}
                    <h1 className="my-0 d-inline">
                      {dataPhotoGallery?.fondo !== "" &&
                        dataPhotoGallery?.fondo}
                    </h1>
                  </div>
                  <div>
                    <div>
                      {dataPhotoGallery?.stato && (
                        <Badge
                          className="badge-report"
                          stato={dataPhotoGallery?.stato}
                        />
                      )}
                    </div>
                    {photoSelection.active && (
                      <div style={{ display: "flex" }}>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handleUrlReset}
                          expand="block"
                        >
                          Annulla
                        </IonButton>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handleSelectDeselectAll(true)}
                          expand="block"
                        >
                          Seleziona Tutto
                        </IonButton>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handleSelectDeselectAll(false)}
                          expand="block"
                        >
                          Deseleziona Tutto
                        </IonButton>
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={downloadZip}
                          expand="block"
                        >
                          Scarica Zip
                        </IonButton>
                      </div>
                    )}
                    <div style={{ display: "flex" }}>
                      {!photoSelection.active && (
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={handlePhotoSelection}
                          expand="block"
                        >
                          Seleziona
                        </IonButton>
                      )}

                      {currentUserParse?.profilo !== RoleID.LETTORE && (
                        <IonButton
                          className="ion-text-uppercase"
                          fill="solid"
                          onClick={onTakePhoto}
                          expand="block"
                          disabled={
                            dataPhotoGallery?.stato === "In Approvazione" ||
                            dataPhotoGallery?.stato === "In Revisione" ||
                            (dataPhotoGallery?.stato === "Revisionato" &&
                              currentUserParse?.profilo === RoleID.BME) ||
                            dataPhotoGallery?.stato === "Approvato" ||
                            geolocation?.permission ===
                              GeolocalizationPermitEnum.PERMISSION_DENIED
                          }
                        >
                          <IonIcon slot="start" icon={addOutline} />
                          aggiungi foto
                        </IonButton>
                      )}
                    </div>
                  </div>
                </div> */}
              </IonRow>
              <IonRow>
                <IonCol>
                  <p className="m-0">Gallery</p>
                </IonCol>
              </IonRow>
              {
                <IonRow
                  className={`${
                    !dataPhotoGallery?.listaImmagini?.length
                      ? "ion-align-items-center vh-100"
                      : ""
                  }`}
                >
                  {dataPhotoGallery?.listaImmagini?.map((el) => (
                    <CardGallery
                      key={el?.idFoto}
                      photo={el}
                      deletePhoto={onSubmitDeletePhoto}
                      idUser={+currentUserParse?.id}
                      dataPhotoGallery={dataPhotoGallery}
                      setCallFetchGetGallery={getCurrentUserGallery}
                      updatePhoto={updateFromDB}
                      isSelectable={photoSelection.active}
                      handleZipUrls={handleZipDispatch}
                    />
                  )) || (
                    <IonCol size="12">
                      <h2 className="ion-text-center">Nessuna foto presente</h2>
                    </IonCol>
                  )}
                </IonRow>
              }
            </IonCol>
          </IonRow>
        </IonGrid>
        <ScrollToUp up={scrollToTop} />
        <Footer />
      </IonContent>
      {/* </IonPage> */}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={isLoading}
        message={"Sincronizzazione Data with Server"}
      />
      {/* <IonLoading
        isOpen={loadingGeolocation}
        onDidDismiss={() => setLoadingGeolocation(false)}
        message={"Recupero della posizione..."}
      /> */}
      <IonToast
        isOpen={errorGeolocation.showError}
        onDidDismiss={() =>
          setErrorGeolocation({ message: "", showError: false })
        }
        message={errorGeolocation.message}
        duration={3000}
      />
      <IonToast
        duration={1000}
        color="danger"
        isOpen={showErrorMessageSave}
        onDidDismiss={() => setShowErrorMessageSave(false)}
        message={errorTextSave}
        position="top"
        buttons={[
          {
            side: "start",
            icon: "star",
            text: "Salvataggio",
            handler: () => {
              console.log("Favorite clicked");
            },
          },
        ]}
      />
      <Modal
        isOpen={!!idPhoto}
        title={"Elimina foto"}
        onIsOpenChange={() => setIdPhoto(undefined)}
      >
        <ChildrenModalDelete
          handleClickNo={() => setIdPhoto(undefined)}
          handleClickYes={handleDeletePhoto}
          text="Stai cancellando questa immagine definitivamente, continuare?"
        />
      </Modal>
      <Modal
        isOpen={!!photoToAdd}
        title={"Aggiungi foto"}
        onIsOpenChange={handleDismissPhotoToAdd}
      >
        <form onSubmit={handleSubmit(handleConfirmSubmitPhoto)}>
          <IonGrid className="m-0 mx-3">
            <IonRow className="mb-2">
              <IonCol className="px-0">
                <Controller
                  control={control}
                  name="didascalia"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      label="didascalia*"
                      value={value}
                      setValue={onChange}
                      error={!!errors?.didascalia}
                    />
                  )}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeLg="12" sizeSm="12" sizeXs="12">
                <React.Fragment>
                  <div className="each-slide">
                    <div
                      style={{
                        backgroundImage: `url(${photoToAdd?.webviewPath})`,
                        height: "300px",
                      }}
                    >
                      <IonFabButton
                        onClick={() => setPhotoToAdd(undefined)}
                        color="danger"
                        className="mt-1 ms-2"
                      >
                        <IonIcon
                          icon={trashOutline}
                          style={{ fontSize: "2rem" }}
                        />
                      </IonFabButton>
                    </div>
                  </div>
                </React.Fragment>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-end pe-0">
                <IonButton
                  expand="block"
                  fill="clear"
                  className="fw-bold ion-text-uppercase"
                  onClick={() => setPhotoToAdd(undefined)}
                >
                  ANNULLA
                </IonButton>
              </IonCol>
              <IonCol className="ion-text-end ps-0">
                <IonButton
                  expand="block"
                  fill="solid"
                  type="submit"
                  className="fw-bold ion-text-uppercase"
                >
                  AGGIUNGI
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </Modal>
      <Loading
        open={loadingSave}
        setOpen={setLoadingSave}
        message="Salvataggio in corso..."
        duration={0}
      />
    </>
  );
};

export default Gallery;
