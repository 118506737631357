import { OptionGallery } from "../../Models/OptionGallery";
import { SavePhotoDTO } from "../../Models/Photo";
import { BaseUrl } from "../../utiles";
import { convertGuidToInt, requestPostOptions } from "../crud";
import { v4 as uuidv4 } from "uuid";
import { PhotoSchema } from "../../store/db/photoSchema";

export const ENDPOINT_FOTO = {
  insertFoto: `${BaseUrl}/Gallery/InsertFoto`,
  deleteFoto: `${BaseUrl}/Gallery/DeleteFoto`,
  getFoto: `${BaseUrl}/Gallery/GetGallery`,
  editFoto: `${BaseUrl}/Gallery/UpdateFoto`,
};

export const insertFoto = async <T>(
  data: T,
  onSuccess: Function,
  onError: Function
) => {
  const options = requestPostOptions<T>(data);
  try {
    const promise = await fetch(ENDPOINT_FOTO.insertFoto, options);
    if (promise.ok) onSuccess();
  } catch (error) {
    onError(error);
  }
};
export const requestDeleteFoto = async <T>(
  data: T,
  onSuccess: Function,
  onError: Function
) => {
  const options = requestPostOptions<T>(data);
  try {
    const promise = await fetch(ENDPOINT_FOTO.deleteFoto, options);
    if (promise.ok) onSuccess();
  } catch (error) {
    onError(error);
  }
};
export const requestGetFoto = async <T>(
  data: T,
  onSuccess: Function,
  onError: Function
) => {
  const options = requestPostOptions<T>(data);
  try {
    const promise = await fetch(ENDPOINT_FOTO.getFoto, options);
    const response = await promise.json();
    if (promise.ok) onSuccess(response);
  } catch (error) {
    onError(error);
  }
};
export const requestEditFoto = async <T>(
  data: T,
  onSuccess: Function,
  onError: Function
) => {
  const options = requestPostOptions<T>(data);
  try {
    const promise = await fetch(ENDPOINT_FOTO.editFoto, options);
    if (promise.ok) onSuccess();
  } catch (error) {
    onError(error);
  }
};
export const fromDBToOptionGallery = async (
  getAll: Function
): Promise<OptionGallery[]> => {
  const records = await getAll();
  const gallery: SavePhotoDTO[] = records.map((el) => el.data);

  return gallery.map(bridgeFromDBToOptionGallery);
};
export const bridgeFromDBToOptionGallery = ({
  didascalia,
  listaImmaginiIspezioni,
}: SavePhotoDTO): OptionGallery => {
  const [{ webviewPath, date, idImmagineProblema, latitudine, longitudine }] =
    listaImmaginiIspezioni;
  const idFoto = idImmagineProblema || convertGuidToInt(uuidv4());
  return {
    didascalia,
    latitudine,
    longitudine,
    immagineUrl: webviewPath,
    dataFoto: date,
    idFoto,
  };
};
export const findIndexFromDB = async (
  getAll: Function,
  index: number
): Promise<number> => {
  const records: PhotoSchema[] = await getAll();
  return records.find((el) =>
    el.data?.listaImmaginiIspezioni
      ? el.data?.listaImmaginiIspezioni?.some(
          (photo) =>
            photo.idFoto === index || photo.idImmagineProblema === index
        )
      : el.data?.id === index
  )?.id;
};
export const findRecordFromDB = async (
  getAll: Function,
  index: number
): Promise<any> => {
  const records: PhotoSchema[] = await getAll();
  return records.find((el) =>
    el.data?.listaImmaginiIspezioni
      ? el.data?.listaImmaginiIspezioni?.some(
          (photo) =>
            photo.idFoto === index || photo.idImmagineProblema === index
        )
      : el.data?.id === index
  );
};
