import React, { useEffect, useState } from 'react'

export const useIsLoading = (autotrigger: boolean = true) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        if(!autotrigger)return;
        isLoading && setTimeout(() => setIsLoading(false), 0)
    }, [autotrigger, isLoading])
  return [
    isLoading,
      setIsLoading
  ] as const
}
