import { IonIcon } from '@ionic/react';
import { NavLink, useParams } from 'react-router-dom';
import { chevronBackOutline } from 'ionicons/icons';
import IconTagData from '../Icon/IconTagData';
import IconChecklist from '../Icon/IconChecklist';
import IconGallery from '../Icon/IconGallery';
import IconReport from '../Icon/IconReport';
import './sidebar.css';
import { ParamsInspection } from '../../Models/ParamsInspection';

export interface SidebarProps {
    showSidebar?: boolean;
    handleShowSidebar?: () => void;
    disableLink?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ showSidebar, handleShowSidebar, disableLink }) => {
    const { id, numberInspection } = useParams < ParamsInspection>()
    return (
        <div className={`${showSidebar ? "ion-hide" : "ion-text-center white m-0 sidebar ion-hide-lg-down"}`}>
            <div className="mt-5 mb-4">
                <IonIcon icon={chevronBackOutline} className="pointer fs-4" onClick={handleShowSidebar} />
            </div>
            <div className="mt-5 mb-4" >
                <NavLink to={disableLink ? "#" :`/dati/${id}/${numberInspection}`} activeClassName="selected">
                    <IconTagData className="icon-data" width={25} />
                    <h6 className={`m-0 title-sidebar ion-text-uppercase`}>Dati</h6>
                </NavLink>
            </div>
            <div className="mb-4">
                <NavLink to={disableLink ? "#" :`/checklist/${id}/${numberInspection}`} activeClassName="selected">
                    <IconChecklist className="icon-data" width={25} />
                    <h6 className="m-0 title-sidebar ion-text-uppercase">Checklist</h6>
                </NavLink>
            </div>
            <div className="mb-4">
                <NavLink to={disableLink ? "#" :`/gallery/${id}/${numberInspection}`} activeClassName="selected">
                    <IconGallery className="icon-data" width={25} height={25} title="gallery" />
                    <h6 className="m-0 title-sidebar ion-text-uppercase">Gallery</h6>
                </NavLink>
            </div>
            <div className="mb-4">
                <NavLink to={disableLink ? "#" :`/report/${id}/${numberInspection}`} activeClassName="selected">
                    <IconReport width={25} height={25} title="report" fill="#6a6a6a" />
                    <h6 className="m-0 title-sidebar ion-text-uppercase">Report</h6>
                </NavLink>
            </div>
        </div>
    );
}

export default Sidebar;