import { IonCol, IonRow } from "@ionic/react";
import "./question.css";
//models
import Modal from "../../../components/Modal/Modal";
import ChildrenModalDeleteAllProblematic from "./ChildrenModalDeleteAllProblematic";
import { OptionQuestion } from "../../../Models/Checklist";
import { QuestionText } from "./QuestionText";
import { ResponseTypeEnum, useQuestionHook } from "./useQuestionHook";
import { SelectResponse } from "./SelectResponse";
import ProblematicWrapper from "../Problematic/ProblematicWrapper";
import { useEntityUser } from "../../../store";
import { RoleID } from "../../Detail/useResponseDetail";

type QuestionProps = {
  valueTab: number;
  question: OptionQuestion;
  stateInspection: string;
};

const Question: React.FC<QuestionProps> = ({
  valueTab,
  question,
  stateInspection,
}) => {

  const {
    iconAnimation,
    showModalDeleteProblematic,
    handleDeleteAllProblematic,
    onClickArrow,
    onSelectQuestionResponse,
    onCloseModal
  } = useQuestionHook(question, valueTab);

  const { user: currentUserParse } = useEntityUser();

  return (
    <>
      <IonRow
        className="bg-white ion-align-items-center"
        key={question.ordinamento}
      >
        <IonCol
          sizeXl="8"
          sizeLg="8"
          sizeMd="7"
          sizeXs="12"
          className="ion-align-self-center px-0"
        >
          <QuestionText question={question} iconAnimation={iconAnimation} onClickArrow={onClickArrow} />
        </IonCol>
        <SelectResponse
          presenzaRisposte={question.presenzaRisposte}
          clickedButton={question.idRisposta}
          onSelectQuestionResponse={onSelectQuestionResponse}
          stateInspection={stateInspection}
        />
      </IonRow>
      {question.visualizzaAggiungi && question.idRisposta === ResponseTypeEnum.Yes && iconAnimation && (
        <ProblematicWrapper
          question={question}
          stateInspection={stateInspection}
        />
      )}
      {!question.presenzaRisposte && currentUserParse?.profilo !== RoleID.LETTORE && (
        <ProblematicWrapper
          question={question}
          stateInspection={stateInspection}
        />
      )}
   
      <Modal
        isOpen={showModalDeleteProblematic}
        title={"Alert"}
        onIsOpenChange={onCloseModal}
      >
        <ChildrenModalDeleteAllProblematic
          handleClickNo={onCloseModal}
          handleClickYes={handleDeleteAllProblematic}
          text="Tutte le problematiche associate verranno eliminate definitivamente, continuare?"
        />
      </Modal>
    </>
  );
};
export default Question;


