import { IonCol, IonGrid, IonHeader, IonIcon, IonRow, IonToolbar } from "@ionic/react";
import './navbar.css';
import Logo from '../../assets/logo/logo.svg';
import IconAccount from '../../assets/icon/icon-account.svg';
import { NavLink } from "react-router-dom";
import Settings from '../../assets/icon/icon-settings.svg';
import { home, logOutOutline } from "ionicons/icons";
import { UserDb } from "../../Models/UserDb";
import { role } from "../../utiles";
import { useEffect } from "react";
import { EndSessionRequest, PublicClientApplication } from "@azure/msal-browser";
import { MSAL_CONFIG } from "../AzureAuthentication/azure-authentication-config";
import { useEntityUser } from "../../store";
import { RoleID } from "../../pages/Detail/useResponseDetail";
// import InstallPWA from "../InstallPwa";

export interface NavBarProps {
    user: UserDb;
    showOfflineMessage?: boolean;
}

const BaseUrl: string = process.env.REACT_APP_BASE_URL;

export let myMSALObj = {} as PublicClientApplication;

const NavBar: React.FC<NavBarProps> = ({ user, showOfflineMessage }) => {
    const { resetUser } = useEntityUser();
    const logOut = () => {
        sessionStorage.clear();
        resetUser();
        window.location.replace(`${BaseUrl}/`);

        const currentUser = myMSALObj.getActiveAccount();
        let logOutRequest: EndSessionRequest = {
            account: currentUser
        };
        myMSALObj.logout(logOutRequest);
    }

    useEffect(() => {
        myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    }, []);

    return (
        <IonHeader>
            <IonToolbar color="navbar" >
                <IonGrid className="headerGrid">

                    <IonRow className="navbar ion-align-items-center ion-justify-content-between mx-30 py-2">
                        {/* OFFLINE MESSAGE */}
                        {showOfflineMessage &&
                            <>
                                <IonCol size="12" className="ps-0 text-danger">
                                    Attenzione sei offline - puoi continuare il sopralluogo, il sistema allineerà i dati appena torni online
                                </IonCol>
                            </>
                        }
                        {!showOfflineMessage &&
                            <>
                                <IonCol sizeXl="4" sizeLg="4" sizeMd="4" sizeSm="6" sizeXs="6" className="ps-0">
                                    <NavLink to="/">
                                        <IonIcon icon={home} className="fs-3 me-2" />
                                        <img className="logo ion-text-left" src={Logo} alt="logo" />
                                    </NavLink>
                                </IonCol>

                                <div className="d-flex ion-align-items-center">
                                    {/* <IonCol size='auto' className="mx-3">
                                        <InstallPWA />
                                    </IonCol> */}

                                    <IonCol size='auto' className="p-0  ion-hide-sm-down" style={{
                                        display: 'inline-flex', alignItems: 'center'
                                    }}>
                                        <img src={IconAccount} alt="account" className="icon-user  d-inline me-2" />
                                        <div style={{
                                            display: ' flex', flexDirection: 'column'
                                        }} >
                                            <p className="name-account  mb-1 mt-0 d-inline me-2">{user.nome} {user.cognome}</p>
                                            <p className="role  my-1 d-inline-flex me-2">{role(user)}</p>
                                        </div>
                                        {[RoleID.LETTORE, RoleID.AMM_BU, RoleID.AMM_SIST, RoleID.BO].includes(user.profilo) && (
                                            <NavLink to="/impostazioni">
                                                <img src={Settings} alt="impostazioni" className="icon-user mx-3" />
                                            </NavLink>
                                        )}
                                    </IonCol>
                                    <IonCol size="2" className="my-thin-col ion-text-end ">
                                        <IonIcon icon={logOutOutline} color="primary" id="authentication" className="pointer align-middle fs-2 " onClick={logOut} />
                                    </IonCol>
                                </div>
                            </>
                        }
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonHeader>
    );
}

export default NavBar;