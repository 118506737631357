import React, { useState } from 'react';

//components
import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonLoading, IonRow, IonToast, IonToolbar } from '@ionic/react';

import Modal from '../../components/Modal/Modal';
import Footer from '../../components/Footer/Footer';
import BadgeList from '../../components/Badge/BadgeList';
import MenuMobile from '../../components/MenuMobile/MenuMobile';
import CoverImage from './CoverImage/CoverImage';
import Tooltip from '@material-ui/core/Tooltip';
import { Option } from '../../Models/Checklist';

import './detail.css';

//models
import { NoteAudio } from '../../Models/NoteAudio';
//hooks
import { Redirect, useParams } from 'react-router-dom';

import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core";
import ScrollToUp from '../../components/ScrollToUp/ScrollToUp';
import { menuOutline } from 'ionicons/icons';

import Loading from '../../components/Loading/Loading';
import ChildrenRecAudio from '../../components/Modal/ChildrenModalRecAudio';
import DatiGenerali from './DetailDatiGenerali';
import UtenzeAttive from './DetailUtenzeAttive';
import Riscaldamento from './DetailRiscaldamento';
import ElettricoSpeciale from './DetailElettricoSpeciale';
import Elevatori from './DetailElevatori';
import Antincendio from './DetailAntincendio';
import Condominio from './DetailCondominio';
import AccordionCustom from '../../components/Accordion/AccordionCustom';
import { useResponseDetail } from './useResponseDetail';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { RoleID } from './useResponseDetail';
import { FormProvider } from 'react-hook-form';
import ChildrenModalDelete from '../../components/Modal/ChildrenModalDelete';
import { useEntityHomepage } from '../../store/Homepage';
import SelectSearchable from '../../components/SelectSearchable/SelectSearchable';
import { setSessionStorage } from '../../service';
import { ParamsInspection } from '../../Models/ParamsInspection';

export const useStylesForTextMultiLine = makeStyles(() =>
    createStyles({
        root: {
            "& .MuiTextField-root": {
                backgroundColor: "white"
            },
            width: '94%',
            marginTop: '0.5rem',
            verticalAlign: 'unset !important',
        },
    }),
);

export const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export const HtmlTooltipWarning = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#eb445a',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export interface DetailProps {
    showSidebar: boolean;
    handleShowSidebar(): any
}

const Detail: React.FC<DetailProps> = ({ showSidebar = true, handleShowSidebar, children }) => {

    const {
        isLoading,
        isOnline,
        dataDetail,
        dataDetailLoading,
        errorDataDetail,
        audioDetails,
        handleAudioStop,
        handleReset,
        onSaveDetail,
        onSubmitRemoveYES,
        showDeletePhotoMessage,
        setShowDeletePhotoMessage,
        showModalDeletePhoto,
        setShowModalDeletePhoto,
        handleDeletePhotoDB,
        currentUserParse,
        methods,
        onSubmitModalAlertFirstYES,
        modalRecAudioBriefDescription,
        setModalRecAudioBriefDescription,
        handleModalRecAudioBriefDescription,
        dataDetailLoadingMessage,
        isModalOpen,
        setIsModalOpen,
        isDeleting,
        setIsDeleting,
        isToastOpen,
        setIsToastOpen,
        toastMessage,
        loadingSendToRiassegna,
        setLoadingSendToRiassegna,
        showModalSendForRiassegna,
        setShowModalSendForRiassegna,
        valueRiassegna,
        setValueRiassegna,
        assegnatariForFornitore,
        getAssegnatariFromFornitore,
        handleSubmitForRiassegnaYES
    } = useResponseDetail();

    const [contentRef, scrollToTop] = useScrollToTop();

    const handleModal = (modalDelete: boolean) => {
        setIsModalOpen(modalDelete);
        setIsDeleting(modalDelete);
    }

    const handleModalRiassegna = (modalRiassegna: boolean) => {
        getAssegnatariFromFornitore(Number(currentUserParse.id), Number(dataDetail.progettoID));
        setShowModalSendForRiassegna(modalRiassegna)
    }
    const isCurrentBM = () => { return (currentUserParse.nome + " " + currentUserParse.cognome) === dataDetail?.bm; }

    console.log(dataDetail)
    console.log(currentUserParse)
    console.log(( dataDetail?.stato === 'Creato' && currentUserParse.profilo === RoleID.BM || currentUserParse.profilo === RoleID.BO ))
    console.log((dataDetail?.stato === "In corso" || dataDetail?.stato === "Creato" || dataDetail?.stato === "Revisionato") && (currentUserParse.profilo === RoleID.AMM_SIST || currentUserParse.profilo === RoleID.BO || isCurrentBM()))
    


    const [errorValueRiassegna, setErrorValueRiassegna] = useState<boolean>(false);
  const { numberInspection } = useParams<ParamsInspection>();
    
//   const confirmButton = () => {
//     setErrorValueRiassegna(errorValueRiassegna === null);
//     if (errorValueRiassegna !== null) {
//         setShowModalSendForRiassegna(true);
//         setSessionStorage(`SendToApproval_${numberInspection}`, true);
//     }
//   };

    return (
        <>
            {dataDetail?.autorizzazione && <MenuMobile disableLink={!isOnline} />}
            <IonContent ref={contentRef} scrollEvents={true}>

                <IonGrid className="p-0 mb-6">
                    <IonRow>
                        {dataDetail?.autorizzazione && children}
                        {errorDataDetail ? (
                            <IonCol offsetLg="1" sizeXs="12" sizeXl="11" sizeLg="11">
                                <IonRow className="ion-align-items-center vh-100">
                                    <IonCol size="12">
                                        <h1 className="ion-text-center text-danger">ATTENZIONE:</h1>
                                        <p className="ion-text-center text-danger">errore</p>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        ) : (
                            dataDetail?.autorizzazione || dataDetail?.autorizzazione === null ? (
                                <IonCol offsetLg={showSidebar ? "0" : "1"} sizeXs="12" sizeXl={showSidebar ? "12" : "11"} sizeLg={showSidebar ? "12" : "11"}>
                                    <IonRow className="ion-justify-content-end ion-align-items-center">
                                        <IonCol>
                                            {showSidebar && <IonIcon icon={menuOutline} className="pointer me-1 fs-5" onClick={() => handleShowSidebar()} color="primary" />}
                                            {dataDetail?.citta === "" ? "" : <h1 className="my-0 d-inline-block">{dataDetail?.fondo}</h1>}
                                        </IonCol>
                                        <IonCol className="ion-text-end pb-0 ion-hide-lg-down">
                                            {/* ELIMINAZIONE SOPRALLUOGO - admin o creatore */}
                                            {
                                                ((dataDetail?.stato === "In corso" || dataDetail?.stato === "Creato" || dataDetail?.stato === "Revisionato") &&
                                                (currentUserParse.profilo === RoleID.AMM_SIST || currentUserParse.profilo === RoleID.BO || isCurrentBM())) &&
                                                <IonButton fill="solid" color="danger" className="ion-text-uppercase" onClick={() => handleModal(true)}>ELIMINA</IonButton>
                                            }
                                            {/* RIASSEGNA SOPRALLUOGO - admin o backoffice */}
                                            {
                                                //(dataDetail?.stato === "In corso" || dataDetail?.stato === "Creato" || dataDetail?.stato === "Revisionato") &&
                                                (currentUserParse.profilo === RoleID.AMM_SIST || currentUserParse.profilo === RoleID.BO) && 
                                                (dataDetail?.stato === 'Creato' || dataDetail?.stato === 'In corso' || dataDetail?.stato === 'Revisionato' || dataDetail?.stato === 'Respinto'  || dataDetail?.stato === 'In Approvazione'  || dataDetail?.stato === 'In Revisione' ) &&
                                                <IonButton fill="solid" color="warning" className="ion-text-uppercase" onClick={() => handleModalRiassegna(true)}>RIASSEGNA</IonButton>
                                            }
                                            {/* BM */}

                                            {
                                                ( (
                                                    (dataDetail?.stato === 'In corso' || dataDetail?.stato === 'Creato' || dataDetail?.stato === 'Revisionato' || dataDetail?.stato === 'Respinto') &&
                                                    ( currentUserParse.profilo === RoleID.BM || currentUserParse.profilo === RoleID.AMM_SIST || (currentUserParse.profilo === RoleID.BME && dataDetail?.stato != 'Revisionato') || (currentUserParse.profilo === RoleID.APPROVATORE && dataDetail?.stessoGruppo) )
                                                ) 
                                                || ( dataDetail?.stato === 'Creato' && currentUserParse.profilo === RoleID.BM || currentUserParse.profilo === RoleID.BO)
                                                || ( (dataDetail?.stato === 'In corso' || dataDetail?.stato === 'Creato' || dataDetail?.stato === 'Revisionato' || dataDetail?.stato === 'Respinto') && isCurrentBM() ) )
                                                && <IonButton onClick={onSaveDetail} fill="solid" className="ion-text-uppercase" >SALVA</IonButton>
                                            }

                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <p className="m-0">Dati principali da anagrafica immobile SAP {dataDetail.unitaEconomica}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <CoverImage
                                            methods={methods}
                                            setShowModalDeletePhoto={setShowModalDeletePhoto}
                                        />
                                        <IonCol sizeLg="4" sizeMd="12" sizeSm="12" sizeXs="12" className="ps-3 mt-xs-3 mt-lg-0">
                                            <h3 className="mt-0">Site visit tecnica</h3>
                                            <p className="my-0">{dataDetail?.citta === "" ? "" : dataDetail?.citta}, {dataDetail?.indirizzo === "" ? "" : dataDetail?.indirizzo}</p>
                                            <p className="mt-0">{dataDetail?.foglio === null ? null : `Foglio ${dataDetail?.foglio}`} {dataDetail?.mappale === null ? null : ` - Particella ${dataDetail?.mappale}`} {dataDetail?.subalterno === null ? null : `-${dataDetail?.subalterno}`}</p>
                                            <h3>Uso prevalente</h3>
                                            <p className="mt-0">{`${dataDetail?.usoPrevalente === "" || dataDetail?.usoPrevalente === null ? "-" : dataDetail?.usoPrevalente}`}</p>
                                            <h3>Occupazione</h3>
                                            <p className="mt-0">{dataDetail?.percentualeOccupazione === null ? "-" : (dataDetail?.percentualeOccupazione + " %")}</p>
                                            <h3>Area</h3>
                                            <p>{dataDetail?.area === null ? "-" : (dataDetail?.area + " mq")}</p>
                                        </IonCol>
                                        <IonCol sizeLg="4" sizeMd="12" sizeSm="12" sizeXs="12" className="ps-3 mt-xs-3 mt-lg-0">
                                            <h3 className="mt-0">Stato</h3>
                                            {dataDetail?.stato !== "" && (
                                                <BadgeList stato={dataDetail?.stato} />
                                            )}
                                            <h3>Data sopralluogo</h3>
                                            <p className="mt-0">{`${dataDetail?.dataIspezione.split("T")[0].split("-").reverse().join("/")}`}</p>
                                            <h3>Lingua</h3>
                                            <p className="mt-0">{dataDetail?.language}</p>
                                            <h3>BM</h3>
                                            { dataDetail?.bmRole === 2 
                                                ? <b className="mt-0">{dataDetail?.bm}</b>
                                                : <p className="mt-0">{dataDetail?.bm}</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <FormProvider {...methods} >
                                            <IonCol>
                                                <form id='details-form'>
                                                    <AccordionCustom title="Dati generali" open={true}>
                                                        <DatiGenerali handleModalRecAudioBriefDescription={handleModalRecAudioBriefDescription} />
                                                    </AccordionCustom>
                                                    <AccordionCustom title="Utenze attive" className="mt-3">
                                                        <UtenzeAttive />
                                                    </AccordionCustom>
                                                    <AccordionCustom title="Impianto di riscaldamento" className="mt-3">
                                                        <Riscaldamento />
                                                    </AccordionCustom>
                                                    <AccordionCustom title="Impianto elettrico e speciale" className="mt-3">
                                                        <ElettricoSpeciale />
                                                    </AccordionCustom>
                                                    <AccordionCustom title="Impianti elevatori" className="mt-3">
                                                        <Elevatori />
                                                    </AccordionCustom>
                                                    <AccordionCustom title="Impianti antincendio" className="mt-3">
                                                        <Antincendio />
                                                    </AccordionCustom>
                                                    <AccordionCustom title="Condominio" className="mt-3">
                                                        <Condominio />
                                                    </AccordionCustom>
                                                </form>
                                            </IonCol>
                                        </FormProvider>
                                    </IonRow>
                                </IonCol>
                            ) : (
                                <IonCol sizeXs="12" sizeXl="12" sizeLg="12">
                                    <IonRow className="ion-align-items-center ion-justify-content-center vh-100">
                                        <IonCol size="12">
                                            <h1 className="ion-text-center text-danger">Errore 401</h1>
                                            <p className="ion-text-center text-danger">non sei autorizzato</p>
                                            <Redirect to="/" />
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            )
                        )}
                    </IonRow>
                </IonGrid>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showDeletePhotoMessage}
                    onDidDismiss={() => setShowDeletePhotoMessage(false)}
                    message={'Eliminazione in corso...'}
                />

                <IonToast
                    duration={1000}
                    color="success"
                    isOpen={isToastOpen}
                    onDidDismiss={() => setIsToastOpen(false)}
                    message={toastMessage}
                    position="top"
                    buttons={[
                        {
                            side: 'start',
                            text: 'Dati generali',
                            handler: () => {
                                console.log('Favorite clicked');
                            }
                        }
                    ]}
                />
                <IonToast
                    duration={1000}
                    color="danger"
                    isOpen={ !!Object.keys(methods.formState.errors)?.length}
                    message={'Controlla i campi del form'}
                    position="top"
                />
                <ScrollToUp up={scrollToTop} />

                <Footer />
                {/*
                <div className="footer">
                    <div>
                        <p className="footer-text ion-text-center">© 2021 Prelios s.p.a. P.iva 02473170153&nbsp;|&nbsp;
                            <a href="https://prelios.com/it/legal-disclaimer" target="_blank" rel="noreferrer">Disclaimer</a>&nbsp;-&nbsp;
                            <a href="https://prelios.com/it/company-info" target="_blank" rel="noreferrer">Company info</a></p>
                    </div>
                </div>
                */}
            </IonContent>
            {/* only for mobile */}
            {
                (dataDetail?.stato === "In corso" || dataDetail?.stato === "Creato" || dataDetail?.stato === "Revisionato" || dataDetail?.stato === "Respinto") &&
                (
                    currentUserParse.profilo === RoleID.BM || currentUserParse.profilo === RoleID.AMM_SIST || (currentUserParse.profilo === RoleID.BME && dataDetail?.stato !== "Revisionato") ||
                    (currentUserParse.profilo === RoleID.APPROVATORE && dataDetail?.stessoGruppo)
                ) &&
                <IonFooter className="ion-no-border ion-hide-lg-up" >
                    <IonToolbar >
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton fill="solid" onClick={onSaveDetail} expand="block">SALVA</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonFooter>
            }

            {/*vedere se unificare le modali in una sola*/}
            <Modal isOpen={showModalDeletePhoto} title={'Elimina foto'} onIsOpenChange={() => setShowModalDeletePhoto(false)}>
                <ChildrenModalDelete
                    handleClickNo={() => setShowModalDeletePhoto(false)}
                    handleClickYes={handleDeletePhotoDB}
                    text="Stai cancellando questa immagine definitivamente, continuare?"
                />
            </Modal>



            <Modal isOpen={isModalOpen} title='Attenzione' onIsOpenChange={() => handleModal(false)}>
                <IonGrid className="m-0 mx-3">
                    <IonRow>
                        <IonCol>
                            {
                                isDeleting
                                    ? <p className="m-0">Si sta eliminando definitivamente il sopralluogo, continuare?</p>
                                    : <p className="m-0">Salvando i dati verrà generata la checklist per i seguenti dati <span className="fw-bold">Tipologia UE:</span> {dataDetail.tipologiaUe ?? ""} e <span className="fw-bold">Occupazione</span> {dataDetail.statoOccupazione ?? ""}, continuare?</p>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-end ion-align-items-end">
                        <IonCol sizeXl="3" className="ion-text-end pe-0">
                            <IonButton expand="block" fill="solid" className="fw-bold" onClick={() => handleModal(false)}>no</IonButton>
                        </IonCol>
                        <IonCol sizeXl="3" className="ion-text-end ps-0">
                            <IonButton
                                expand="block"
                                fill="solid"
                                color="danger"
                                className="fw-bold"
                                onClick={isDeleting ? onSubmitRemoveYES : onSubmitModalAlertFirstYES}
                            >
                                si
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </Modal>

            <Modal isOpen={showModalSendForRiassegna} title={"Riassegna soppraluogo"} onIsOpenChange={setShowModalSendForRiassegna}>
                <IonGrid className="mx-3">
                <IonRow>
                    <IonCol className="px-0">
                    {assegnatariForFornitore.length > 0 && (
                        <SelectSearchable
                        label="Seleziona assegnatario"
                        value={valueRiassegna}
                        setValue={setValueRiassegna}
                        options={assegnatariForFornitore}
                        error={errorValueRiassegna}
                        disablePortal={false}
                        />
                    )}
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-end">
                    <IonButton
                    fill="clear"
                    className="fw-bold ion-text-uppercase"
                    onClick={() => setShowModalSendForRiassegna(false)}
                    >
                    annulla
                    </IonButton>
                    <IonButton
                    fill="solid"
                    className="fw-bold ion-text-uppercase"
                    onClick={handleSubmitForRiassegnaYES}
                    >
                    conferma
                    </IonButton>
                </IonRow>
                </IonGrid>
            </Modal>

            {/* rec audio */}
            <Modal isOpen={modalRecAudioBriefDescription} title='Descrizione sommaria audio' onIsOpenChange={setModalRecAudioBriefDescription}>
                <ChildrenRecAudio
                    audioDetails={audioDetails.url}
                    handleAudioStop={(data: NoteAudio) => handleAudioStop(data)}
                    handleReset={handleReset}
                    audioBase64DB={dataDetail?.descrizioneSommariaAudioUrl}
                />
            </Modal>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={isLoading}
                message={"Sincronizzazione Data with Server"}
            />
            <Loading open={dataDetailLoading} message={dataDetailLoadingMessage} duration={0} />
            
      <Loading
        open={loadingSendToRiassegna}
        setOpen={setLoadingSendToRiassegna}
        message="Riassegnazione in corso..."
        duration={0}
      />
        </>
    );
}

export default Detail;