import create from "zustand";
import { getBu } from "../service/api/bu";
import { BU } from "../Models/BU";

export const useEntityBU = create((set: Function, get: Function) => ({
    buList: [] as BU[],
    buListLoading: true,
    buListLoadingMessage: 'Caricamento in corso...',
    errorBuList: '',
    getBuList: (idUtente: number) => {
        const onSuccess = (buList: BU[]) => set({ ...get(), buList, errorBuList: '', buListLoading: false, buListLoadingMessage: '' });
        const onError = (errorBuList) => set({ ...get(), errorBuList, buListLoading: false });
        getBu({idUtente: idUtente}, onSuccess, onError);
    }
}));
