import { useCallback, useEffect } from "react";
import { useIndexedDB } from "react-indexed-db";
import { useParams } from "react-router";
import { useIsLoading } from "../../hooks/useIsLoading";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { requestPostOptions } from "../../service";
import { useEntityChecklist, useEntityConnectivity, useEntityUser } from "../../store";
import { DBSchema } from "../../store/db";

export function useResponseChecklist() {

    const { connectivity: isOnline } = useEntityConnectivity();
    const { user: currentUserParse } = useEntityUser();
    const { id, numberInspection } = useParams<ParamsInspection>()
    const { dataChecklist, refreshChecklist } = useEntityChecklist(({ dataChecklist, refreshChecklist }) => ({ dataChecklist, refreshChecklist }));
    const { getAll, deleteRecord } = useIndexedDB('checklist');
    const [isLoading, setIsLoading] = useIsLoading(false);
    const sincronizeOfflineDBToOnline = useCallback(async () => {
        if (!isOnline) return;

        const requestArray = await getAll<DBSchema>()
        if (!requestArray?.length) {
            isOnline && refreshChecklist({
                idImmobile: +id,
                idIspezione: + numberInspection,
                idUtente: +currentUserParse?.id
            });
            return;
        }
        requestArray?.length && setIsLoading(true)
        const insertPromiseArray = requestArray.map(({ id, data, endpoint }) =>
            fetch(endpoint, requestPostOptions(data)).then(async () => await deleteRecord(id))
        );
        Promise.allSettled([
            ...insertPromiseArray,
        ]).finally(() => {
            refreshChecklist({
                idImmobile: +id,
                idIspezione: + numberInspection,
                idUtente: +currentUserParse?.id
            });
            setIsLoading(false)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserParse, id, isOnline, numberInspection, refreshChecklist])

    useEffect(() => {
        sincronizeOfflineDBToOnline()
    }, [sincronizeOfflineDBToOnline]);
    return { dataChecklist, refreshChecklist, isLoading };
}
