import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';


export interface ChildrenModalDeleteProps {
    handleClickNo: () => void;
    handleClickYes:  () => void;
    text: string;
}

const ChildrenModalDelete: React.FC<ChildrenModalDeleteProps> = ({handleClickNo,handleClickYes, text}) => {
    return (
        <IonGrid className="m-0 mx-3">
            <IonRow>
                <IonCol className="px-0">
                    <p className="m-0">{text}</p>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end ion-align-items-end">
                <IonCol sizeXl="3" className="ion-text-end px-0">
                    <IonButton expand="block" fill="solid" className="fw-bold" onClick={handleClickNo}>no</IonButton>
                </IonCol>
                <IonCol sizeXl="3" className="ion-text-end ps-0">
                    <IonButton expand="block" fill="solid" color="danger" className="fw-bold" onClick={handleClickYes}>si</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}

export default ChildrenModalDelete;