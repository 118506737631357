import { useCallback, useEffect, useMemo, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import { useHistory, useParams } from "react-router";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { requestPostOptions } from "../../service";
import { useEntityConnectivity, useEntityDetail, useEntityHomepage, useEntityUser } from "../../store";
import { DataDetail } from '../../Models/DataDetail';
import { DetailSchema } from "../../store/db/detailSchema";
import { deleteCopertina, disableSopralluogo, ENDPOINT_DETAILS, findRecordDetailFromDB, saveSopralluogo, sendToRiassegna } from "../../service/api";
import { useRecAudio } from "../../hooks/useRecAudio";
import { useForm } from "react-hook-form";
import { IMPIANTO_ANTINCENDIO_STATO, IMPIANTO_ELETTRICO_STATO, IMPIANTO_ELEVATORI_STATO, IMPIANTO_RISCALDAMENTO_STATO, STATO_MANUTENTIVO_GENERALE, STATO_OCCUPAZIONE, TIPOLOGIAUE } from "./selectConstantsOptions";
import { BaseUrl, result } from "../../utiles";
import { useSendToApproval } from "../Checklist/useSendToApproval";
import { useIsLoading } from "../../hooks/useIsLoading";
import { Option } from "../../Models/Checklist";
import { ResponseForApproval } from "../../Models/ResponseForApproval";

export enum RoleID {
    AMM_SIST = 1,
    BM,
    APPROVATORE,
    BME,
    LETTORE,
    AMM_BU,
    BO
};



const bridgeDetailToForm = (dataDetail: DataDetail) => {
    const {
        tipologiaUe,
        tipologiaUeID,
        statoOccupazione,
        statoOccupazioneID,
        statoManutentivoGenerale,
        statoManutentivoGeneraleID,
        impiantoRiscaldamentoStato,
        impiantoRiscaldamentoStatoID,
        impiantoElettricoStato,
        impiantoElettricoStatoID,
        impiantiElevatoriStato,
        impiantiElevatoriStatoID,
        impiantiAntincendioStato,
        impiantiAntincendioStatoID,
        ...data
    } = dataDetail;

    return ({
        ...data,
        tipologiaUe: tipologiaUeID < 0 ? null : tipologiaUeID,
        statoOccupazione: statoOccupazioneID < 0 ? null : statoOccupazioneID,
        statoManutentivoGenerale: statoManutentivoGeneraleID < 0 ? null : statoManutentivoGeneraleID,
        impiantoRiscaldamentoStato: impiantoRiscaldamentoStatoID < 0 ? null : impiantoRiscaldamentoStatoID,
        impiantoElettricoStato: impiantoElettricoStatoID < 0 ? null : impiantoElettricoStatoID,
        impiantiElevatoriStato: impiantiElevatoriStatoID < 0 ? null : impiantiElevatoriStatoID,
        impiantiAntincendioStato: impiantiAntincendioStatoID < 0 ? null : impiantiAntincendioStatoID,
    })
}

export const bridgeFormToDetail = (form) => {
    const {
        tipologiaUe,
        statoOccupazione,
        statoManutentivoGenerale,
        impiantoRiscaldamentoStato,
        impiantoElettricoStato,
        impiantiElevatoriStato,
        impiantiAntincendioStato,
        ...data
    } = form;
    return ({
        ...data,
        tipologiaUe: result(TIPOLOGIAUE, tipologiaUe)?.nome || '',
        tipologiaUeID: tipologiaUe || -1,
        statoOccupazione: result(STATO_OCCUPAZIONE, statoOccupazione)?.nome || '',
        statoOccupazioneID: statoOccupazione || -1,
        statoManutentivoGenerale: result(STATO_MANUTENTIVO_GENERALE, statoManutentivoGenerale)?.nome || '',
        statoManutentivoGeneraleID: statoManutentivoGenerale || -1,
        impiantoRiscaldamentoStato: result(IMPIANTO_RISCALDAMENTO_STATO, impiantoRiscaldamentoStato)?.nome || '',
        impiantoRiscaldamentoStatoID: impiantoRiscaldamentoStato || -1,
        impiantoElettricoStato: result(IMPIANTO_ELETTRICO_STATO, impiantoElettricoStato)?.nome || '',
        impiantoElettricoStatoID: impiantoElettricoStato || -1,
        impiantiElevatoriStato: result(IMPIANTO_ELEVATORI_STATO, impiantiElevatoriStato)?.nome || '',
        impiantiElevatoriStatoID: impiantiElevatoriStato || -1,
        impiantiAntincendioStato: result(IMPIANTO_ANTINCENDIO_STATO, impiantiAntincendioStato)?.nome || '',
        impiantiAntincendioStatoID: impiantiAntincendioStato || -1,
    })
}
  
export function useResponseDetail() {



    const {
        dataDetail,
        getDataDetail,
        updateDataDetail,
        dataDetailLoading,
        errorDataDetail,
        setDataDetailLoading,
        dataDetailLoadingMessage,
        setDataDetailLoadingMessage,
        setPhoto,
        setPhotoimmageURL,
        assegnatariForFornitore,
        getAssegnatariFromFornitore
    } = useEntityDetail(store => store);

    const { id, numberInspection } = useParams<ParamsInspection>();
    const sendToApproval = useSendToApproval(numberInspection);

    const isOnline = useEntityConnectivity(store => store.connectivity);

    const { user: currentUserParse } = useEntityUser();

    const { getAll, deleteRecord, add, update } = useIndexedDB('detail');

    const { audioDetails, handleAudioStop, handleReset, saveAudioBase64 } = useRecAudio();

    const methods = useForm({ mode: 'onChange' });

    //STATES
    const [showDeletePhotoMessage, setShowDeletePhotoMessage] = useState<boolean>(false);
    const [showModalDeletePhoto, setShowModalDeletePhoto] = useState<boolean>(false);
    const [modalRecAudioBriefDescription, setModalRecAudioBriefDescription] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isLoading, setIsLoading] = useIsLoading(false);
    // sincro to db
    const sincronizeOfflineDBToOnline = useCallback(async () => {
        if (!isOnline) return;

        const requestArray = await getAll<DetailSchema>()

        if (!requestArray?.length) {
            getDataDetail(+currentUserParse.id, +id, +numberInspection);
            return;
        }
        requestArray?.length && setIsLoading(true)
        const insertPromiseArray = requestArray.map(({ id, data, endpoint }) =>
            fetch(endpoint, requestPostOptions(data)).then(async () => await deleteRecord(id))
        );

        Promise.allSettled([
            ...insertPromiseArray,
        ]).finally(() => {
            getDataDetail(+currentUserParse.id, +id, +numberInspection);
            setIsLoading(false)
            return;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserParse.id, getDataDetail, id, isOnline, numberInspection])

    useEffect(() => {
        const ac = new AbortController();
        sincronizeOfflineDBToOnline()
        return () => ac.abort();
    }, [sincronizeOfflineDBToOnline]);

    useEffect(() => {
        if (dataDetail.id === -1) return;
        const formData = bridgeDetailToForm(dataDetail);
        Object.entries(formData).forEach(([key, value]) => methods.setValue(key, value))
        !!sendToApproval && methods.trigger();
    }, [dataDetail, methods, sendToApproval]);

    const onSaveDetail = () => {
        const formData = methods.watch();
        const dataHasChanged = formData.statoOccupazione !== dataDetail.statoOccupazioneID || formData.tipologiaUe !== dataDetail.tipologiaUeID;
        dataHasChanged ? setIsModalOpen(true) : methods.handleSubmit(save)();
    };
    const save = async (dataSubmitted: DataDetail) => {
        setDataDetailLoadingMessage('Salvataggio in corso...');
        setDataDetailLoading(true);
        const convertedData = bridgeFormToDetail(dataSubmitted);
        const photoToUpload = dataDetail?.immagineSave?.length ? dataDetail?.immagineSave : [];
        const data = {
            ...convertedData,
            idUtente: Number(currentUserParse.id),
            idIspezione: Number(numberInspection),
            descrizioneSommariaAudioUrl: saveAudioBase64,
            immagineSave: photoToUpload
        };

        const onSuccess = (result) => {

            if (result) {
                setToastMessage('Dati salvati con successo');
            } else {
                setToastMessage('Errore durante il salvataggio dei dati');
            }

            setIsToastOpen(true);
            setDataDetailLoadingMessage('');
            setDataDetailLoading(false);
            getDataDetail(+currentUserParse.id, +id, +numberInspection);
        };

        const addDetailToDB = async () => {

            const photoToUpload =
                dataDetail?.immagineSave?.length
                    ? dataDetail?.immagineSave?.[0].webviewPath
                    : dataDetail?.immagineUrl
                        ? dataDetail.immagineUrl
                        : '';

            const dataConvertedOffline = {
                ...data,
                immagineSave: [],
                immagineUrl: photoToUpload
            }
            data.idIspezione
                && findRecordDetailFromDB(getAll, data.idIspezione)
                    .then(
                        async storedForm => {
                            storedForm
                                ? await update({ ...storedForm, data: data })
                                : await add({ endpoint: ENDPOINT_DETAILS.saveSopralluogo, data: data, idIspezione: +numberInspection })
                        }
                    ).finally(() => {
                        setToastMessage('Dati salvati con successo');
                        setIsToastOpen(true);
                        setDataDetailLoadingMessage('');
                        setDataDetailLoading(false);
                        updateDataDetail(dataConvertedOffline);
                    })
        };

        isOnline
            ? saveSopralluogo(data, onSuccess, console.error)
            : addDetailToDB();

        setIsModalOpen(false);

    };

    const onSubmitRemoveYES = async () => {

        setDataDetailLoadingMessage('Eliminazione in corso...');
        setDataDetailLoading(true);


        const data = {
            idUtente: Number(currentUserParse.id),
            idIspezione: Number(numberInspection)
        };

        const base_url = window.location.origin;

        const onSuccess = (result) => {
            setIsModalOpen(false);

            if (result) {
                window.location.href = base_url;
            } else {
                setToastMessage("Errore nell'eliminazione del sopralluogo");
            }

            setIsToastOpen(true);
            setDataDetailLoadingMessage('');
            setDataDetailLoading(false);
        };

        const removeInspectionOffline = async () => {
            data.idIspezione
                && findRecordDetailFromDB(getAll, data.idIspezione)
                    .then(
                        async storedForm => {
                            storedForm
                                ? await update({ ...storedForm, data: data, endpoint: ENDPOINT_DETAILS.disableSopralluogo })
                                : await add({ endpoint: ENDPOINT_DETAILS.disableSopralluogo, data: data, idIspezione: +numberInspection });
                        }
                    )
                    .finally(() => {
                        setIsModalOpen(false);
                        setIsToastOpen(true);
                        setDataDetailLoadingMessage('');
                        setDataDetailLoading(false);
                        window.location.href = base_url;
                    })
        };
        isOnline
            ? await disableSopralluogo(data, onSuccess, console.error)
            : removeInspectionOffline()

        setIsDeleting(false);
    };

    const handleDeletePhotoDB = async () => {

        setShowDeletePhotoMessage(true);

        const data = {
            idUtente: Number(currentUserParse.id),
            idImmobile: +id,
            idIspezione: +numberInspection
        };

        const isImgInLocale = !!dataDetail?.immagineSave?.[0];

        const onSuccess = () => {
            setShowModalDeletePhoto(false);
            getDataDetail(+currentUserParse.id, +id, +numberInspection);
            setShowDeletePhotoMessage(false)
        };

        const handleDeleteCopertina = () => {
            if (isImgInLocale) {
                setPhoto([], dataDetail);
                onSuccess();
            } else {
                deleteCopertina(data, onSuccess, () => setShowDeletePhotoMessage(false));
            }
        };

        const handleDeleteCopertinaOffline = async () => {
            const formData = methods.watch();
            if (isImgInLocale) {
                setPhoto([], bridgeFormToDetail(formData));
                const storedForm = data.idIspezione && await findRecordDetailFromDB(getAll, data.idIspezione);
                storedForm && await update({ ...storedForm, data: { ...data, immagineSave: [] } });
            } else {
                await add({ endpoint: ENDPOINT_DETAILS.deleteCopertina, data: data, idIspezione: +numberInspection });
                setPhotoimmageURL("", bridgeFormToDetail(formData));
            }
            setShowModalDeletePhoto(false);
            setShowDeletePhotoMessage(false)
        }

        isOnline
            ? handleDeleteCopertina()
            : handleDeleteCopertinaOffline()
    };

    const onSubmitModalAlertFirstYES = (): void => {
        methods.handleSubmit(save)();
        setIsModalOpen(false);
    }

    const history = useHistory();
    const DETAIL_ENDPOINTS = useMemo(() => ({
        RIASSEGNA: `${BaseUrl}/Sopralluoghi/RiassegnaSopralluogo`
      }), []);
    
    const [loadingSendToRiassegna, setLoadingSendToRiassegna] = useState<boolean>(false);
    const [showModalSendForRiassegna, setShowModalSendForRiassegna] = useState<boolean>(false);
    const [responseForRiassegna, setResponseForRiassegna] = useState<ResponseForApproval>();
    const [valueRiassegna, setValueRiassegna] = useState<Option>(null);
    
    const handleSubmitForRiassegnaYES = async () => {
    
        setLoadingSendToRiassegna(true);
        const approversSavedOnDb = await getAll();
    
        const data = {
          UserID: Number(currentUserParse.id),
          InspectionId: +numberInspection,
          Assegnatario: valueRiassegna.id,
        };
    
        const onSuccess = (result: boolean) => {
          if(result){
            setShowModalSendForRiassegna(false);
            history.go(0);
          }
          setLoadingSendToRiassegna(false);
        }
      
        const onError = (error) => console.error(error);
    
        const addToDB = async () => {
          const recordAlreadyExist = assegnatariForFornitore.some(data => data.endpoint === DETAIL_ENDPOINTS.RIASSEGNA);
          !recordAlreadyExist && await add({ endpoint: DETAIL_ENDPOINTS.RIASSEGNA, data });
          setShowModalSendForRiassegna(false);
          setLoadingSendToRiassegna(false);
        } 
    
        isOnline
          ? await sendToRiassegna(data, onSuccess, onError)
          : addToDB();
      };
    
    const handleModalRecAudioBriefDescription = useCallback(() => setModalRecAudioBriefDescription(data => !data), []);

    return {
        isLoading,
        isOnline,
        dataDetail,
        dataDetailLoading,
        errorDataDetail,
        audioDetails,
        handleAudioStop,
        handleReset,
        onSaveDetail,
        onSubmitRemoveYES,
        showDeletePhotoMessage,
        setShowDeletePhotoMessage,
        showModalDeletePhoto,
        setShowModalDeletePhoto,
        handleDeletePhotoDB,
        currentUserParse,
        methods,
        onSubmitModalAlertFirstYES,
        modalRecAudioBriefDescription,
        setModalRecAudioBriefDescription,
        handleModalRecAudioBriefDescription,
        dataDetailLoadingMessage,
        isModalOpen,
        setIsModalOpen,
        isDeleting,
        setIsDeleting,
        isToastOpen,
        setIsToastOpen,
        toastMessage,
        loadingSendToRiassegna,
        setLoadingSendToRiassegna,
        showModalSendForRiassegna,
        setShowModalSendForRiassegna,
        valueRiassegna,
        setValueRiassegna,
        getAssegnatariFromFornitore,
        assegnatariForFornitore,
        handleSubmitForRiassegnaYES,
    };
}
