import { useCallback, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import { useParams } from "react-router";
import { OptionQuestion } from "../../../Models/Checklist";
import { ParamsInspection } from "../../../Models/ParamsInspection";
import { QuestionResponse } from "../../../Models/QuestionResponse";
import { deleteProblematicaMassiva, ENDPOINT_CHECKLIST, findRecordQuestionResponseFromDB, responseChecklist } from "../../../service/api";
import { useEntityChecklist, useEntityConnectivity, useEntityUser } from "../../../store";

export enum ResponseTypeEnum {
  No = 1,
  NA = 2,
  NotView = 3,
  Yes = 4,
}
export const useQuestionHook = (question: OptionQuestion, valueTab) => {
  const { id, numberInspection } = useParams<ParamsInspection>()
  const { connectivity: isOnline } = useEntityConnectivity();
  const { user: currentUserParse } = useEntityUser();
  const {
    refreshChecklist,
    addResponse,
    deleteAllProblematic
  } = useEntityChecklist(({refreshChecklist, addResponse, deleteAllProblematic}) => ({refreshChecklist, addResponse, deleteAllProblematic}));
  const setCallFetchGetChecklist = useCallback(() => refreshChecklist({
    idImmobile: +id,
    idIspezione: +numberInspection,
    idUtente: +currentUserParse?.id
  }), [currentUserParse, id, numberInspection, refreshChecklist]);

  const { getAll, add, update } = useIndexedDB('checklist');

  const [clickedButton, setClickedButton] = useState(question.idRisposta);
  const [iconAnimation, setIconAnimation] = useState(false);

  const [showModalDeleteProblematic, setShowModalDeleteProblematic] = useState<boolean>(false);
  const [responseModalDeleteProblematic, setResponseModalDeleteProblematic] = useState<number>(0);

  const saveResponse = (selectedResponse: ResponseTypeEnum, fromDelete = false) => {

    const data: QuestionResponse = {
      idUtente: Number(currentUserParse.id),
      idIspezione: +numberInspection,
      idRisposta: selectedResponse,
      idChecklist: question.idDomanda,
    };

    const handleSuccess = () => onSuccess(selectedResponse, fromDelete);

    isOnline
      ? responseChecklist(data, handleSuccess, console.error)
      : addResponseToDB(data);
  };
  const onSuccess = (selectedResponse: ResponseTypeEnum, fromDelete: boolean) => {
    setClickedButton(selectedResponse);
    setCallFetchGetChecklist();
    fromDelete && deletedEffect();
  }
  const deletedEffect = ()=>{
    setIconAnimation(false)
    setShowModalDeleteProblematic(false);
  }
  const addResponseToDB = async (data: QuestionResponse) => {
    const record = await findRecordQuestionResponseFromDB(getAll, data.idChecklist)
    record
      ? update({ ...record, data: { ...record?.data, idRisposta: data.idRisposta } })
      : add({ endpoint: ENDPOINT_CHECKLIST.add, data });

    setClickedButton(data.idRisposta);
    const { idChecklist, idRisposta } = data
    addResponse({ idChecklist, idRisposta, valueTab })
  }

  const handleDeleteAllProblematic = () => {
    const data = {
      idUtente: Number(currentUserParse.id),
      idDomanda: question.idDomanda,
    };
    isOnline
      ? deleteProblematicaMassiva(data, deleteAllProblematicOnSuccess, console.error)
      : deleteAllProblematicOnOffline(data)
  }

  const deleteAllProblematicOnSuccess = () => saveResponse(responseModalDeleteProblematic, true);

  const deleteAllProblematicOnOffline = async (data) => {

    setClickedButton(responseModalDeleteProblematic);
    deletedEffect();

    add({ endpoint: ENDPOINT_CHECKLIST.delete, data })
    const { idDomanda } = data;
    deleteAllProblematic({ idDomanda, valueTab })
  }

  const onSelectQuestionResponse = (select: ResponseTypeEnum) => () => {
    clickedButton === ResponseTypeEnum.Yes
      ? question.visualizzaAggiungi && isSelected(select)
      : setIconAnimation(true);
    saveResponse(select, false)
  }
  const isSelected = (select: ResponseTypeEnum) => {
    setResponseModalDeleteProblematic(select);
    setShowModalDeleteProblematic(true);
  }
  const onClickArrow = useCallback(() => {
    setIconAnimation(anim => !anim)
  }, []);
  const onCloseModal = () => setShowModalDeleteProblematic(false);
  return {
    clickedButton,
    iconAnimation,
    showModalDeleteProblematic,
    handleDeleteAllProblematic,
    onClickArrow,
    onSelectQuestionResponse,
    onCloseModal
  }
}
