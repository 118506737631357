import { IonCol, IonContent, IonGrid, IonLoading, IonRow, IonToast, ToastButton } from '@ionic/react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { BUSelect } from '../../components/BUSelect';
import Footer from '../../components/Footer/Footer';
import ChildrenModalDelete from '../../components/Modal/ChildrenModalDelete';
import Modal from '../../components/Modal/Modal';
import ScrollToUp from '../../components/ScrollToUp/ScrollToUp';
import { ShowIf } from '../../components/ShowIf';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { Fornitore } from '../../Models/Fornitori';
import { GetGroupDTO } from '../../Models/Group';
import { Users } from '../../Models/Users';
import { isFornitoreGroupValid, isGroupValid, isUserValid } from '../../utiles';
import { RoleID } from '../Detail/useResponseDetail';
import { FormGroup } from './FormGroup';
import { FormUser } from './FormUser';
import { FormFornitori } from './Fornitori/FormFornitore';
import { RowFornitori } from './Fornitori/RowFornitori';
import RowGroup from './RowGroup/RowGroup';
import RowListSettings from './RowListSettings/RowListSettings';
import { SettingList } from './SettingList';
import { UserHeadingList } from './UserHeadingList';
import { SelectedSettingType, useSettingsFormHook } from './useSettingsFormHook';
import { useEntityUser } from '../../store';

export interface SettingsProps { }

const Settings: React.VFC<SettingsProps> = () => {

    const [contentRef, scrollToTop] = useScrollToTop();
    const {
        showLoader,
        isSyncing,
        users,
        group,
        fornitori,
        methods,
        modal,
        openAddEditModal,
        handleChangeTab,
        submitAddUser,
        closeAddEditModal,
        closeToast,
        closeToastError,
        messageToast,
        messageToastError,
        openEditGroupModal,
        openDeleteModal,
        openEditFornitoreModal,
        onCreateGroup,
        onUpdateGroup,
        onDeleteGroup,
        onDeleteUser,
        onUpdateUserRole,
        entityModalId,
        isUserUpdated,
        buOptions,
        onChangeBu,
        asBu,
        currentUserParse,

        onCreateFornitori,
        onUpdateFornitori,
        onDeleteFornitori,
        toggleAssociateBU
    } = useSettingsFormHook();

    const buttonMessage: ToastButton[] = [{ side: 'end', text: 'Chiudi'}]

    const entityNames: Record<SelectedSettingType, string> = {
        [SelectedSettingType.user]: 'Utente',
        [SelectedSettingType.group]: 'Gruppo',
        [SelectedSettingType.fornitori]: 'Fornitore',
    }

    const isUpdateMode = !!(entityModalId || isUserUpdated);
    const modalTitleMessage = `${modal.isDeleting ? 'Cancella' : isUpdateMode ? "Modifica" : "Aggiungi"} ${entityNames[modal.type]}`

    const addBtnTexts: Record<SelectedSettingType, string> = {
        [SelectedSettingType.user]: "aggiungi utente",
        [SelectedSettingType.group]: "aggiungi gruppo",
        [SelectedSettingType.fornitori]: "aggiungi Fornitore",
    };

    const deleteEntityMethods: Record<SelectedSettingType, () => void> = {
        [SelectedSettingType.user]: onDeleteUser,
        [SelectedSettingType.group]: onDeleteGroup,
        [SelectedSettingType.fornitori]: onDeleteFornitori,
    }

    var canUserSeeOption = !(currentUserParse.profilo === RoleID.BO);

    return (
        <>
            <IonContent ref={contentRef} scrollEvents={true}>
                <IonGrid className="mb-6" >
                    <IonRow className="ms-3">
                        <IonCol>
                            <h1>Impostazioni</h1>
                        </IonCol>
                        <ShowIf condition={currentUserParse.profilo === RoleID.AMM_SIST}>
                            <IonCol>
                                <BUSelect label="BU" options={buOptions} onChangeValue={onChangeBu} value={asBu} />
                            </IonCol>
                        </ShowIf>
                    </IonRow>
                    <IonRow className="mx-3">
                        <IonCol>
                            <AppBar position="static" color="transparent" style={{ boxShadow: 'none', 'borderBottom': '2px black' }}>
                                <Tabs
                                    value={modal.type}
                                    onChange={handleChangeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs"
                                >
                                    <AntTab label="Utenti" {...a11yProps(SelectedSettingType.user)} />
                                    <AntTab label="Gruppi applicativi" {...a11yProps(SelectedSettingType.group)} />
                                    <AntTab label="Gruppi Fornitori" {...a11yProps(SelectedSettingType.fornitori)} />

                                </Tabs>
                            </AppBar>
                            <TabPanel value={modal.type} index={modal.type} >
                                <IonGrid className="pt-0">
                                    <ShowIf condition={modal.type === SelectedSettingType.user}>
                                        <SettingList<Users> 
                                            addBtnText={addBtnTexts[modal.type]}
                                            addBtnEnabled={canUserSeeOption}
                                            onAdd={() => openAddEditModal()}
                                            items={users}
                                            validateFilteredItem={isUserValid}
                                            heading={<UserHeadingList />}
                                            RowComponent={(el) => (
                                                <RowListSettings
                                                    items={el}
                                                    key={el.id}
                                                    id={"cambio ruolo" + el.id}
                                                    onUpdateUserRole={({valueRole}) => onUpdateUserRole({id: Number(el.id), valueRole})}
                                                    onDelete={()=> openDeleteModal(Number(el.id))}
                                                    canEditRole={canUserSeeOption}
                                                />
                                            )}
                                        />
                                    </ShowIf>

                                    <ShowIf condition={modal.type === SelectedSettingType.group}>
                                        <SettingList<GetGroupDTO> 
                                            addBtnText={addBtnTexts[modal.type]}
                                            addBtnEnabled={true}
                                            onAdd={() => openAddEditModal()}
                                            items={group}
                                            validateFilteredItem={isGroupValid}
                                            RowComponent={(el) => (
                                                <RowGroup
                                                    onUpdate={()=> openEditGroupModal(el.id)}
                                                    onDelete={()=> openDeleteModal(el.id)}
                                                    el={el} 
                                                    key={el.id}
                                                />
                                            )}
                                        />
                                    </ShowIf>

                                    <ShowIf condition={modal.type === SelectedSettingType.fornitori}>
                                        <SettingList<Fornitore> 
                                            addBtnText={addBtnTexts[modal.type]}
                                            addBtnEnabled={canUserSeeOption}
                                            onAdd={() => openAddEditModal()}
                                            items={fornitori}
                                            validateFilteredItem={isFornitoreGroupValid}
                                            RowComponent={(el) => (
                                                <RowFornitori
                                                    asBuId={asBu?.value ?? null}
                                                    onUpdate={() => openEditFornitoreModal(el.id)}
                                                    onDelete={() => openDeleteModal(el.id)}
                                                    toggleAssociateBU={() => toggleAssociateBU(el.id)}
                                                    el={el} 
                                                    key={el.id}
                                                />
                                            )}
                                        />
                                    </ShowIf>

                                </IonGrid>
                            </TabPanel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <ScrollToUp up={scrollToTop} />
                <Footer />
            </IonContent>
            <Modal isOpen={modal.isOpen} title={modalTitleMessage} onIsOpenChange={closeAddEditModal}>
                
                <ShowIf condition={!!modal.isDeleting}>
                    <ChildrenModalDelete
                        handleClickNo={closeAddEditModal}
                        handleClickYes={deleteEntityMethods[modal.type]}
                        text="L'eliminazione sarà definitiva, continuare?"
                    />
                </ShowIf>

                <ShowIf condition={!modal.isDeleting}>
                    <FormProvider {...methods} >
                        <ShowIf condition={modal.type === SelectedSettingType.user}>
                            <FormUser
                                asBu={asBu?.value ?? null}
                                submitAddUser={submitAddUser}
                                addCancelUser={closeAddEditModal}
                            />
                        </ShowIf>

                        <ShowIf condition={modal.type === SelectedSettingType.group}>
                            <FormGroup
                                asBu={asBu?.value ?? null}
                                onClose={closeAddEditModal}
                                onCreate={onCreateGroup}
                                onUpdate={onUpdateGroup}
                            />
                        </ShowIf>

                        <ShowIf condition={modal.type === SelectedSettingType.fornitori}>
                            <FormFornitori 
                                asBu={asBu?.value ?? null}
                                onClose={closeAddEditModal}
                                onCreate={onCreateFornitori}
                                onUpdate={onUpdateFornitori}
                            />
                        </ShowIf>
                    </FormProvider>
                </ShowIf>

            </Modal>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={isSyncing}
                message={"Sincronizzazione Data with Server"}
            />
            <IonLoading
                cssClass="my-custom-class"
                isOpen={showLoader}
                message={"Caricamento..."}
            />
            <IonToast
                duration={4500}
                color="success"
                isOpen={!!messageToast}
                onDidDismiss={closeToast}
                message={messageToast}
                position="top"
                buttons={buttonMessage}
            />
            <IonToast
                duration={4500}
                color="danger"
                isOpen={!!messageToastError}
                onDidDismiss={closeToastError}
                message={messageToastError}
                position="top"
                buttons={buttonMessage}
            />
        </>
    );
}

export default Settings;


interface StyledTabProps {
    label: string;
}

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightBold,
            marginRight: theme.spacing(4),
            color: "rgba(0, 0, 0, 0.54)",
            fontFamily: [
                "-apple-system, 'Montserrat', sans-serif"
            ].join(','),
            '&:hover': {
                color: '#33A7B2',
                opacity: 1,
            },
            '&$selected': {
                color: '#33A7B2',
                fontWeight: theme.typography.fontWeightBold,
            },
            '&:focus': {
                color: '#33A7B2',
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

function a11yProps(index: SelectedSettingType) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
