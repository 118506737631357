import { v4 as uuidv4 } from "uuid";

import { useCallback } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useIndexedDB } from "react-indexed-db";
import { ListaUtentiGroupModel, UpdateGroupDTO } from "../../Models/Group";
import {
    deleteGruppo,
    ENDPOINT_SETTINGS,
    insertGruppo,
    updateGruppo,
} from "../../service/api";
import {
    useEntityConnectivity,
    useEntitySettings,
    useEntityUser,
} from "../../store";
import { SettingFormModalType } from "./useSettingsFormHook";

const INITIAL_STATE_GROUP = {
    businessUnitId: null,
    id: undefined,
    nomeGruppo: "",
    listaUtenti: [] as ListaUtentiGroupModel[],
};

export type AddGroupType = typeof INITIAL_STATE_GROUP;

interface Props {
    reset: UseFormReset<FieldValues>;
    setMessage: (message: string, isErrorMessage: boolean) => void;
    setShowLoader: (isLoading: boolean) => void;
    onClose: Function;
    modal: SettingFormModalType;
    asBu: number | null;
}

export const useCRUDGroup = ({ reset, modal, onClose, setMessage, setShowLoader, asBu }: Props) => {
    const { user: currentUserParse } = useEntityUser();
    const { add, getAll, deleteRecord, update } = useIndexedDB("settings");

    const {
        group,
        groupLoading,
        groupLoadingError,
        getGroup,
        setGroupLoading,
        setGroupLoadingError,
        updateGroup,
        addToGroup,
        deleteFromGroup,
    } = useEntitySettings((store) => store);
    const { connectivity: isOnline } = useEntityConnectivity();

    const onCreateGroup = useCallback(
        async (dataSubmitted: AddGroupType) => {
            setShowLoader(true);
            const { listaUtenti, ...form } = dataSubmitted;
            const temporaryID = uuidv4();

            const data = {
                idGruppo: temporaryID,
                idUtente: +currentUserParse.id,
                ...form,
                listaUtenti: listaUtenti.map(({ id }) => ({ id })),
            };

            const addToDB = async () => {
                await add({ endpoint: ENDPOINT_SETTINGS.insertGruppo, data });
                addToGroup(form.nomeGruppo, listaUtenti, temporaryID);
            };

            const onError = () => {
                // setMessageToastError("Errore Aggiunta Gruppo");
                setMessage("Errore Aggiunta Gruppo", true);
                setGroupLoadingError(true);
            };

            isOnline
                ? await insertGruppo(
                      data,
                      () => {
                          // setMessageToast("Aggiunta Gruppo con successo");
                          setMessage("Aggiunta Gruppo con successo", false);
                          setGroupLoading(true);
                          getGroup(+currentUserParse.id, asBu);
                      },
                      onError
                  )
                : addToDB();
            onClose();
            setShowLoader(false);
            reset(INITIAL_STATE_GROUP);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [add, addToGroup, currentUserParse.id, getGroup, isOnline, onClose, reset, setGroupLoading, setGroupLoadingError, setMessage, setShowLoader, asBu]
    );

    const onUpdateGroup = useCallback(
        async (dataSubmitted: AddGroupType) => {
            setShowLoader(true);
            const { id, nomeGruppo, listaUtenti, businessUnitId } = dataSubmitted;

            const data: UpdateGroupDTO = {
                idUtente: +currentUserParse.id,
                idGruppo: id,
                nome: nomeGruppo,
                listaUtenti: listaUtenti.map(({ id }) => ({ id })),
                businessUnitId,
            };

            const checkIfDataAlreadyExist = async () => {
                const record = await getAll();
                const sameID = record.find(
                    (lastUpdate) => lastUpdate.data.idGruppo === id
                );
                const nome =
                    sameID && sameID.endpoint === ENDPOINT_SETTINGS.updateGruppo
                        ? "nome"
                        : "nomeGruppo";

                sameID
                    ? await update({
                          ...sameID,
                          data: {
                              ...sameID.data,
                              [nome]: dataSubmitted.nomeGruppo,
                              listaUtenti: dataSubmitted.listaUtenti,
                          },
                      })
                    : await add({
                          endpoint: ENDPOINT_SETTINGS.updateGruppo,
                          data,
                      });

                updateGroup(
                    id,
                    dataSubmitted.nomeGruppo,
                    dataSubmitted.listaUtenti
                );
                setGroupLoading(false);
            };

            const onError = () => {
                // setMessageToastError("Errore Modifica Gruppo");
                setMessage("Errore Modifica Gruppo", true);
                setGroupLoadingError(true);
            };

            isOnline
                ? await updateGruppo(
                      data,
                      () => {
                          // setMessageToast("Modifica Gruppo con successo");
                          setMessage("Modifica Gruppo con successo", false);
                          setGroupLoading(true);
                          getGroup(+currentUserParse.id, asBu);
                      },
                      onError
                  )
                : checkIfDataAlreadyExist();

            onClose();
            reset(INITIAL_STATE_GROUP);
            setShowLoader(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [add, currentUserParse.id, getAll, getGroup, isOnline, onClose, reset, setGroupLoading, setGroupLoadingError, setMessage, setShowLoader, update, updateGroup, asBu]
    );

    const onDeleteGroup = useCallback(async () => {
        setShowLoader(true);
        const data = {
            idUtente: +currentUserParse.id,
            id: modal.isDeleting,
            idGruppo: modal.isDeleting,
        };

        const checkIfDataAlreadyExist = async () => {
            const record = await getAll();
            const sameID = record.find(
                (lastUpdate) =>
                    lastUpdate.data.idGruppo === modal.isDeleting &&
                    lastUpdate.endpoint !== ENDPOINT_SETTINGS.updateGruppo
            );

            sameID
                ? await deleteRecord(sameID.id)
                : await add({ endpoint: ENDPOINT_SETTINGS.deleteGruppo, data });

            deleteFromGroup(modal.isDeleting);
        };

        const onError = () => {
            // setMessageToastError("Errore Elimina Gruppo");
            setMessage("Errore Elimina Gruppo", true);
            setGroupLoadingError(true);
        };

        isOnline
            ? await deleteGruppo(
                  data,
                  () => {
                      // setMessageToast("Elimina Gruppo con successo");
                      setMessage("Elimina Gruppo con successo", false);
                      setGroupLoading(true);
                      getGroup(+currentUserParse.id, asBu);
                  },
                  onError
              )
            : checkIfDataAlreadyExist();

        onClose();
        setShowLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentUserParse.id,
        deleteFromGroup,
        getGroup,
        isOnline,
        modal.isDeleting,
        setGroupLoading,
    ]);

    return {
        group,
        onCreateGroup,
        onUpdateGroup,
        onDeleteGroup,
        groupLoading,
        groupLoadingError,
    };
};
