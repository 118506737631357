import { IonCol, IonGrid, IonRow } from "@ionic/react";

import { NoteAudio } from '../../Models/NoteAudio';

import Recorder from '../CustomRecorder/Recorder';

export interface ChildrenRecAudioProps {
    handleAudioStop: (data: NoteAudio) => void;
    handleReset: () => void;
    audioDetails: string;
    audioBase64DB?: string;
}

const ChildrenRecAudio: React.FC<ChildrenRecAudioProps> = ({ handleAudioStop, handleReset, audioDetails, audioBase64DB }) => {
    return (
        <IonGrid className="m-0 mx-3">
            <IonRow>
                <IonCol>
                    <Recorder
                        showUIAudio
                        handleAudioStop={(data: NoteAudio) => handleAudioStop(data)}
                        handleReset={handleReset}
                        audioURL={audioDetails}
                        audioBase64DB={audioBase64DB}
                    />
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}

export default ChildrenRecAudio;