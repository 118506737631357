export const TIPOLOGIAUE = [
    { id: 1, nome: "Cielo/terra" },
    { id: 2, nome: "Singole U.I" }
];

export const STATO_OCCUPAZIONE = [
    { id: 1, nome: "Occupato" },
    { id: 2, nome: "Sfitto" },
    { id: 3, nome: "Parzialmente occupato" }
];

export const STATO_MANUTENTIVO_GENERALE = [
    { id: 1, nome: "Ottimo" },
    { id: 2, nome: "Normale" },
    { id: 3, nome: "Scadente" },
    { id: 4, nome: "Degradato" }
];

export const IMPIANTO_RISCALDAMENTO_STATO = [
    { id: 1, nome: "Disponibilità" },
    { id: 2, nome: "Degrado" },
    { id: 3, nome: "Indisponibilità" },
    { id: 4, nome: "Fuori servizio" },
    { id: 5, nome: "In funzione" },
    { id: 6, nome: "Riposo" },
    { id: 7, nome: "Attesa" },
    { id: 8, nome: "Pericolo" },
];

export const IMPIANTO_ELETTRICO_STATO = [
    { id: 1, nome: "Disponibilità" },
    { id: 2, nome: "Degrado" },
    { id: 3, nome: "Indisponibilità" },
    { id: 4, nome: "Fuori servizio" },
    { id: 5, nome: "In funzione" },
    { id: 6, nome: "Riposo" },
    { id: 7, nome: "Attesa" },
    { id: 8, nome: "Pericolo" },
];

export const IMPIANTO_ELEVATORI_STATO = [
    { id: 1, nome: "Disponibilità" },
    { id: 2, nome: "Degrado" },
    { id: 3, nome: "Indisponibilità" },
    { id: 4, nome: "Fuori servizio" },
    { id: 5, nome: "In funzione" },
    { id: 6, nome: "Riposo" },
    { id: 7, nome: "Attesa" },
    { id: 8, nome: "Pericolo" },
];

export const IMPIANTO_ANTINCENDIO_STATO = [
    { id: 1, nome: "Disponibilità" },
    { id: 2, nome: "Degrado" },
    { id: 3, nome: "Indisponibilità" },
    { id: 4, nome: "Fuori servizio" },
    { id: 5, nome: "In funzione" },
    { id: 6, nome: "Riposo" },
    { id: 7, nome: "Attesa" },
    { id: 8, nome: "Pericolo" },
];