import React, { useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionSummary } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordion from '@material-ui/core/Accordion';
import './AccordionCustom.css'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "-apple-system, 'Montserrat', sans-serif",
      color: "#424242"

    },
  }),
);

// const Accordion = withStyles({
//   root: {
//     boxShadow: 'none',
//     '&:not(:last-child)': {
//       borderBottom: 0,
//     },
//     '&:before': {
//       display: 'none',
//     },
//     '&$expanded': {
//       margin: 'auto',
//     },
//   },
//   expanded: {},
// })(MuiAccordion);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: '#f3f3f3'
  },
}))(MuiAccordionDetails);

export interface AccordionComponentProps {
  title: string;
  className?: string;
  open?: boolean;
}

const AccordionCustom: React.FC<AccordionComponentProps> = ({ title, children, className, open }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(open ?? false);

  const handleChange = (opening: boolean) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? opening : false);
  };

  return (
    <div className={`${className} ${classes.root}`}>
      <div className='accordionCustom' onClick={() => setExpanded(!expanded)}>
        <h5 className=''>{title}</h5>
        <button type='button' className='accordionCustom' onClick={() => setExpanded(!expanded)}>
          {/* <span className="MuiIconButton-label"> */}
          <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path fill='#333' d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
          {/* </span> */}
        </button>
      </div>
      <div className={`accordionCustom-content ${expanded ? "active" : ""}`}>
        {children}
      </div>
    </div>
  );
}

export default AccordionCustom;