import { useCallback, useEffect, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import { useParams } from "react-router";
import { PhotoSchema } from "../../store/db/photoSchema";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { EditPhotoDTO, SavePhotoDTO } from "../../Models/Photo";
import { requestPostOptions } from "../../service";
import { ENDPOINT_FOTO, findIndexFromDB, findRecordFromDB, fromDBToOptionGallery } from "../../service/api";
import { useEntityConnectivity, useEntityGallery, useEntityUser } from "../../store";
import { useIsLoading } from "../../hooks/useIsLoading";


export function useResponsePhotoGallery() {
    
    const { id, numberInspection } = useParams<ParamsInspection>();
    const isOnline = useEntityConnectivity(store => store.connectivity);

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { user: currentUserParse } = useEntityUser();

    const { getAll, getByID, deleteRecord, add, update } = useIndexedDB('photo');
    const {
        dataPhotoGallery,
        errorText,
        refreshGallery,
        addPhotoGallery,
        getGalleryFromDB,
        updateGallery,
        deletePhotoGallery
    } = useEntityGallery();


    // add
    const getCurrentUserGallery = () => refreshGallery({
        idUtente: Number(currentUserParse.id),
        idImmobile: +id,
        idIspezione: +numberInspection,
    })

    const getPhotoFromDB = useCallback(async () => {
        const listaImmagini = await fromDBToOptionGallery(getAll);
        listaImmagini?.length && getGalleryFromDB(listaImmagini)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const addRecordToState = async (data: SavePhotoDTO) => {
        await add({ endpoint: ENDPOINT_FOTO.insertFoto, data })
        addPhotoGallery(data)
    }
    //remove
    const removeFromDB = async (idFoto: number) => {
        const index = await findIndexFromDB(getAll, idFoto);
        if (index) {
            const record = await getByID(index);
            (record.endpoint !== ENDPOINT_FOTO.deleteFoto) && await add({ endpoint: ENDPOINT_FOTO.deleteFoto, data: { idUtente: Number(currentUserParse.id), idFoto } })
            await deleteRecord(index)
        } else {
            await add({ endpoint: ENDPOINT_FOTO.deleteFoto, data: { idUtente: Number(currentUserParse.id), idFoto } })
        }
        deletePhotoGallery(idFoto);
        setLoadingSave(false);
    }
    // update 
    const updateFromDB = async (photo: EditPhotoDTO) => {
        const { id, didascalia } = photo
        const storedPhoto = await findRecordFromDB(getAll, id)
        if (storedPhoto) {
            await update({ ...storedPhoto, data: { ...storedPhoto.data, didascalia } })
        } else {
            await add({ endpoint: ENDPOINT_FOTO.editFoto, data: photo })
        }
        updateGallery(photo)
    }
    const [isLoading, setIsLoading] = useIsLoading(false);
    // sincro to db
    const sincronizeOfflineDBToOnline = useCallback(async () => {
        if (!isOnline) return;
        const requestArray = await getAll<PhotoSchema>()
        if (!requestArray?.length) {
            refreshGallery({
                idUtente: Number(currentUserParse.id),
                idImmobile: +id,
                idIspezione: +numberInspection,
            })
            return;
        }
        requestArray?.length && setIsLoading(true)
        const insertPromiseArray = requestArray.map(({ id, data, endpoint }) =>
            fetch(endpoint, requestPostOptions(data)).then(async () => await deleteRecord(id))
        );
        Promise.allSettled([
            ...insertPromiseArray,
        ]).finally(() =>{ 
            refreshGallery({
                idUtente: Number(currentUserParse.id),
                idImmobile: +id,
                idIspezione: +numberInspection,
            });
            setIsLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserParse, getPhotoFromDB, id, isOnline, numberInspection, refreshGallery])

    useEffect(() => {
        const ac = new AbortController();
        sincronizeOfflineDBToOnline()
        return () => ac.abort();
    }, [sincronizeOfflineDBToOnline]);



    return {
        isLoading,
        dataPhotoGallery,
        errorText,
        getCurrentUserGallery,
        addRecordToState,
        removeFromDB,
        updateFromDB,
        loadingSave,
        setLoadingSave
    };
}
