import { IonButton, IonCol, IonFabButton, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { addOutline, trashOutline } from 'ionicons/icons';
import { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { AssociateBUSelect } from "../../components/AssociateBUSelect";
import CustomInput from "../../components/CustomInput/CustomInput";
import SelectSearchableArray from '../../components/SelectSearchable/SelectSearchableArray';
import { ShowIf } from "../../components/ShowIf";
import { ListaUtentiGroupModel } from "../../Models/Group";
import { useEntitySettings, useEntityUser } from "../../store";
import { RoleID } from "../Detail/useResponseDetail";
import { AddGroupType } from "./useCRUDGroup";

interface FormGroupProps {
    onClose(): void;
    onCreate(data: AddGroupType): void;
    onUpdate(data: AddGroupType): void;
    asBu: number | null;

}

interface SelectUserModel {
    listaUtenti: ListaUtentiGroupModel[]
}

export const FormGroup = ({ onCreate, onClose, onUpdate, asBu }: FormGroupProps) => {

    const { user: currentUser } = useEntityUser();
    const isCurrentUserAmmSist = currentUser.profilo === RoleID.AMM_SIST;
    const {users} = useEntitySettings(({users}) => ({users}));

    const buIdSelected = useWatch({name: 'businessUnitId'});

    const usersForAdd = useMemo(() => {
        const BUID = asBu ? asBu : isCurrentUserAmmSist ? buIdSelected : currentUser.businessUnitId;
        const filteredForBU = users.filter(el => el.businessUnitId === BUID);
        
        return filteredForBU.map(({ id, nome, cognome, mail }) => {
            return {
                id,
                nome: nome.trim() + " " + cognome.trim() + " - " + mail?.trim().toLowerCase()
            }
        })
    }, [users, isCurrentUserAmmSist, currentUser.businessUnitId, buIdSelected,asBu]);

    // const usersForAdd: Option[] = users.length > 0 && users.map(({ id, nome, cognome, mail }) => {
    //     return {
    //         id,
    //         nome: nome + " " + cognome + " - " + mail?.toLowerCase()
    //     }
    // });
    
    const { control, formState:{ errors}, handleSubmit, getValues, setValue} = useFormContext();

    const { fields, append, remove, update  } = useFieldArray<SelectUserModel, any, 'uuid'>({
        name: "listaUtenti",
    });

    useEffect(() => {
        if(asBu && !getValues().id) setValue("businessUnitId", asBu)
    }, [asBu, getValues, setValue])

    const canSubmit = fields.length && (fields.length === 1 ? !!(fields[0] as any).mail : true);
    
    const groupId = useWatch({name: 'id'});

    /*vedo quali utenti sono già stati inseriti onde evitare che lo stesso utente possa
     essere inserito più volte causando un problema di key*/
    const insertedUsers = useWatch({name: 'listaUtenti'});
    const insertedUsersIDS: number[] = insertedUsers?.map(user => user?.id); // CHECK NULL USER
    const usersForAddFiltered = useMemo(() => {
        return usersForAdd.filter(user => !insertedUsersIDS?.includes(+user.id))
    }, [insertedUsersIDS, usersForAdd]);

    const onSubmit = groupId ? onUpdate : onCreate;

    const onChangeBU = () => setValue("listaUtenti", []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <IonGrid className="m-0 mx-3 px-0">
                <ShowIf condition={isCurrentUserAmmSist && !asBu}>
                    <IonRow>
                        <IonCol>
                            <AssociateBUSelect formName="businessUnitId" label="BU" onChangeValue={onChangeBU} />
                        </IonCol>
                    </IonRow>
                </ShowIf>
                <IonRow>
                    <IonCol>
                        <Controller 
                            control={control}
                            name='nomeGruppo'
                            rules={{required: true}}
                            render={({ field: { onChange, value, } }) =>
                                <CustomInput
                                    label="Nome Gruppo"
                                    setValue={onChange}
                                    value={value}
                                    error={!!errors?.nomeGruppo}
                                />
                            }

                        />
                    </IonCol>
                </IonRow>
                { (fields as unknown as ListaUtentiGroupModel[])?.map(({nome, cognome, mail, id, uuid}, i) => {
                    return <IonRow key={i} className="ion-align-items-center">
                    <IonCol size="11" className="pe-0">
                        <Controller 
                            control={control}
                            name={`listaUtenti.${i}`}
                            rules={{required: true}}
                            render={() =>
                                <SelectSearchableArray
                                    disableClearable
                                    value={{id, nome: `${nome.trim()} ${cognome.trim()} ${mail.trim()}`}}
                                    setValue={(value) => {
                                        if(!value) return;
                                        const {id, ...rest} = value; 
                                        const [nome, cognome, _, mail] = rest?.nome.split(' ');
                                        update(i, {id, nome, cognome, mail })}
                                    }
                                    options={usersForAddFiltered}
                                    label={`Utente ${i + 1}`}
                                    error={!!errors?.listaUtenti?.[i]}
                                />
                            }
                        />
                    </IonCol>
                    <IonCol size="1" className="mt-4 ps-0">
                        <IonFabButton
                            color="danger"
                            size="small"
                            onClick={() => remove(i)}
                        >
                            <IonIcon icon={trashOutline} />
                        </IonFabButton>
                    </IonCol>
                </IonRow>
                }
                )}
                <IonRow>
                    <IonCol>
                        <IonButton
                            type="button"
                            className="ion-text-uppercase fw-bolder"
                            fill="clear"
                            disabled={isCurrentUserAmmSist ? !buIdSelected : false}
                            onClick={() => append({
                                id: -1,
                                nome: '',
                                cognome: '',
                                mail: ''
                            }) }
                        >
                            <IonIcon slot="start" icon={addOutline} className="fw-bold" />
                            aggiungi utente
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-end">
                    <IonCol sizeXl="3" className="ion-text-end pe-0">
                        <IonButton expand="block" fill="clear" className="fw-bold ion-text-uppercase" onClick={onClose}>ANNULLA</IonButton>
                    </IonCol>
                    <IonCol sizeXl="3" className="ion-text-end ps-0">
                        <IonButton type="submit" expand="block" fill="solid" className="fw-bold ion-text-uppercase" disabled={!canSubmit} >SALVA</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </form>
    )
}
