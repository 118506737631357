import create from 'zustand';
import { getSopralluoghi, getFilterSopralluoghi, getFilterAddSopralluoghi, exportCSV, getAssegnatariForFornitore, GetUtentiCreatoriSopralluogo } from '../service/api';
import { Inspection } from "../Models/Inspection";
import { Filter } from '../Models/Filter';
import { FilterParamiter } from '../Models/FilterParamiter';
import { persist } from 'zustand/middleware';
import { dateNow, oneYearFromNow } from '../utiles';

export const FILTER_FORM_INITIAL_STATE: FilterParamiter = {
    fondo: 0,
    indirizzo: 0,
    stato: 0,
    unitaEconomica: "",
    utente: 0,
    dataPrimaIspezione: oneYearFromNow(),
    dataUltimaIspezione: dateNow(),
    pagina: 1,
    risulatati: 500,
}
interface HomepageModel {
    dataInspections: any,
    dataFilter: any,
    dataFilterOption: any,
    dataSopralluoghiOption: any
    dataNewInspectionFilter: any,
    errorDataInspection: string,
    getDataInspections: any,
    getDataInspectionsFilters: any,
    getDataNewInspectionFilter: any,
    getResetForm: any,
    getDataFromFilters: any,
    refreshDataToExportInExcel: any,
    dataForExport: any,

    assegnatariForFornitore: any[];
    errorGetAssegnatari: string;
    getAssegnatariFromFornitore: (idUtente: number, idProgetto: number) => void;
    creatori: any[];
    errorGetCreatori: string;
    getCreatoriSopralluogo: (idUtente: number) => void;
}

export const useEntityHomepage = create<HomepageModel>(
    persist(
        (set: Function, get: Function) => ({
            dataInspections: undefined as Inspection[],
            dataFilter: FILTER_FORM_INITIAL_STATE,
            dataFilterOption: undefined,
            dataSopralluoghiOption: undefined,
            dataNewInspectionFilter: undefined,
            errorDataInspection: '',
            dataForExport: undefined,
            getDataInspections: async (idUtente: number) => {
                const dataFilters = get().dataFilter;

                const onSuccess = (result: Inspection[]) => set({ ...get(), dataInspections: [...result] });
                const onError = (errorDataInspection) => set({ ...get(), errorDataInspection })
                await getSopralluoghi({ idUtente, ...dataFilters }, onSuccess, onError);
            },
            getDataInspectionsFilters: async (idUser: number) => {
                const onSuccess = (result: Filter) => set({
                    ...get(), dataFilterOption: result, dataFilter: {
                        ...FILTER_FORM_INITIAL_STATE,
                        ...get().dataFilter,
                        dataPrimaIspezione: result.dataPrimaIspezione,
                        dataUltimaIspezione: result.dataUltimaIspezione
                    }
                });
                const onError = (errorDataInspection) => set({ ...get(), errorDataInspection });
                await getFilterSopralluoghi({ idUtente: idUser }, onSuccess, onError);
            },
            getDataNewInspectionFilter: async (idUser: number) => {
                const onSuccess = (result: Filter) => set({ ...get(), dataSopralluoghiOption: result });
                const onError = (errorDataInspection) => set({ ...get(), errorDataInspection });
                await getFilterAddSopralluoghi({ idUtente: idUser }, onSuccess, onError);
            },
            getResetForm: async (idUtente: number) => {
                set({
                    ...get(),
                    dataFilter: FILTER_FORM_INITIAL_STATE,

                })
                const onSuccess = (result: Inspection[]) => set({ ...get(), dataInspections: [...result] });
                const onError = (errorDataInspection) => set({ ...get(), errorDataInspection });
                await getSopralluoghi({ idUtente, ...FILTER_FORM_INITIAL_STATE }, onSuccess, onError);
            },
            getDataFromFilters: async (idUtente: number, data: FilterParamiter) => {
                const filteredData = Object.entries(data).reduce((acc, [key, val]) => ({
                    ...acc,
                    [key]: val ?? FILTER_FORM_INITIAL_STATE[key]
                }), {})
                set({
                    ...get(),
                    dataFilter: {
                        ...FILTER_FORM_INITIAL_STATE,
                        ...filteredData
                    }
                })
                const onSuccess = (result: Inspection[]) => set({ ...get(), dataInspections: [...result] })
                const onError = (errorDataInspection) => set({ ...get(), errorDataInspection })
                await getSopralluoghi({ idUtente, ...filteredData }, onSuccess, onError);
            },
            refreshDataToExportInExcel: async (idUtente: number) => {
                const dataFilters = get().dataFilter;
                const data = {
                    fondo: dataFilters.fondo,
                    indirizzo: dataFilters.indirizzo,
                    stato: dataFilters.stato,
                    unitaEconomica: dataFilters.unitaEconomica,
                    utente: dataFilters.utente,
                    dataPrimaIspezione: dataFilters.dataPrimaIspezione,
                    dataUltimaIspezione: dataFilters.dataUltimaIspezione,
                }
                const onSuccess = (result: Inspection[]) => set({ ...get(), dataForExport: result });
                const onError = (errorDataInspection) => set({ ...get(), errorDataInspection })
                await exportCSV({ idUtente, ...data }, onSuccess, onError);
            },

            assegnatariForFornitore: [],
            errorGetAssegnatari: "",
            getAssegnatariFromFornitore: async (idUtente: number, idProgetto: number) => {
                const onSuccess = (listaAssegnatari: any[]) => set({ ...get(), assegnatariForFornitore: listaAssegnatari});
                const onError = (errorGetAssegnatari) => set({ ...get(), errorGetAssegnatari });

                await getAssegnatariForFornitore({ idUtente, idProgetto }, onSuccess, onError);
            },
            creatori: [],
            errorGetCreatori: "",
            getCreatoriSopralluogo: async (idUtente: number) => {
                const onSuccess = (listaAssegnatari: any[]) => set({ ...get(), creatori: listaAssegnatari});
                const onError = (errorGetCreatori) => set({ ...get(), errorGetCreatori });

                await GetUtentiCreatoriSopralluogo({ idUtente }, onSuccess, onError);
            },
        })
        , {
            name: "homepage",
            getStorage: () => sessionStorage,
        }
    ))