import { IonItem, IonIcon } from "@ionic/react";
import { caretForwardOutline, caretDownOutline } from "ionicons/icons";
import { useParams } from "react-router";
import { OptionQuestion } from "../../../Models/Checklist";
import { ParamsInspection } from "../../../Models/ParamsInspection";
import { useSendToApproval } from "../useSendToApproval";
import { ResponseTypeEnum } from "./useQuestionHook";

interface QuestionTextProps {
    question: OptionQuestion,
    iconAnimation: boolean,
    onClickArrow: () => void,
}
export const QuestionText = ({ question, iconAnimation, onClickArrow, }: QuestionTextProps) => {
    const { numberInspection } = useParams<ParamsInspection>();
    const sendToApproval = useSendToApproval(numberInspection);
    return <IonItem lines="none" color="white">
        {
            question.visualizzaAggiungi
        }
        {question.visualizzaAggiungi && question.idRisposta === ResponseTypeEnum.Yes && (
            <span className="ps-2">
                <IonIcon
                    className="pointer m-0"
                    icon={iconAnimation ? caretDownOutline : caretForwardOutline}
                    onClick={onClickArrow} />
            </span>
        )}
        <h5
            className={`${question.visualizzaAggiungi && question.idRisposta === ResponseTypeEnum.Yes
                ? "ps-0 me-2"
                : "ps-4"} ${
                /* CHECK #1:
                    - risposta errata (idRisposta === -1),
                */
                (sendToApproval &&
                    question.idRisposta === -1 &&
                    "text-danger") ||
                /* CHECK #2:
                    - visualizzaAggiungi ? true,
                    - risposta "SI", possiede problematiche (idRisposta === 4),
                    - lista problematiche vuota
                */
                (sendToApproval &&
                    question.visualizzaAggiungi &&
                    question.idRisposta === ResponseTypeEnum.Yes &&
                    (question.problemi.length === 0 ||
                        question.problemi.some(
                            (problema) => problema.competenza === "" ||
                                problema.stimaCosti === "" ||
                                problema.intervento === "" ||
                                problema.possibiliCause === ""
                        )) &&
                    "text-danger")}  my-0`}
        >
            {question.ordinamento}
        </h5>
        <p
            className={`${question.visualizzaAggiungi && question.idRisposta === ResponseTypeEnum.Yes
                ? "ps-0"
                : "ms-2"} my-0`}
        >
            {question.descrizione}
        </p>
    </IonItem>;
}