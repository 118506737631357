import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { IonLabel } from '@ionic/react';

import './selectSearchable.css';
import { Option } from '../../Models/Checklist';
import { makeStyles } from '@material-ui/core';
import { ChangeEvent } from 'react';

const useStyles = makeStyles(() => ({
    inputRoot: {
        'backgroundColor': 'white',
        
    },
}));


export interface SelectSearchableArrayProps {
    value: Option;
    setValue: (newValue: Option) => void;
    options: Option[];
    label: string;
    disableClearable?: boolean;
    error?: boolean;
    disabled?: boolean;
}

const SelectSearchableArray: React.FC<SelectSearchableArrayProps> = ({ value, setValue, options, label, disableClearable, error, disabled }) => {
    const classes = useStyles();

    const handleCss = (label: string) => {
        switch (label) {
            case "tipologia UE*":
            case "occupazione*":
            case "stato":
            case "Stato manutentivo generale":
                return "d-inline-flex w-88"
            default:
                break;
        }
    }

    return (
        <>
            <IonLabel className="label p-0 ion-text-capitalize">{label}</IonLabel>
            <Autocomplete
                disableClearable={disableClearable}
                disabled={disabled}
                className={`mt-2 ${handleCss(label)}`}
                classes={classes}
                getOptionLabel={(option: Option) => (option.nome ?? "")}
                value={value}
                onChange={(event: ChangeEvent<{}>, newValue: Option ) => {
                    if (newValue) {
                        setValue(newValue);
                    } else if (newValue === null) {
                        setValue(null);
                    }
                }}
                options={options}
                getOptionSelected={(option: Option, value: Option) =>{
                    return value.id >= 0 && option.id === value.id
                }}
                renderInput={(params) => <TextField 
                {...params}
                variant="outlined" 
                error={error} 
                helperText={error ? "Campo obbligatorio" : null}
                />
                }
            />
        </>
    );
}

export default SelectSearchableArray;