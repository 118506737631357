import { useCallback, useEffect, useRef, useState } from "react";

import {
  Geolocation,
  Geoposition,
  PositionError,
} from "@ionic-native/geolocation";
import { GeolocalizationPermitEnum } from "../Models/Photo";

export interface LocationError {
  showError: boolean;
  message?: string;
}
export interface GeoState {
  position?: Geoposition;
  permission: GeolocalizationPermitEnum;
}
export function useGeolocation() {
  const [loadingGeolocation, setLoadingGeolocation] = useState<boolean>(false);
  const [errorGeolocation, setErrorGeolocation] = useState<LocationError>({
    showError: false,
  });

  const geo = useRef<GeoState>({
    position: undefined,
    permission: GeolocalizationPermitEnum.POSITION_UNAVAILABLE,
  });

  const getLocationSuccess = useCallback((position: Geoposition) => {
    setErrorGeolocation({ showError: false });
    const { coords } = position;
    if (!coords) return;
    geo.current = {
      position,
      permission: GeolocalizationPermitEnum.PERMISSION,
    };
  }, []);
  const getLocationError = useCallback((positionError: PositionError) => {
    geo.current = {
      permission: positionError.code,
    };
    setErrorGeolocation({ showError: true, message: positionError.message });
  }, []);
  const getGeoPermission = useCallback(
    (response: Geoposition | PositionError) => {
      if (!response) return;
      // setLoadingGeolocation(true);
      try {
        if (
          (response as PositionError).code ||
          (response as PositionError).message
        ) {
          getLocationError(response as PositionError);
        } else {
          getLocationSuccess(response as Geoposition);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: useGeolocation.ts:55 ~ useGeolocation ~ error:",
          error
        );
      }
      // setLoadingGeolocation(false);
    },
    [getLocationError, getLocationSuccess]
  );

  useEffect(() => {
    const listener = Geolocation.watchPosition().subscribe(getGeoPermission);
    return () => listener.unsubscribe();
  }, [getGeoPermission]);

  return {
    loadingGeolocation,
    setLoadingGeolocation,
    errorGeolocation,
    setErrorGeolocation,
    getLocationSuccess,
    getLocationError,
    geolocation: geo.current,
  };
}
