import { state } from './Badge';
import './badgeList.css';

export interface BadgeListProps {
    stato: string;
}
 
const BadgeList: React.FC<BadgeListProps> = ({stato}) => {
    return ( 
        <div className={`badge-list ${state(stato)} me-2`}>{stato}</div>
     );
}
 
export default BadgeList;