import React from "react";
import { IonCol, IonFab, IonFabButton, IonGrid, IonIcon, IonLabel, IonRow } from "@ionic/react";
import SelectSearchable from "../../components/SelectSearchable/SelectSearchable";
import WarningIcon from '@material-ui/icons/Warning';
import { HtmlTooltip, HtmlTooltipWarning, useStylesForTextMultiLine } from './Detail';
import TextField from '@material-ui/core/TextField';
import { mic } from "ionicons/icons";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput";
import InfoIcon from '@material-ui/icons/Info';
import CustomInputNumber from "../../components/CustomInput/CustomInputNumber";
import CustomRadioButton from "../../components/CustomRadioButton/CustomRadioButton";
import { useTooltip } from "../../hooks/useTooltip";
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { STATO_MANUTENTIVO_GENERALE, STATO_OCCUPAZIONE, TIPOLOGIAUE } from "./selectConstantsOptions";
import { result, onChangeValue } from "../../utiles";
import { useParams } from "react-router";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { useEntityConnectivity, useEntityDetail, useEntityUser } from "../../store";
import { useSendToApproval } from "../Checklist/useSendToApproval";
import { RoleID } from "./useResponseDetail";
import CustomInputNumberFormat from "../../components/CustomInput/CustomInputNumberFormat";

interface Props {
    handleModalRecAudioBriefDescription(): void;
}

const DatiGenerali: React.FC<Props> = ({ handleModalRecAudioBriefDescription }) => {

    const { numberInspection } = useParams<ParamsInspection>();
    const dataDetail = useEntityDetail(store => store.dataDetail);
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);
    const isOnline = useEntityConnectivity(store => store.connectivity);

    const portineria = useWatch({name: 'portineria'});
    const vigilanza = useWatch({name: 'vigilanza'});

    const classes: ClassNameMap<"root"> = useStylesForTextMultiLine();

    const { control, formState:{ errors }} = useFormContext();

    const { tooltip, closeTooltip, openTooltip, toggleTooltip } = useTooltip();

    const { user: currentUserParse } = useEntityUser();
    
    const shouldBeDisabled = dataDetail?.stato === "Approvato" || dataDetail?.stato === "In Approvazione"  || currentUserParse?.profilo === RoleID.LETTORE;


    return (
        <IonGrid className="p-0">
            <IonRow className="ion-align-items-center">
                <IonCol className={`${classes.root}`}>
                    <IonLabel className="label p-0 ion-text-capitalize">Descrizione sommaria</IonLabel>
                    <Controller
                        defaultValue={''}
                        control={control}
                        name='descrizioneSommaria'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <TextField
                                id="descrizione-sommaria"
                                disabled={shouldBeDisabled}
                                multiline
                                rows={4}
                                variant="outlined"
                                className={`${classes.root ?? ""} w-lg-94 w-xs-88 d-inline-flex`}
                                value={value || ''}
                                onChange={onChange}
                                error={ !!errors?.descrizioneSommaria}
                                helperText={ !!sendToApproval && !dataDetail?.descrizioneSommaria ? "Campo obbligatorio" : null}
                            />
                        }
                    />
                    <IonFab className="d-inline ms-2 mt-4_4">
                        <IonFabButton size="small" disabled={shouldBeDisabled}
                        color={!dataDetail?.descrizioneSommariaAudioUrl ? "primary" : "secondary"} onClick={handleModalRecAudioBriefDescription}>
                            <IonIcon icon={mic} className="pointer fs-3" />
                        </IonFabButton>
                    </IonFab>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12">
                    <Controller
                        control={control}
                        name='tipologiaUe'
                        rules={{ required: true && 'Campo Obbligatorio' }}
                        render={({ field: { onChange, value, } }) => 
                            <SelectSearchable
                                disabled={ !isOnline || shouldBeDisabled}
                                disableClearable={true}
                                options={TIPOLOGIAUE}
                                setValue={onChangeValue(onChange)}
                                value={result(TIPOLOGIAUE, value)}
                                error={ !!errors?.tipologiaUe?.message}
                                label="tipologia UE*"
                            />
                        }
                    />
                    <HtmlTooltipWarning
                        onClick={() => toggleTooltip("typeUE")}
                        onOpen={() => openTooltip("typeUE")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "typeUE"}
                        title={
                            <p>{`Cambiando questo campo verrà rigenerata la checklist`}</p>
                        }
                    >
                        <WarningIcon fontSize="small" className="align-middle ms-2 fs-4 text-danger" />
                    </HtmlTooltipWarning>
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='statoOccupazione'
                        rules={{ required: true && 'Campo Obbligatorio' }}
                        render={({ field: { onChange, value, } }) => 
                            <SelectSearchable
                                disabled={ !isOnline || shouldBeDisabled}
                                disableClearable={true}
                                options={STATO_OCCUPAZIONE}
                                setValue={onChangeValue(onChange)}
                                value={result(STATO_OCCUPAZIONE, value)}
                                error={ !!errors?.statoOccupazione?.message}
                                label="occupazione*"
                            />
                        }
                    />
                    <HtmlTooltipWarning
                        onClick={() => toggleTooltip("occupation")}
                        onOpen={() => openTooltip("occupation")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "occupation"}
                        title={
                            <>
                                <p>{`Cambiando questo campo verrà rigenerata la checklist`}</p>
                            </>
                        }
                    >
                    <WarningIcon fontSize="small" className="align-middle ms-2 fs-4 text-danger" />
                    </HtmlTooltipWarning>
                </IonCol>
            </IonRow>
            {/* Dettaglio UE e Valore Debito Residuo
             <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12" className={`${classes.root}`}>
                    <IonLabel className="label p-0 ion-text-capitalize">Dettaglio UE</IonLabel>
                    <Controller
                        defaultValue={''}
                        control={control}
                        name='dettaglioUE'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <TextField
                                id="dettaglioUE"
                                disabled={shouldBeDisabled}
                                multiline
                                rows={4}
                                variant="outlined"
                                className={`${classes.root ?? ""} w-lg-94 w-xs-88 d-inline-flex`}
                                value={value || ''}
                                onChange={onChange}
                                error={ !!errors?.dettaglioUE}
                                helperText={ !!sendToApproval && !dataDetail?.dettaglioUE ? "Campo obbligatorio" : null}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">                    
                    <Controller
                        control={control}
                        name='valoreDebitoResiduo'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumberFormat
                                disabled={shouldBeDisabled}
                                label="Valore Debito Residuo"
                                className={`${classes.root} w-88 w-xs-82`}
                                number={value}
                                setValue={onChange}
                                symbol="€"
                                error={ !!errors?.valoreDebitoResiduo}
                            />
                        }
                    />
                </IonCol>
                
            </IonRow> */}

            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='statoManutentivoGenerale'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <SelectSearchable
                                disabled={shouldBeDisabled}
                                disableClearable={true}
                                options={STATO_MANUTENTIVO_GENERALE}
                                setValue={onChangeValue(onChange)}
                                value={result(STATO_MANUTENTIVO_GENERALE, value)}
                                error={!!errors?.statoManutentivoGenerale }
                                label="Stato manutentivo generale"
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("maintenance")}
                        onOpen={() => openTooltip("maintenance")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "maintenance"}
                        placement="right"
                        title={
                            <>
                                <p><span className="fw-bold">{"Ottimo"}</span> {`nel caso si riscontri uno stato manutentivo almeno buono per otto degli elementi comuni indicati in tabella n.1`}</p>
                                <p><span className="fw-bold">{"Normale"}</span> {`quando siano in normali o in buone condizionifino sette degli elementi comuni indicati in tabella n.1`}</p>
                                <p><span className="fw-bold">{"Scadente"}</span> {`quando sono in condizioni scadenti quattroelementi tra quelli indicati in tabella n.1`}</p>
                                <p><span className="fw-bold">{"Degradato"}</span> {`quando sono in condizioni scadenti più di quattro elementi tra quelli indicati in tabella/elenco n.1`}</p>
                                <p><span className="fw-bold">{"Tabella n.1"}</span></p>
                                <ol type="a">
                                    <li>{"Facciata"}</li>
                                    <li>{"Infissi comuni"}</li>
                                    <li>{"Impianto elettrico"}</li>
                                    <li>{"Copertura"}</li>
                                    <li>{"Accessi, scale, parti comuni"}</li>
                                    <li>{"Impianto di riscaldamento centralizzato"}</li>
                                    <li>{"Impianto fognario"}</li>
                                    <li>{"Ascensore"}</li>
                                    <li>{"Impianto citofonico/videocitofonico"}</li>
                                </ol>
                            </>
                        }
                    >
                        <InfoIcon color="action" fontSize="large" className="align-middle ms-2 fs-4" />
                    </HtmlTooltip>
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='presenzaCartelloRecapiti'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Presenza cartello con recapiti"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.presenzaCartelloRecapiti}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='portineria'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={shouldBeDisabled}
                                label="Portineria"
                                value={value || ''}
                                handleChangeValue={onChange}
                                classNO="w-75"
                                classSI="w-75"
                                error={ !!errors?.portineria}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='contatto'
                        rules={{ required: !!sendToApproval && portineria === "SI" }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={dataDetail.stato === "Approvato" || dataDetail.stato === "In Approvazione" || portineria === "NO"  || currentUserParse?.profilo === RoleID.LETTORE}
                                label="Contatto"
                                value={value || ''}
                                setValue={onChange}
                                error={ !!errors?.contatto}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='vigilanza'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={shouldBeDisabled}
                                label="Vigilanza"
                                value={value || ''}
                                handleChangeValue={onChange}
                                classNO="w-75"
                                classSI="w-75"
                                error={ !!errors?.vigilanza}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='tipo'
                        rules={{ required: !!sendToApproval && vigilanza === "SI" }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={dataDetail.stato === "Approvato" || dataDetail.stato === "In Approvazione" || vigilanza === "NO" || currentUserParse?.profilo === RoleID.LETTORE}
                                label="Tipologia Vigilanza"
                                value={value || ''}
                                setValue={onChange}
                                error={ !!errors?.tipo}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("vigilanza")}
                        onOpen={() => openTooltip("vigilanza")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "vigilanza"}
                        placement="right"
                        title={
                            <>
                                <p>Armata/non armata, Ronda diurna e notturna, posto fisso, orari</p>
                            </>
                        }
                    >
                        <InfoIcon color="action" className="align-middle ms-2 fs-4" />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='numeroAccessiEsterno'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={99}
                                disabled={shouldBeDisabled}
                                label="Numero accessi dall'esterno"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.numeroAccessiEsterno}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='numeroVaniScala'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={99}
                                disabled={shouldBeDisabled}
                                label="Numero vani scala"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.numeroVaniScala}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='numeroAscensori'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={99}
                                disabled={shouldBeDisabled}
                                label="Numero ascensori"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.numeroAscensori}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='areaRecintata'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={shouldBeDisabled}
                                label="Area Recintata"
                                value={value || ''}
                                handleChangeValue={onChange}
                                classNO="w-75"
                                classSI="w-75"
                                error={ !!errors?.areaRecintata}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='pianiFuoriTerra'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={99}
                                disabled={shouldBeDisabled}
                                label="Piani fuori terra"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.pianiFuoriTerra}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='pianiInterrati'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={99}
                                disabled={shouldBeDisabled}
                                label="Piani interrati"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.pianiInterrati}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="4" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='postiAutoScoperti'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={999}
                                disabled={shouldBeDisabled}
                                label="Posti auto scoperti"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.postiAutoScoperti}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="4" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='postiAutoCoperti'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={999}
                                disabled={shouldBeDisabled}
                                label="Posti auto coperti"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.postiAutoCoperti}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="4" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='boxAutoCoperti'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInputNumber
                                max={999}
                                disabled={shouldBeDisabled}
                                label="Box auto coperti"
                                number={value}
                                setValue={onChange}
                                error={ !!errors?.boxAutoCoperti}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='struttura'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Struttura"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.struttura}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("Struttura")}
                        onOpen={() => openTooltip("Struttura")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "Struttura"}
                        placement="right"
                        title={
                            <>
                                <p>Indicare se sono presenti anomalie ad una prima ricognizione visiva (danni, lesioni)</p>
                            </>
                        }
                    >
                        <InfoIcon color="action" className="align-middle ms-2 fs-4" />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='copertura'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Copertura"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.copertura}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("Copertura")}
                        onOpen={() => openTooltip("Copertura")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "Copertura"}
                        placement="right"
                        title={
                            <>
                                <p>Tipologia, stato, presenza di infiltrazioni, pulizia canali e pluviali</p>
                            </>
                        }
                    >
                        <InfoIcon color="action" className="align-middle ms-2 fs-4" />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='terrazzeLoggeBalconi'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Terrazze/logge/balconi"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.terrazzeLoggeBalconi}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("Terrazze")}
                        onOpen={() => openTooltip("Terrazze")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "Terrazze"}
                        placement="right"
                        title={
                            <>
                                <p>Tipologia, stato, presenza di infiltrazioni</p>
                            </>
                        }
                    >
                        <InfoIcon color="action" className="align-middle ms-2 fs-4" />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='areeEsterne'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Aree esterne"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.areeEsterne}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("Aree")}
                        onOpen={() => openTooltip("Aree")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "Aree"}
                        placement="right"
                        title={
                            <>
                                <p>Condizioni pavimentazione, presenza di aree a verde, stato</p>
                            </>
                        }
                    >
                        <InfoIcon color="action" className="align-middle ms-2 fs-4" />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='facciate'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Facciate"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.facciate}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("Facciate")}
                        onOpen={() => openTooltip("Facciate")}
                        onClose={() => closeTooltip()}
                        open={tooltip === "Facciate"}
                        placement="right"
                        title={
                            <>
                                <p>Materiale, stato di conservazione</p>
                            </>
                        }
                    >
                        <InfoIcon color="action" className="align-middle ms-2 fs-4" />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className={`${classes.root}`}>
                    <IonLabel className="label p-0 ion-text-capitalize">Note</IonLabel>
                        <Controller
                            defaultValue={''}
                            control={control}
                            name='note'
                            rules={{ required: !!sendToApproval }}
                            render={({ field: { onChange, value, } }) => 
                                <TextField
                                    disabled={shouldBeDisabled}
                                    id="note"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    className={`${classes.root} w-lg-94 w-xs-88 `}
                                    value={value || ''}
                                    onChange={onChange}
                                    error={ !!errors?.note}
                                    helperText={ !!sendToApproval && !dataDetail.note ? "Campo obbligatorio" : null}
                                />
                            }
                        />
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default DatiGenerali