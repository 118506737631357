export interface IconGalleryProps {
    width: number;
    height: number;
    className: string;
    title: string;
}

const IconGallery: React.FC<IconGalleryProps> = ({ width, height, className, title }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>{title}</title>
            <path className={className} d="M12.1875,0.328125 C13.5595824,0.328125 14.671875,1.44041757 14.671875,2.8125 L14.671875,2.8125 L14.671875,12.1875 C14.671875,13.5595824 13.5595824,14.671875 12.1875,14.671875 L12.1875,14.671875 L2.8125,14.671875 C2.71739168,14.671875 2.6235316,14.6665306 2.53120369,14.6561259 C2.36515564,14.6946871 2.18513484,14.6617352 2.04047292,14.5576466 L2.02521713,14.5445437 C1.03901742,14.2152904 0.328125,13.2844141 0.328125,12.1875 L0.328125,12.1875 L0.328125,2.8125 C0.328125,1.44041757 1.44041757,0.328125 2.8125,0.328125 L2.8125,0.328125 Z M10.4165625,6.9028125 L3.867125,13.452125 L12.1875,13.453125 C12.8427988,13.453125 13.3817781,12.9551019 13.4465907,12.3169028 L13.453125,12.1875 L13.453125,9.940125 L10.4165625,6.9028125 Z M12.1875,1.546875 L2.8125,1.546875 C2.11351461,1.546875 1.546875,2.11351461 1.546875,2.8125 L1.546875,2.8125 L1.546875,12.1875 C1.546875,12.6910872 1.84099198,13.1259797 2.26681944,13.329771 L9.98577347,5.61077347 C10.1999516,5.39659531 10.5338957,5.37517749 10.7720271,5.54652002 L10.8475599,5.61077347 L13.453125,8.216125 L13.453125,2.8125 C13.453125,2.1572012 12.9551019,1.61822193 12.3169028,1.55340928 L12.1875,1.546875 Z M4.94791667,3.24479167 C5.88852663,3.24479167 6.65104167,4.0073067 6.65104167,4.94791667 C6.65104167,5.88852663 5.88852663,6.65104167 4.94791667,6.65104167 C4.0073067,6.65104167 3.24479167,5.88852663 3.24479167,4.94791667 C3.24479167,4.0073067 4.0073067,3.24479167 4.94791667,3.24479167 Z M4.94791667,4.46354167 C4.68040374,4.46354167 4.46354167,4.68040374 4.46354167,4.94791667 C4.46354167,5.21542959 4.68040374,5.43229167 4.94791667,5.43229167 C5.21542959,5.43229167 5.43229167,5.21542959 5.43229167,4.94791667 C5.43229167,4.68040374 5.21542959,4.46354167 4.94791667,4.46354167 Z"></path>
        </svg>
    );
}

export default IconGallery;