import { DBSchema } from ".";

export const homepageSchema = {
    store: 'homepage',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'endpoint', keypath: 'endpoint', options: { unique: false } },
        { name: 'idIspezione', keypath: 'idIspezione', options: { unique: false } }
    ]
}
export interface HomepageSchema extends DBSchema{
    idIspezione: number
}
