import { IonRow, IonCol } from '@ionic/react'

export const NoSearchResult = () => {
    return (
        <IonRow className="ion-align-items-center vh-100">
            <IonCol size="12">
                <h2 className="ion-text-center">La ricerca non ha portato risultati.</h2>
                <p className="ion-text-center">Fai una nuova ricerca.</p>
            </IonCol>
        </IonRow>  
    )
}
