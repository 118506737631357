import { MouseEventHandler } from 'react';
import IconScrollToUp from '../../assets/icon/icon-up.svg';
import './goToUp.css';

export interface ScrollToUpProps {
    up: MouseEventHandler<HTMLDivElement>;
}

const ScrollToUp = ({up}: ScrollToUpProps) => {

    return (
        <div className="scroll-to-top cursor-pointer" onClick={up}>
            <img src={IconScrollToUp} alt="go to top" />
        </div>
    );
}

export default ScrollToUp;