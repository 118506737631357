import { IonLabel } from '@ionic/react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ChangeEvent } from 'react';
import { Multiselect, MultiselectOption } from './Multiselect';

export type onChangeMultiValue = (event: ChangeEvent<{}>, value: MultiselectOption[]) => void;
export type onChangeSingleValue = (event: ChangeEvent<{}>, value: MultiselectOption) => void;

interface MultiProps {
    isMulti: true;
    onChangeValue: onChangeMultiValue,
    value?: MultiselectOption[],
}

interface SingleProps {
    isMulti?: false;
    onChangeValue: onChangeSingleValue
    value?: MultiselectOption
}

interface Props {
    options: MultiselectOption[]
    label: string;
    id?:string;
    error?:boolean;
    formName?:string;
}

export const BUSelect = ({ id, formName, label, isMulti = false, error,  value, options, onChangeValue }: Props & (MultiProps | SingleProps)) => {

return (
    isMulti ? 
            (
                <Multiselect 
                    id={id}
                    label={label}
                    options={options}
                    value={value as MultiselectOption[] ?? []}
                    onChange={onChangeValue as onChangeMultiValue}
                    errors={error}
                />
            ) : (
                <>
                    <IonLabel className="label p-0 ion-text-capitalize">{label}</IonLabel>
                    <Autocomplete
                        value={value as MultiselectOption ?? null}
                        onChange={onChangeValue as onChangeSingleValue}
                        options={options}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => {
                            return (
                            <TextField
                                {...params}
                                name={formName}
                                variant="outlined"
                                error={error}
                                helperText={error ? "Campo obbligatorio" : null}
                            />
                            );
                        }}
                        disablePortal={false}
                    />
                </>

        )
  )
}
