import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonCol, IonRow } from '@ionic/react';

import Badge from '../Badge/Badge'

import IconAccountColor from '../../assets/icon/icon-account-color.svg';

import placeholder from '../../assets/img/placeholder.png';

import './card.css';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Inspection } from '../../Models/Inspection';

export interface CardProps {
  cardData: Inspection[];
}

const Card: React.FC<CardProps> = ({ cardData }) => {
  return (
    <>
      {cardData && cardData.length && cardData.map((el) => {

        return (
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12" className="p-0" key={el.id}>
            <IonCard className="card-home">
              <NavLink to={"/dati/" + el.immobileID + "/" + el.id}>
                {el.immagineCopertinaUrl === "" || el.immagineCopertinaUrl === null ? 
                  <img src={placeholder} alt="img" className="img-card" /> : 
                  <div style={{ 'backgroundImage': `url(${el.immagineCopertinaUrl})`, 'backgroundPosition': 'center center', 'backgroundSize': 'cover', 'height': '130px', 'width': '100%' }}></div>
                }
                <Badge className="badge" stato={el.stato} />
              </NavLink>
              <IonCardHeader className="pb-0">
                <NavLink to={"/dati/" + el.immobileID + "/" + el.id}>
                  <IonCardTitle className="title-card">{el.fondo}</IonCardTitle>
                </NavLink>
              </IonCardHeader>
              <IonCardContent className="content-card">
                  <img className="icon-account me-1 pe-0 d-inline" src={IconAccountColor} alt="icon account" />
                  { el.BuildingManagerEsternoRuolo === 2 
                    ? <b className="fullname-account d-inline">{el.buildingManagerEsterno}</b>
                    : <p className="fullname-account d-inline">{el.buildingManagerEsterno}</p>
                  }
                  <p className="street mt-0">{(`${el.indirizzo}, ${el.citta}`)} </p>
                  <p className="date mt-0">{el?.dataIspezione?.split("T")[0]?.split("-").reverse().join("/")}</p>
              </IonCardContent>
            </IonCard>
          </IonCol>
        )
      })}

    </>
  );
};

export default Card;