import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './style/mediaQuery.css';
import { BrowserRouter } from 'react-router-dom';
import { IonApp,  setupIonicReact } from '@ionic/react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'react-slideshow-image/dist/styles.css';
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
const theme = createTheme({
  palette: {
    primary: {
      main: "#33A7B2" 
    },
    secondary: {
      main: "#ffcc80" 
    },
    error:{
      main: '#eb445a'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Montserrat',
      'sans-serif'
    ].join(','),
  },
});
setupIonicReact();

const IonicApplication = () =>(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <IonApp>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IonApp>
    </ThemeProvider>
  // </React.StrictMode>
);

ReactDOM.render(
  <IonicApplication/>,
  document.getElementById('root')
);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
