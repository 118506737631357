import { IonCardTitle, IonCol, IonRow } from '@ionic/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Inspection } from '../../Models/Inspection';
import BadgeList from '../Badge/BadgeList';
import placeholder from '../../assets/img/placeholder.png';

import './list.css';

export interface ListProps {
    listData: Inspection[]
}

const List: React.FC<ListProps> = ({ listData }) => {
    return (
        <>
            {listData && listData.length > 0 && listData.map((el) => {
                return (
                    <IonRow className="ion-align-items-center bg-list-white" key={el.id}>
                        <IonCol size="3">
                            <IonRow className="ion-align-items-center">
                                <IonCol size="3">
                                    <NavLink to={"/dati/" + el.immobileID + "/" + el.id}>
                                    {el.immagineCopertinaUrl === "" || el.immagineCopertinaUrl === null ?  <img src={placeholder} alt="img" className="img-card-list" /> : <img src={el.immagineCopertinaUrl} alt="img" className="img-card-list" />}
                                    </NavLink>
                                </IonCol>
                                <IonCol size="9">
                                <NavLink to={"/dati/" + el.immobileID + "/" + el.id}>
                                    <IonCardTitle className="title-list">{el.fondo}</IonCardTitle>
                                    </NavLink>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="1" >
                            <IonCardTitle className="city">{el.citta}</IonCardTitle>
                        </IonCol>
                        <IonCol size="2">
                            <IonCardTitle className="address">{el.indirizzo}</IonCardTitle>

                        </IonCol>
                        <IonCol size="2">
                            <IonCardTitle className="economic-unit">{el.unitaEconomica}</IonCardTitle>
                        </IonCol>
                        <IonCol size="1">
                            <IonCardTitle className="date-list">{el.dataIspezione.split("T")[0].split("-").reverse().join("/")}</IonCardTitle>

                        </IonCol>
                        <IonCol size="1">
                            <IonCardTitle className="bm-list">{el.buildingManagerEsterno}</IonCardTitle>
                        </IonCol>
                        <IonCol size="2">
                            <BadgeList stato={el.stato} />
                        </IonCol>
                    </IonRow>
                )
            })}
        </>
    );
}

export default List;