import { useEffect, useMemo, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import { useHistory, useParams } from "react-router-dom";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { Option } from "../../Models/Checklist";
import { 
  useEntityUser,
  useEntityReport,
  useEntityConnectivity 
} from "../../store";
import { BaseUrl } from "../../utiles";
import { ResponseForApproval } from "../../Models/ResponseForApproval";
import { RegenerateReportOnNewerTemplate, GetReportDownloadURL, approval, nuovaRevisione, reject, sendMail, sendToApproval, translateInspectionData, sendToRevision } from "../../service/api";
import { removeSessionStorage, requestPostOptions } from "../../service";
import { useIsLoading } from "../../hooks/useIsLoading";

import { ReportDownloadURLInfo } from "../../Models/ReportDownloadURLInfo";
import { GetReportDownloadURLsInput } from "../../Models/GetReportDownloadURLsInput";
export function useResponseReport() {

  //SEND TO APPROVAL STATES
  const [showModalAlertForApproval, setShowModalAlertForApproval] = useState<boolean>(false);
  const [showModalAlertForRevision, setShowModalAlertForRevision] = useState<boolean>(false);
  const [valueApprover, setValueApprover] = useState<Option>(null);
  const [loadingSendToApproval, setLoadingSendToApproval] = useState<boolean>(false);
  const [loadingSendToRevision, setLoadingSendToRevision] = useState<boolean>(false);
  const [showModalSendForApproval, setShowModalSendForApproval] = useState<boolean>(false);
  const [responseForApproval, setResponseForApproval] = useState<ResponseForApproval>();

  //REJECT STATES
  const [errorValueNote, setErrorValueNote] = useState<boolean>(false);
  const [valueNote, setValueNote] = useState<string>("");
  const [loadingRefuse, setLoadingRefuse] = useState<boolean>(false);
  const [showModalReject, setShowModalReject] = useState<boolean>(false);

  //APPROVAL STATES
  const [showModalAlertConfirm, setShowModalAlertConfirm] = useState<boolean>(false);
  const [successTextApproval, setSuccessTextApproval] = useState<string>("");
  const [loadingApproval, setLoadingApproval] = useState<boolean>(false);
  const [showSuccessMessageApproval, setShowSuccessMessageApproval] = useState<boolean>(false);
  const [errorTextConfirmApproval, setErrorTextConfirmApproval] = useState<string>("");
  const [showErrorMessageConfirmApproval, setShowErrorMessageConfirmApproval] = useState<boolean>(false);

  //REPORT DOWNLOAD STATES RegenerateReportsOnNewerTemplate
  const [successTextRegenerateReportsOnNewerTemplate, setSuccessTextRegenerateReportsOnNewerTemplate] = useState<string>("");
  const [loadingRegenerateReportsOnNewerTemplate, setLoadingRegenerateReportsOnNewerTemplate] = useState<boolean>(false);
  const [showSuccessMessageRegenerateReportsOnNewerTemplate, setShowSuccessMessageRegenerateReportsOnNewerTemplate] = useState<boolean>(false);
  const [errorTextRegenerateReportsOnNewerTemplate, setErrorTextRegenerateReportsOnNewerTemplate] = useState<string>("");
  const [showErrorMessageRegenerateReportsOnNewerTemplate, setShowErrorMessageRegenerateReportsOnNewerTemplate] = useState<boolean>(false);


  //NEW VERSIONE STATES
  const [showModalSendForNewVersion, setShowModalSendForNewVersion] = useState<boolean>(false);
  const [showSuccessMessageNewVersion, setShowSuccessMessageNewVersion] = useState<boolean>(false);
  const [showErrorMessageNewVersion, setShowErrorMessageNewVersion] = useState<boolean>(false);

  //SEND EMAIL TO BM STATES
  const [loadingNotifyToBM, setLoadingNotifyToBM] = useState<boolean>(false);
  const [successTextNotifyToBM, setSuccessTextNotifyToBM] = useState<string>("");
  const [showSuccessMessageNotifyToBM, setShowSuccessMessageNotifyToBM ] = useState<boolean>(false);
  const [errorTextNotifyToBM, setErrorTextNotifyToBM] = useState<string>("");
  const [showErrorMessageNotifyToBM, setShowErrorMessageNotifyToBM] = useState<boolean>(false);

  //TRANSALTE STATES
  const [loadingTranslate, setLoadingTranslate] = useState<boolean>(false);
  const [successTextTranslate, setSuccessTextTranslate] = useState<string>("");
  const [showSuccessMessageTranslate, setShowSuccessMessageTranslate] = useState<boolean>(false);
  const [errorTextTranslate, setErrorTextTranslate] = useState<string>("");
  const [showErrorMessageTranslate, setShowErrorMessageTranslate] = useState<boolean>(false);
  const [showModalTranslate, setShowModalTranslate] = useState<boolean>(false);

  //HISTORY
  const history = useHistory();

  const { id, numberInspection } = useParams<ParamsInspection>();
  const { user: currentUserParse } = useEntityUser();
  const { getAll, deleteRecord, add } = useIndexedDB('report');

  const REPORT_ENDPOINTS = useMemo(() => ({
    SEND_TO_APPROVAL: `${BaseUrl}/Report/SendToApproval`,
    REJECT: `${BaseUrl}/Report/Reject`,
    APPROVAL: `${BaseUrl}/report/approval`,
    REGENERATEONNEWERTEMPLATE: `${BaseUrl}/report/RegenerateOnNewerTemplate`,
    GETREPORTDOWNLOADURL: `${BaseUrl}/report/GetReportDownloadURL`, 
    NEW_VERSION: `${BaseUrl}/sopralluoghi/NuovaRevisione`,
    SEND_EMAIL: `${BaseUrl}/Mail/SendMail`,
    TRANSLATE: `${BaseUrl}/Translate/TranslateInspectionData`
  }), []);

  const { 
    dataReport,
    refreshReport,
    dataForExport,
    refreshExportReport,
    approvers,
    refreshReportApprovers
  } = useEntityReport();

  const isOnline = useEntityConnectivity(store => store.connectivity);
  const [isLoading, setIsLoading] = useIsLoading(false);
  const data = {
    idUtente: +currentUserParse.id,
    idImmobile: +id,
    idIspezione: +numberInspection,
  };

  const sincronizeOfflineDBToOnline = async () => {
    if(!isOnline) return;

    const requestArray = await getAll();

    if (!requestArray.length) {
      refreshReport(data);
      refreshExportReport(data);
      refreshReportApprovers(data);
    }
    requestArray?.length && setIsLoading(true)
    const insertPromiseArray = requestArray.map(({id, data, endpoint}) => fetch(endpoint, requestPostOptions(data)).then(async () => await deleteRecord(id)));
    Promise.allSettled([
      ...insertPromiseArray
    ]).finally(() => {
      refreshReport(data);
      refreshExportReport(data);
      refreshReportApprovers(data);
      setIsLoading(false)
    });
  };

    //HANDLERS
  const handleSubmitForApprovalYES = async () => {

    setLoadingSendToApproval(true);
    const approversSavedOnDb = await getAll();

    const data = {
      idUtente: Number(currentUserParse.id),
      idImmobile: +id,
      idIspezione: +numberInspection,
      approvatore: valueApprover.id,
    };

    const onSuccess = (result: ResponseForApproval) => {
      if(result.inApprovazione){
        setShowModalAlertForApproval(false);
        setShowModalSendForApproval(false);
        history.go(0);
      }else {
        setShowModalAlertForApproval(false);
      }
      setResponseForApproval(result);
      setLoadingSendToApproval(false);
    }
  
    const onError = (error) => console.error(error);

    const addToDB = async () => {
      const recordAlreadyExist = approversSavedOnDb.some(data => data.endpoint === REPORT_ENDPOINTS.SEND_TO_APPROVAL);
      !recordAlreadyExist && await add({ endpoint: REPORT_ENDPOINTS.SEND_TO_APPROVAL, data });
      setShowModalAlertForApproval(false);
      setShowModalSendForApproval(false);
      setLoadingSendToApproval(false);
    } 

    isOnline
      ? await sendToApproval(data, onSuccess, onError)
      : addToDB();
  };

  const handleSubmitForRevisionYES = async () => {

    setLoadingSendToRevision(true);
    const approversSavedOnDb = await getAll();

    const data = {
      idUtente: Number(currentUserParse.id),
      idImmobile: +id,
      idIspezione: +numberInspection
    };

    const onSuccess = (result: ResponseForApproval) => {
      if(result.inApprovazione){
        setShowModalAlertForRevision(false);
        history.go(0);
      }else {
        //setShowModalAlertForRevision(false);
      }
      setResponseForApproval(result);
      setLoadingSendToRevision(false);
    }
  
    const onError = (error) => console.error(error);

    const addToDB = async () => {
      const recordAlreadyExist = approversSavedOnDb.some(data => data.endpoint === REPORT_ENDPOINTS.SEND_TO_APPROVAL);
      !recordAlreadyExist && await add({ endpoint: REPORT_ENDPOINTS.SEND_TO_APPROVAL, data });
      setShowModalAlertForRevision(false);
      setLoadingSendToRevision(false);
    } 

    isOnline
      ? await sendToRevision(data, onSuccess, onError)
      : addToDB();
  }

  const handleSubmitConfirmReject = async () => {

    setErrorValueNote(valueNote === "");
    const approversSavedOnDb = await getAll();

    if (valueNote !== "") {
      setLoadingRefuse(true);
      const data = {
        idUtente: Number(currentUserParse.id),
        idImmobile: +id,
        idIspezione: +numberInspection,
        note: valueNote,
      };

      const onSuccess = () => {
        window.setTimeout(() => history.go(0), 500);
        removeSessionStorage(`SendToApproval_${numberInspection}`);
      };
      const onError = (error) => console.error(error);

      const addToDB = async () => {
        const recordAlreadyExist = approversSavedOnDb.some(data => data.endpoint === REPORT_ENDPOINTS.REJECT);
        !recordAlreadyExist && await add({ endpoint: REPORT_ENDPOINTS.REJECT, data });
        setLoadingRefuse(false);
        setShowModalReject(false);
        removeSessionStorage(`SendToApproval_${numberInspection}`);
      }

      isOnline
       ?  await reject(data, onSuccess, onError)
       :  addToDB()
    }
  };


  const handleReportOnClick = (templateName: string)  =>{ 

    setLoadingRegenerateReportsOnNewerTemplate(true);

    const onSuccess = (result: string) => {

      if (!result) return;
        
        setSuccessTextRegenerateReportsOnNewerTemplate("Reperimento del report completato");
        setShowSuccessMessageRegenerateReportsOnNewerTemplate(true);

        const onURLReturned = (r: ReportDownloadURLInfo) =>
        {
          window.open(r.reportURL);
          window.setTimeout(() => { setLoadingRegenerateReportsOnNewerTemplate(false);  }, 500);
        }

        const onURLNotReturned = (err) =>
        {
          setErrorTextRegenerateReportsOnNewerTemplate("Errore nel reperimento del report");
          setShowErrorMessageRegenerateReportsOnNewerTemplate(true);
          setLoadingRegenerateReportsOnNewerTemplate(false);
        }

        GetReportDownloadURL<GetReportDownloadURLsInput,ReportDownloadURLInfo>({
          ispezioneID: +numberInspection,
          templateName: templateName
        }, onURLReturned, onURLNotReturned)
 
    }

    const onError = (error) => {
    }

    const data = {
      InspectionId: +numberInspection,
      UserId: +currentUserParse.id,
    };

    RegenerateReportOnNewerTemplate(data, onSuccess, onError);

  

  }

  const handleSubmitConfirmApproval = async () => {

    setLoadingApproval(true);
    const approversSavedOnDb = await getAll();

    const data = {
      InspectionId: +numberInspection,
      UserId: +currentUserParse.id
    };

    const onSuccess = (result: string) => {
      if (result) {
        setSuccessTextApproval("Approvazione eseguita correttamente");
        setShowSuccessMessageApproval(true);
        window.setTimeout(() => history.go(0), 1000);
      } else {
        setErrorTextConfirmApproval("Errore nella procedura di approvazione");
        setShowErrorMessageConfirmApproval(true);
        setLoadingApproval(false);
      }
    }
    const onError = (error) => {
      setErrorTextConfirmApproval(error.message);
      setShowErrorMessageConfirmApproval(true);
      setLoadingApproval(false);
    }

    const addToDB = async () => {
      const recordAlreadyExist = approversSavedOnDb.some(data => data.endpoint === REPORT_ENDPOINTS.APPROVAL);
      !recordAlreadyExist && await add({ endpoint: REPORT_ENDPOINTS.APPROVAL, data });
      setShowModalAlertConfirm(false)
      setLoadingApproval(false);
    }

    isOnline
     ? await approval(data, onSuccess, onError)
     : addToDB();
    
  };

  const handleNewVersion = async () => {

    const approversSavedOnDb = await getAll();

    const data = {
      InspectionId: +numberInspection,
      UserId: +currentUserParse.id,
    };

    const onSuccess = (result: string) => {
      if (result === "true") {
        setShowSuccessMessageNewVersion(true);
        window.setTimeout(() => history.go(0), 2000);
      } else {
        setShowErrorMessageNewVersion(true);
      }
    }

    const onError = (error) => console.error(error);

    const addToDB = async () => {
      const recordAlreadyExist = approversSavedOnDb.some(data => data.endpoint === REPORT_ENDPOINTS.NEW_VERSION);
      !recordAlreadyExist && await add({ endpoint: REPORT_ENDPOINTS.NEW_VERSION, data });
      setShowModalSendForNewVersion(false)
    }

    isOnline
      ? await nuovaRevisione(data, onSuccess, onError)
      : addToDB();  
  };

  const handleSubmitSendMailToBM = async () => {

    setLoadingNotifyToBM(true);

    const approversSavedOnDb = await getAll();

    const data = {
      inspectionId: +numberInspection,
      mailTemplate: "TemplateSendToBM",
      idUtente: +currentUserParse.id,
    };

    const onSuccess = (result: string) => {
      setLoadingNotifyToBM(false);
      if (result) {
        setSuccessTextNotifyToBM("Notifica inviata");
        setShowSuccessMessageNotifyToBM(true);
      } else {
        setErrorTextNotifyToBM("Errore nella procedura di invio mail");
        setShowErrorMessageNotifyToBM(true);
      }
    }

    const onError = (error) => {
      setLoadingNotifyToBM(false);
      setErrorTextNotifyToBM(error.message);
      setShowErrorMessageNotifyToBM(true);
    }

    const addToDB = async () => {
      const recordAlreadyExist = approversSavedOnDb.some(data => data.endpoint === REPORT_ENDPOINTS.SEND_EMAIL);
      !recordAlreadyExist && await add({ endpoint: REPORT_ENDPOINTS.SEND_EMAIL, data });
      setLoadingNotifyToBM(false);
    }

    isOnline
      ? await sendMail(data, onSuccess, onError)
      : addToDB();
  };

  const handleSubmitTranslate = async () => {

    setLoadingTranslate(true);

    const approversSavedOnDb = await getAll();

    const data = {
      InspectionId: +numberInspection,
      UserId: +currentUserParse.id,
    };

    const onSuccess = (result: string) => {
      if (result) {
        setSuccessTextTranslate(result);
        setShowSuccessMessageTranslate(true);
      } else {
        setErrorTextTranslate("Errore nella procedura di traduzione in inglese");
        setShowErrorMessageTranslate(true);
      }
      setLoadingTranslate(false);
      setShowModalTranslate(false);
    }

    const onError = (error) => {
      setErrorTextTranslate('Errore nella procedura di traduzione in inglese');
      setShowErrorMessageTranslate(true);
      setLoadingTranslate(false);
      setShowModalTranslate(false);
    }

    const addToDB = async () => {
      const recordAlreadyExist = approversSavedOnDb.some(data => data.endpoint === REPORT_ENDPOINTS.TRANSLATE);
      !recordAlreadyExist && await add({ endpoint: REPORT_ENDPOINTS.TRANSLATE, data });
      setLoadingTranslate(false);
      setShowModalTranslate(false);
    }

    isOnline
      ? await translateInspectionData(data, onSuccess, onError)
      : addToDB();
  };

  useEffect(() => {
    const ac = new AbortController();
    sincronizeOfflineDBToOnline();
    return () => ac.abort();
  }, [currentUserParse, id, numberInspection, isOnline]);

  return {
    isLoading,
    dataReport,
    dataForExport,
    approvers,
    showModalSendForApproval,
    setShowModalSendForApproval,
    showModalAlertForApproval,
    setShowModalAlertForApproval,
    showModalAlertForRevision,
    setShowModalAlertForRevision,
    valueApprover,
    setValueApprover,
    responseForApproval,
    loadingSendToApproval,
    setLoadingSendToApproval,
    loadingSendToRevision,
    setLoadingSendToRevision,
    handleSubmitForApprovalYES,
    handleSubmitForRevisionYES,
    errorValueNote,
    valueNote,
    setValueNote,
    loadingRefuse,
    setLoadingRefuse,
    showModalReject,
    setShowModalReject,
    handleSubmitConfirmReject,
    successTextApproval,
    loadingApproval,
    setLoadingApproval,

    successTextRegenerateReportsOnNewerTemplate,
    loadingRegenerateReportsOnNewerTemplate,
    setLoadingRegenerateReportsOnNewerTemplate,
    showSuccessMessageRegenerateReportsOnNewerTemplate,
    setShowSuccessMessageRegenerateReportsOnNewerTemplate,
    errorTextRegenerateReportsOnNewerTemplate,
    showErrorMessageRegenerateReportsOnNewerTemplate,

    showSuccessMessageApproval,
    setShowSuccessMessageApproval,
    errorTextConfirmApproval,
    showErrorMessageConfirmApproval,
    setShowErrorMessageConfirmApproval,
    showModalAlertConfirm,
    setShowModalAlertConfirm,
    handleSubmitConfirmApproval,
    showModalSendForNewVersion,
    setShowModalSendForNewVersion,
    showSuccessMessageNewVersion,
    setShowSuccessMessageNewVersion,
    showErrorMessageNewVersion,
    setShowErrorMessageNewVersion,
    handleNewVersion,
    loadingNotifyToBM,
    setLoadingNotifyToBM,
    successTextNotifyToBM,
    showSuccessMessageNotifyToBM,
    setShowSuccessMessageNotifyToBM,
    errorTextNotifyToBM,
    showErrorMessageNotifyToBM,
    setShowErrorMessageNotifyToBM,
    handleSubmitSendMailToBM,
    loadingTranslate,
    setLoadingTranslate,
    successTextTranslate,
    showSuccessMessageTranslate,
    setShowSuccessMessageTranslate,
    errorTextTranslate,
    showErrorMessageTranslate,
    setShowErrorMessageTranslate,
    showModalTranslate,
    setShowModalTranslate,
    handleSubmitTranslate,
    handleReportOnClick
  };
}