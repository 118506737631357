import create from "zustand";
import { Checklist, ProblematicFormModel, OptionProblematic } from "../Models/Checklist";
import { RequestDTO } from "../Models/RequestDTO";
import { COMPENTANCE, LIV_INTERVENTO, T_INTERVENTO } from "../pages/Checklist/constants";
import { ResponseTypeEnum } from "../pages/Checklist/Question/useQuestionHook";
import { requestGetChecklist } from "../service/api";

type BridgeProblematicFromFormToDTO = (form: ProblematicFormModel) => OptionProblematic

const bridgeProblematic: BridgeProblematicFromFormToDTO = (form) => {
    return {
        ...form,
        livelloIntervento: LIV_INTERVENTO.find(livello => livello.id === form.livelloIntervento).nome,
        livelloInterventoID: form.livelloIntervento,
        competenza: COMPENTANCE.find(competenza => competenza.id === form.competenza).nome,
        competenzaID: form.competenza,
        tipologiaIntervento: T_INTERVENTO.find(tipologia => tipologia.id === form.tipologiaIntervento).nome,
        tipologiaInterventoID: form.tipologiaIntervento
    }
};

export const useEntityChecklist = create((set: Function, get: Function) => ({
    dataChecklist: undefined as Checklist,
    tabList: [],
    valueTab: 0,
    setValueTab: (tab: number) => set({ ...get(), valueTab: tab }),
    refreshChecklist: (data: RequestDTO) => {
        requestGetChecklist<RequestDTO>(data, onSucces(set, get), onError(set, get))
    },
    addResponse: ({ idChecklist, idRisposta, valueTab }) => {
        const dataChecklist = get().dataChecklist;
        const listaTab = dataChecklist.listaTab.map(tab =>
            tab.idNome === valueTab
                ? {
                    ...tab,
                    domande: tab.domande.map(domanda => domanda.idDomanda === idChecklist ? { ...domanda, idRisposta } : domanda)
                }
                : tab
        );
        set({
            ...get(),
            dataChecklist: {
                ...dataChecklist,
                listaTab
            }
        })
    },
    removeProblematic: ({ idDomanda, idProblema }) => {
        const { dataChecklist, valueTab } = get()
        const listaTab = dataChecklist?.listaTab?.map(tab =>
            tab.idNome === valueTab
                ? {
                    ...tab,
                    domande: tab.domande.map(domanda =>
                        domanda.idDomanda === idDomanda
                            ? { ...domanda, problemi: domanda.problemi?.filter(problema => problema.idProblema !== idProblema) }
                            : domanda
                    )
                }
                : tab
        )
        set({
            ...get(),
            dataChecklist: {
                ...dataChecklist,
                listaTab
            }
        })
    },
    deleteAllProblematic: ({ idDomanda, valueTab }) => {
        const dataChecklist = get().dataChecklist;
        const listaTab = dataChecklist?.listaTab?.map(tab =>
            tab.idNome === valueTab
                ? {
                    ...tab,
                    domande: tab.domande.map(domanda => domanda.idDomanda === idDomanda ? { ...domanda, problemi: [] } : domanda)
                }
                : tab
        );
        set({
            ...get(),
            dataChecklist: {
                ...dataChecklist,
                listaTab
            }
        })
    },
    moveProblematicTo: ({ tabTo, idDomandaDestinazione, idDomanda, ...data }) => {
        const { idProblema } = data;
        const { dataChecklist, valueTab } = get();
        const listaTab = dataChecklist?.listaTab?.map( tab =>
            tab.idNome === valueTab 
                ? {
                    ...tab,
                    domande: tab.domande?.map(domanda => 
                        domanda.idDomanda === idDomanda 
                        ? {
                            ...domanda,
                            problemi: domanda.problemi.filter(problema => problema.idProblema !== idProblema)
                        }
                        : domanda.idDomanda === idDomandaDestinazione
                                ? {
                                    ...domanda,
                                    idRisposta: domanda?.problemi?.length ? domanda.idRisposta : ResponseTypeEnum.Yes,
                                    problemi: [data, ...domanda?.problemi || []]
                                }
                                : domanda
                    )
                }
                : tab.idNome === tabTo.id
                    ?{
                        ...tab,
                        domande: tab.domande?.map(domanda =>
                            domanda.idDomanda === idDomandaDestinazione
                                ? {
                                    ...domanda,
                                    idRisposta: domanda?.problemi?.length ? domanda.idRisposta : ResponseTypeEnum.Yes,
                                    problemi: [data, ...domanda?.problemi || []]
                                }
                                : domanda

                        )
                    }
                    : tab
        )
        set({
            ...get(),
            dataChecklist: {
                ...dataChecklist,
                listaTab
            }
        })
    },
    addProblematicTo: (form: ProblematicFormModel) => {
        const { idDomanda, ...problematic } = form;
        const { dataChecklist, valueTab } = get();
        const listaTab = dataChecklist?.listaTab?.map(tab =>
            tab.idNome === valueTab
                ? {
                    ...tab,
                    domande: tab.domande.map(domanda => domanda.idDomanda === idDomanda ? { ...domanda, problemi: [...domanda?.problemi, bridgeProblematic(form)] } : domanda)
                }
                : tab
        );
        set({
            ...get(),
            dataChecklist: {
                ...dataChecklist,
                listaTab
            }
        })
    },
    updateProblematicTo: (form: ProblematicFormModel) => {
        const { idDomanda, ...problematic } = form;
        const { dataChecklist, valueTab } = get();
        const listaTab = dataChecklist?.listaTab?.map(tab =>
            tab.idNome === valueTab
                ? {
                    ...tab,
                    domande: tab.domande.map(domanda =>
                        domanda.idDomanda === idDomanda
                            ? {
                                ...domanda,
                                problemi: domanda?.problemi?.map(problema =>
                                    problema.idProblema === problematic.idProblema
                                        ? {
                                            ...problema,
                                            ...bridgeProblematic(form)
                                        }
                                        : problema
                                )
                            }
                            : domanda
                    )
                }
                : tab
        );
        set({
            ...get(),
            dataChecklist: {
                ...dataChecklist,
                listaTab
            }
        })
    }
}));
const onSucces = (set: Function, get: Function) => (dataChecklist: Checklist) => {
    const tabList = dataChecklist?.listaTab?.reduce(
        (acc, el) =>
            acc.concat({
                id: el.idNome,
                nome: el.nome,
            }),
        [{ id: null, nome: "" }]
    );
    set({
        ...get(),
        dataChecklist,
        tabList
    })
}
const onError = (set: Function, get: Function) => (error: Error) => set({ dataChecklist: get().dataChecklist, errorText: error.message })