import { IonCard, IonCol, IonFabButton, IonIcon, IonRow } from '@ionic/react';
import { GetGroupDTO } from '../../../Models/Group';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import { useEntityUser } from '../../../store';
import { RoleID } from '../../Detail/useResponseDetail';

export interface RowGroupProps {
    el: GetGroupDTO;
    onUpdate(): void;
    onDelete(): void;
}

const RowGroup: React.FC<RowGroupProps> = ({ el, onUpdate, onDelete }) => {
 
    const { user: currentUserParse } = useEntityUser();

    console.log(el);

    return (
        <>
            <IonRow>
                <IonCol>
                    <IonCard color="light" className="pb-3">
                        <IonRow className="mb-0 ion-justify-content-between">
                            <IonCol className="ps-3" size="3">                             
                                <h4>{el.nome}</h4>
                            </IonCol>
                            { currentUserParse?.profilo === RoleID.LETTORE ? "" :
                            <IonCol size="4" className="ion-align-self-start">
                                <IonFabButton
                                    size="small"
                                    className="ion-float-end"
                                    onClick={ onUpdate}
                                >
                                    <IonIcon icon={pencilOutline} />
                                </IonFabButton>
                                <IonFabButton
                                    color="danger"
                                    size="small"
                                    className="ion-float-end"
                                    onClick={onDelete}
                                >
                                    <IonIcon icon={trashOutline} />
                                </IonFabButton>
                            </IonCol>
                            }
                        </IonRow>
                        <IonRow className="mx-3">
                            <IonCol size="4" className="ps-3">
                                <h6>Nome e Cognome</h6>
                            </IonCol>
                            <IonCol size="4" className="ps-3">
                                <h6>Email</h6>
                            </IonCol>
                            <IonCol size="4" className="ps-3">
                                <h6>Ruolo</h6>
                            </IonCol>
                        </IonRow>
                        {el.listaUtenti.map(val => (
                            <IonRow className="ion-align-items-center bg-list-white-group mx-3" key={val.id}>
                                <IonCol size="4" className="ps-3">
                                    <p>{val.nome + " " + val.cognome}</p>
                                </IonCol>
                                <IonCol size="4" className="ps-3">
                                    <p>{val?.mail?.toLowerCase()}</p>
                                </IonCol>
                                <IonCol size="4" className="ps-3">
                                    {val?.profiloId === 2 
                                        ? <b>{val?.profilo}</b>
                                        : <p>{val?.profilo}</p>
                                    }
                                </IonCol>
                            </IonRow>
                        ))}
                    </IonCard>
                </IonCol>
            </IonRow >
        </>
    )
}

export default RowGroup;