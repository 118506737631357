import { IonBadge, } from '@ionic/react';
import './badge.css';


export const state = (stato: string): string => {
    if (stato.toLocaleLowerCase() === 'creato') {
        return "created";
    } else if (stato.toLocaleLowerCase() === "in corso" || stato.toLocaleLowerCase() === "revisionato") {
        return "in-progress";
    } else if (stato.toLocaleLowerCase() === "in approvazione" || stato.toLocaleLowerCase() === "in revisione") {
        return "in-approval";
    } else if (stato.toLocaleLowerCase() === "respinto") {
        return "reject";
    } else if (stato.toLocaleLowerCase() === "approvato") {
        return "approved";
    } 
}
export interface BadgeProps {
    stato: string;
    className?: string;
}
const Badge: React.FC<BadgeProps> = ({ stato,className }) => {
    //badge ion-text-uppercase
    return <IonBadge className={`${className} ion-text-uppercase ${state(stato)}`}>{stato}</IonBadge>
}

export default Badge;