import { DBSchema } from ".";

export const detailSchema ={
    store: 'detail',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'endpoint', keypath: 'endpoint', options: { unique: false } },
        { name: 'idIspezione', keypath: 'idIspezione', options: { unique: false } }
    ]
}
export interface DetailSchema extends DBSchema{
    idIspezione: number   
}
