import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import CustomRadioButton from "../../components/CustomRadioButton/CustomRadioButton";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { useEntityDetail, useEntityUser } from "../../store";
import { useSendToApproval } from "../Checklist/useSendToApproval";
import { RoleID } from "./useResponseDetail";

const UtenzeAttive: React.FC = () => {

    const { numberInspection } = useParams<ParamsInspection>();
    const dataDetail = useEntityDetail(store => store.dataDetail);
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);

    const { control, formState:{ errors}} = useFormContext();

    const { user: currentUserParse } = useEntityUser();

    const shouldBeDisabled = dataDetail?.stato === "Approvato" || dataDetail?.stato === "In Approvazione"  || currentUserParse?.profilo === RoleID.LETTORE;

    return (
        <IonGrid>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='utenzeAttive_Elettrico'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={shouldBeDisabled}
                                classNO="w-75"
                                classSI="w-75"
                                label="elettrico"
                                value={value || ''}
                                handleChangeValue={onChange}
                                error={ !!errors?.utenzeAttive_Elettrico}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='utenzeAttive_Idrico'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={shouldBeDisabled}
                                classNO="w-75"
                                classSI="w-75"
                                label="idrico"
                                value={value || ''}
                                handleChangeValue={onChange}
                                error={ !!errors?.utenzeAttive_Idrico}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='utenzeAttive_Termico'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={shouldBeDisabled}
                                classNO="w-75"
                                classSI="w-75"
                                label="riscaldamento"
                                value={value || ''}
                                handleChangeValue={onChange}
                                error={ !!errors?.utenzeAttive_Termico}
                            />
                        }
                    />
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='utenzeAttive_Gas'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomRadioButton
                                disabled={shouldBeDisabled}
                                classNO="w-75"
                                classSI="w-75"
                                label="gas"
                                value={value || ''}
                                handleChangeValue={onChange}
                                error={ !!errors?.utenzeAttive_Gas}
                            />
                        }
                    />
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default UtenzeAttive