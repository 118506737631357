import { GetCreatoriForProgetto } from "../../Models/DataDetail";
import { requestPostOptions } from "../crud";

const BaseUrl: string = process.env.REACT_APP_BASE_URL_API; 

export const ENDPOINT_HOMEPAGE = {
    addIspezione: `${BaseUrl}/Sopralluoghi/InsertSopralluoghi`,
    searchSopralluoghi : `${BaseUrl}/Sopralluoghi/SearchSopralluoghi`,
    getFilterSopralluoghi : `${BaseUrl}/Sopralluoghi/GetFilterSopralluoghi`,
    getFilterAddSopralluoghi : `${BaseUrl}/Sopralluoghi/GetFilterAddSopralluoghi`,
    GetUtentiCreatoriSopralluogo: `${BaseUrl}/Sopralluoghi/GetUtentiCreatoriSopralluogo`,
}

export const requestAddIspezione = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_HOMEPAGE.addIspezione, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const getSopralluoghi = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_HOMEPAGE.searchSopralluoghi, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const getFilterSopralluoghi = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_HOMEPAGE.getFilterSopralluoghi, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const getFilterAddSopralluoghi = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_HOMEPAGE.getFilterAddSopralluoghi, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const GetUtentiCreatoriSopralluogo = async (data: GetCreatoriForProgetto, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<GetCreatoriForProgetto>(data);
    try {
        const promise = await fetch(ENDPOINT_HOMEPAGE.GetUtentiCreatoriSopralluogo, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};