import { base64Encoding } from "../utiles";
import { parse as uuidParse } from 'uuid';
import { UserDb } from "../Models/UserDb";




export const requestPostOptions = <T>(data: T) => {
    let User = JSON.parse(sessionStorage.getItem('user')).state.user;
    return ({
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 
    'Basic ' + base64Encoding(process.env.REACT_APP_API_AUTH +':'+ User.nome + ':' + User.mail + ':' + User.profilo
    + ':' + User.id + ':' + User.cognome + ':' + User.gruppo) },
    body: JSON.stringify(data)
})};




export const convertGuidToInt = (uuid) => {
    // parse accountId into Uint8Array[16] variable
    const parsedUuid = uuidParse(uuid);
    // convert to integer - see answers to https://stackoverflow.com/q/39346517/2860309
    const buffer = Buffer.from(parsedUuid);
    return buffer.readUInt16BE(0);
};

export const handleQuery = (query: any) => new URLSearchParams(query).toString();
