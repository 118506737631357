import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useEntityBU } from "../store/Bu";
import { BUSelect } from "./BUSelect";
import { MultiselectOption } from './Multiselect';

interface MultiProps {
    isMulti: true;
    onChangeValue?: (value: MultiselectOption[]) => void; // pensata solo per fare altre azioni che non siano il setting del campo
}

interface SingleProps {
    isMulti?: false;
    onChangeValue?: (value: MultiselectOption) => void; // pensata solo per fare altre azioni che non siano il setting del campo
}

interface Props {
    formName: string, 
    label: string;
}

export const AssociateBUSelect = ({ formName, label, isMulti = false, onChangeValue }: Props & (MultiProps | SingleProps)) => {
    const { buList } = useEntityBU();

    const { control, formState:{ errors }, setValue } = useFormContext();

    const buAssociateFormValues = useWatch({ name: formName, control });

    const { BUOptions, BUAssociate } = useMemo(() => {
        const mappedBU = buList.map(bu => ({ label: bu.nome, value: bu.id}));
        if(!isMulti) return {
            BUOptions: mappedBU,
            BUAssociate: !buAssociateFormValues ? null : mappedBU.find(bu => bu.value === buAssociateFormValues as number)
        }

        const BUOptions = mappedBU.filter(bu => !buAssociateFormValues?.includes(bu.value));
        const BUAssociate = mappedBU.filter(bu => buAssociateFormValues?.includes(bu.value));

        return {
            BUOptions,
            BUAssociate
        }
    }, [buList, buAssociateFormValues, isMulti]);

    const onChangeSingle = (_, value) => {
        onChangeValue?.(value as any)
        setValue(formName, (value as MultiselectOption)?.value, { shouldDirty: true })
    }

    const onChangeMulti = (_, value) => {
        const newList = value.map((bu) => bu.value)
        onChangeValue?.(value as any)
        setValue(formName, newList, { shouldDirty: true })
    }

  return (
    <Controller 
        control={control}
        name={formName}
        rules={{required: true}}
        render={({field: { value }}) => (
            <BUSelect 
                isMulti={isMulti as false}
                id="buAssociate"
                label={label}
                options={BUOptions}
                value={BUAssociate as MultiselectOption}
                onChangeValue={isMulti ? onChangeMulti : onChangeSingle}
                error={!!errors?.[formName]}
            />
        )}
    />
)}


    