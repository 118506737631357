import { OptionQuestion } from "../Models/Checklist";

export const countInvalidQuestions = (el: OptionQuestion[]) => {
    return el.reduce((acc, val) => {
        const isInvalid =
            (val.idRisposta === -1)
            ||
            (val.visualizzaAggiungi &&
                val.idRisposta === 4 &&
                (val.problemi.length === 0 ||
                    val.problemi.some((problema) =>
                        !problema.competenza ||
                        !problema.stimaCosti ||
                        !problema.intervento ||
                        !problema.possibiliCause
                    )
                )
            )

        return isInvalid ? acc + 1 : acc;
    }, 0);
};