import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { requestAddIspezione } from "../../service/api";
import { FILTER_FORM_INITIAL_STATE, useEntityConnectivity, useEntityHomepage, useEntityUser } from "../../store";
import { useIndexedDB } from "react-indexed-db";
import { requestPostOptions } from "../../service";
import { useForm } from "react-hook-form";
import { AddInspection } from "../../Models/AddInspection";
import { FilterParamiter } from "../../Models/FilterParamiter";
import { useIsLoading } from "../../hooks/useIsLoading";
import { RoleID } from "../Detail/useResponseDetail";

const useHomePage = () => {

    const {
        dataInspections,
        dataFilter,
        getDataInspectionsFilters,
        getDataNewInspectionFilter,
        getDataInspections,
        getResetForm,
        dataFilterOption,
        getDataFromFilters,
        refreshDataToExportInExcel,
        dataForExport
    } = useEntityHomepage(store => store);

    const methods = useForm({ mode: 'onSubmit' }); 

    const { user: currentUserParse } = useEntityUser();
    const history = useHistory();
    const { connectivity: isOnline } = useEntityConnectivity();
    const { getAll, deleteRecord } = useIndexedDB('homepage');

    //STATES
    const [addModal, setAddModal] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [showFilter, setShowFilter] = useState(false);
    const [page, setPage] = useState(1);
    const [disableInfiniteScroll] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useIsLoading(false);
    async function searchNext($event: CustomEvent<void>) {
        setPage(page + 1);
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    const sincronizeOfflineDBToOnline = useCallback(async () => {
        if (!isOnline) return;
        const requestArray = await getAll();
        if (!requestArray.length) {
            getDataInspections(+currentUserParse.id);
        }
        requestArray?.length && setIsLoading(true)
        const insertPromiseArray = requestArray.map(({ id, data, endpoint }) => fetch(endpoint, requestPostOptions(data)).then(async () => await deleteRecord(id)));
        Promise.allSettled([
            ...insertPromiseArray
        ]).finally(() => {
            getDataInspections(+currentUserParse.id);
            setIsLoading(false)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserParse.id, isOnline]);

    useEffect(() => {
        getDataInspectionsFilters(+currentUserParse.id);
        if(currentUserParse.profilo !== RoleID.LETTORE)
            getDataNewInspectionFilter(+currentUserParse.id);
        methods.reset(dataFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserParse.id, getDataInspectionsFilters, getDataNewInspectionFilter, !!dataFilter])

    useEffect(() => {
        sincronizeOfflineDBToOnline();
    }, [sincronizeOfflineDBToOnline]);

    useEffect(()=>{
        refreshDataToExportInExcel(+currentUserParse.id);
    }, [dataFilter])
    //API
    const addIspezione = (data: AddInspection): void => {
        setAddModal(false); // close modal
        const onSuccess = (result: number) => {
            if (result > 0) {
                const path = `/dati/${data.indirizzoCitta}/${result}`;
                // setAddModal(false);
                setTimeout(() => { history.push(path); }, 1000);
            }
            else {
                setError("Errore durante la creazione del sopralluogo");
            }
        };
        const addToDB = () => console.log("add al db");
        isOnline
            ? requestAddIspezione(data, onSuccess, console.error)
            : addToDB();
    }


    const handleUpdateDataInspections =  () => {
         methods.handleSubmit(updateDataInspections)();
    }
    const updateDataInspections = (dataSubmitted: FilterParamiter) => {
        setPage(1);
        getDataFromFilters(+currentUserParse.id, dataSubmitted);
    }

    const handleResetForm =   () => {
        setPage(1);
        methods.reset(FILTER_FORM_INITIAL_STATE);
        getResetForm(+currentUserParse.id);
    }

    return {
        isLoading,
        addIspezione,
        addModal,
        setAddModal,
        error,
        showFilter,
        setShowFilter,
        currentUserParse,
        dataInspections,
        searchNext,
        disableInfiniteScroll,
        setPage,
        methods,
        handleUpdateDataInspections,
        handleResetForm,
        dataFilter,
        dataFilterOption,
        dataForExport
    }
};

export default useHomePage;

