import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useIndexedDB } from "react-indexed-db";
import { useIsLoading } from "../../hooks/useIsLoading";
import { Fornitore } from "../../Models/Fornitori";
import { GetGroupDTO } from "../../Models/Group";
import { requestPostOptions } from "../../service";
import {
    useEntityConnectivity,
    useEntitySettings,
    useEntityUser,
} from "../../store";
import { useCRUDFornitori } from "./Fornitori/useCRUDFornitore";
import { useAddEditSettingsModal } from "./useAddEditSettingsModal";
import { useCRUDGroup } from "./useCRUDGroup";
import { useCRUDUser } from "./useCRUDUser";
import { onChangeSingleValue } from "../../components/BUSelect";
import { MultiselectOption } from "../../components/Multiselect";
import { useEntityBU } from "../../store/Bu";

export enum SelectedSettingType {
    user,
    group,
    fornitori,
}

export interface SettingFormModalType {
    isOpen: boolean;
    type: SelectedSettingType;
    isDeleting: number;
}

export interface ModificationRoleType {
    valueRole: number;
    id: number;
}

export const useSettingsFormHook = () => {
    const { user: currentUserParse } = useEntityUser();
    const { connectivity: isOnline } = useEntityConnectivity();
    const {
        getGroup,
        getAllUsers,
        getFornitori,
        setGroupLoading,
        setGroupLoadingError,
        setUserLoading,
        setUserLoadingError,
        setFornitoriLoading,
        setFornitoriLoadingError
    } = useEntitySettings((store) => store);
    const { buList } = useEntityBU();
    
    const methods = useForm({ mode: "all" });
    const { reset } = methods;

    const { modal, openAddEditModal, closeAddEditModal, setModal } =
        useAddEditSettingsModal({ resetFormDataFn: reset });

    const [messageToast, setMessageToast] = useState<string>("");
    const [messageToastError, setMessageToastError] = useState<string>("");
    const [isSyncing, setIsSyncing] = useIsLoading(false);
    const [showLoader, setShowLoader] = useIsLoading(false);
    const [asBu, setAsBu] = useState<MultiselectOption | null>(null)

    const buOptions = useMemo(() => {
        const mappedBU = buList.map(bu => ({ label: bu.nome, value: bu.id}));
        return mappedBU.filter(el => el.value !== asBu?.value)
    }, [buList, asBu])

    const onChangeBu: onChangeSingleValue = (event, value) => {
        setAsBu(value)
    }


    function setMessage(message: string, isErrorMessage: boolean) {
        if (isErrorMessage) {
            setMessageToastError(message);
            setTimeout(() => setMessageToastError(""), 5000);
            return
        }
        
        setMessageToast(message);
        setTimeout(() => setMessageToast(""), 5000);
    }
    const {
        group,
        groupLoading,
        groupLoadingError,
        onCreateGroup,
        onDeleteGroup,
        onUpdateGroup,
    } = useCRUDGroup({ reset, modal, onClose: closeAddEditModal, setMessage, setShowLoader, asBu: asBu?.value ?? null });

    const {
        users,
        submitAddUser,
        onUpdateUserRole,
        onDeleteUser,
        userLoading,
        userLoadingError,
        isUserUpdated,
    } = useCRUDUser({ reset, modal, onClose: closeAddEditModal, setMessage, setShowLoader, asBu: asBu?.value ?? null });

    const {
        fornitori,
        onCreateFornitori,
        onUpdateFornitori,
        onDeleteFornitori,
        toggleAssociateBU,
        fornitoriLoading,
        fornitoriLoadingError,
    } = useCRUDFornitori({ reset, modal, onClose: closeAddEditModal, setMessage, setShowLoader, asBu: asBu?.value ?? null });

    const { getAll, deleteRecord } = useIndexedDB("settings");
    const entityModalId = useWatch({ name: "id", control: methods.control });

    const handleChangeTab = useCallback(
        (event: React.ChangeEvent<{}>, type: SelectedSettingType) =>
            setModal((m) => ({ ...m, type })),
        [setModal]
    );

    const sincronizeOfflineDBToOnline = useCallback(async () => {
        if (!isOnline) return;

        setShowLoader(true)

        const requestArray = await getAll();

        if (!requestArray.length) {
            getGroup(+currentUserParse.id, asBu?.value ?? null);
            getAllUsers(+currentUserParse.id, asBu?.value ?? null);
            getFornitori(+currentUserParse.id);
        }
        requestArray?.length && setIsSyncing(true);
        const insertPromiseArray = requestArray.map(({ id, data, endpoint }) =>
            fetch(endpoint, requestPostOptions(data)).then(
                async () => await deleteRecord(id)
            )
        );
        Promise.allSettled([...insertPromiseArray]).finally(() => {
            getGroup(+currentUserParse.id, asBu?.value ?? null);
            getAllUsers(+currentUserParse.id, asBu?.value ?? null);
            getFornitori(+currentUserParse.id);
            setIsSyncing(false);
            setShowLoader(false)

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserParse.id, getAllUsers, getGroup, getFornitori, isOnline, asBu]);

    useEffect(() => {
        sincronizeOfflineDBToOnline();
    }, [sincronizeOfflineDBToOnline]);
    useEffect(() => {
        !modal.isOpen &&
            modal.isDeleting &&
            setTimeout(
                () => setModal((prev) => ({ ...prev, isDeleting: undefined })),
                250
            );
    }, [modal.isDeleting, modal.isOpen, setModal]);

    const openEditGroupModal = (groupId: number) => {
        const { nome: nome, ...record }: GetGroupDTO = group.find(
            (el) => el.id === groupId
        );
        openAddEditModal({
            ...record,
            nomeGruppo: nome,
        });
    };

    const openEditFornitoreModal = (fornitoreId: number) => {
        const { nomeFornitore, listaUtenti,...record }: Fornitore = fornitori.find(
            (el) => el.id === fornitoreId
        );
        openAddEditModal({
            ...record,
            listaUtenti: listaUtenti.map(el => ({...el, isOwner: el.isOwner ? "SI" : "NO"})),
            nomeFornitore,
        });
    };

    const openDeleteModal = (entityId: number) => {
        setModal((prev) => ({ ...prev, isOpen: true, isDeleting: entityId }));
    };

    const closeToast = () =>{
        (userLoading && setUserLoading(false)) ||
        (groupLoading && setGroupLoading(false)) ||
        (fornitoriLoading && setFornitoriLoading(false));
        // setIsLoading(false)
    }

    const closeToastError = () => {
        (userLoadingError && setUserLoadingError(false)) ||
        (groupLoadingError && setGroupLoadingError(false)) ||
        (fornitoriLoadingError && setFornitoriLoadingError(false));
        // setIsLoading(false)
    }

    return {
        methods,
        modal,
        isSyncing,
        showLoader,
        openAddEditModal,
        handleChangeTab,
        closeAddEditModal,
        closeToast,
        closeToastError,
        messageToast,
        messageToastError,
        buOptions,
        onChangeBu,
        asBu,
        currentUserParse,

        //USER
        users,
        isUserUpdated,
        submitAddUser,
        onDeleteUser,
        onUpdateUserRole,

        //GROUP
        group,
        openEditGroupModal,
        openDeleteModal,
        openEditFornitoreModal,
        onCreateGroup,
        onUpdateGroup,
        onDeleteGroup,
        entityModalId,

        //FORNITORI
        fornitori,
        onCreateFornitori,
        onUpdateFornitori,
        onDeleteFornitori,
        toggleAssociateBU
    };
};
