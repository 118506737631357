import { requestPostOptions } from "../crud";

const BaseUrl: string = process.env.REACT_APP_BASE_URL_API;

export const ENDPOINT_CHECKLIST = {
    get: `${BaseUrl}/Checklist/GetChecklist`,
    add: `${BaseUrl}/Checklist/ResponseChecklist`,
    delete: `${BaseUrl}/Problemi/DeleteProblematicaMassiva`,
    deleteProblematic: `${BaseUrl}/Problemi/DeleteProblemi`,
    moveProblematic: `${BaseUrl}/Problemi/MoveProblemi`,
    insert: `${BaseUrl}/Problemi/InsertProblemi`,
    update: `${BaseUrl}/Problemi/UpdateProblemi`,
}

export const requestGetChecklist = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch( ENDPOINT_CHECKLIST.get, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result.fondo ? result : []);
    } catch (error) {
        onError(error)
    }
};
export const responseChecklist = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_CHECKLIST.add, options);
        if (promise.ok) onSuccess();
    } catch (error) {
        onError(error);
    }
};
export const deleteProblematicaMassiva = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_CHECKLIST.delete, options);
        if (promise.ok) onSuccess();
    } catch (error) {
        onError(error);
    }
};
export const deleteProblematic = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_CHECKLIST.deleteProblematic, options);
        if (promise.ok) onSuccess();
    } catch (error) {
        onError(error);
    }
};
export const insertProblematic = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_CHECKLIST.insert, options);
        if (promise.ok) onSuccess();
    } catch (error) {
        onError(error);
    }
};
export const updateProblematic = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_CHECKLIST.update, options);
        if (promise.ok) onSuccess();
    } catch (error) {
        onError(error);
    }
};
export const moveProblematic = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_CHECKLIST.moveProblematic, options);
        if (promise.ok) onSuccess();
    } catch (error) {
        onError(error);
    }
};
export const findRecordQuestionResponseFromDB = async (getAll: Function, index: number): Promise<any> => {
    const records = await getAll();
    return records.find(el => el.data.idChecklist === index)
};
export const findMoveProblematicFromDB = async (getAll: Function, idProblema: number) => {
    const records = await getAll();
    return records.find(el => el.data.idProblema === idProblema && (el.endpoint === ENDPOINT_CHECKLIST.insert || el.endpoint === ENDPOINT_CHECKLIST.moveProblematic))
};
export const filterProblematicFromDB = async (getAll: Function, idProblema: number) => {
    const records = await getAll();
    return records.filter(el => el.data.idProblema === idProblema)
};
export const findUpdatedProblematicFromDB = async (getAll: Function, idProblema: number) => {
    const records = await getAll();
    return records.find(el => el.data.idProblema === idProblema)
};