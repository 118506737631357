import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FilterParamiter } from '../Models/FilterParamiter';



export function useExportCSV(type: string) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName: string) => {
        const ws = XLSX.utils.json_to_sheet(csvData);

        // rinomino le colonne
        //if (type === "sopralluoghi")
        //{
        //    ws.A1.v = "Fondo/cliente";
        //    ws.B1.v = "Città";
        //    ws.C1.v = "Indirizzo";
        //    ws.D1.v = "Sede Tecnica/UE";
        //    ws.E1.v = "Data";
        //    ws.F1.v = "BM";
        //    ws.G1.v = "Stato";
        //}
        //else
        //{
            ws.A1.v = "Tipologia";
            ws.B1.v = "Competenza";
            ws.C1.v = "Descrizione breve";
            ws.D1.v = "STIMA MASSIMA COSTI";
            ws.E1.v = "Livello 1 Messa in Sicurezza";
            ws.F1.v = "Livello 2 Mantenimento Valore Asset";
            ws.G1.v = "Livello 3 Accrescimento Valore Asset";
        //}

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const exportToCSVWithFilter = (csvData, fileName: string, filter: FilterParamiter) => {
        const ws = XLSX.utils.json_to_sheet(csvData);

        // rinomino le colonne
        ws.A1.v = "Fondo/cliente";
        ws.B1.v = "Città";
        ws.C1.v = "Indirizzo";
        ws.D1.v = "Sede Tecnica/UE";
        ws.E1.v = "Data";
        ws.F1.v = "BM";
        ws.G1.v = "Stato";
        
        if(filter.dataPrimaIspezione !== null && filter.dataPrimaIspezione !== undefined )
            csvData = csvData.filter(x => x.dataIspezione >= filter.dataPrimaIspezione);
        if(filter.dataUltimaIspezione !== null && filter.dataUltimaIspezione !== undefined)
            csvData = csvData.filter(x => x.dataIspezione <= filter.dataUltimaIspezione);
        if(filter.fondo !== null && filter.fondo !== undefined)
            csvData = csvData.filter(x => x.fondo == filter.fondo);
        if(filter.indirizzo !== null && filter.indirizzo !== undefined)
            csvData = csvData.filter(x => x.indirizzo == filter.indirizzo);
        if(filter.stato !== null && filter.stato !== undefined)
            csvData = csvData.filter(x => x.stato == filter.stato);
        if(filter.unitaEconomica !== null && filter.unitaEconomica !== undefined)
            csvData = csvData.filter(x => x.unitaEconomica == filter.unitaEconomica);
        if(filter.utente !== null && filter.utente !== undefined) 
            csvData = csvData.filter(x => x.buildingManagerEsterno == filter.utente);
        

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return {exportToCSV,exportToCSVWithFilter}
}