import { BaseUrl } from "../../utiles";
import { requestPostOptions } from "../crud";

export const ENDPOINT_BU = {
    getBU: `${BaseUrl}/BusinessUnits/GetBusinessUnits`,
};

export const getBu = async (data: {idUtente: number}, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions(data);
    try {
        const promise = await fetch(ENDPOINT_BU.getBU, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
        else onError(promise);
    } catch (error) {
        onError(error.message)
    }
};
