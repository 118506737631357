export interface Photo {
  filepath: string;
  webviewPath: string;
  date: string;
  idImmagineProblema?: number;
  idFoto?: number;
  latitudine: number;
  longitudine: number;
}

export interface SavePhotoDTO {
  idUtente: number;
  idIspezione: number;
  didascalia: string;
  listaImmaginiIspezioni: Photo[];
}

export interface DeletePhotoDTO {
  idUtente: number;
  idFoto: number;
}

export interface EditPhotoDTO {
  idUtente: number;
  id: number;
  didascalia: string;
}

export enum StatoImmagineIspezioneEnum {
  NoInfo = 0,
  Geolocalization = 1,
  UploadTime = 2,
  AllInfo = 3,
}

export enum GeolocalizationPermitEnum {
  PERMISSION = 0,
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}
