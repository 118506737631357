import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import { HtmlTooltip } from "./Detail";
import InfoIcon from '@material-ui/icons/Info';
import SelectSearchable from "../../components/SelectSearchable/SelectSearchable";
import { useTooltip } from "../../hooks/useTooltip";
import { Controller, useFormContext } from "react-hook-form";
import { IMPIANTO_RISCALDAMENTO_STATO } from "./selectConstantsOptions";
import { useParams } from "react-router";
import { ParamsInspection } from "../../Models/ParamsInspection";
import { useEntityDetail, useEntityUser } from "../../store";
import { onChangeValue ,result} from "../../utiles";
import { useSendToApproval } from "../Checklist/useSendToApproval";
import { RoleID } from "./useResponseDetail";

const Riscaldamento: React.FC = () => {

    const { numberInspection } = useParams<ParamsInspection>();
    const dataDetail = useEntityDetail(store => store.dataDetail);
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);

    const { tooltip, closeTooltip, openTooltip, toggleTooltip } = useTooltip();

    const { control, formState:{ errors}} = useFormContext();

    const { user: currentUserParse } = useEntityUser();

    const shouldBeDisabled = dataDetail?.stato === "Approvato" || dataDetail?.stato === "In Approvazione" || currentUserParse?.profilo === RoleID.LETTORE;

    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='impiantoRiscaldamentoDescrizione'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Descrizione"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.impiantoRiscaldamentoDescrizione}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("Descrizione")}
                        onOpen={() => openTooltip("Descrizione")}
                        onClose={() => closeTooltip()}
                        open={tooltip == "Descrizione"}
                        placement="right"
                        title={
                            <p>Centralizzato/autonomo, tipologia </p>
                        }
                    >
                        <InfoIcon color="action" fontSize="small" style={{ 'verticalAlign': 'middle', 'marginLeft': '0.5rem', 'marginRight': '0px' }} />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        defaultValue={1}
                        control={control}
                        name='impiantoRiscaldamentoStato'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <SelectSearchable
                                disabled={shouldBeDisabled}
                                disableClearable={!!sendToApproval}
                                options={IMPIANTO_RISCALDAMENTO_STATO}
                                setValue={onChangeValue(onChange)}
                                value={result(IMPIANTO_RISCALDAMENTO_STATO, value)}
                                error={!!errors?.impiantoRiscaldamentoStato}
                                label="stato"
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("stato")}
                        onOpen={() => openTooltip("stato")}
                        onClose={() => closeTooltip()}
                        open={tooltip == "stato"}
                        placement="right"
                        title={
                            <p>
                                <p><span className="fw-bold">{"Stato di disponibilità"}</span><br /> {`Stato di un’entità caratterizzato dal fatto che essa possa eseguireuna funzione richiesta, partendo dal presupposto che la fornitura dirisorse esterne, eventualmente necessaria, sia assicurata.`}</p>
                                <p><span className="fw-bold">{"Stato di degrado"}</span><br /> {`Stato di un’entità in cui l’entità continua ad eseguire una funzioneentro limiti accettabili, ma minori dei valori specificati, oppure con-tinua ad effettuare solo una parte delle funzioni richieste.`}</p>
                                <p><span className="fw-bold">{"Stato dì indisponibilità"}</span><br /> {`quando sono in condizioni scadenti più di quattro elementi tra quelli indicati in tabella/elenco n.1`}</p>
                                <p><span className="fw-bold">{"Stato fuori servizio"}</span><br /> {`Stato di un’entità caratterizzato dalla sua incapacità ad eseguireuna funzione richiesta, qualunque ne sia la ragione; incapacità percause esterne.`}</p>
                                <p><span className="fw-bold">{"Stato di funzionamento"}</span><br /> {`Stato in cui un’entità esegue una funzione richiesta.`}</p>
                                <p><span className="fw-bold">{"Stato di riposo"}</span><br /> {`Stato di un’entità disponibile e in stato di non funzionamentodurante un periodo non richiesto.`}</p>
                                <p><span className="fw-bold">{"Stato di attesa"}</span><br /> {`Stato di un’entità disponibile e non funzionante durante il periodorichiesto.`}</p>
                                <p><span className="fw-bold">{"Stato di pericolo"}</span><br /> {`Stato di un’entità che è giudicato come fonte probabile di lesionialle persone, di rilevanti danni materiali o di altre conseguenzeinaccettabili.`}</p>
                            </p>
                        }
                    >
                        <InfoIcon color="action" fontSize="small" style={{ 'verticalAlign': 'middle', 'marginLeft': '0.5rem', 'marginRight': '0px' }} />
                    </HtmlTooltip>
                </IonCol>
                <IonCol sizeLg="6" sizeSm="12" sizeXs="12">
                    <Controller
                        control={control}
                        name='impiantoRiscaldamentoDocumentazione'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Documentazione"
                                value={value}
                                setValue={onChange}
                                id="documentazione-riscaldamento"
                                error={ !!errors?.impiantoRiscaldamentoDocumentazione}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("Documentazione")}
                        onOpen={() => openTooltip("Documentazione")}
                        onClose={() => closeTooltip()}
                        open={tooltip == "Documentazione"}
                        placement="right"
                        title={
                            <p>Presente/non presente, completa/incompleta</p>
                        }
                    >
                        <InfoIcon color="action" fontSize="small" style={{ 'verticalAlign': 'middle', 'marginLeft': '0.5rem', 'marginRight': '0px' }} />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='impiantoRiscaldamentoManutenzionePeriodica'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Manutenzione periodica"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.impiantoRiscaldamentoManutenzionePeriodica}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("periodica")}
                        onOpen={() => openTooltip("periodica")}
                        onClose={() => closeTooltip()}
                        open={tooltip == "periodica"}
                        placement="right"
                        title={
                            <p>Indicare se effettuata regolarmente o se sono presenti problematiche</p>
                        }
                    >
                        <InfoIcon color="action" fontSize="small" style={{ 'verticalAlign': 'middle', 'marginLeft': '0.5rem', 'marginRight': '0px' }} />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Controller
                        control={control}
                        name='impiantoRiscaldamentoContrattiManutenzione'
                        rules={{ required: !!sendToApproval }}
                        render={({ field: { onChange, value, } }) => 
                            <CustomInput
                                disabled={shouldBeDisabled}
                                label="Contratti manutenzione"
                                value={value}
                                setValue={onChange}
                                error={ !!errors?.impiantoRiscaldamentoContrattiManutenzione}
                            />
                        }
                    />
                    <HtmlTooltip
                        onClick={() => toggleTooltip("manutenzione")}
                        onOpen={() => openTooltip("manutenzione")}
                        onClose={() => closeTooltip()}
                        open={tooltip == "manutenzione"}
                        placement="right"
                        title={
                            <p>Indicare se è presente un contratto di manutenzione e nominativo ditta</p>
                        }
                    >
                        <InfoIcon color="action" fontSize="small" style={{ 'verticalAlign': 'middle', 'marginLeft': '0.5rem', 'marginRight': '0px' }} />
                    </HtmlTooltip>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default Riscaldamento