import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import base64 from "react-native-base64";
import { UserDb } from "./Models/UserDb";
import { Users } from "./Models/Users";
import { GetGroupDTO } from "./Models/Group";
import { Fornitore } from "./Models/Fornitori";

export const dateNow = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let output = year + "-";
  if (month > 9) output += month + "-";
  else output += `0${month}-`;
  if (day > 9) output += day;
  else output += `0${day}`;
  return output;
};

export const oneYearFromNow = () => {
  let date = new Date();
  let year = date.getFullYear() - 1;
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let output = year + "-";
  if (month > 9) output += month + "-";
  else output += `0${month}-`;
  if (day > 9) output += day;
  else output += `0${day}`;
  return output;
};

export const dateNowForPhoto = (currentDate?: Date) => {
  let date = currentDate || new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let output = "" as string;
  if (day > 9) output += day + "_";
  else output += `0${day}_`;
  if (month > 9) output += month + "_";
  else output += `0${month}_`;

  output += year + "-";
  output += `${
    date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds()
  }`;

  return output;
};
export const checkFormatDate = (date: string) => {
  const [firstpart, secondpart] = date.split(" ");
  const hasColon = /:/.test(firstpart);

  if (hasColon) {
    const reformat = firstpart.replaceAll(":", "/");
    return `${reformat} ${secondpart}`;
  }
  const hasCorrectDate = /^\d{4}\/\d{1,2}\/\d{1,2}$/.test(firstpart);

  if (!hasCorrectDate) {
    const [day, month, year] = firstpart.split("/");
    return `${year}/${month}/${day} ${secondpart}`;
  }

  return date;
};
export const formatDate = (currentDate?: string) => {
  const validatedDate = checkFormatDate(currentDate);
  const date = new Date(validatedDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDate = `${day > 9 ? day : "0" + day}/${
    month > 9 ? month : "0" + month
  }/${year} ${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }`;

  return formattedDate;
};
//style for datapiker
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginTop: "0.5rem !important",
      width: "100%",
    },
  })
);

export const stringIsNullOrEmpty = (val: string | null | undefined) =>
  val === "" || val == null || val == undefined;
export const numberIsNull = (val: number | null | undefined) =>
  val <= 0 || val == null || val == undefined;

export const base64Encoding = (input: string) => {
  var encoded = base64.encode(input);
  return encoded;
};

export const initialStateCurrentUser = (currentUserParse: UserDb) =>
  currentUserParse && {
    id: Number(currentUserParse.id),
    nome: currentUserParse.cognome + " " + currentUserParse.nome,
  };

export const BaseUrl: string = process.env.REACT_APP_BASE_URL_API;
export const BaseUrlHome: string = process.env.REACT_APP_BASE_URL_API;

export const role = (user: UserDb) => {
  if (user.profilo === 1) {
    return "Amministratore";
  } else if (user.profilo === 2) {
    return "Building Manager";
  } else if (user.profilo === 3) {
    return "Approvatore";
  } else if (user.profilo === 4) {
    return "Building Manager Esterno";
  } else if (user.profilo === 5) {
    return "Lettore";
  } else if (user.profilo === 6) {
    return "Amministratore BU";
  } else if (user.profilo === 7) {
    return "Back Office";
  }
};

export const roleForSettings = (user: Users) => {
    if (user.profiloID === 1) {
        return "Amministratore";
    } else if (user.profiloID === 2) {
        return "<b>Building Manager</b>";
    } else if (user.profiloID === 3) {
        return "Approvatore";
    } else if (user.profiloID === 4) {
        return "Building Manager Esterno";
    } else if (user.profiloID === 5) {
        return "Lettore";
    } else if (user.profiloID === 6) {
        return "Amministratore BU";
    } else if (user.profiloID === 7) {
        return "BackOffice"
    }
}

export const validEmail = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
);

export const result = (options, value) =>
  options.find((el) => el?.id === value) || null;
export const onChangeValue = (onChange) => (newValue) =>
  onChange(newValue?.id ?? null);

export const isUserValid = (user: Users, textToFilter: string) =>
  user?.nome.toLowerCase().includes(textToFilter?.toLowerCase())
  || user?.cognome.toLowerCase().includes(textToFilter?.toLowerCase())
  || user?.mail.toLowerCase().includes(textToFilter?.toLowerCase())
  || user?.gruppo?.toLowerCase().includes(textToFilter?.toLowerCase())
  || (user?.nome.toLowerCase() + ' ' + user?.cognome.toLowerCase()).includes(textToFilter?.toLowerCase())
  || (user?.cognome.toLowerCase() + ' ' + user?.nome.toLowerCase()).includes(textToFilter?.toLowerCase());

export const isGroupValid = (group: GetGroupDTO, textToFilter: string) =>
  group?.nome.toLowerCase().includes(textToFilter?.toLowerCase())
  || group?.listaUtenti?.some(user => 
      user?.nome.toLowerCase().includes(textToFilter?.toLowerCase())
      || user?.cognome.toLowerCase().includes(textToFilter?.toLowerCase())
      || user?.mail.toLowerCase().includes(textToFilter?.toLowerCase())
      || (user?.nome.toLowerCase() + ' ' + user?.cognome.toLowerCase()).includes(textToFilter?.toLowerCase())
      || (user?.cognome.toLowerCase() + ' ' + user?.nome.toLowerCase()).includes(textToFilter?.toLowerCase())
  )

export const isFornitoreGroupValid = (fornitore: Fornitore, textToFilter: string) =>
  fornitore?.nomeFornitore.toLowerCase().includes(textToFilter?.toLowerCase())
  || fornitore?.listaUtenti?.some(user => 
      user?.nome.toLowerCase().includes(textToFilter?.toLowerCase())
      || user?.cognome.toLowerCase().includes(textToFilter?.toLowerCase())
      || user?.mail.toLowerCase().includes(textToFilter?.toLowerCase())
      || (user?.nome.toLowerCase() + ' ' + user?.cognome.toLowerCase()).includes(textToFilter?.toLowerCase())
      || (user?.cognome.toLowerCase() + ' ' + user?.nome.toLowerCase()).includes(textToFilter?.toLowerCase())
  )

export function isAssociateWCurrentUserBU(fornitore:Fornitore, currentUserBuId:number | undefined, asBUId:number | null) {
    if(asBUId) return fornitore.buAssociate.includes(asBUId) 
    if(currentUserBuId) return fornitore.buAssociate.includes(currentUserBuId)
    
    return false
}