import { IonGrid, IonRow, IonCol, IonLabel } from "@ionic/react";
import { Controller, useFormContext } from "react-hook-form";
import { DateInput } from "../../components/DateInput";
import SelectSearchable from "../../components/SelectSearchable/SelectSearchable";
import { useEntityHomepage } from "../../store";
import { onChangeValue, result, useStyles } from "../../utiles";

const FormFilters: React.FC = ({children}) => {

    const classesDatepicker = useStyles();
    const dataFilterOption = useEntityHomepage(store => store.dataFilterOption);
    const { control } = useFormContext();

    return(
        <>
            <IonGrid className="filter p-3">
                <IonRow>
                    <IonCol sizeMd="6"  size='12'>
                    <Controller
                        defaultValue={0}
                        control={control}
                        name='fondo'
                        render={({ field: { onChange, value, } }) => 
                            <SelectSearchable
                                options={dataFilterOption.listaFondi}
                                setValue={onChangeValue(onChange)}
                                value={result(dataFilterOption.listaFondi, value)}
                                label="Fondo"
                            />
                        }
                    />
                    </IonCol>
                    <IonCol sizeMd="6" size="12">
                        {dataFilterOption.listaIndirizzi.length && (
                            <Controller
                                defaultValue={0}
                                control={control}
                                name='indirizzo'
                                render={({ field: { onChange, value, } }) => 
                                    <SelectSearchable
                                        options={dataFilterOption.listaIndirizzi}
                                        setValue={onChangeValue(onChange)}
                                        value={result(dataFilterOption.listaIndirizzi, value)}
                                        label="Indirizzo"
                                    />
                                }
                            />
                        )}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" sizeMd='auto' className='flex-grow'>
                        {dataFilterOption.listaStati.length && (
                            <Controller
                                defaultValue={0}
                                control={control}
                                name='stato'
                                render={({ field: { onChange, value, } }) => 
                                    <SelectSearchable
                                        options={dataFilterOption.listaStati}
                                        setValue={onChangeValue(onChange)}
                                        value={result(dataFilterOption.listaStati, value)}
                                        label="Stato"
                                    />
                                }
                            />
                        )}
                    </IonCol>

                    <IonCol size="12" sizeMd='auto' className='flex-grow'>
                        {dataFilterOption.listaUnitaEconomica.length && (
                            <Controller
                                defaultValue={0}
                                control={control}
                                name='unitaEconomica'
                                render={({ field: { onChange, value, } }) => 
                                    <SelectSearchable
                                        options={dataFilterOption.listaUnitaEconomica}
                                        setValue={onChangeValue(onChange)}
                                        value={result(dataFilterOption.listaUnitaEconomica, value)}
                                        label="Sede Tecnica/UE"
                                    />
                                }
                            />
                        )}
                    </IonCol>
                    <IonCol size="12" sizeMd='auto' className='flex-grow'>
                        {dataFilterOption.listaUtenti.length && (
                            <Controller
                                defaultValue={0}
                                control={control}
                                name='utente'
                                render={({ field: { onChange, value, } }) => 
                                    <SelectSearchable
                                        options={dataFilterOption.listaUtenti}
                                        setValue={onChangeValue(onChange)}
                                        value={result(dataFilterOption.listaUtenti, value)}
                                        label="Assegnatario"
                                    />
                                }
                            />
                        )}
                    </IonCol>
                    <IonCol size="12" sizeMd='auto' className='flex-grow'>
                        {dataFilterOption.dataPrimaIspezione && (
                            <>
                                <IonLabel className="label p-0 ion-text-capitalize">Da</IonLabel>
                                <Controller
                                    defaultValue={dataFilterOption.dataPrimaIspezione}
                                    control={control}
                                    name='dataPrimaIspezione'
                                    render={({ field: { onChange, value, } }) => 
                                        <DateInput
                                            id="date"
                                            type="date"
                                            className={classesDatepicker.textField}
                                            variant="outlined"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    }
                                />
                            </>
                        )}
                    </IonCol>
                    <IonCol size="12" sizeMd='auto' className='flex-grow'>
                        {dataFilterOption.dataUltimaIspezione && (
                            <>
                                <IonLabel className="label p-0 ion-text-capitalize">a</IonLabel>
                                <Controller
                                    defaultValue={dataFilterOption.dataUltimaIspezione}
                                    control={control}
                                    name='dataUltimaIspezione'
                                    render={({ field: { onChange, value, } }) => 
                                        <DateInput
                                            id="date"
                                            type="date"
                                            className={classesDatepicker.textField}
                                            variant="outlined"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    }
                                />
                            </>
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>
            {children}
        </>
    );
};

export default FormFilters;