import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';

interface DateInputProps extends OutlinedTextFieldProps {
    onChange?(event: any): void;
}
export const DateInput = (props: DateInputProps) => {
    return (
        <TextField
            {...props}
        />
    )
}
