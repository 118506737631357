import { useCallback, useState } from 'react';
import { FieldValues, UseFormReset } from 'react-hook-form';
import { SelectedSettingType, SettingFormModalType } from './useSettingsFormHook';

export const useAddEditSettingsModal = ({ resetFormDataFn }: { resetFormDataFn: UseFormReset<FieldValues> }) => {
  const [modal, setModal] = useState<SettingFormModalType>({ type: SelectedSettingType.user, isOpen: false, isDeleting: undefined });

  const openModal = useCallback(() => setModal(m => ({ ...m, isOpen: true })), []);

  const closeAddEditModal = useCallback(() => {
      setModal(m => ({ ...m, isOpen: false }));
      setTimeout(() => resetFormDataFn({}), 250);
  }, [resetFormDataFn]);

  const openAddEditModal = useCallback((data?: object) => {
    if(data) resetFormDataFn(data);
    openModal();
  }, [openModal, resetFormDataFn]);

  return {
    modal,
    openAddEditModal,
    closeAddEditModal,
    setModal
  }
}
