import { IonCard, IonCol, IonFabButton, IonIcon, IonRow } from '@ionic/react';
import { linkOutline, pencilOutline, trashOutline, unlinkOutline } from 'ionicons/icons';
import { ShowIf } from '../../../components/ShowIf';
import { Fornitore } from '../../../Models/Fornitori';
import { useEntityUser } from '../../../store';
import { RoleID } from '../../Detail/useResponseDetail';
import { isAssociateWCurrentUserBU } from '../../../utiles';

export interface RowGroupProps {
    el: Fornitore;
    asBuId?: number;
    onUpdate(): void;
    onDelete(): void;
    toggleAssociateBU(): void
}

const linkIcon = linkOutline.replaceAll("Link", "Associa");
const unLinkIcon = unlinkOutline.replaceAll("Unlink", "Disassocia");

export const RowFornitori: React.FC<RowGroupProps> = ({ el, asBuId, onUpdate, onDelete, toggleAssociateBU }) => {
    const { user: currentUserParse } = useEntityUser();
    const isAmministratoreSistema = currentUserParse?.profilo === RoleID.AMM_SIST;
    const isAmministratoreBU = currentUserParse?.profilo === RoleID.AMM_BU;
    const canUserEditFornitore = isAmministratoreSistema || (isAmministratoreBU && el.buAssociate.includes(currentUserParse.businessUnitId))

    return (
        <>
            <IonRow>
                <IonCol>
                    <IonCard color="light" className="pb-3">
                        <IonRow className="mb-0 ion-justify-content-between">
                            <IonCol className="ps-3" size="3">                             
                                <h4>{el.nomeFornitore}</h4>
                            </IonCol>
                            { currentUserParse?.profilo === RoleID.LETTORE ? "" :
                            <IonCol size="4" className="ion-align-self-start">
                                <ShowIf condition={currentUserParse.profilo === RoleID.AMM_BU || (currentUserParse.profilo === RoleID.AMM_SIST && !!asBuId)}>
                                    <IonFabButton
                                        size="small"
                                        className="ion-float-end"
                                        onClick={toggleAssociateBU}
                                    >
                                        <IonIcon icon={isAssociateWCurrentUserBU(el, currentUserParse.businessUnitId, asBuId) ? unLinkIcon : linkIcon} />
                                    </IonFabButton>
                                </ShowIf>

                                <ShowIf condition={canUserEditFornitore}>
                                    <>
                                        <IonFabButton
                                            size="small"
                                            className="ion-float-end"
                                            onClick={onUpdate}
                                            >
                                            <IonIcon icon={pencilOutline} />
                                        </IonFabButton>
                                        <IonFabButton
                                            color="danger"
                                            size="small"
                                            className="ion-float-end"
                                            onClick={onDelete}
                                        >
                                            <IonIcon icon={trashOutline} />
                                        </IonFabButton>
                                    </>
                                </ShowIf>

                            </IonCol>
                            }
                        </IonRow>
                        <IonRow className="mx-3">
                            <IonCol size="3" className="ps-3">
                                <h6>Nome e Cognome</h6>
                            </IonCol>
                            <IonCol size="4" className="ps-3">
                                <h6>Email</h6>
                            </IonCol>
                            <IonCol size="4" className="ps-3">
                                <h6>Ruolo</h6>
                            </IonCol>
                            <IonCol size="1" className="ps-3">
                                <h6>Resp.</h6>
                            </IonCol>
                        </IonRow>
                        {el.listaUtenti.map(val => (
                            <IonRow className="ion-align-items-center bg-list-white-group mx-3" key={val.id}>
                                <IonCol size="3" className="ps-3">
                                    <p>{val.nome + " " + val.cognome}</p>
                                </IonCol>
                                <IonCol size="4" className="ps-3">
                                    <p>{val?.mail?.toLowerCase()}</p>
                                </IonCol>
                                <IonCol size="4" className="ps-3">
                                    {val?.profiloID === 2 
                                        ? <b>{val?.profilo}</b>
                                        : <p>{val?.profilo}</p>
                                    }
                                </IonCol>
                                <IonCol size="1" className="ps-3">
                                    <p>{val?.isOwner ? "SI" : "NO"}</p>
                                </IonCol>
                            </IonRow>
                        ))}
                    </IonCard>
                </IonCol>
            </IonRow >
        </>
    )
}
