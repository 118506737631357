import { Checklist } from "./Models/Checklist";
import { DataDetail } from "./Models/DataDetail";
import { IExportReport } from "./Models/ExportReport";
import { Filter } from "./Models/Filter";
import { Gallery } from "./Models/Gallery";
import { NoteAudio } from "./Models/NoteAudio";
import { Report } from "./Models/Report";
import { Option } from "./Models/Checklist";
import { Users } from "./Models/Users";

export function EmptyFilter(): Filter {
    return {
        listaIndirizzi: [],
        listaStati: [],
        listaFondi: [],
        listaUtenti: [],
        listaUnitaEconomica: [],
        listaCitta: [],
        listaCittaIndirizzo: [],
        dataPrimaIspezione: undefined,
        dataUltimaIspezione: undefined,
        risultati: 12,
        pagina: 1,
    }
}
export function EmptyDataDetail(): DataDetail {
    return {
        id: -1,
        immagineSave: [],
        salvataggio: false,
        immagineUrl: "",
        fondo: "",
        tipologiaUe: "",
        tipologiaUeID: -1,
        dettaglioUE: "",
        valoreDebitoResiduo: 0,
        statoOccupazione: "",
        statoOccupazioneID: -1,
        impiantoRiscaldamentoDescrizione: "",
        impiantoRiscaldamentoStato: "",
        impiantoRiscaldamentoStatoID: -1,
        impiantoElettricoDescrizione: "",
        impiantoElettricoStato: "",
        impiantoElettricoStatoID: -1,
        impiantiElevatoriDescrizione: "",
        impiantiElevatoriStato: "",
        impiantiElevatoriStatoID: -1,
        impiantiAntincendioDescrizione: "",
        impiantiAntincendioStato: "",
        impiantiAntincendioStatoID: -1,
        statoManutentivoGenerale: "",
        statoManutentivoGeneraleID: -1,
        area: -1,
        citta: "",
        foglio: "",
        indirizzo: "",
        mappale: "",
        percentualeOccupazione: null,
        subalterno: "",
        usoPrevalente: "",
        stato: "",
        areeEsterne: "",
        boxAutoCoperti: null,
        contatto: "",
        copertura: "",
        denominazione: "",
        descrizioneSommaria: "",
        descrizioneSommariaAudioUrl: null,
        facciate: "",
        impiantiAntincendioContrattiManutenzione: "",
        impiantiAntincendioDocumentazione: "",
        impiantiAntincendioManutenzionePeriodica: "",
        impiantiElevatoriContrattiManutenzione: "",
        impiantiElevatoriDocumentazione: "",
        impiantiElevatoriManutenzionePeriodica: "",
        impiantoElettricoContrattiManutenzione: "",
        impiantoElettricoDocumentazione: "",
        impiantoElettricoManutenzionePeriodica: "",
        impiantoRiscaldamentoContrattiManutenzione: "",
        impiantoRiscaldamentoDocumentazione: "",
        impiantoRiscaldamentoManutenzionePeriodica: "",
        indirizzoCondominio: "",
        mail: "",
        nomeCognome: "",
        note: "",
        numeroAccessiEsterno: null,
        numeroAscensori: null,
        numeroVaniScala: null,
        portineria: "",
        postiAutoCoperti: null,
        postiAutoScoperti: null,
        presenzaCartelloRecapiti: "",
        presenzaCondominio: null,
        struttura: "",
        studioSocieta: "",
        telefono: "",
        terrazzeLoggeBalconi: "",
        tipo: "",
        vigilanza: null,
        utenzeAttive_Elettrico: null,
        utenzeAttive_Gas: null,
        utenzeAttive_Idrico: null,
        utenzeAttive_Termico: null,
        areaRecintata: null,
        pianiFuoriTerra: null,
        pianiInterrati: null,
        autorizzazione: null,
        stessoGruppo: null,
        language: "",
        dataIspezione: "",
        bm: "",
        unitaEconomica: ""
    }
}
export function EmptyAddPhoto(): Gallery {
    return {
        fondo: "",
        stato: null,
        listaImmagini: undefined
    }
}

export function EmptyDataChecklist(): Checklist {
    return {
        fondo: "",
        stato: "",
        listaTab: [{
            idNome: -1,
            nome: "",
            domande: [{
                idDomanda: -1,
                idRisposta: -1,
                ordinamento: "",
                descrizione: "",
                presenzaRisposte: null,
                visualizzaAggiungi: null,
                problemi: [{
                    competenza: "",
                    competenzaID: -1,
                    livelloInterventoID: -1,
                    descrizione: "",
                    descrizioneAudioUrl: "",
                    didascalia: "",
                    idDomanda: -1,
                    intervento: "",
                    listaImmaginiProblemi: [{
                        filepath: null,
                        date: null,
                        webviewPath: "",
                        latitudine: -1,
                        longitudine: -1
                    }],
                    livelloIntervento: "",
                    localizzazione: "",
                    possibiliCause: "",
                    stimaCosti: "",
                    tipologiaInterventoID: -1,
                    tipologiaIntervento: "",
                    idProblema: -1,
                    progressivo: -1,
                    immagineUrl: ""
                }]
            }],
        }],

    }
}

export function EmptyApprovers(): Option[] {
    return [{
        id: -1,
        nome: ""
    }]
}

export function EmptyGetUsers(): Users[] {
    return [{
        id: -1,
        nome: "",
        cognome: "",
        gruppo: "",
        mail: "",
        profiloID: -1
    }]
}

export function EmptyRecAudio(): NoteAudio {
    return {
        url: null,
        blob: null,
        chunks: null,
        duration: {
            h: 0,
            m: 0,
            s: 0
        }
    }
}

export function EmptyReport(): Report {
    return {
        fondo: "",
        stato: "",
        stessoGruppo: false,
        pdfUrl: null,
        rischiSpecificiUrl: null,
        reportClienteUrl: null,
        listaProblemi: [],
        assegnatarioID: -1,
        creatoreID: -1,
        approvatoreID: null
    }
}

export function EmptyExportReport(): IExportReport {
    return {
        tipologia: "",
        competenza: "",
        descrizioneBreve: "",
        stimaMassimaCosti: 0,
        livello1: 0,
        livello2: 0,
        livello3: 0
    }
}