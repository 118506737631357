import { GetAssegnatariForProgetto, GetCreatoriForProgetto } from "../../Models/DataDetail";
import { RequestDTO } from "../../Models/RequestDTO";
import { ResponseForApproval } from "../../Models/ResponseForApproval";
import { handleQuery, requestPostOptions } from "../crud";

const BaseUrl: string = process.env.REACT_APP_BASE_URL_API; 

export const ENDPOINT_DETAILS = {
    saveSopralluogo: `${BaseUrl}/Dati/SaveSopralluogo`,
    getSopralluogo: `${BaseUrl}/Dati/GetSopralluogo`,
    disableSopralluogo: `${BaseUrl}/Sopralluoghi/DisableSopralluogo`,
    deleteCopertina: `${BaseUrl}/Dati/DeleteCopertina`,
    exportCSV: `${BaseUrl}/Sopralluoghi/ExportSopralluoghi`,
    GetUtentiAssegnabiliSopralluogo: `${BaseUrl}/Sopralluoghi/GetUtentiAssegnabiliSopralluogo`,
};

export const saveSopralluogo = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_DETAILS.saveSopralluogo, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const getSopralluogo = async (data: RequestDTO, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<RequestDTO>(data);
    const query = handleQuery({idIspezione: data.idIspezione});
    try {
        const promise = await fetch(`${ENDPOINT_DETAILS.getSopralluogo}?${query}`, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const sendToRiassegna = async <T>(data: T, onSuccess: Function, onError: Function ) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(`${BaseUrl}/Sopralluoghi/RiassegnaSopralluogo`, options);
        const response: ResponseForApproval = await promise.json();
        if (promise.ok) onSuccess(response);
    } catch (error) {
        onError(error)
    }
}

export const disableSopralluogo = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_DETAILS.disableSopralluogo, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const deleteCopertina = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_DETAILS.deleteCopertina, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const exportCSV = async <T>(data: T, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<T>(data);
    try {
        const promise = await fetch(ENDPOINT_DETAILS.exportCSV, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};

export const findRecordDetailFromDB = async (getAll: Function, index: number): Promise<any> => {
    const records = await getAll();
    return records?.find(el =>
        el.data.idIspezione === index && el.endpoint === ENDPOINT_DETAILS.saveSopralluogo)
    };

export const getAssegnatariForFornitore = async (data: GetAssegnatariForProgetto, onSuccess: Function, onError: Function) => {
    const options = requestPostOptions<GetAssegnatariForProgetto>(data);
    try {
        const promise = await fetch(ENDPOINT_DETAILS.GetUtentiAssegnabiliSopralluogo, options);
        const result = await promise.json();
        if (promise.ok) onSuccess(result);
    } catch (error) {
        onError(error.message)
    }
};