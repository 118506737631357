import {  createStyles,  makeStyles, Theme, ThemeProvider, withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import { countInvalidQuestions } from "../../../service/countInvalidQuestions";
import { useParams } from "react-router";
import { ParamsInspection } from "../../../Models/ParamsInspection";
import { useSendToApproval } from "../useSendToApproval";
import { createTheme } from '@material-ui/core/styles'
interface StyledTabProps {
    label: string;
    style: object;
}

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "1rem",
            overflow: "visible",
            textTransform: "none",
            minWidth: 72,
            fontWeight: theme.typography.fontWeightBold,
            marginRight: theme.spacing(4),
            color: "rgba(0, 0, 0, 0.54)",
            fontFamily: ["-apple-system, 'Montserrat', sans-serif"].join(","),
            "&:hover": {
                color: "#33A7B2",
                opacity: 1,
            },
            "&$selected": {
                color: "#33A7B2",
                fontWeight: theme.typography.fontWeightBold,
            },
            "&:focus": {
                color: "#33A7B2",
            },
        },
        selected: {},
    })
)((props: StyledTabProps) => (
    <Tab disableRipple {...props} style={props.style} />
));

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent",
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#33A7B2",
        },
        secondary: {
            main: "#eb445a",
        },
    },
});

const a11yProps = (index: number) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};
export const ChecklistTabs = ({ valueTab, onChange, listTab, isInvalidQuestion}) => {
    const classes = useStyles();
    const { numberInspection } = useParams<ParamsInspection>();
    const sendToApproval: undefined | boolean = useSendToApproval(numberInspection);
    return <ThemeProvider theme={theme}>
        <AppBar
            position="static"
            color="transparent"
            style={{ boxShadow: "none" }}
        >
            <Tabs
                value={valueTab}
                onChange={onChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                textColor={isInvalidQuestion ? "secondary" : "primary"}
                indicatorColor={isInvalidQuestion ? "secondary" : "primary"}
            >
                {listTab?.map((el) => {
                    const isInvalid = sendToApproval && countInvalidQuestions(el.domande);
                    return (
                        <AntTab
                            key={el.idNome}
                            {...a11yProps(el.idNome)}
                            classes={classes}
                            style={isInvalid ? { color: "#eb445a" } : {}}
                            label={isInvalid ? el.nome + " [" + isInvalid + "]" : el.nome}>
                        </AntTab>
                    );
                })}
            </Tabs>
        </AppBar>
    </ThemeProvider>;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}
