import { useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import { useParams } from "react-router";
import { ParamsInspection } from "../../../Models/ParamsInspection";
import { deleteProblematic, ENDPOINT_CHECKLIST, filterProblematicFromDB } from "../../../service/api";
import { useEntityUser, useEntityConnectivity, useEntityChecklist } from "../../../store";


export const useProblematicHook = (question) => {
    const { id, numberInspection } = useParams<ParamsInspection>()
    const { user: currentUserParse } = useEntityUser();
    const { connectivity: isOnline } = useEntityConnectivity();
    const [idProblematic, setIdProblematic] = useState<number>();
    const { add, getAll, deleteRecord } = useIndexedDB('checklist');
    const {refreshChecklist, removeProblematic} = useEntityChecklist(({refreshChecklist, removeProblematic}) => ({refreshChecklist, removeProblematic}));
    const setCallFetchGetChecklist = () => refreshChecklist({
        idImmobile: +id,
        idIspezione: +numberInspection,
        idUtente: +currentUserParse?.id
    })

    const [modalDelete, setModalDelete] = useState<boolean>(false);

    const cancelProblematic = () => {
        setModalDelete(false);
        setIdProblematic(null);
        const data = {
            idUtente: Number(currentUserParse.id),
            idProblema: idProblematic,
        };

        isOnline
            ? deleteProblematic(data, setCallFetchGetChecklist, console.error)
            : deleteOfflineProblematic(data)
    };
    const deleteOfflineProblematic = async (data) => {
        const records = await filterProblematicFromDB(getAll, data.idProblema);
        const promises = records.map(async ({id}) => await deleteRecord(id));
        Promise.allSettled([
            ...promises,
        ]).finally(() => {
            add({ endpoint: ENDPOINT_CHECKLIST.deleteProblematic, data });
            const { idProblema } = data;
            removeProblematic({ idProblema, idDomanda: question.idDomanda })
        })
    }
    return {
        idProblematic,
        setIdProblematic,
        currentUserParse,
        modalDelete,
        setModalDelete,
        setCallFetchGetChecklist,
        cancelProblematic
    }
}
