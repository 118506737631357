import { IonRow, IonCol, IonIcon } from "@ionic/react"
import { menuOutline } from "ionicons/icons"
import Badge from "../../../components/Badge/Badge"


export const ChecklistHeader = ({showSidebar, handleShowSidebar, dataChecklist:{stato, fondo}}) => {
    return(
        <IonRow className="ion-justify-content-end">
            <IonCol>
                {showSidebar && (
                    <IonIcon
                        icon={menuOutline}
                        className="pointer me-1 fs-5"
                        onClick={() => handleShowSidebar()}
                        color="primary"
                    />
                )}
                <h1 className="my-0">{fondo}</h1>
                <p>Checklist e problematiche</p>
            </IonCol>
            <IonCol className="ion-text-end">
                <div>
                    {/* entrambi */}
                    {stato && (
                        <Badge
                            className="badge-report"
                            stato={stato}
                        />
                    )}
                </div>
            </IonCol>
        </IonRow>
    )
}